import React from 'react';
import { Button } from 'react-bootstrap';

import { ILinkButtonProps } from './link_button.types';
import styles from './link_button.module.scss';

const LinkButton: React.FC<ILinkButtonProps> = ({ children, disabled = false, className, onClick }) => {
  const buttonStyles = [styles.button];

  if (className) {
    buttonStyles.push(className);
  }

  return (
    <Button className={buttonStyles.join(' ')} variant="link" disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  );
};

export default LinkButton;
