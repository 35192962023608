import React, { useContext, useEffect } from 'react';

import Footer from 'components/footer';
import Header from 'components/header';
import PropertyAdditionalInfoSection from 'components/property_additional_info_section';
import PropertyFacilitiesSection from 'components/property_facilities_section';
import PropertyInfoSection from 'components/property_info_section';
import PropertyRatesSection from 'components/property_rates_section';
import Loading from 'components/loading';
import MapSection from 'components/map_section';
import PhotoGallery from 'components/photo_gallery';
import SearchSection from 'components/search_section';

import { AppActionsContext, BookingActionsContext, BookingDataContext } from 'containers/data_context';

import { IBestOfferParams } from './property_page.types';

import styles from './property_page.module.scss';

const PropertyPage: React.FC = () => {
  const { property, channelId, params, propertyType } = useContext(BookingDataContext);
  const bookingActions = useContext(BookingActionsContext);
  const { init } = useContext(AppActionsContext);
  const { data: propertyData, isLoading } = property;
  const isPropertyPresent = propertyData && !isLoading;
  const { currency, checkinDate, checkoutDate, pickupTime, dropoffTime } = params;

  useEffect(
    function initApp() {
      init(bookingActions);
    },
    [bookingActions, init],
  );

  useEffect(
    function loadClosedDates() {
      bookingActions.loadClosedDates(channelId, propertyType);
    },
    [channelId, bookingActions],
  );

  useEffect(
    function loadBestOffer() {
      if (currency && channelId && checkinDate && checkoutDate) {
        const requestParams: IBestOfferParams = {
          currency,
          checkinDate,
          checkoutDate,
        };

        if (pickupTime) {
          requestParams.pickupTime = pickupTime;
        }

        if (dropoffTime) {
          requestParams.dropoffTime = dropoffTime;
        }

        bookingActions.loadBestOffer(channelId, propertyType, requestParams);
      }
    },
    [bookingActions, channelId, currency, checkinDate, checkoutDate],
  );

  if (!isPropertyPresent) {
    return <Loading />;
  }

  const isPhotosPresent = Boolean(propertyData.photos.length);

  return (
    <div className={styles.root}>
      <Header property={propertyData} />
      {isPhotosPresent && (
        <div className={styles.photoGalleryWrapper}>
          <PhotoGallery photos={propertyData.photos} />
        </div>
      )}

      <div className={styles.floatingSearchContainer}>
        <SearchSection propertyType={params.type} />
        <PropertyInfoSection property={propertyData} />
        <PropertyRatesSection property={propertyData} propertyType={params.type} />
        <PropertyFacilitiesSection property={propertyData} />
        <PropertyAdditionalInfoSection property={propertyData} />
      </div>

      <MapSection property={propertyData} />
      <Footer property={propertyData} />
    </div>
  );
};

export default PropertyPage;
