// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Row, Col } from 'antd';

import { IGiftCampaignRecipientFormProps } from './gift_campaign_recipient_form.types';
import styles from './gift_campaign_recipient_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 } },
  wrapperCol: { xs: { span: 24 } },
};

const initialValues = {
  email: '',
  name: '',
  governmentIssuedId: '',
};

const GiftCampaignRecipientForm: React.FC<IGiftCampaignRecipientFormProps> = ({ onSave, onCancel }) => {
  const { t } = useTranslation();

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const requiredRules = [{ required: true, message: t('gift_campaign.required') }];

  const [form] = Form.useForm();

  const handleSave = async () => {
    const updatedValues = form.getFieldsValue(true);

    try {
      setIsSaving(true);
      await onSave(updatedValues);
    } catch (e) {
      console.error(e);
    } finally {
      setIsSaving(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <div data-testid="GiftCampaignRecipientForm" className={styles.root}>
      <Form form={form} initialValues={initialValues} onFinish={handleSave} {...formItemLayout} layout="vertical">
        <Row justify="space-between">
          <Col sm={11} xs={24}>
            <Form.Item label={t('gift_campaign.name')} name="name" rules={requiredRules}>
              <Input placeholder={t('gift_campaign.name')} />
            </Form.Item>
          </Col>
          <Col sm={11} xs={24}>
            <Form.Item
              label={t('gift_campaign.email')}
              name="email"
              rules={[
                { required: true, message: t('gift_campaign.required') },
                { type: 'email', message: t('general.email.valid_message') },
              ]}
            >
              <Input placeholder={t('gift_campaign.email')} />
            </Form.Item>
          </Col>
          <Col sm={11} xs={24}>
            <Form.Item label={t('gift_campaign.government_issued_id')} name="governmentIssuedId" rules={requiredRules}>
              <Input placeholder={t('gift_campaign.government_issued_id')} type="number" />
            </Form.Item>
          </Col>
        </Row>

        <div className={styles.footer}>
          <Form.Item>
            <Button shape="round" onClick={handleCancel} className={styles.cancelButton}>
              {t('gift_campaign.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              shape="round"
              htmlType="submit"
              loading={isSaving}
              disabled={isSaving}
              className={styles.saveButton}
            >
              {t('gift_campaign.confirm')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default GiftCampaignRecipientForm;
