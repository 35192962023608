// Generated with util/create-component.js
import React, { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Drawer, notification } from 'antd';

import buildPath from 'utils/build_path';
import {
  ResellerContractsDataContext,
  ResellerContractsActionsContext,
  AuthenticationDataContext,
  SupplierContractsDataContext,
  SupplierContractsActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';
import USER_ROLE from 'constants/user_role';
import DRAWER_SIZE from 'constants/drawer_size';
import CONTRACT_STATUS from 'constants/contract_status';
import { PROPERTY_CHANNELS } from 'constants/property_channels';
import SearchInput from 'components/search_input';
import ContractsList from 'components/contracts/contracts_list/contracts_list';
import { IPaginationParams, IContractsFilters } from 'components/contracts/contracts_list/contracts_list.types';
import { IContract, IContractStatus } from 'components/contracts/pending/pending_contracts/pending_contracts.types';
import ActiveContractDetails from 'components/contracts/active/active_contract_details';

import styles from './active_contracts.module.scss';
import routes from 'routing/routes';
import isCarProperty from 'utils/is_car_property';

const DEFAULT_PAGINATION = { page: 1, limit: 10 };

const ActiveContracts: React.FC = () => {
  const history = useHistory();
  const [openedContractDetails, toogleContractDetails] = useState<boolean>(false);
  const [contractDetails, setContractDetails] = useState<IContract | null>(null);
  const [filters, setFilters] = useState<IContractsFilters>({
    pagination: DEFAULT_PAGINATION,
    term: '',
  });

  const { t } = useTranslation();

  const { userRole } = useContext(AuthenticationDataContext);

  const isReseller = useMemo(() => userRole === USER_ROLE.RESELLER, [userRole]);

  if (!userRole) return null;

  const {
    contracts: {
      data: {
        approved: { contracts, meta },
      },
      isLoading,
      isTerminatingContract,
    },
  } = useContext(userRole === USER_ROLE.RESELLER ? ResellerContractsDataContext : SupplierContractsDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const { loadContractsList, terminateContract } = useContext(
    userRole === USER_ROLE.RESELLER ? ResellerContractsActionsContext : SupplierContractsActionsContext,
  );

  useEffect(() => {
    handleLoadContractsList(filters);
  }, [filters]);

  useEffect(() => {
    setFilters({ ...filters, pagination: DEFAULT_PAGINATION });
  }, [selectedProperty]);

  const handleLoadContractsList = useCallback(
    (additionalParams = {}) => {
      const params: { status: IContractStatus; propertyId?: string } = {
        status: CONTRACT_STATUS.APPROVED as IContractStatus,
      };

      if (selectedProperty) {
        params.propertyId = selectedProperty;
      }

      loadContractsList({ ...params, ...additionalParams });
    },
    [loadContractsList, selectedProperty, filters],
  );

  const handlePageChange = useCallback(
    (pagination: IPaginationParams) => {
      setFilters({ ...filters, pagination });
    },
    [loadContractsList, filters],
  );

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setFilters({ ...filters, pagination: DEFAULT_PAGINATION, term });
    },
    [loadContractsList, filters],
  );

  const openNotificationWithIcon = useCallback((type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('contracts.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  }, []);

  const handleTerminateContract = useCallback(
    async (contractId: string) => {
      const params: { contractId: IContract['id']; propertyId?: string } = {
        contractId,
      };

      if (selectedProperty) {
        params.propertyId = selectedProperty;
      }

      try {
        await terminateContract(params);
        handleCloseContractDetails();
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    },
    [terminateContract, selectedProperty],
  );

  const handleCloseContractDetails = useCallback(() => {
    toogleContractDetails(false);
    setContractDetails(null);
  }, []);

  const handleOpenContractDetails = useCallback((contract: IContract) => {
    toogleContractDetails(true);
    setContractDetails(contract);
  }, []);

  const handleBook = useCallback((contract: IContract) => {
    const type = isCarProperty(contract.propertyType) ? PROPERTY_CHANNELS.CAR : PROPERTY_CHANNELS.HOTEL;

    const redirectPath = buildPath(`?adults=2&children=0&type=${type}`, routes.propertyPage, {
      channelId: contract.channelId,
    });
    return history.push(redirectPath);
  }, []);

  const modalTitle = useMemo(
    () => (contractDetails ? (isReseller ? contractDetails.reservaHotelName : contractDetails.agentName) : ''),
    [isReseller, contractDetails],
  );

  return (
    <div data-testid="ActiveContracts" className={styles.root}>
      <div className={styles.search_input_container}>
        <SearchInput onSearch={handleSearchTermChange} placeholder={t('contracts.search_for_contract')} />
      </div>
      <ContractsList
        onPageChange={handlePageChange}
        userRole={userRole}
        contracts={contracts}
        pagination={meta}
        isLoading={isLoading}
        isActiveContracts={true}
        onOpenContractDetails={handleOpenContractDetails}
        onBook={handleBook}
      />
      <Drawer
        title={modalTitle}
        open={openedContractDetails}
        onClose={handleCloseContractDetails}
        width={window.innerWidth > 900 ? DRAWER_SIZE.SMALL : window.innerWidth}
        destroyOnClose={true}
      >
        {contractDetails && (
          <ActiveContractDetails
            contract={contractDetails}
            onTerminateContract={handleTerminateContract}
            isTerminatingContract={isTerminatingContract}
          />
        )}
      </Drawer>
    </div>
  );
};

export default ActiveContracts;
