import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './checkin_details.module.scss';
import { ICheckinDetailsProps } from './checkin_details.types';

const CheckinDetails: React.FC<ICheckinDetailsProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="CheckinDetails">
      <legend>{t(`booking_${booking.propertyType}.checkin_details`)}</legend>
      <Row>
        <Col span={7} className={styles.label}>
          {t(`booking_${booking.propertyType}.checkin_date`)}:
        </Col>
        <Col span={16} offset={1}>
          {booking.arrivalDate}
        </Col>
      </Row>
      <Row>
        <Col span={7} className={styles.label}>
          {t(`booking_${booking.propertyType}.checkout_date`)}:
        </Col>
        <Col span={16} offset={1}>
          {booking.departureDate}
        </Col>
      </Row>
      {booking.arrivalTime && (
        <Row>
          <Col span={7} className={styles.label}>
            {t('booking.arrival_time')}:
          </Col>
          <Col span={16} offset={1}>
            {booking.arrivalTime}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={7} className={styles.label}>
          {t(`bookings_list_${booking.propertyType}.duration_unit`)}:
        </Col>
        <Col span={16} offset={1}>
          {booking.totalNights}
        </Col>
      </Row>
      <Row>
        <Col span={7} className={styles.label}>
          {t(`bookings_list_${booking.propertyType}.units`)}:
        </Col>
        <Col span={16} offset={1}>
          {booking?.units?.length}
        </Col>
      </Row>
    </div>
  );
};

export default CheckinDetails;
