import ApiActions from 'api_actions';
import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';
import {
  formatPropertySettings,
  formatPropertyFacilities,
  formatPropertiesList,
  formatPropertyPolicies,
} from './utils/property_settings_format';

export const SET_PROPERTIES_LIST_LOADING = 'SET_PROPERTIES_LIST_LOADING';
export const SET_PROPERTIES_LIST = 'SET_PROPERTIES_LIST';

export const CLEAR_PROPERTIES_LIST = 'CLEAR_PROPERTIES_LIST';

export const SET_SELECTED_PROPERTY = 'SET_SELECTED_PROPERTY';

export const SET_PROPERTY_SETTINGS_LOADING = 'SET_PROPERTY_SETTINGS_LOADING';
export const SET_PROPERTY_SETTINGS = 'SET_PROPERTY_SETTINGS';

export const SET_PROPERTY_SETTINGS_UPDATE_LOADING = 'SET_PROPERTY_SETTINGS_UPDATE_LOADING';

export const SET_PROPERTY_GIFT_CARDS_STORE_SETTINGS_UPDATE_LOADING =
  'SET_PROPERTY_GIFT_CARDS_STORE_SETTINGS_UPDATE_LOADING';

export const SET_PROPERTY_GIFT_CARDS_STORE_SLUG_CREATE_LOADING =
  'SET_PROPERTY_GIFT_CARDS_STORE_SLUG_CREATE_LOADING';

export const SET_PROPERTY_FACILITIES_LOADING = 'SET_PROPERTY_FACILITIES_LOADING';
export const SET_PROPERTY_FACILITIES = 'SET_PROPERTY_FACILITIES';

export const SET_PROPERTY_PHOTO_UPLOAD = 'SET_PROPERTY_PHOTO_UPLOAD';

export const SET_PROPERTY_POLICIES = 'SET_PROPERTY_POLICIES';

export const SET_PROPERTY_AUTH_TOKEN = 'SET_PROPERTY_AUTH_TOKEN';

export const RESET_PROPERTY_SETTINGS = 'RESET_PROPERTY_SETTINGS';

export const SET_PROPERTY_GROUP_GIFT_CARDS_STORES_LOADING = 'SET_PROPERTY_GROUP_GIFT_CARDS_STORES_LOADING';
export const SET_PROPERTY_GROUP_GIFT_CARDS_STORES = 'SET_PROPERTY_GROUP_GIFT_CARDS_STORES';

export const SET_PROPERTY_GIFT_CARDS_STORES_LOADING = 'SET_PROPERTY_GIFT_CARDS_STORES_LOADING';
export const SET_PROPERTY_GIFT_CARDS_STORES = 'SET_PROPERTY_GIFT_CARDS_STORES';

export const SET_NEW_PROPERTY_GIFT_CARDS_STORE = 'SET_NEW_PROPERTY_GIFT_CARDS_STORE';
export const SET_UPDATED_PROPERTY_GIFT_CARDS_STORE = 'SET_UPDATED_PROPERTY_GIFT_CARDS_STORE';

const setPropertiesListLoading = dispatch => {
  return dispatch({ type: SET_PROPERTIES_LIST_LOADING });
};

const setPropertiesList = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTIES_LIST, payload });
};

const loadSuppliersList = async (dispatch, params) => {
  setPropertiesListLoading(dispatch);

  const data = await ApiActions.getSupplierPropertiesList(params);
  const propertiesList = formatPropertiesList(data);
  const defaultProperty = LocaleStorage.get('selectedProperty');
  const selectedProperty = propertiesList.find(p => p.value == defaultProperty)
    ? defaultProperty
    : propertiesList?.[0].value;

  setSelectedProperty(dispatch, selectedProperty);
  setPropertiesList(dispatch, propertiesList);
};

const clearPropertiesList = (dispatch, payload) => {
  return dispatch({ type: CLEAR_PROPERTIES_LIST, payload });
};

const setSelectedProperty = (dispatch, payload) => {
  return dispatch({ type: SET_SELECTED_PROPERTY, payload });
};

const setPropertySettingsLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_SETTINGS_LOADING });
};

const setPropertySettings = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_SETTINGS, payload });
};

const setPropertyPolicies = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_POLICIES, payload });
};

const loadPropertySettings = async (dispatch, params) => {
  setPropertySettingsLoading(dispatch);

  const { data } = await ApiActions.getPropertySettings(params);
  const propertyPolicies = formatPropertyPolicies(data);
  const formattedData = formatPropertySettings(data);

  setPropertyPolicies(dispatch, propertyPolicies);
  setPropertySettings(dispatch, formattedData);
};

const setUpdatePropertySettingsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_SETTINGS_UPDATE_LOADING, params });
};

const updatePropertySettings = async (dispatch, params) => {
  setUpdatePropertySettingsLoading(dispatch);

  try {
    await ApiActions.updatePropertySettings({ propertyId: params.id, property: params });
    setUpdatePropertySettingsLoading(dispatch, false);
  } catch (e) {
    setUpdatePropertySettingsLoading(dispatch, false);
    throw e;
  }
};

const setUpdatePropertyGiftCardsStoreSettingsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARDS_STORE_SETTINGS_UPDATE_LOADING, params });
};

const updatePropertyGiftCardsStoreSettings = async (dispatch, params) => {
  setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch);

  try {
    await ApiActions.updatePropertyGiftCardsStoreSettings({
      propertyId: params.propertyId,
      storeSettings: params.storeSettings,
    });
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
  } catch (e) {
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
    throw e;
  }
};

const setCreatePropertyGiftCardsStoreSlugLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARDS_STORE_SLUG_CREATE_LOADING, params });
};

const createPropertyGiftCardsStoreSlug = async (dispatch, params) => {
  setCreatePropertyGiftCardsStoreSlugLoading(dispatch);
  try {
    await ApiActions.createPropertyGiftCardsStoreSlug({
      propertyId: params.propertyId,
      slugParams: params.slugParams,
    });
    setCreatePropertyGiftCardsStoreSlugLoading(dispatch, false);
  } catch (e) {
    setCreatePropertyGiftCardsStoreSlugLoading(dispatch, false);
    throw e;
  }
};

const setPropertyFacilitiesLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_FACILITIES_LOADING });
};

const setPropertyFacilities = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_FACILITIES, payload });
};

const loadPropertyFacilities = async (dispatch, params) => {
  setPropertyFacilitiesLoading(dispatch);

  const { data } = await ApiActions.getPropertyFacilities(params);
  const formattedData = formatPropertyFacilities(data);

  setPropertyFacilities(dispatch, formattedData);
};

const setPropertyGroupGiftCardsStoresLoading = (dispatch, payload = true) => {
  return dispatch({ type: SET_PROPERTY_GROUP_GIFT_CARDS_STORES_LOADING, payload });
};
const setPropertyGroupGiftCardsStores = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_GROUP_GIFT_CARDS_STORES, payload });
};

const loadPropertyGroupGiftCardsStores = async (dispatch, params) => {
  setPropertyGroupGiftCardsStoresLoading(dispatch);
  try {
    const data  = await ApiActions.getPropertyGroupGiftCardsStores(params);
    setPropertyGroupGiftCardsStores(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setPropertyGroupGiftCardsStoresLoading(dispatch, false);
  }
};

const setPropertyGiftCardsStoresLoading = (dispatch, payload = true) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARDS_STORES_LOADING, payload });
};
const setPropertyGiftCardsStores = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARDS_STORES, payload });
};
const loadPropertyGiftCardsStores = async (dispatch, params) => {
  setPropertyGiftCardsStoresLoading(dispatch);
  try {
    const data  = await ApiActions.getPropertyGiftCardsStores(params);
    setPropertyGiftCardsStores(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setPropertyGiftCardsStoresLoading(dispatch, false);
  }
};

const setNewPropertyGiftCardsStore = (dispatch, payload) => {
  return dispatch({ type: SET_NEW_PROPERTY_GIFT_CARDS_STORE, payload });
};
const setUpdatedPropertyGiftCardsStore = (dispatch, payload) => {
  return dispatch({ type: SET_UPDATED_PROPERTY_GIFT_CARDS_STORE, payload });
};
const createPropertyGiftCardsStore = async (dispatch, params) => {
  setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch);

  try {
    const data  = await ApiActions.createPropertyGiftCardsStore(params);
    setNewPropertyGiftCardsStore(dispatch, data);
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
  } catch (e) {
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
    throw e;
  }
};
const updatePropertyGiftCardsStore = async (dispatch, params) => {
  setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch);

  try {
    const data  = await ApiActions.updatePropertyGiftCardsStore(params);
    setUpdatedPropertyGiftCardsStore(dispatch, data);
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
  } catch (e) {
    setUpdatePropertyGiftCardsStoreSettingsLoading(dispatch, false);
    throw e;
  }
};


const uploadPropertyPhoto = async (dispatch, params) => {
  const data = await ApiActions.uploadPropertyPhoto(params);
  return data;
};

const setPropertyAuthToken = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_AUTH_TOKEN, payload });
};

const loadOneTimePropertyAuthToken = async (dispatch, params) => {
  try {
    const data = await ApiActions.getOneTimeHotelAuthToken(params);
    setPropertyAuthToken(dispatch, data?.token);
  } catch (e) {
    throw e;
  }
};

const resetSupplierSettings = dispatch => {
  return dispatch({ type: RESET_PROPERTY_SETTINGS });
};

export const actions = {
  setSelectedProperty,
  loadSuppliersList,
  clearPropertiesList,
  loadPropertySettings,
  loadPropertyFacilities,
  loadPropertyGroupGiftCardsStores,
  loadPropertyGiftCardsStores,
  createPropertyGiftCardsStore,
  updatePropertyGiftCardsStore,
  uploadPropertyPhoto,
  updatePropertySettings,
  updatePropertyGiftCardsStoreSettings,
  loadOneTimePropertyAuthToken,
  resetSupplierSettings,
  createPropertyGiftCardsStoreSlug,
};
