// Generated with util/create-component.js
import React, { useState } from 'react';
import { Row, Col, Input, Divider, Button, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { IContractRequestProps, IContractRequestPayload } from './contract_request.types';
import styles from './contract_request.module.scss';

const { TextArea } = Input;

const ContractRequest: React.FC<IContractRequestProps> = ({
  selectedEntity,
  closeDrawer,
  requestContractLoading,
  requestContract,
}) => {
  const [requestMessage, setRequestMessage] = useState<string>('');
  const { t } = useTranslation();

  const handleSendContract = async (): Promise<void> => {
    const payload: IContractRequestPayload = {
      propertyId: selectedEntity.propertyId,
      requestMessage: requestMessage,
    };
    try {
      await requestContract(payload);
      openNotificationWithIcon('success');
      closeDrawer();
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      openNotificationWithIcon('error', e.message);
    }
  };

  const openNotificationWithIcon = (type: 'success' | 'error', message?: string) => {
    if (type === 'success') {
      return notification['success']({
        message: t('contracts.requested_successfully'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: message ? message : t('general.error_description'),
      });
    }
  };

  const handleRequestMessage = (value: string) => {
    setRequestMessage(value);
  };

  return (
    <div data-testid="ContractRequest" className={styles.root}>
      <p>{t('contracts.request_contract_message')}</p>
      <Row gutter={24}>
        <Col span={24}>
          <TextArea
            rows={10}
            className={styles.fullWidth}
            onChange={e => handleRequestMessage(e.target.value)}
            placeholder={t('general.enter_value')}
            data-testid="ContractRequest.RequestMessage"
            value={requestMessage}
          />
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24} className={styles.alignRight}>
          <Button
            type="primary"
            onClick={handleSendContract}
            loading={requestContractLoading}
            data-testid="ContractRequest.RequestContract"
          >
            {t('contracts.request_contract')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default ContractRequest;
