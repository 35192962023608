import {
  SET_PROPERTY_UNITS,
  SET_PROPERTY_UNITS_LOADING,
  SET_PROPERTY_UNIT,
  SET_PROPERTY_UNIT_LOADING,
  SET_UNIT_FACILITIES,
  SET_UNIT_FACILITIES_LOADING,
  SET_UNIT_UPDATE_LOADING,
  REMOVE_SELECTED_UNIT,
  SET_REMOVE_UNIT_LOADING,
  RESET_PROPERTY_UNITS,
} from './property_unit_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  propertyUnits: EMPTY_ENTITY,
  propertyUnit: EMPTY_ENTITY,
  unitFacilities: EMPTY_ENTITY,
  isUpdatingUnit: false,
  isRemovingUnit: false,
};

const actionHandlers = {
  [SET_PROPERTY_UNITS_LOADING]: state => {
    const propertyUnits = { ...state.propertyUnits, isLoading: true };

    return { ...state, propertyUnits };
  },
  [SET_PROPERTY_UNITS]: (state, action) => {
    const propertyUnits = { data: action.payload, isLoading: false };

    return { ...state, propertyUnits };
  },
  [SET_PROPERTY_UNIT_LOADING]: state => {
    const propertyUnit = { ...state.propertyUnit, isLoading: true };

    return { ...state, propertyUnit };
  },
  [SET_PROPERTY_UNIT]: (state, action) => {
    const propertyUnit = { data: action.payload, isLoading: false };

    return { ...state, propertyUnit };
  },
  [SET_UNIT_FACILITIES_LOADING]: state => {
    const unitFacilities = { ...state.unitFacilities, isLoading: true };

    return { ...state, unitFacilities };
  },
  [SET_UNIT_FACILITIES]: (state, action) => {
    const unitFacilities = { data: action.payload, isLoading: false };

    return { ...state, unitFacilities };
  },
  [SET_UNIT_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingUnit: params };
  },
  [REMOVE_SELECTED_UNIT]: (state, action) => {
    const propertyUnit = EMPTY_ENTITY;

    return { ...state, propertyUnit };
  },
  [SET_REMOVE_UNIT_LOADING]: (state, { params }) => {
    return { ...state, isRemovingUnit: params };
  },
  [RESET_PROPERTY_UNITS]: () => {
    return INITIAL_STATE;
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
