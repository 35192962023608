import React from 'react';
import { Button as BootstrapButton, Spinner } from 'react-bootstrap';

import { IButtonProps } from './button.types';
import styles from './button.module.scss';

const Button: React.FC<IButtonProps> = ({
  children,
  variant = 'primary',
  loading,
  disabled,
  className = '',
  onClick,
}) => {
  const buttonStyles = [styles.button];

  if (className) {
    buttonStyles.push(className);
  }

  return (
    <>
      <BootstrapButton
        variant={variant}
        className={buttonStyles.join(' ')}
        disabled={loading || disabled}
        onClick={onClick}
      >
        <>
          {loading && <Spinner animation="border" size="sm" className={styles.spinner} />}
          {children}
        </>
      </BootstrapButton>
    </>
  );
};

export default Button;
