import React from 'react';

import styles from './occupancy_section.module.scss';
import { IOccupancySectionProps } from './occupancy_section.types';

const OccupancySection: React.FC<IOccupancySectionProps> = ({ additionalSpaces, type, icon }) => {
  const captionText = `× ${additionalSpaces}`;

  return (
    <div className={styles.occupancyContainer}>
      <img src={icon} alt={type} />
      {Boolean(additionalSpaces) && <div className={styles.occupancyCaption}>{captionText}</div>}
    </div>
  );
};

export default OccupancySection;
