import React from "react";

import Currency from "components/currency";
import Cell from "components/layout/cell";

import RatePriceBreakdown from "./rate_price_breakdown";

import styles from "./unit_price_breakdown.module.css";

export default function UnitPriceBreakdown({ unitWithSelectedRates, currency, propertyType }) {
  return (
    <Cell className={styles.container}>
      <div className={styles.title}>
        {unitWithSelectedRates.title}
        <Currency amount={unitWithSelectedRates.total} currency={currency} />
      </div>
      <>
        {unitWithSelectedRates.selectedRates.map((ratePlan, index) => (
          <RatePriceBreakdown
            key={`${ratePlan.id}-${index}`}
            ratePlan={ratePlan}
            currency={currency}
            propertyType={propertyType}
          />
        ))}
      </>
    </Cell>
  );
}
