import {
  SET_PROPERTIES_LIST,
  SET_PROPERTIES_LOADING,
  CLEAR_PROPERTIES_LIST,
  CLEAR_CARS_LIST,
  SET_ALL_PROPERTIES_LOADING,
  SET_ALL_CARS_LOADING,
  SET_ALL_PROPERTIES_LIST,
  SET_ALL_CARS_LIST,
  CLEAR_ALL_PROPERTIES_LIST,
  SET_ADDITIONAL_PROPERTIES_LIST,
  SET_ADDITIONAL_PROPERTIES_LIST_LOADING,
} from './search_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  properties: { ...EMPTY_ENTITY, isLoadingAdditionalProperties: false },
  allProperties: {... EMPTY_ENTITY, data: {}},
  allCars: EMPTY_ENTITY,
};

const actionHandlers = {
  [SET_PROPERTIES_LOADING]: state => {
    const properties = { ...state.properties, isLoading: true };

    return { ...state, properties };
  },
  [SET_PROPERTIES_LIST]: (state, action) => {
    const properties = { ...state.properties, data: action.payload, isLoading: false };

    return { ...state, properties };
  },
  [SET_ADDITIONAL_PROPERTIES_LIST]: (state, action) => {
    const properties = { ...state.properties, data: [...(state?.properties?.data || []), ...action.payload] };

    return { ...state, properties };
  },
  [SET_ADDITIONAL_PROPERTIES_LIST_LOADING]: (state, action) => {
    const properties = { ...state.properties, isLoadingAdditionalProperties: action.payload };

    return { ...state, properties };
  },
  [CLEAR_PROPERTIES_LIST]: state => {
    const properties = { isLoading: true };

    return { ...state, properties };
  },
  [CLEAR_CARS_LIST]: state => {
    const properties = { isLoading: true };

    return { ...state, properties };
  },
  [SET_ALL_PROPERTIES_LOADING]: state => {
    const allProperties = { ...state.properties, isLoading: true };

    return { ...state, allProperties };
  },
  [SET_ALL_CARS_LOADING]: state => {
    const allCars = { ...state.properties, isLoading: true };

    return { ...state, allCars };
  },
  [SET_ALL_PROPERTIES_LIST]: (state, action) => {
    const allProperties = { data: action.payload, isLoading: false };

    return { ...state, allProperties };
  },
  [SET_ALL_CARS_LIST]: (state, action) => {
    const allCars = { data: action.payload, isLoading: false };

    return { ...state, allCars };
  },
  [CLEAR_ALL_PROPERTIES_LIST]: state => {
    const allProperties = { isLoading: true };

    return { ...state, allProperties };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
