export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATE_UI_FORMAT = 'DD/MM/YYYY';
export const DATE_API_FORMAT = 'YYYY-MM-DD';
export const DATE_UI_FULL_MONTH_FORMAT = 'DD. MMMM, YYYY';
export const DATE_UI_SHORT_MONTH_FORMAT = 'DD MMM YYYY';
export const DATE_UI_DAY_FORMAT = 'DD';
export const DATE_UI_MONTH_FORMAT = 'MMMM';
export const DATE_UI_YEAR_FORMAT = 'YYYY';
export const DATE_TIME_UI_FORMAT = 'DD MMM YYYY HH:mm';
export const DATE_WITH_DAY_NAME_FORMAT = 'ddd, MMM DD, YYYY';
export const TIME = 'HH:mm';
export const FULL_TIME_FORMAT = 'hh:mm:ss';
export const FULL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
