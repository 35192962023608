import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { Button, Tooltip, Row, Col } from 'antd';

import routes from 'routing/routes';

import EmptyIcon from 'static/empty-property.svg';
import { ReactComponent as CarDoor } from 'assets/car_door.svg';
import { ReactComponent as CarSeat } from 'assets/car_seat.svg';
import { ReactComponent as CarSuitcase } from 'assets/car_suitcase.svg';

import { BookingActionsContext } from 'containers/data_context';
import buildPath from 'utils/build_path';
import isCarProperty from 'utils/is_car_property';
import { renderProviderLogo } from 'utils/vehicle';
import { buildVehicleBookingData } from './utils/build_booking';

import BestOffer from '../best_offer';
import styles from '../properties.module.scss';
import { IPropertyItemProps } from './properties_item.types';

const PropertiesItem: React.FC<IPropertyItemProps> = props => {
  const { property, currency, isHighlighted, showBestOffer, onMouseOver, onMouseOut, propertyType } = props;
  const { saveDataToStorage } = useContext(BookingActionsContext);

  const { t } = useTranslation();
  const history = useHistory();

  const {
    description,
    photos,
    title,
    id,
    bestOffer,
    stationChannelId,
    stationTitle,
    ratePlans,
    acrissCode,
    largeSuitcasesCapacity,
    smallSuitcasesCapacity,
    seats,
    doors,
  } = property;

  const handleMouseOver = () => {
    onMouseOver(property);
  };

  const handleMouseOut = () => {
    onMouseOut(property);
  };

  const isCar = useMemo(() => isCarProperty(propertyType), [propertyType]);

  const handleBookNow = () => {
    if (isCar) {
      const bookingData = buildVehicleBookingData(property);
      saveDataToStorage(bookingData);
    }

    const route = isCar ? routes.checkoutPage : routes.propertyPage;
    const path = buildPath(history.location.search, route, { channelId: stationChannelId || id });
    history.push(path);
  };

  const photo = useMemo(() => {
    if (photos?.length > 0) {
      return <div className={styles.image} style={{ backgroundImage: `url(${photos[0].url})` }} />;
    }

    return (
      <div className={styles.emptyImage}>
        <img src={EmptyIcon} alt={title} />
      </div>
    );
  }, [photos, title]);

  const itemClassName = classNames(styles.item, isHighlighted && styles.itemHighlighted);
  const price = !ratePlans?.length ? bestOffer : ratePlans[0].totalPrice;
  const curr = !ratePlans?.length ? currency : ratePlans[0].currency;

  const providerLogo = useMemo(() => renderProviderLogo(property.provider), [property.provider]);

  return (
    <div
      className={itemClassName}
      onClick={handleBookNow}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
    >
      <div className={styles.overlay}>
        <div className={styles.previewBtnWrapper}>
          <div className={styles.previewButton} onClick={handleBookNow}>
            {t('properties.book_now')}
          </div>
        </div>
      </div>

      <Row>
        <Col span={24} className={styles.imageWrapper}>
          {photo}
        </Col>

        <Col className={styles.info} span={property?.provider ? 16 : 24}>
          <p className={styles.title}>{title}</p>
          {stationTitle && <p className={styles.propertyDetails}>{stationTitle}</p>}
          <p className={styles.description}>{description || t('properties.no_info')}</p>
          {acrissCode && <p className={styles.propertyDetails}>{`Acriss Code: ${acrissCode}`}</p>}
          {ratePlans?.[0]?.liability && (
            <p className={styles.propertyDetails}>{`Liability: ${ratePlans?.[0]?.liability}`}</p>
          )}
          <div className={styles.vehicleDetailsRow}>
            {Number(largeSuitcasesCapacity) > 0 && (
              <p className={styles.vehicleDetails}>
                <CarSuitcase className={styles.vehicleDetailsIcon} />
                <span className={styles.vehicleDetails}>{largeSuitcasesCapacity}</span>
              </p>
            )}
            {Number(smallSuitcasesCapacity) > 0 && (
              <p className={styles.vehicleDetails}>
                <CarSuitcase className={styles.smallVehicleDetailsIcon} />
                <span className={styles.vehicleDetails}>{smallSuitcasesCapacity}</span>
              </p>
            )}
            {Number(seats) > 0 && (
              <p className={styles.vehicleDetails}>
                <CarSeat className={styles.vehicleDetailsIcon} />
                <span className={styles.vehicleDetails}>{seats}</span>
              </p>
            )}
            {Number(doors) > 0 && (
              <p className={styles.vehicleDetails}>
                <CarDoor className={styles.vehicleDetailsIcon} />
                <span className={styles.doors}>{smallSuitcasesCapacity}</span>
              </p>
            )}
          </div>
        </Col>
        {providerLogo && (
          <Col className={styles.info} span={8}>
            <div className={styles.providerLogo}>{providerLogo}</div>
          </Col>
        )}
      </Row>

      <div className={styles.footer}>
        {showBestOffer ? (
          <BestOffer amount={price} currency={curr} />
        ) : (
          <Tooltip title={t(`property.select_dates_message_${propertyType}`)}>
            <Button className={styles.show_prices} type="primary" onClick={event => event.stopPropagation()}>
              {t('property.show_prices')}
            </Button>
          </Tooltip>
        )}

        <div className={styles.seeMoreLink} onClick={handleBookNow}>
          {t('properties.book_now')}
        </div>
      </div>
    </div>
  );
};

export default PropertiesItem;
