import React from 'react';

import styles from './field_wrapper.module.scss';
import { IFieldWrapperProps } from './field_wrapper.types';

const FieldWrapper: React.FC<IFieldWrapperProps> = ({ children }) => {
  return <div className={styles.fieldWrapper}>{children}</div>;
};

export default FieldWrapper;
