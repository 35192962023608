import ApiActions from 'api_actions';
import notification from 'utils/notification/notification';
import isAccommodationProperty from 'utils/is_accommodation_property';

import { formatBookingsList, formatBookingUnits, formatUpdatedBooking } from './utils/bookings_format';

export const SET_BOOKINGS_LIST_LOADING = 'SET_BOOKINGS_LIST_LOADING';
export const SET_BOOKINGS_LIST = 'SET_BOOKINGS_LIST';
export const RESET_BOOKINGS_LIST = 'RESET_BOOKINGS_LIST';
export const SET_UPDATE_BOOKING_GUESTS_LOADING = 'SET_UPDATE_BOOKING_GUESTS_LOADING';
export const SET_UPDATE_BOOKING_SPECIAL_REQUEST_LOADING = 'SET_UPDATE_BOOKING_SPECIAL_REQUEST_LOADING';
export const SET_UPDATED_BOOKING = 'SET_UPDATED_BOOKING';

export const SET_SUPPLIERS_LIST_LOADING = 'SET_SUPPLIERS_LIST_LOADING';
export const SET_SUPPLIERS_LIST = 'SET_SUPPLIERS_LIST_LOADING';

export const SET_BOOKERS_LIST_LOADING = 'SET_BOOKERS_LIST_LOADING';
export const SET_BOOKERS_LIST = 'SET_BOOKERS_LIST';

export const SET_RESELLERS_LIST_LOADING = 'SET_RESELLERS_LIST_LOADING';
export const SET_RESELLERS_LIST = 'SET_RESELLERS_LIST';

const setBookingsListLoading = dispatch => {
  return dispatch({ type: SET_BOOKINGS_LIST_LOADING });
};

const setBookingsList = (dispatch, payload) => {
  return dispatch({ type: SET_BOOKINGS_LIST, payload });
};


const loadBookingInfo = async (dispatch, params) => {
  return await ApiActions.getPropertyBookingInfo(params);
};

const loadBookingsList = async (dispatch, params) => {
  setBookingsListLoading(dispatch);
  let bookings;
  try {
    if (params?.propertyId) {
      bookings = await ApiActions.getSupplierBookingsList(params);
    } else {
      bookings = await ApiActions.getResellerBookingsList(params);
    }
  } catch (e) {
    return notification.withIcon('error', e.message);
  }

  if (bookings) {
    const { data, ...rest } = bookings;
    const formattedData = formatBookingsList(data);
    setBookingsList(dispatch, { data: formattedData, ...rest });
  } else {
    setBookingsList(dispatch, { data: [] });
  }
};

const resetBookingsList = (dispatch) => {
  return dispatch({ type: RESET_BOOKINGS_LIST });
};

const cancelBooking = async (dispatch, params) => {
  let data;
  if (isAccommodationProperty(params.propertyType)) {
    data = await ApiActions.cancelAccommodationBooking(params);
  } else {
    data = await ApiActions.cancelVehicleBooking(params);
  }
  return data;
};

const cancelPropertyBooking = async (dispatch, params) => {
  const data = await ApiActions.cancelPropertyBooking(params);
  return data;
};

const setUpdateBookingGuestsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UPDATE_BOOKING_GUESTS_LOADING, params });
};

const setUpdatedBooking = (dispatch, params) => {
  return dispatch({ type: SET_UPDATED_BOOKING, params });
}

const updateBookingGuests = async (dispatch, params) => {
  try {
    const units = formatBookingUnits(params);
    setUpdateBookingGuestsLoading(dispatch);
    const booking = await ApiActions.updateBookingGuests({ ...params, units });
    const updatedBooking = formatUpdatedBooking(booking, params.propertyType);
    setUpdatedBooking(dispatch, updatedBooking);
    return updatedBooking;
  } catch (e) {
    throw e;
  } finally {
    setUpdateBookingGuestsLoading(dispatch, false);
  }
};

const setUpdateBookingSpecialRequestLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UPDATE_BOOKING_SPECIAL_REQUEST_LOADING, params });
};

const updateBookingSpecialRequest = async (dispatch, params) => {
  try {
    setUpdateBookingSpecialRequestLoading(dispatch);
    const booking = await ApiActions.updateBookingSpecialRequest(params);
    const updatedBooking = formatUpdatedBooking(booking, params.propertyType);
    setUpdatedBooking(dispatch, updatedBooking);
    return updatedBooking;
  } catch (e) {
    throw e;
  } finally {
    setUpdateBookingSpecialRequestLoading(dispatch, false);
  }
};

const deleteBookingUnits = async (dispatch, params) => {
  try{
    const booking = await ApiActions.deleteBookingUnits(params);
    const updatedBooking = formatUpdatedBooking(booking, params.propertyType);
    setUpdatedBooking(dispatch, updatedBooking);
    return updatedBooking;
  } catch (e) {
    throw e;
  } finally {
    setUpdateBookingGuestsLoading(dispatch, false);
  }
};

const setSuppliersListLoading = dispatch => {
  return dispatch({ type: SET_SUPPLIERS_LIST_LOADING });
};

const setSuppliersList = (dispatch, payload) => {
  return dispatch({ type: SET_SUPPLIERS_LIST, payload });
};

const loadSuppliersList = async (dispatch, params) => {
  setSuppliersListLoading(dispatch);

  const data = await ApiActions.getSuppliersList(params);
  if (data) {
    setSuppliersList(dispatch, { data });
  } else {
    setSuppliersList(dispatch, []);
  }
};

const setBookersListLoading = dispatch => {
  return dispatch({ type: SET_BOOKERS_LIST_LOADING });
};

const setBookersList = (dispatch, payload) => {
  return dispatch({ type: SET_BOOKERS_LIST, payload });
};

const loadBookersList = async (dispatch, params) => {
  setBookersListLoading(dispatch);

  const data = await ApiActions.getResellerUsers(params);
  setBookersList(dispatch, { data });
};

const setResellersListLoading = dispatch => {
  return dispatch({ type: SET_RESELLERS_LIST_LOADING });
};

const setResellersList = (dispatch, payload) => {
  return dispatch({ type: SET_RESELLERS_LIST, payload });
};

const loadResellersList = async (dispatch, params) => {
  setResellersListLoading(dispatch);

  const { data } = await ApiActions.getResellersList(params);
  setResellersList(dispatch, { data });
};

export const actions = {
  loadBookingsList,
  loadBookingInfo,
  resetBookingsList,
  cancelBooking,
  updateBookingGuests,
  updateBookingSpecialRequest,
  cancelPropertyBooking,
  loadSuppliersList,
  loadBookersList,
  loadResellersList,
  deleteBookingUnits,
};
