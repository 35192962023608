import {
  SET_RESELLERS_LIST,
  SET_RESELLERS_LOADING,
  SET_RESELLER_IS_UPDATING,
  SET_UPDATED_RESELLER,
  SET_RESELLER_IS_CREATING,
} from './resellers_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  resellers: EMPTY_ENTITY,
  reseller: {
    isUpdating: false,
    isCreating: false,
  },
};

const actionHandlers = {
  [SET_RESELLERS_LOADING]: (state, { params }) => {
    const resellers = { ...state.resellers, isLoading: params };

    return { ...state, resellers };
  },
  [SET_RESELLERS_LIST]: (state, action) => {
    const resellers = { data: action.payload, isLoading: false };

    return { ...state, resellers };
  },
  [SET_RESELLER_IS_UPDATING]: (state, action) => {
    const reseller = { ...state.reseller, isUpdating: action.params };

    return { ...state, reseller };
  },
  [SET_UPDATED_RESELLER]: (state, { params }) => {
    const resellersData = [...state.resellers.data.agents];
    const resellerIndex = state.resellers.data.agents.findIndex(reseller => reseller.id === params.id);
    if (resellerIndex !== -1) {
      resellersData[resellerIndex] = params;
    } else {
      resellersData.push(params);
    }

    const resellers = { ...state.resellers, data: { ...state.resellers.data, agents: resellersData } };

    return { ...state, resellers };
  },
  [SET_RESELLER_IS_CREATING]: (state, action) => {
    const reseller = { ...state.reseller, isCreating: action.params };

    return { ...state, reseller };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
