import React from 'react';
import { useTranslation } from 'react-i18next';

import Link from 'components/link';

const PolicyLink: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Link to="https://www.reserva.is/privacy-policy" target="_blank">
      {t('footer.privacy_policy')}
    </Link>
  );
};

export default PolicyLink;
