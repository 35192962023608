import { useCallback, useMemo, useReducer } from 'react';
import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';

import { actions, INITIAL_STATE, reducer } from './reducers/login_form';

export default () => {
  const [authenticationData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const login = useCallback(
    loginData => {
      return actions.login(dispatch, loginData);
    },
    [dispatch],
  );

  const reloadLoggedInUser = useCallback(
    () => {
      return actions.reload(dispatch);
    },
    [dispatch],
  );

  const logout = useCallback(() => {
    LocaleStorage.remove('session');
    return actions.logout(dispatch);
  }, [dispatch]);

  const resetPassword = useCallback(
    email => {
      return actions.resetPassword(dispatch, email);
    },
    [dispatch],
  );

  const startSubmit = useCallback(() => {
    actions.setFormSubmitting(dispatch);

    return authenticationData.submitHandler();
  }, [dispatch, authenticationData]);

  const setLoggedInUser = useCallback(
    data => {
      return actions.setLoggedInUser(dispatch, data);
    },
    [dispatch],
  );

  const updatePassword = useCallback(
    params => {
      return actions.updatePassword(dispatch, params);
    },
    [dispatch],
  );

  const verifyResetPasswordEmail = useCallback(
    params => {
      return actions.verifyResetPasswordEmail(dispatch, params);
    },
    [dispatch],
  );

  const authenticationActions = useMemo(
    () => ({
      login,
      reloadLoggedInUser,
      logout,
      resetPassword,
      startSubmit,
      setLoggedInUser,
      updatePassword,
      verifyResetPasswordEmail,
    }),
    [login, startSubmit, setLoggedInUser, updatePassword, verifyResetPasswordEmail],
  );

  return useMemo(() => ({ authenticationData, authenticationActions }), [authenticationData, authenticationActions]);
};
