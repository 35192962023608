import {
  SET_PROPERTY_RATE_PLANS,
  SET_PROPERTY_RATE_PLANS_LOADING,
  SET_PROPERTY_RATE_PLAN,
  SET_PROPERTY_RATE_PLAN_LOADING,
  SET_RATE_PLAN_UPDATE_LOADING,
  REMOVE_SELECTED_RATE_PLAN,
  SET_REMOVE_RATE_PLAN_LOADING,
  SET_ALL_RATE_PLANS_LOADING,
  SET_ALL_RATE_PLANS,
  RESET_PROPERTY_RATE_PLANS,
} from './property_rate_plans_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  propertyRatePlans: {},
  propertyRatePlan: EMPTY_ENTITY,
  isUpdatingRatePlan: false,
  isRemovingRatePlan: false,
  allPropertyRatePlans: EMPTY_ENTITY,
};

const actionHandlers = {
  [SET_PROPERTY_RATE_PLANS_LOADING]: (state, action) => {
    const propertyRatePlans = { ...state.propertyRatePlans };
    propertyRatePlans[action.roomTypeId] = {
      data: [],
      isLoading: true,
    };

    return { ...state, propertyRatePlans };
  },
  [SET_PROPERTY_RATE_PLANS]: (state, action) => {
    const { roomTypeId, data } = action.payload;

    const propertyRatePlans = { ...state.propertyRatePlans };
    propertyRatePlans[roomTypeId] = {
      isLoading: false,
      data,
    };

    return { ...state, propertyRatePlans };
  },
  [SET_PROPERTY_RATE_PLAN_LOADING]: state => {
    const propertyRatePlan = { ...state.propertyRatePlan, isLoading: true };

    return { ...state, propertyRatePlan };
  },
  [SET_PROPERTY_RATE_PLAN]: (state, action) => {
    const propertyRatePlan = { data: action.payload, isLoading: false };

    return { ...state, propertyRatePlan };
  },
  [SET_ALL_RATE_PLANS_LOADING]: state => {
    const allPropertyRatePlans = { ...state.allPropertyRatePlans, isLoading: true };

    return { ...state, allPropertyRatePlans };
  },
  [SET_ALL_RATE_PLANS]: (state, action) => {
    const allPropertyRatePlans = { data: action.payload, isLoading: false };

    return { ...state, allPropertyRatePlans };
  },
  [SET_RATE_PLAN_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingUnit: params };
  },
  [REMOVE_SELECTED_RATE_PLAN]: (state, action) => {
    const propertyRatePlan = EMPTY_ENTITY;

    return { ...state, propertyRatePlan };
  },
  [SET_REMOVE_RATE_PLAN_LOADING]: (state, { params }) => {
    return { ...state, isRemovingUnit: params };
  },
  [RESET_PROPERTY_RATE_PLANS]: () => {
    return INITIAL_STATE;
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
