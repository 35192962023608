import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './get_channel_ad.module.scss';

const GetChannelAd: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <span className={styles.text}>{t('footer.get_free_channel')} Reserva</span>
    </div>
  );
};

export default GetChannelAd;
