import React from 'react';

import styles from './caption.module.scss';
import { ICaptionProps } from './caption.types';

const CAPTION_VARIANTS = {
  right: styles.captionRight,
  center: styles.captionCenter,
  green: styles.captionGreen,
};

const Caption: React.FC<ICaptionProps> = ({ children, variant = 'right' }) => {
  const captionClasses = [styles.caption];
  const modifierClass = CAPTION_VARIANTS[variant];

  if (modifierClass) {
    captionClasses.push(modifierClass);
  }

  return <div className={captionClasses.join(' ')}>{children}</div>;
};

export default Caption;
