import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import getUrlParams from 'utils/get_url_params';

import Loading from 'components/loading';
import { IOrder } from 'components/gift_card_store_confirmation/gift_card_store_confirmation.types';
import { PropertyGiftCardsActionsContext } from 'containers/data_context';
import useTrackingCookieConsent from 'hooks/useTrackingCookieConsent';

import GiftCardStoreConfirmation from 'components/gift_card_store_confirmation/gift_card_store_confirmation';

import { IPurchaseStatus } from './gift_card_store_confirmation_page.types';
import styles from './gift_card_store_confirmation_page.module.scss';

const GiftCardStoreConfirmationPage: React.FC = () => {
  const [giftCards, setGiftCards] = useState<IOrder[]>([]);
  const [purchaseStatus, setPurchaseStatus] = useState<IPurchaseStatus>({
    success: true,
    reason: null,
  });
  const [isLoadingGCPurchaseStatus, setIsLoadingGCPurchaseStatus] = useState(false);

  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const isTrackingAllowed: boolean = useTrackingCookieConsent();

  const { clearDataFromStorage, getGiftCardsPurchaseStatus } = useContext(PropertyGiftCardsActionsContext);

  const { ReferenceNumber, DigitalSignatureResponse } = getUrlParams() as {
    ReferenceNumber: string;
    DigitalSignatureResponse: string;
  };

  useEffect(
    function initApp() {
      setIsLoadingGCPurchaseStatus(true);
      setTimeout(async () => await loadGiftCardsPurchaseStatus(), 3000);
    },
    [getGiftCardsPurchaseStatus, selectedProperty, ReferenceNumber, DigitalSignatureResponse],
  );

  let numberOfAttemptsToLoadPurchaseStatus = 0;

  const loadGiftCardsPurchaseStatus = async () => {
    numberOfAttemptsToLoadPurchaseStatus++;

    if (selectedProperty && ReferenceNumber && DigitalSignatureResponse) {
      try {
        const res: IOrder[] = await getGiftCardsPurchaseStatus({
          propertyId: selectedProperty,
          purchaseParams: {
            referenceNumber: ReferenceNumber,
            propertyId: selectedProperty,
            digitalSignature: DigitalSignatureResponse,
          },
        });
        const totalGiftCardsPrice = res?.reduce((acc, curr) => acc + curr.price, 0);
        const giftCardItems = res?.map((item: IOrder) => ({
          item_id: item.generatedId,
          item_name: item.name,
          item_category: 'Gift Card',
          price: item.price,
          quantity: 1,
        }));
        isTrackingAllowed &&
          window?.gtag('event', 'purchase', {
            currency: 'ISK',
            transaction_id: ReferenceNumber,
            value: totalGiftCardsPrice,
            items: giftCardItems,
          });
        if (res instanceof Error) {
          setGiftCards([]);
          setPurchaseStatus({
            reason: res?.message,
            success: false,
          });

          if (numberOfAttemptsToLoadPurchaseStatus < 3) {
            setTimeout(async () => {
              await loadGiftCardsPurchaseStatus();
            }, 1000);
            return;
          }
        } else {
          localStorage.removeItem(`cart-items-${selectedProperty}`);
          clearDataFromStorage();
          setGiftCards(res);
          setPurchaseStatus({
            reason: null,
            success: true,
          });
        }
        setIsLoadingGCPurchaseStatus(false);
      } catch (e) {
        console.log(e, 'error');
      }
    }
  };

  return (
    <div className={styles.root} data-testid="GiftCardStoreConfirmationPage">
      {isLoadingGCPurchaseStatus ? (
        <Loading size={48} />
      ) : (
        <GiftCardStoreConfirmation orderData={giftCards} purchaseStatus={purchaseStatus} />
      )}
    </div>
  );
};

export default GiftCardStoreConfirmationPage;
