// Generated with util/create-component.js
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Steps, Tooltip, Popconfirm, Divider } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import USER_ROLE from 'constants/user_role';
import CONTRACT_INITIATOR from 'constants/contract_initiator';
import CONTRACT_MODIFIER from 'constants/contracts_modifier';

import { IPendingContractDetailsProps } from './pending_contract_details.types';
import styles from './pending_contract_details.module.scss';

const PendingContractDetails: React.FC<IPendingContractDetailsProps> = ({
  userRole,
  contract,
  isAcceptingContract,
  isDecliningContract,
  isCancelingContractProposal,
  onAcceptContract,
  onDeclineContract,
  onCancelContractProposal,
}) => {
  const { t } = useTranslation();

  const isInitiator =
    (contract.initiator === CONTRACT_INITIATOR.RESELLER && userRole === USER_ROLE.RESELLER) ||
    (userRole === USER_ROLE.SUPPLIER && contract.initiator !== CONTRACT_INITIATOR.RESELLER);

  const priceModifier = useMemo(
    () => `${contract.modifierLogic.includes('decrease') ? '-' : '+'} ${contract.modifierAmount}
  ${
    [CONTRACT_MODIFIER.DECREASE_BY_PERCENT, CONTRACT_MODIFIER.INCREASE_BY_PERCENT].includes(contract.modifierLogic)
      ? '%'
      : 'of amount'
  }`,
    [contract],
  );

  const renderRoomRates = useCallback(() => {
    if (!contract?.roomRates?.length) return null;
    return (
      <>
        {contract?.roomRates?.map((roomRate, ind) => (
          <div key={`roomRate-${ind}`}>
            <div className={styles.roomRateTitle}>{roomRate.title}</div>
            {roomRate.ratePlans?.map((ratePlan, ind) => (
              <div key={`ratePlan-${ind}`} className={styles.ratePlanTitle}>
                {`${ratePlan.title} `}
                (<UserOutlined className={styles.occupancyIcon} /> {ratePlan.occupancy})
              </div>
            ))}
            {ind < roomRate.ratePlans?.length - 1 && <Divider className={styles.divider} />}
          </div>
        ))}
      </>
    );
  }, [contract]);

  const steps = [
    {
      title: t('contracts.proposed'),
      description: `${
        contract.initiator === CONTRACT_INITIATOR.RESELLER ? contract.agentName : contract.reservaHotelName
      } proposed ${priceModifier} price modifier`,
      ['data-testid']: 'PendingContractStep1',
    },
    {
      title: t('contracts.pending_approval'),
      description: `${t('contracts.pending_approval')} ${t('general.from')} ${
        contract.initiator === CONTRACT_INITIATOR.RESELLER ? contract.reservaHotelName : contract.agentName
      }`,
      ['data-testid']: 'PendingContractStep2',
    },
  ];
  return (
    <div data-testid="PendingContractDetails" className={styles.root}>
      <div className={styles.statusLabel} data-testid="PendingContractStatus">
        {isInitiator ? t('contracts.proposed') : t('contracts.request')}
      </div>

      <div className={styles.detailsLabel}>
        <div>{contract.reservaHotelName}</div>
        <div>
          {t('contracts.price_modifier')} {priceModifier}
        </div>
      </div>

      {renderRoomRates()}

      <Steps progressDot current={2} direction="vertical" className={styles.stepsWrapper} items={steps} />

      {!isInitiator && (
        <div className={styles.footer}>
          <Tooltip title={t('contracts.accept_contract')}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  <p>{t('contracts.confirm_accept_contract')}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => onAcceptContract(contract)}
              disabled={isAcceptingContract}
            >
              <Button
                loading={isAcceptingContract}
                aria-label={t('contracts.accept_contract')}
                disabled={isAcceptingContract}
                className={styles.acceptContractButton}
              >
                {t('contracts.accept_contract')}
              </Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title={t('contracts.decline_contract')}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  <p>{t('contracts.confirm_decline_contract')}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => onDeclineContract(contract)}
              disabled={isDecliningContract}
            >
              <Button
                loading={isDecliningContract}
                aria-label={t('contracts.decline_contract')}
                disabled={isDecliningContract}
                className={styles.declineContractButton}
              >
                {t('contracts.decline_contract')}
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      )}
      {isInitiator && (
        <div className={styles.footer}>
          <Tooltip title={t('contracts.cancel_proposal')}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  <p>{t('contracts.confirm_cancel_contract_proposal')}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => onCancelContractProposal(contract)}
              disabled={isCancelingContractProposal}
            >
              <Button
                loading={isCancelingContractProposal}
                aria-label={t('contracts.cancel_proposal')}
                disabled={isCancelingContractProposal}
                className={styles.cancelProposalButton}
              >
                {t('contracts.cancel_proposal')}
              </Button>
            </Popconfirm>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default PendingContractDetails;
