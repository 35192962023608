import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import classNames from 'classnames';

import Loading from 'components/loading';
import GiftCardStoreList from 'components/gift_card_store_list';
import { IPublishedGiftCard } from 'components/gift_card_store_list/gift_card_store_list.types';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_page.module.scss';
import SearchInput from 'components/search_input';

const GiftCardStorePage: React.FC = () => {
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const propertyGiftCardsActions = useContext(PropertyGiftCardsActionsContext);

  const [searchTerm, setSearchTerm] = useState<string>('');

  const {
    storeGiftCardTypes: { data: giftCards = [], isLoading },
    propertyInfo: { data: propertyInfo },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(() => {
    async function initApp() {
      if (selectedProperty) {
        try {
          await propertyGiftCardsActions.getStoreGiftCardTypes({ propertyId: selectedProperty });
        } catch (e) {
          console.log('error', e);
        }
      }
    }
    initApp();
  }, [propertyGiftCardsActions, selectedProperty]);

  const handleOnSearch = (term: string) => {
    setSearchTerm(term);
  };

  const giftCardsList = useMemo(
    () =>
      giftCards?.filter((gc: IPublishedGiftCard) =>
        gc.title.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()),
      ),
    [searchTerm, giftCards],
  );

  return (
    <div className={styles.root} data-testid="GiftCardStorePage">
      {isLoading ? (
        <Loading size={48} />
      ) : (
        <>
          <div className={styles.giftCardStoreWrapper}>
            <div className={styles.headerContainer}>
              {propertyInfo?.storeSetting?.photoUrl && (
                <img src={propertyInfo?.storeSetting?.photoUrl} style={{ position: 'absolute' }} alt="Property Cover" />
              )}
              <div className={classNames(styles.headerNote, 'roundedWrapper')}>
                <div className={classNames(styles.headerNoteTitle, 'highlighted')}>
                  {propertyInfo?.storeSetting?.bannerTitle}
                </div>
                <div className={styles.headerNoteDescription}>{propertyInfo?.storeSetting?.bannerParagraph}</div>
              </div>
            </div>
          </div>
          <div className={styles.giftCardsContainer}>
            <Row className={styles.searchInputContainer}>
              <Col sm={16} offset={8} xs={24}>
                <SearchInput placeholder={t('gift_card.search')} onSearch={handleOnSearch} />
              </Col>
            </Row>
            <GiftCardStoreList giftCardsList={giftCardsList} />
          </div>
        </>
      )}
    </div>
  );
};

export default GiftCardStorePage;
