export default [
  'AbrilFatface',
  'Alegreya',
  'Alegreya Black',
  'Alegreya Black Italic',
  'Alegreya Bold',
  'Alegreya Bold Italic',
  'Alegreya ExtraBold',
  'Alegreya ExtraBold Italic',
  'Alegreya Italic',
  'Alegreya Medium',
  'Alegreya Medium Italic',
  'Alegreya SemiBold',
  'Alegreya SemiBold Italic',
  'Alfa Slab One',
  'Bebas Neue Bold',
  'Comfortaa',
  'Comfortaa Bold',
  'Comfortaa Light',
  'Comfortaa Medium',
  'Comfortaa SemiBold',
  'Gotham',
  'Gotham Rounded Bold',
  'Gotham Rounded Book',
  'Lato',
  'Lato Black',
  'Lato Black Italic',
  'Lato Bold',
  'Lato Bold Italic',
  'Lato Italic',
  'Lato Light',
  'Lato Light Italic',
  'Lato Thin',
  'Lato Thin Italic',
  'Lobster',
  'Lora',
  'Lora Bold',
  'Lora Bold Italic',
  'Lora Italic',
  'Lora Medium',
  'Lora Medium Italic',
  'Lora SemiBold',
  'Lora SemiBold Italic',
  'Merriweather',
  'Merriweather Black',
  'Merriweather Black Italic',
  'Merriweather Bold',
  'Merriweather Bold Italic',
  'Merriweather Italic',
  'Merriweather Light',
  'Merriweather Light Italic',
  'Montserrat',
  'Montserrat Black',
  'Montserrat Black Italic',
  'Montserrat Bold',
  'Montserrat Bold Italic',
  'Montserrat ExtraBold',
  'Montserrat ExtraBold Italic',
  'Montserrat ExtraLight',
  'Montserrat ExtraLight Italic',
  'Montserrat Italic',
  'Montserrat Light',
  'Montserrat Light Italic',
  'Montserrat Medium',
  'Montserrat Medium Italic',
  'Montserrat SemiBold',
  'Montserrat SemiBold Italic',
  'Montserrat Thin',
  'Montserrat Thin Italic',
  'Open Sans',
  'Open Sans Bold',
  'Open Sans Bold Italic',
  'Open Sans ExtraBold',
  'Open Sans ExtraBold Italic',
  'Open Sans Italic',
  'Open Sans Light',
  'Open Sans Light Italic',
  'Open Sans Medium',
  'Open Sans Medium Italic',
  'Open Sans SemiBold',
  'Open Sans SemiBold Italic',
  'Oswald',
  'Oswald Bold',
  'Oswald ExtraLight',
  'Oswald Light',
  'Oswald Medium',
  'Oswald SemiBold',
  'PT Serif',
  'PT Serif Bold',
  'PT Serif Bold Italic',
  'PT Serif Italic',
  'Playfair Display',
  'Playfair Display Black',
  'Playfair Display Black Italic',
  'Playfair Display Bold',
  'Playfair Display Bold Italic',
  'Playfair Display ExtraBold',
  'Playfair Display ExtraBold Italic',
  'Playfair Display Italic',
  'Playfair Display Medium',
  'Playfair Display Medium Italic',
  'Playfair Display SemiBold',
  'Playfair Display SemiBold Italic',
  'Poppins',
  'Poppins Black',
  'Poppins Black Italic',
  'Poppins Bold',
  'Poppins Bold Italic',
  'Poppins ExtraBold',
  'Poppins ExtraBold Italic',
  'Poppins ExtraLight',
  'Poppins ExtraLight Italic',
  'Poppins Italic',
  'Poppins Light',
  'Poppins Light Italic',
  'Poppins Medium',
  'Poppins Medium Italic',
  'Poppins SemiBold',
  'Poppins SemiBold Italic',
  'Poppins Thin',
  'Poppins Thin Italic',
  'Raleway',
  'Raleway Black',
  'Raleway Black Italic',
  'Raleway Bold',
  'Raleway Bold Italic',
  'Raleway ExtraBold',
  'Raleway ExtraBold Italic',
  'Raleway ExtraLight',
  'Raleway ExtraLight Italic',
  'Raleway Italic',
  'Raleway Light',
  'Raleway Light Italic',
  'Raleway Medium',
  'Raleway Medium Italic',
  'Raleway SemiBold',
  'Raleway SemiBold Italic',
  'Raleway Thin',
  'Raleway Thin Italic',
  'Roboto',
  'Roboto Black',
  'Roboto Black Italic',
  'Roboto Bold',
  'Roboto Bold Italic',
  'Roboto Italic',
  'Roboto Light',
  'Roboto Light Italic',
  'Roboto Medium',
  'Roboto Medium Italic',
  'Roboto Slab',
  'Roboto Slab Black',
  'Roboto Slab Bold',
  'Roboto Slab ExtraBold',
  'Roboto Slab ExtraLight',
  'Roboto Slab Light',
  'Roboto Slab Medium',
  'Roboto Slab SemiBold',
  'Roboto Slab Thin',
  'Roboto Thin',
  'Roboto Thin Italic',
  'TT Norms',
  'TT Norms Bold',
  'Turbinado Bold Pro',
  'Josefin Sans',
  'Josefin Sans Bold',
  'Josefin Sans Bold Italic',
  'Josefin Sans Thin',
  'Josefin Sans Thin Italic',
  'Josefin Sans SemiBold',
  'Josefin Sans SemiBold Italic',
  'Josefin Sans Medium',
  'Josefin Sans Medium Italic',
  'Josefin Sans Light',
  'Josefin Sans Light Italic',
  'Josefin Sans Italic',
  'Josefin Sans ExtraLight',
  'Josefin Sans ExtraLight Italic',
  'Silk Serif',
  'Silk Serif Black',
  'Silk Serif Black Italic',
  'Silk Serif Bold',
  'Silk Serif Bold Italic',
  'Silk Serif ExtraLight',
  'Silk Serif ExtraLight Italic',
  'Silk Serif Light',
  'Silk Serif Light Italic',
  'Silk Serif Medium',
  'Silk Serif Medium Italic',
  'Silk Serif Italic',
  'Silk Serif SemiBold',
  'Silk Serif SemiBold Italic',
  'Bianco Serif',
  'Bianco Serif Italic',
  'Bianco Serif Heavy Italic',
  'Bianco Serif Heavy',
  'Bianco Serif ExtraBold Italic',
  'Bianco Serif ExtraBold',
  'Bianco Serif Bold Italic',
  'Bianco Serif Bold',
  'Helvetica',
  'Bodoni Book',
];
