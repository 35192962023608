// Generated with util/create-component.js
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Tooltip, Popconfirm } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import CONTRACT_MODIFIER from 'constants/contracts_modifier';
import { IContractRoomRate } from 'components/contracts/pending/pending_contracts/pending_contracts.types';

import { IActiveContractDetailsProps } from './active_contract_details.types';
import styles from './active_contract_details.module.scss';

const ActiveContractDetails: React.FC<IActiveContractDetailsProps> = ({
  onTerminateContract,
  isTerminatingContract,
  contract,
}) => {
  const { t } = useTranslation();

  const priceModifier = useMemo(
    () => `${contract.modifierLogic.includes('decrease') ? '-' : '+'} ${contract.modifierAmount} 
  ${
    [CONTRACT_MODIFIER.DECREASE_BY_PERCENT, CONTRACT_MODIFIER.INCREASE_BY_PERCENT].includes(contract.modifierLogic)
      ? '%'
      : 'of amount'
  }`,
    [contract],
  );

  const renderRoomRates = useCallback(
    () =>
      contract?.roomRates?.map((roomRate: IContractRoomRate, ind: number) => (
        <div key={`roomRate-${ind}`}>
          <Divider className={styles.divider} />
          <div className={styles.roomRateTitle}>{roomRate.title}</div>
          {roomRate.ratePlans?.map((ratePlan, ind) => (
            <div key={`ratePlan-${ind}`} className={styles.ratePlanTitle}>
              {`${ratePlan.title} `}
              (<UserOutlined className={styles.occupancyIcon} /> {ratePlan.occupancy})
            </div>
          ))}
        </div>
      )),
    [contract],
  );

  return (
    <div data-testid="ActiveContractDetails" className={styles.root}>
      <div className={styles.contractDetailsTable}>
        <div className={styles.approvedContractTitle}>
          <div className={styles.approvedContract}>{t('contracts.approved_contract')}</div>
          <div className={styles.approvedContractLabel}>{t('contracts.active')}</div>
        </div>
        <Divider className={styles.divider} />
        <div>
          {t('contracts.price_modifier')} {priceModifier}
        </div>
        {renderRoomRates()}
      </div>

      <div className={styles.footer}>
        <Tooltip title={t('contracts.terminate_contract')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('contracts.confirm_terminate_contract')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('link.yes')}
            placement="bottomRight"
            trigger="click"
            onConfirm={() => onTerminateContract(contract.id)}
            disabled={isTerminatingContract}
          >
            <Button
              loading={isTerminatingContract}
              aria-label={t('contracts.terminate_contract')}
              disabled={isTerminatingContract}
              className={styles.terminateContractButton}
            >
              {t('contracts.terminate_contract')}
            </Button>
          </Popconfirm>
        </Tooltip>
      </div>
    </div>
  );
};

export default ActiveContractDetails;
