import {
  SET_SUPPLIER_DISCOVER_CONTRACTS_LIST_LOADING,
  SET_SUPPLIER_DISCOVER_CONTRACTS_LIST,
  SET_SUPPLIER_CONTRACTS_LIST_LOADING,
  SET_SUPPLIER_CONTRACTS_LIST,
  SET_SUPPLIER_ACCEPT_CONTRACT_LOADING,
  SET_SUPPLIER_TERMINATING_CONTRACT_LOADING,
  SET_SUPPLIER_CONTRACTS_RATE_PLANS_LOADING,
  SET_SUPPLIER_CONTRACTS_RATE_PLANS_LIST,
  RESET_SUPLIER_CONTRACTS_ALL_UNIT_TYPES_LIST,
  SET_SUPPLIER_CREATE_CONTRACT_LOADING,
  SET_SUPPLIER_DECLINING_CONTRACT_LOADING,
  SET_SUPPLIER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING,
  RESET_CONTRACTS,
} from './supplier_contracts_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  discoverResellers: EMPTY_ENTITY,
  contracts: {
    data: {
      proposed: [],
      approved: [],
    },
    isLoading: false,
  },
  isAcceptingContract: false,
  isTerminatingContract: false,
  isDecliningContract: false,
  isCancelingContractProposal: false,
  resellerUnits: EMPTY_ENTITY,
  supplierCreateContractIsLoading: false,
};

const actionHandlers = {
  [SET_SUPPLIER_DISCOVER_CONTRACTS_LIST_LOADING]: state => {
    const discoverResellers = { ...state.discoverResellers, isLoading: true };

    return { ...state, discoverResellers };
  },
  [SET_SUPPLIER_DISCOVER_CONTRACTS_LIST]: (state, action) => {
    const discoverResellers = { data: action.payload, isLoading: false };

    return { ...state, discoverResellers };
  },
  [SET_SUPPLIER_CONTRACTS_LIST_LOADING]: state => {
    const contracts = { ...state.contracts, isLoading: true };

    return { ...state, contracts };
  },
  [SET_SUPPLIER_CONTRACTS_LIST]: (state, action) => {
    const { status, contracts } = action.payload;

    const dataUpdated = JSON.parse(JSON.stringify(state.contracts.data));
    dataUpdated[status] = contracts;
    const updatedContracts = { data: dataUpdated, isLoading: false };

    return { ...state, contracts: updatedContracts };
  },
  [SET_SUPPLIER_ACCEPT_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isAcceptingContract: params };
  },
  [SET_SUPPLIER_TERMINATING_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isTerminatingContract: params };
  },
  [SET_SUPPLIER_DECLINING_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isDecliningContract: params };
  },
  [SET_SUPPLIER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isCancelingContractProposal: params };
  },
  [SET_SUPPLIER_CONTRACTS_RATE_PLANS_LOADING]: state => {
    const resellerUnits = { ...state.resellerUnits, isLoading: true };

    return { ...state, resellerUnits };
  },
  [SET_SUPPLIER_CONTRACTS_RATE_PLANS_LIST]: (state, action) => {
    const resellerUnits = { data: action.payload, isLoading: false };

    return { ...state, resellerUnits };
  },
  [RESET_SUPLIER_CONTRACTS_ALL_UNIT_TYPES_LIST]: state => {
    const resellerUnits = EMPTY_ENTITY;

    return { ...state, resellerUnits };
  },
  [SET_SUPPLIER_CREATE_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, supplierCreateContractIsLoading: params };
  },
  [RESET_CONTRACTS]: () => {
    return INITIAL_STATE;
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
