// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import dateFormatter from 'utils/date_formatter';
import GiftCampaignRecipientForm from 'components/gift_campaign_recipient_form/gift_campaign_recipient_form';
import { IGiftCampaignRecipient } from 'components/gift_campaign_recipient_form/gift_campaign_recipient_form.types';

import { IGiftCampaignChoosenOptionFormProps } from './gift_campaign_choosen_option_form.types';
import styles from './gift_campaign_choosen_option_form.module.scss';

const GiftCampaignChoosenOptionForm: React.FC<IGiftCampaignChoosenOptionFormProps> = ({
  giftCampaignOption,
  onSaveGiftRecipient,
  onResetGiftRecipient,
}) => {
  const { t } = useTranslation();

  const expirationDate = useMemo(
    () => dateFormatter.toUiShortMonth(giftCampaignOption.expirationDate),
    [giftCampaignOption.expirationDate],
  );

  const handleSaveRecipient = async (recepient: IGiftCampaignRecipient) => {
    await onSaveGiftRecipient({ ...recepient, giftOptionPublicId: giftCampaignOption.publicId });
  };

  return (
    <div data-testid="GiftCampaignChoosenOptionForm" className={styles.root}>
      <Row>
        <Col span={24}>
          <Row>
            {giftCampaignOption.photo && (
              <Col sm={window.innerWidth < 1800 ? 12 : 9} xs={24} className={styles.optionImageContainer}>
                <img
                  src={giftCampaignOption.photo}
                  alt={giftCampaignOption.name}
                  data-testid="GiftCampaignOptionPhoto"
                />
              </Col>
            )}
            <Col sm={window.innerWidth < 1800 ? 12 : 15} xs={24}>
              <Row justify="space-between" className={styles.descriptionContainer}>
                <Col span={24}>
                  <Row>
                    <Col span={24} className={styles.optionName}>
                      {giftCampaignOption.name}
                    </Col>
                    <Col span={24}>{giftCampaignOption.description}</Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row className={styles.optionExpiration}>
                    <Col span={24}>{`${t('general.valid_until')} ${expirationDate}`}</Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <GiftCampaignRecipientForm onSave={handleSaveRecipient} onCancel={onResetGiftRecipient} />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCampaignChoosenOptionForm;
