import { IPropertyItem } from '../properties_item.types';

/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export const buildVehicleBookingData = (property: IPropertyItem): any => {
  const bookingData = {
    channelId: property.stationChannelId,
    propertyType: 'vehicle',
    property: {
      data: {
        address: property.address,
        currency: property.currency,
        description: property.description,
        email: null,
        exactMatch: false,
        hideLogo: null,
        hideTitle: null,
        hotelPolicy: null,
        location: {
          latitude: property.latitude,
          longitude: property.longitude,
        },
        logo: null,
        phone: null,
        photos: property.photos,
        requestBillingInfo: false,
        requestCreditCard: false,
        timezone: property.timezone,
        title: property.title,
        id: property.id,
        provider: property.provider,
      },
      isLoading: false,
    },
    propertyUnitsInfo: {
      data: [
        {
          ratePlans: [
            {
              availability: 1,
              cancellationPolicy: null,
              id: property.id,
              occupancy: {
                adults: property.seats,
                children: 0,
                infants: 0,
              },
              parentRatePlanId: property.stationChannelId,
              title: property.title,
              totalPrice: property?.totalPrice || property.ratePlans?.[0].totalPrice,
              netPrice: property?.totalPrice || property.ratePlans?.[0].totalPrice,
              amount: 1,
            },
          ],
        },
      ],
      isLoading: false,
    },
    closedDates: { data: null, isLoading: false },
    propertyChannels: { data: null, isLoading: false },
    params: {
      ratesOccupancyPerUnit: {
        [property.stationChannelId as string]: {
          [property.id as number]: 1,
        },
      },
      currency: property.currency,
      type: 'vehicle',
    },
    unitRequestParams: {
      ratesOccupancyPerUnit: {
        [property.stationChannelId as string]: {
          [property.id as number]: property.seats,
        },
      },
    },
  };

  return bookingData;
};
