import React from 'react';

import { ICellProps } from './cell.types';
import styles from './cell.module.scss';

const Cell: React.FC<ICellProps> = ({ children, className, noLine, noPadding }) => {
  const classNames = [styles.cell];

  if (noLine) {
    classNames.push(styles.cellNoLine);
  }

  if (noPadding) {
    classNames.push(styles.cellNoPadding);
  }

  if (className) {
    classNames.push(className);
  }

  return <div className={classNames.join(' ')}>{children}</div>;
};

export default Cell;
