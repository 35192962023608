import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoEntry from '../info_entry';

import { ISmokingPoliciesProps } from './smoking_policies.types';

const TRANSLATION_PATH = 'hotel_page.hotel_policy.smoking';

const SmokingPolicies: React.FC<ISmokingPoliciesProps> = ({ propertyPolicy }) => {
  const { t } = useTranslation();
  const { smokingPolicy } = propertyPolicy;

  return <InfoEntry title={t(`${TRANSLATION_PATH}.title`)} text={t(`${TRANSLATION_PATH}.options.${smokingPolicy}`)} />;
};

export default SmokingPolicies;
