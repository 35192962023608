import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoEntry from '../info_entry';
import { IParkingPoliciesProps } from './parking_policies.types';

const TRANSLATION_PATH = 'parking';

const ParkingPolicies: React.FC<IParkingPoliciesProps> = ({ propertyPolicy }) => {
  const { t } = useTranslation();
  const { parkingIsPrivate, parkingReservation, parkingType } = propertyPolicy;

  const isParkingAvailable = parkingType !== 'none';
  const parkingProperty = parkingIsPrivate ? 'private' : 'public';

  return (
    <>
      <InfoEntry
        title={t(`${TRANSLATION_PATH}.type_title`)}
        text={t(`${TRANSLATION_PATH}.type_options.${parkingType}`)}
      />
      {isParkingAvailable && (
        <>
          <InfoEntry
            title={t(`${TRANSLATION_PATH}.reservation_title`)}
            text={t(`${TRANSLATION_PATH}.reservation_options.${parkingReservation}`)}
          />
          <InfoEntry
            title={t(`${TRANSLATION_PATH}.property_title`)}
            text={t(`${TRANSLATION_PATH}.property_options.${parkingProperty}`)}
          />
        </>
      )}
    </>
  );
};

export default ParkingPolicies;
