// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Select } from 'antd';

import { IPropertyPhotoDetailsProps } from './property_photo_details.types';
import styles from './property_photo_details.module.scss';

const photoKindOptions = ['photo', 'ad', 'menu'];

const validateMessages = {
  required: 'required',
};

const PropertyPhotoDetails: React.FC<IPropertyPhotoDetailsProps> = ({ photo, onChange, onClose }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const formItemLayout = {
    labelCol: { sm: { span: 24 }, md: { span: 6 } },
    wrapperCol: { sm: { span: 24 }, md: { span: 18 } },
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const updatedPhotoDetails = form.getFieldsValue(true);
      onChange(updatedPhotoDetails);
    } catch (err) {
      console.log(err, 'errors');
    }
  };

  return (
    <div data-testid="PropertyPhotoDetails" className={styles.root}>
      <Form
        form={form}
        initialValues={photo}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        {...formItemLayout}
      >
        <Form.Item name="description" label={t('photo.description')} rules={[{ required: true }]}>
          <Input placeholder={t('photo.description')} />
        </Form.Item>
        <Form.Item name="kind" label={t('photo.type')}>
          <Select
            showSearch
            placeholder={t('photo.type')}
            optionFilterProp="children"
            filterOption={(input, option) => !!option && option?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            className={styles.kind_label}
          >
            {photoKindOptions.map(kind => (
              <Select.Option key={kind} value={kind} className={styles.kind_label}>
                {kind}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="author" label={t('photo.author')}>
          <Input placeholder={t('photo.author')} />
        </Form.Item>
        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={onClose}>
              {t('general.buttons.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className={styles.submit_button}>
              {t('general.buttons.save')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PropertyPhotoDetails;
