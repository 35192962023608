import React from 'react';

import Cell from 'components/layout/cell';

import OccupancySection from './occupancy_section';

import IconGuestChild from 'static/icons-guest-child.svg';
import IconGuestDouble from 'static/icons-guest-double.svg';
import IconGuestSingle from 'static/icons-guest-single.svg';
import IconGuestInfant from 'static/icons-guest-infant.svg';
import styles from './rate_plan_occupancy.module.scss';
import { IRatePlanOccupancyProps } from './rate_plan_occupancy.types';

const CHILD_BASE_NUMBER = 1;
const ADULT_BASE_NUMBER = 2;

const getAdditionalSpaces = (spaces: number, baseAmount: number) => {
  return spaces > baseAmount ? spaces : null;
};

const RatePlanOccupancy: React.FC<IRatePlanOccupancyProps> = ({ occupancy, childrenOccupancy }) => {
  const { adults, children, infants } = occupancy;
  const additionalChildSpaces = getAdditionalSpaces(Number(children), CHILD_BASE_NUMBER);
  const isChildIconShown = children && childrenOccupancy;
  const isInfantIconShown = infants && childrenOccupancy;
  const additionalAdultsSpaces = getAdditionalSpaces(Number(adults), ADULT_BASE_NUMBER);
  const adultsOccupancyIcon = adults === 2 ? IconGuestDouble : IconGuestSingle;

  return (
    <Cell className={styles.occupancyCell}>
      <div className={styles.occupanciesContainer}>
        <OccupancySection type="adults" additionalSpaces={additionalAdultsSpaces} icon={adultsOccupancyIcon} />
        {Boolean(isChildIconShown) && (
          <OccupancySection type="children" additionalSpaces={additionalChildSpaces} icon={IconGuestChild} />
        )}
        {Boolean(isInfantIconShown) && (
          <OccupancySection type="infant" additionalSpaces={additionalChildSpaces} icon={IconGuestInfant} />
        )}
      </div>
    </Cell>
  );
};

export default RatePlanOccupancy;
