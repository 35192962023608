import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/api_keys';

export default () => {
  const [apiKeysData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const getApiKeys = useCallback(propertyId => {
    return actions.getApiKeys(dispatch, propertyId);
  }, []);

  const createApiKey = useCallback(propertyId => {
    return actions.createApiKey(dispatch, propertyId);
  }, []);

  const disableApiKey = useCallback(propertyId => {
    return actions.disableApiKey(dispatch, propertyId);
  }, []);

  const apiKeysActions = useMemo(
    () => ({
      getApiKeys,
      createApiKey,
      disableApiKey,
    }),
    [getApiKeys, createApiKey, disableApiKey],
  );

  return useMemo(() => ({ apiKeysData, apiKeysActions }), [apiKeysData, apiKeysActions]);
};
