import React, { useCallback } from 'react';
import { Dropdown as BootstrapDropdown } from 'react-bootstrap';

import Label from 'components/label';

import styles from './dropdown.module.scss';
import { IDropdownProps } from './dropdown.types';

const VERTICAL_ORIENTATION = 'vertical';

const Dropdown: React.FC<IDropdownProps> = ({ className, layout, children, title, show, onToggle, label }) => {
  const containerClass = layout === VERTICAL_ORIENTATION ? styles.containerVertical : styles.containerHorizontal;

  const handleVisibilityToggle = useCallback(
    // @ts-expect-error: Ignoring until type issues are resolved
    (newVisibilityState, event, meta) => {
      if (meta.source === 'select') {
        return;
      }

      onToggle(newVisibilityState);
    },
    [onToggle],
  );

  return (
    <div className={[containerClass, className].join(' ')}>
      {label && <Label>{label}</Label>}
      <BootstrapDropdown className={styles.dropdown} show={show} onToggle={handleVisibilityToggle}>
        <BootstrapDropdown.Toggle className={styles.dropdownToggle}>{title}</BootstrapDropdown.Toggle>
        <BootstrapDropdown.Menu className={styles.dropdownMenu}>{children}</BootstrapDropdown.Menu>
      </BootstrapDropdown>
    </div>
  );
};

export default Dropdown;
