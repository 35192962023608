import { useCallback, useMemo, useReducer } from 'react';
import { actions, INITIAL_STATE, reducer } from './reducers/payment_providers';

export default () => {
  const [paymentProvidersData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadPaymentProviders = useCallback(
    params => {
      return actions.loadPaymentProviders(dispatch, params);
    },
    [dispatch],
  );

  const createPaymentProvider = useCallback(
    params => {
      return actions.createPaymentProvider(dispatch, params);
    },
    [dispatch],
  );

  const updatePaymentProvider = useCallback(
    params => {
      return actions.updatePaymentProvider(dispatch, params);
    },
    [dispatch],
  );

  const deletePaymentProvider = useCallback(
    params => {
      return actions.deletePaymentProvider(dispatch, params);
    },
    [dispatch],
  );

  const resetPaymentProviders = useCallback(
    params => {
      return actions.resetPaymentProviders(dispatch, params);
    },
    [dispatch],
  );


  const paymentProvidersActions = useMemo(
    () => ({
      loadPaymentProviders,
      createPaymentProvider,
      updatePaymentProvider,
      deletePaymentProvider,
      resetPaymentProviders,
    }),
    [
      loadPaymentProviders,
      createPaymentProvider,
      updatePaymentProvider,
      deletePaymentProvider,
      resetPaymentProviders,
    ],
  );

  return useMemo(
    () => ({ paymentProvidersData, paymentProvidersActions }),
    [paymentProvidersData, paymentProvidersActions],
  );
};
