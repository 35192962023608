import ApiActions from 'api_actions';

export const SET_RESELLERS_LIST = 'SET_RESELLERS_LIST';
export const SET_RESELLERS_LOADING = 'SET_RESELLERS_LOADING';
export const SET_RESELLER_IS_UPDATING = 'SET_RESELLER_IS_UPDATING';
export const SET_UPDATED_RESELLER = 'SET_UPDATED_RESELLER';
export const SET_RESELLER_IS_CREATING = 'SET_RESELLER_IS_CREATING';

const setResellers = (dispatch, payload) => {
  return dispatch({ type: SET_RESELLERS_LIST, payload });
};

const setResellersLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLERS_LOADING, params });
};

const loadResellers = async (dispatch, params) => {
  try {
    setResellersLoading(dispatch);

    const data = await ApiActions.getResellers(params);

    setResellers(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setResellersLoading(dispatch, false);
  }
};

const setResellerIsCreating = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_IS_CREATING, params });
};

const createReseller = async (dispatch, params) => {
  try {
    setResellerIsCreating(dispatch);

    const data = await ApiActions.createReseller(params);
    setUpdatedReseller(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setResellerIsCreating(dispatch, false);
  }
};

const setResellerIsUpdating = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_IS_UPDATING, params });
};

const setUpdatedReseller = (dispatch, params) => {
  return dispatch({ type: SET_UPDATED_RESELLER, params });
};

const updateReseller = async (dispatch, params) => {
  try {
    setResellerIsUpdating(dispatch);

    const data = await ApiActions.updateReseller(params);
    setUpdatedReseller(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setResellerIsUpdating(dispatch, false);
  }
};

const lockOverrideReseller = async (dispatch, params) => {
  try {
    setResellerIsUpdating(dispatch);

    const data = await ApiActions.putResellerLockOverride(params);
    setUpdatedReseller(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setResellerIsUpdating(dispatch, false);
  }
};

export const actions = {
  setResellers,
  setResellersLoading,
  loadResellers,
  updateReseller,
  createReseller,
  setResellerIsCreating,
  setUpdatedReseller,
  setResellerIsUpdating,
  lockOverrideReseller,
};
