import { IOccupancy } from 'components/bookings_list/bookings_list.types';
import { IRatePlan } from 'components/booking_checkout_form/booking_checkout_form.types';
import React, { useEffect, useState } from 'react';

import NoRatesPlaceholder from './no_rates_placeholder';
import RatePlan from './rate_plan';
import PropertyUnitInfo from './property_unit_info';

import styles from './property_unit.module.scss';
import { IPropertyUnitProps } from './property_unit.types';

const DEFAULT_UNIT_RATES: IOccupancy = {};

const PropertyUnit: React.FC<IPropertyUnitProps> = props => {
  const {
    unit,
    currency,
    ratesOccupancyPerUnit,
    adultsOccupancy,
    checkinDate,
    disabled,
    childrenOccupancy,
    propertyType,
    onRatesOccupancyChange,
  } = props;

  const [occupiedSpaces, setOccupiedSpaces] = useState<number>(0);
  const [sortedRates, setSortedRates] = useState<Array<IRatePlan>>([]);

  const { ratePlans, id } = unit;
  const { [id]: roomRates = DEFAULT_UNIT_RATES } = ratesOccupancyPerUnit;

  const handleRatesOccupancyChange = (updatedRatesOccupancy: { [prop: string]: number }) => {
    onRatesOccupancyChange({ ...ratesOccupancyPerUnit, [id]: updatedRatesOccupancy });
  };

  useEffect(
    function updateAvailability() {
      const updatedOccupiedSpaces = Object.values(roomRates).reduce((a, b) => a + b, 0);

      setOccupiedSpaces(updatedOccupiedSpaces);
    },
    [roomRates],
  );

  useEffect(
    function updateRatesPlans() {
      if (!Array.isArray(ratePlans)) {
        return;
      }

      const ratesByOccupancyMatch: Array<IRatePlan> = ratePlans.sort((a, b) => {
        const aOccupancyMatchRating =
          Number(a.occupancy.children === childrenOccupancy) + Number(a.occupancy.adults === adultsOccupancy);

        const bOccupancyMatchRating =
          Number(b.occupancy.children === childrenOccupancy) + Number(b.occupancy.adults === adultsOccupancy);

        return bOccupancyMatchRating - aOccupancyMatchRating;
      });

      setSortedRates([...ratesByOccupancyMatch]);
    },
    [ratePlans, adultsOccupancy, childrenOccupancy],
  );

  return (
    <div className={styles.roomContainer}>
      <PropertyUnitInfo unit={unit} />
      <div className={styles.ratesList}>
        {sortedRates.map((ratePlan, index) => {
          return (
            <RatePlan
              propertyType={propertyType}
              disabled={disabled}
              key={`${ratePlan.id}-${index}`}
              ratePlan={ratePlan}
              currency={currency}
              occupiedSpaces={occupiedSpaces}
              ratesOccupancy={roomRates}
              checkinDate={checkinDate}
              onOccupancyChange={handleRatesOccupancyChange}
              adultsOccupancy={adultsOccupancy}
              childrenOccupancy={childrenOccupancy}
            />
          );
        })}
        {!sortedRates.length && <NoRatesPlaceholder propertyType={propertyType} />}
      </div>
    </div>
  );
};

export default PropertyUnit;
