// Generated with util/create-component.js
import React, { useState } from 'react';
import { Col, Row } from 'antd';
import classNames from 'classnames';

import { IGiftCardPhotosGalleryProps } from './gift_card_photos_gallery.types';
import styles from './gift_card_photos_gallery.module.scss';

const GiftCardPhotosGallery: React.FC<IGiftCardPhotosGalleryProps> = ({ photos }) => {
  const [activePhotoIndex, setActivePhotoIndex] = useState<number>(0);

  if (!photos?.length) return null;

  const imageWrapperClasses = (index: number) =>
    classNames(
      styles.imageWrapper,
      activePhotoIndex === index ? 'roundedWrapper' : '',
      activePhotoIndex === index ? styles.selectedImageWrapper : '',
    );

  return (
    <div data-testid="GiftCardPhotosGallery" className={styles.root}>
      <div className={styles.item}>
        <img src={photos?.[activePhotoIndex]?.publicUrl} className="roundedWrapper" alt="Gift Card" />
      </div>
      <Row className={styles.imagesWrapper} gutter={[0, 10]}>
        {photos.map((photo, index) => (
          <Col sm={4} xs={6} key={`photo-${index}`} className={styles.imageContainer}>
            <div className={imageWrapperClasses(index)}>
              <img
                src={photo.publicUrl}
                className={classNames(styles.galleryImage, 'roundedWrapper')}
                onClick={() => setActivePhotoIndex(index)}
                alt="Gift Card"
              />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GiftCardPhotosGallery;
