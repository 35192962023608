import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import ExpandButton from './expand_button';

import { IExpandableContainerProps } from './expendable_container.types';

const ExpandableContainer: React.FC<IExpandableContainerProps> = ({ title, children }) => {
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const isExpanded = Boolean(activeKey);

  const toggleActiveTab = () => {
    setActiveKey(activeKey ? undefined : '0');
  };

  return (
    <div>
      <ExpandButton title={title} expanded={isExpanded} onClick={toggleActiveTab} />
      <Accordion activeKey={activeKey}>
        <Accordion.Collapse eventKey="0">
          <>{children}</>
        </Accordion.Collapse>
      </Accordion>
    </div>
  );
};

export default ExpandableContainer;
