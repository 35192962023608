// Generated with util/create-component.js
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, notification } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { AuthenticationActionsContext } from 'containers/data_context';

import styles from './change_password_page.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const initialValues = {
  password: '',
  confirmPassword: '',
};

const validateMessages = {
  required: 'required',
};

const isUpdating = false;

const ChangePasswordPage: React.FC = () => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { updatePassword } = useContext(AuthenticationActionsContext);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const changePasswordData = form.getFieldsValue(true);

    try {
      await updatePassword(changePasswordData);
      openNotificationWithIcon('success');
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };
  return (
    <div data-testid="ChangePasswordPage" className={styles.root}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
        className={styles.form_wrapper}
      >
        <Form.Item
          label={t('general.other.password')}
          name="password"
          rules={[{ required: true }, { min: 6, message: t('general.password.validation_message') }]}
        >
          <Input.Password
            placeholder={t('general.other.password')}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          label={t('general.password.confirm_password')}
          name="confirmPassword"
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('general.password.confirm_password.not_match')));
              },
            }),
          ]}
        >
          <Input.Password
            placeholder={t('general.password.confirm_password')}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <div className={styles.footer}>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdating} className={styles.save_button}>
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ChangePasswordPage;
