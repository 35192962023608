import React from 'react';

import Label from 'components/label';

import styles from './info_entry.module.scss';
import { IInfoEntryProps } from './info_entry.types';

const InfoEntry: React.FC<IInfoEntryProps> = ({ title, text }) => {
  return (
    <div className={styles.container}>
      <Label className={styles.label}>{title}</Label>
      <div className={styles.content}>{text}</div>
    </div>
  );
};

export default InfoEntry;
