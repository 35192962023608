// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col } from 'antd';
import classNames from 'classnames';

import { ICancelGiftCardReservationProps } from './cancel_gift_card_reservation.types';
import styles from './cancel_gift_card_reservation.module.scss';

const CancelGiftCardReservation: React.FC<ICancelGiftCardReservationProps> = ({
  property,
  isLoading = false,
  successfulCancel = false,
  checkinDate,
  checkoutDate,
  reservationId,
  onViewGiftCards,
  onUseGiftCard,
  onConfirmCancel,
}) => {
  const { t } = useTranslation();

  const coverImage = property?.storeSetting?.photoUrl ? (
    <img src={property?.storeSetting?.photoUrl} className={classNames(styles.coverImage, 'roundedWrapper')} />
  ) : (
    <div>{property?.title}</div>
  );

  return (
    <div data-testid="CancelGiftCardReservation" className={styles.root}>
      <Row justify="center" gutter={[10, 10]}>
        <Col span={24}>
          <div className={styles.coverImageContainer}>{coverImage}</div>
        </Col>
        <Col span={24}>
          <div className={classNames(styles.title, 'highlighted')}>{t('gift_card.cancel_reservation')}</div>
        </Col>
        <Col span={24}>
          <div className={styles.reservationDetails}>{`${t('gift_card.reservation_id')}: ${reservationId}`}</div>
        </Col>
        <Col span={24}>
          <div className={styles.reservationDetails}>{`${t('booking.checkin_date')}: ${checkinDate}`}</div>
        </Col>
        <Col span={24}>
          <div className={styles.reservationDetails}>{`${t('booking.checkout_date')}: ${checkoutDate}`}</div>
        </Col>
        <Col span={24}>
          <div className={styles.description}>
            {successfulCancel ? t('gift_card.booking_cancelled') : t('gift_card.booking_warning_cancelled')}
          </div>
        </Col>
        {successfulCancel ? (
          <>
            <Col span={12} className={styles.center}>
              <Button
                size="large"
                className={classNames(styles.backToStoreButton, 'blackButton', 'highlighted')}
                onClick={onViewGiftCards}
              >
                {t('gift_card.view_gift_cards')}
              </Button>
            </Col>

            <Col span={12} className={styles.center}>
              <Button
                size="large"
                className={classNames(styles.useGiftCardButton, 'blackButton', 'highlighted')}
                onClick={onUseGiftCard}
              >
                {t('gift_card.use_gift_card')}
              </Button>
            </Col>
          </>
        ) : (
          <Col span={24} className={styles.center}>
            <Button
              size="large"
              className={classNames(styles.confirmButton, 'blackButton', 'highlighted')}
              onClick={onConfirmCancel}
              loading={isLoading}
            >
              {t('general.confirm')}
            </Button>
          </Col>
        )}
      </Row>
    </div>
  );
};

export default CancelGiftCardReservation;
