// Generated with util/create-component.js
import React, { useState } from 'react';
import { Row, Col, Select, InputNumber, Checkbox, Divider, Button, notification } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useTranslation } from 'react-i18next';

import Loading from 'components/loading';
import CONTRACT_MODIFIER from 'constants/contracts_modifier';

import {
  IContractCreateProps,
  IContractPropertyRate,
  IResellerInitiatedContractPayload,
} from './contract_create.types';
import styles from './contract_create.module.scss';

const { Option } = Select;

const ContractCreate: React.FC<IContractCreateProps> = ({
  selectedEntity,
  closeDrawer,
  isReseller,
  rateTypes,
  isLoading,
  createContractLoading,
  createContract,
  propertyType,
}) => {
  const [checkedRatePlanIds, setCheckedRatePlanIds] = useState<string[]>([]);
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [modifierLogic, setModifierLogic] = useState<string>(CONTRACT_MODIFIER.DECREASE_BY_PERCENT);
  const [modifierAmount, setModifierAmount] = useState<number | null>(0);
  const { t } = useTranslation();
  const sendContractDisabled =
    isNaN(Number(modifierAmount)) ||
    modifierAmount === null ||
    !modifierLogic ||
    !checkedRatePlanIds.length ||
    createContractLoading;

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    const allRates: string[] = [];
    const checked = e.target.checked;
    if (checked) {
      rateTypes?.forEach((rt: IContractPropertyRate) => {
        rt.ratePlans.forEach(rr => allRates.push(rr.id));
      });
    }
    setCheckAll(checked);
    setIndeterminate(false);
    setCheckedRatePlanIds(checked ? allRates : []);
  };

  const handleSingleRateChange = (e: CheckboxChangeEvent) => {
    const value = e.target.value;
    const checked = e.target.checked;
    const allRates: string[] = [];
    const checkedListUpdated: string[] = checked
      ? [...checkedRatePlanIds, value]
      : checkedRatePlanIds.filter(roomRate => roomRate !== value);

    rateTypes.forEach((rt: IContractPropertyRate) => {
      rt.ratePlans.forEach(rr => {
        allRates.push(rr.id);
      });
    });

    setIndeterminate(!!checkedListUpdated.length && checkedListUpdated.length < allRates.length);
    setCheckAll(allRates.length === checkedListUpdated.length);
    setCheckedRatePlanIds(checkedListUpdated);
  };

  const handleModifierLogic = (value: string) => {
    setModifierLogic(value);
  };

  const handleModifierAmount = (value: number | null) => {
    setModifierAmount(value);
  };

  const handleSendContract = async (): Promise<void> => {
    let checkedRoomRatesUpdated: IContractPropertyRate[] = [];
    if (!checkAll) {
      // logic for mapping individual rate plan to its rate based on selected checkboxes
      checkedRatePlanIds.forEach(ratePlanId => {
        rateTypes.forEach((rate: IContractPropertyRate) => {
          rate.ratePlans.forEach(ratePlan => {
            if (ratePlan.id === ratePlanId) {
              const hasSelectedRatesIndex = checkedRoomRatesUpdated.findIndex(chr => chr.id === rate.id);
              const hasRatePlans =
                hasSelectedRatesIndex !== -1 && checkedRoomRatesUpdated[hasSelectedRatesIndex].ratePlans;
              if (hasRatePlans && hasRatePlans.length > 0) {
                checkedRoomRatesUpdated[hasSelectedRatesIndex].ratePlans = [...hasRatePlans, ratePlan];
              } else {
                checkedRoomRatesUpdated = [
                  ...checkedRoomRatesUpdated,
                  {
                    ...rate,
                    ...{
                      ratePlans: [{ ...ratePlan }],
                    },
                  },
                ];
              }
            }
          });
        });
      });
    }

    const payload: IResellerInitiatedContractPayload = {
      propertyId: selectedEntity.propertyId,
      propertyType,
      contract: {
        modifierLogic: modifierLogic,
        modifierAmount: modifierAmount || 0,
        roomRates: checkAll ? rateTypes : checkedRoomRatesUpdated,
      },
    };
    if (!isReseller) {
      payload.agentId = selectedEntity.id;
    }
    try {
      await createContract(payload);
      openNotificationWithIcon('success');
      closeDrawer();
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (e: any) {
      openNotificationWithIcon('error', e.message);
    }
  };

  const openNotificationWithIcon = (type: 'success' | 'error', message?: string) => {
    if (type === 'success') {
      return notification['success']({
        message: t('contracts.created_successfully'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: message ? message : t('general.error_description'),
      });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading />
      </div>
    );
  }
  return (
    <div data-testid="ContractCreate" className={styles.root}>
      <Row>
        <Col span={24} className={styles.alignRight}>
          <Button
            type="primary"
            onClick={handleSendContract}
            disabled={sendContractDisabled}
            loading={createContractLoading}
            data-testid="ContractCreate.SendContract"
          >
            {t('contracts.send_contract')}
          </Button>
        </Col>
      </Row>
      <p>{t('contracts.price_modifier')}</p>
      <Row gutter={12}>
        <Col span={12}>
          <Select
            placeholder={t('general.choose_type')}
            className={styles.fullWidth}
            onChange={handleModifierLogic}
            data-testid="ContractCreate.ModifierType"
            value={modifierLogic}
          >
            <Option value={CONTRACT_MODIFIER.INCREASE_BY_AMOUNT}>
              {t('rate_plan.modificator_option.increase_by_amount')}
            </Option>
            <Option value={CONTRACT_MODIFIER.DECREASE_BY_AMOUNT}>
              {t('rate_plan.modificator_option.decrease_by_amount')}
            </Option>
            <Option value={CONTRACT_MODIFIER.INCREASE_BY_PERCENT}>
              {t('rate_plan.modificator_option.increase_by_percent')}
            </Option>
            <Option value={CONTRACT_MODIFIER.DECREASE_BY_PERCENT}>
              {t('rate_plan.modificator_option.decrease_by_percent')}
            </Option>
          </Select>
        </Col>
        <Col span={12}>
          <InputNumber
            onChange={handleModifierAmount}
            className={styles.fullWidth}
            min={0}
            type="number"
            placeholder={t('general.enter_value')}
            data-testid="ContractCreate.ModifierAmount"
            value={modifierAmount}
          />
        </Col>
      </Row>
      <Divider />
      <p>{t('contracts.choose_properties')}</p>
      <Checkbox
        className={styles.selectAll}
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        {t('inventory.bulk_update.select_all')}
      </Checkbox>
      {rateTypes &&
        rateTypes.map((rt: IContractPropertyRate) => (
          <div key={rt.id}>
            <Checkbox.Group value={checkedRatePlanIds}>
              {rt.title}
              <div className={styles.individualRate}>
                {rt?.ratePlans && rt.ratePlans.length
                  ? rt.ratePlans.map(rp => (
                      <div key={rp.id}>
                        <Checkbox value={rp.id} onChange={handleSingleRateChange} /> {rp.title} (<UserOutlined />{' '}
                        {rp.occupancy})
                      </div>
                    ))
                  : t('contracts.no_rates')}
              </div>
            </Checkbox.Group>
          </div>
        ))}
    </div>
  );
};

export default ContractCreate;
