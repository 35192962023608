// Generated with util/create-component.js
import React from 'react';
import { useHistory } from 'react-router-dom';

import { IPageTitleProps } from './page_title.types';
import styles from './page_title.module.scss';

const PageTitle: React.FC<IPageTitleProps> = ({ steps }) => {
  const history = useHistory();

  const handleRedirect = (link?: string) => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <div data-testid="PageTitle" className={styles.pageTitle}>
      {steps.map(({ title, link }, index) => {
        if (index < steps.length - 1) {
          return (
            <div key={`step-${index}`} onClick={() => handleRedirect(link)}>
              <span className={link ? styles.link : styles.disabled}>{`${title}`}</span> /&nbsp;{' '}
            </div>
          );
        }
      })}
      <div className={styles.boldLabel}>{steps[steps.length - 1]?.title}</div>
    </div>
  );
};

export default PageTitle;
