import i18n from 'i18next';

const t = subPath => i18n.t(`hotel_page.hotel_policy.${subPath}`);

const concatSentences = sentences => sentences.filter(Boolean).join('. ');

const getAgePolicy = propertyPolicy => {
  const { isAdultsOnly } = propertyPolicy;

  return isAdultsOnly ? i18n.t('hotel_policy.adults_only') : i18n.t('hotel_policy.all_ages');
};

const getInternetPolicy = propertyPolicy => {
  const { currency, internetAccessCost, internetAccessCoverage, internetAccessType } = propertyPolicy;

  if (internetAccessType === 'none') {
    return i18n.t('hotel_policy.internet.not_available');
  }

  const coverage = `${i18n.t('hotel_policy.internet.coverage_title')} ${i18n.t('general.in')} ${i18n.t(
    `hotel_policy.internet.coverage_options.${internetAccessCoverage}`,
  )}`;
  const type = `${i18n.t('hotel_policy.internet.type_title')} ${i18n.t('general.is')} ${i18n.t(
    `hotel_policy.internet.type_options.${internetAccessType}`,
  )}`;
  const costValue = internetAccessCost
    ? `${internetAccessCost} ${currency}`
    : i18n.t('hotel_policy.internet.cost_options.free');
  const cost = `${i18n.t('hotel_policy.internet.cost_title')} ${i18n.t('general.is')} ${costValue}`;

  return concatSentences([type, coverage, cost]);
};

const getParkingPolicy = propertyPolicy => {
  const { parkingIsPrivate, parkingReservation, parkingType } = propertyPolicy;

  if (parkingType === 'none') {
    return i18n.t('parking.not_available');
  }

  const type = `${i18n.t('parking.type_title')} ${i18n.t('general.is')} ${i18n.t(
    `parking.type_options.${parkingType}`,
  )}`;
  const reservation = `${i18n.t('parking.reservation_title')} ${i18n.t(
    `parking.reservation_options.${parkingReservation}`,
  )}`;
  const propertyType = parkingIsPrivate
    ? i18n.t('parking.property_options.private')
    : i18n.t('parking.property_options.public');
  const property = `${i18n.t('parking.property_title')} ${i18n.t('general.is')} ${propertyType}`;

  return concatSentences([type, reservation, property]);
};

const getPetsPolicy = propertyPolicy => {
  const { petsNonRefundableFee, petsPolicy, petsRefundableDeposit, currency } = propertyPolicy;

  if (petsPolicy === 'not_allowed') {
    return t('pets.options.not_allowed');
  }

  const policy = t(`pets.options.${petsPolicy}`);
  const parsedFee = parseFloat(petsNonRefundableFee);
  const fee =
    parsedFee &&
    `${t('pets')} ${i18n.t('general.is')} ${petsNonRefundableFee} ${currency}`;
  const parsedDeposit = parseFloat(petsRefundableDeposit);
  const deposit =
    parsedDeposit &&
    `${t('pets.refundable_deposit')} ${i18n.t(
      'general.is',
    )} ${petsRefundableDeposit} ${currency}`;

  return concatSentences([policy, fee, deposit]);
};

const getSmokingPolicy = propertyPolicy => {
  const { smokingPolicy } = propertyPolicy;

  return t(`smoking.options.${smokingPolicy}`);
};

const getPropertyPolicyText = propertyPolicy => {
  if (!propertyPolicy) {
    return null;
  }

  const agePolicy = getAgePolicy(propertyPolicy);
  const internetPolicy = getInternetPolicy(propertyPolicy);
  const parkingPolicy = getParkingPolicy(propertyPolicy);
  const petsPolicy = getPetsPolicy(propertyPolicy);
  const smokingPolicy = getSmokingPolicy(propertyPolicy);

  return concatSentences([agePolicy, internetPolicy, parkingPolicy, petsPolicy, smokingPolicy]);
};

export default getPropertyPolicyText;
