export const formatRatePlan = ratePlanData => {
  if (!ratePlanData) return;
  const { relationships, ...params } = ratePlanData;
  const { property, propertyUnit } = relationships;

  const formattedData = {
    ...params,
    propertyId: property?.data?.id,
    roomTypeId: propertyUnit?.data?.id,
    primaryOccupancy: getPrimaryOccupancy(params.options),
  };
  return formattedData;
};

export const filterBasicRatePlans = ratePlansData => {
  const filteredRatePlans = ratePlansData?.filter(rp => !rp?.relationships?.channel);
  return filteredRatePlans;
};

const getPrimaryOccupancy = (options) => {
  const primaryOccupancy = options.find(option => option.isPrimary);
  return primaryOccupancy?.occupancy;
}
