import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

import styles from './bed_option.module.scss';
import { IBedOptionProps } from './bed_option.types';

const BedOption: React.FC<IBedOptionProps> = ({ title, count }) => {
  return (
    <div className={styles.bed}>
      <div className={styles.bedTitle}>{`${count} x ${title}`}</div>
      <CheckOutlined className={styles.bedIcon} />
    </div>
  );
};
export default BedOption;
