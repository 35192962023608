import React from 'react';

import styles from './panel.module.scss';
import { IPanelProps } from './panel.types';

const Panel: React.FC<IPanelProps> = ({ title, addOn, children }) => {
  return (
    <div className={styles.panelContainer}>
      <div className={styles.panelTitle}>
        <span>{title}</span>
        <span>{addOn}</span>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default Panel;
