// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, Radio, Select, Typography, Col, Row } from 'antd';
import { DeleteOutlined, EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

import { IUser } from 'components/admin/users/users_list/users_list.types';
import { IProperty } from 'components/admin/properties/properties_list/properties_list.types';

import { IUserFormProps, ISupplierRole, IReservaHotelUsersAttributes, IUserFormValues } from './user_form.types';
import styles from './user_form.module.scss';
import { IReseller } from 'components/admin/resellers/resellers_list/resellers_list.types';

const { Text } = Typography;

const UserForm: React.FC<IUserFormProps> = ({
  user,
  onCancel,
  onSaveUser,
  isSaving,
  properties,
  resellers,
  isLoadingResellers,
  isChangeable,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const radioOptions = [
    { value: true, label: t('general.yes') },
    { value: false, label: t('general.no') },
  ];

  const roleOptions = [
    { value: 'manager', label: t('general.manager') },
    { value: 'user', label: t('general.user') },
  ];

  const initialValues = {
    agentId: null,
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
    sendBookingNotificationEmail: true,
    reservaHotelUsersAttributes: [{ reservaHotelId: null, role: roleOptions?.[1].value }],
  };

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const getAvailableProperties = (index?: number) => {
    const reservaHotelUsersAttributes = JSON.parse(JSON.stringify(form.getFieldValue('reservaHotelUsersAttributes')));

    return properties?.filter((p: IProperty) => {
      return !reservaHotelUsersAttributes?.find((rh: IReservaHotelUsersAttributes, ind: number) => {
        if (index === ind) return false;
        return rh.reservaHotelId === p.id && !rh._destroy;
      });
    });
  };

  const resellersOptions = useMemo(() => {
    const options = resellers?.map((ag: IReseller) => ({ value: ag.id, label: ag.name }));
    return options;
  }, [resellers]);

  const handleSubmit = async (): Promise<void> => {
    try {
      let user = form.getFieldsValue(true);

      const { reservaHotelUsersAttributes, ...rest } = user;
      const filteredHotelUsers = reservaHotelUsersAttributes.filter(
        (rh: IReservaHotelUsersAttributes) => rh.reservaHotelId !== null,
      );
      user = { ...rest, reservaHotelUsersAttributes: filteredHotelUsers };

      await onSaveUser(user);
    } catch (e) {
      console.error(e);
    }
    onCancel();
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleAddPropertyRole = () => {
    const reservaHotelUsersAttributes = JSON.parse(JSON.stringify(form.getFieldValue('reservaHotelUsersAttributes')));
    reservaHotelUsersAttributes.push({ reservaHotelId: null, role: roleOptions?.[1].value });
    form.setFieldsValue({ reservaHotelUsersAttributes });
  };

  const handleDeleteRole = (index: number) => {
    const reservaHotelUsersAttributes = JSON.parse(JSON.stringify(form.getFieldValue('reservaHotelUsersAttributes')));
    reservaHotelUsersAttributes[index]._destroy = true;

    form.setFieldsValue({ reservaHotelUsersAttributes });
  };

  const handleUpdatePropertyRole = (reservaHotelId: IProperty['id'], index: number) => {
    const reservaHotelUsersAttributes = JSON.parse(JSON.stringify(form.getFieldValue('reservaHotelUsersAttributes')));
    reservaHotelUsersAttributes[index].reservaHotelId = reservaHotelId;

    const existingRole = user?.reservaHotels?.find(rh => rh.id === reservaHotelId);
    if (existingRole) {
      reservaHotelUsersAttributes[index].id = existingRole.reservaHotelUserId;
    } else {
      delete reservaHotelUsersAttributes[index].id;
    }

    const hasRoleIndex = reservaHotelUsersAttributes.findIndex(
      (rh: ISupplierRole, ind: number) => rh.reservaHotelId === reservaHotelId && ind !== index,
    );
    if (hasRoleIndex !== -1) {
      reservaHotelUsersAttributes.splice(hasRoleIndex, 1);
    }

    if (reservaHotelUsersAttributes?.length === 0) {
      reservaHotelUsersAttributes.push({ reservaHotelId: null, role: roleOptions?.[1].value });
    }
    form.setFieldsValue({ reservaHotelUsersAttributes });
  };

  const getRHAttributes = () => {
    if (user?.reservaHotels?.length) {
      return user?.reservaHotels?.map((p: IProperty) => {
        return {
          reservaHotelId: p.id,
          role: p.role,
          ...{ ...(p.reservaHotelUserId && { id: p.reservaHotelUserId }) },
        };
      });
    }

    return [{ reservaHotelId: null, role: roleOptions?.[1].value }];
  };

  const isAddRoleDisabled = (userReservaHotels: ISupplierRole[]) =>
    !isChangeable || properties?.length === userReservaHotels?.filter(rh => !rh._destroy).length;

  const formatUsersData = (user: IUser) => {
    return {
      agentId: user?.agent?.id || null,
      id: user.id,
      name: user.name,
      email: user.email,
      status: user.status,
      reservaHotelUsersAttributes: getRHAttributes(),
      sendBookingNotificationEmail: user.sendBookingNotificationEmail,
    };
  };

  const shouldUpdateAddPropertyRole = (prevValues: IUserFormValues, currentValues: IUserFormValues) =>
    prevValues.agentId !== currentValues.agentId ||
    JSON.stringify(prevValues.reservaHotelUsersAttributes) !==
      JSON.stringify(currentValues.reservaHotelUsersAttributes);

  return (
    <div data-testid="UserForm" className={styles.root}>
      <Form
        form={form}
        initialValues={user ? formatUsersData(user) : initialValues}
        onFinish={handleSubmit}
        validateMessages={validateMessages}
        layout="vertical"
      >
        <Form.Item
          label={t('general.other.email')}
          name="email"
          rules={[{ required: true }, { type: 'email', message: t('general.email.valid_message') }]}
        >
          <Input placeholder={t('general.other.email')} />
        </Form.Item>
        <Form.Item label={t('user.username')} name="name" rules={[{ required: true }]}>
          <Input placeholder={t('user.username')} />
        </Form.Item>

        <Form.Item
          label={t('general.other.password')}
          name="password"
          rules={[{ required: !user }, { min: 6, message: t('general.password.validation_message') }]}
          hidden={!!user}
        >
          <Input.Password
            placeholder={t('general.other.password')}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <Form.Item
          label={t('general.password.confirm')}
          name="confirmPassword"
          data-testid="ConfirmPassword"
          rules={[
            {
              required: !user,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(new Error(t('general.password.confirm_password.not_match')));
              },
            }),
          ]}
          hidden={!!user}
        >
          <Input.Password
            placeholder={t('general.password.confirm')}
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
          />
        </Form.Item>

        <legend>{t('general.roles')}</legend>
        <Form.Item
          noStyle
          hidden={!isLoadingResellers && !resellersOptions?.length}
          shouldUpdate={(prevValues, currentValues) =>
            JSON.stringify(prevValues.reservaHotelUsersAttributes) !==
            JSON.stringify(currentValues.reservaHotelUsersAttributes)
          }
        >
          {({ getFieldValue }) => {
            return (
              <Form.Item name="agentId" hidden={!isLoadingResellers && !resellersOptions?.length}>
                <Select
                  placeholder={t('general.reseller')}
                  showSearch
                  allowClear
                  optionFilterProp="label"
                  filterOption={true}
                  options={resellersOptions}
                  loading={isLoadingResellers}
                  disabled={
                    getFieldValue('reservaHotelUsersAttributes')?.filter(
                      (rh: IReservaHotelUsersAttributes) => rh.reservaHotelId !== null && !rh._destroy,
                    )?.length > 0 || !isChangeable
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.roles !== currentValues.roles ||
            prevValues.agentId !== currentValues.agentId ||
            JSON.stringify(prevValues.reservaHotelUsersAttributes) !==
              JSON.stringify(currentValues.reservaHotelUsersAttributes)
          }
        >
          {({ getFieldValue }) => {
            return getFieldValue('reservaHotelUsersAttributes')?.map((rh: ISupplierRole, index: number) => {
              if (!rh._destroy) {
                return (
                  <Row key={`role-${index}`} gutter={6} justify="space-between">
                    <Col span={11}>
                      <Form.Item
                        name={['reservaHotelUsersAttributes', index, 'reservaHotelId']}
                        rules={[{ required: !getFieldValue('agentId') }]}
                      >
                        <Select
                          showSearch
                          optionFilterProp="children"
                          placeholder={t('general.property_title')}
                          disabled={getFieldValue('agentId') || !isChangeable}
                          onChange={val => handleUpdatePropertyRole(val, index)}
                        >
                          {getAvailableProperties(index)?.map(p => (
                            <Select.Option key={p.id} value={p.id}>
                              {p.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={11}>
                      <Form.Item name={['reservaHotelUsersAttributes', index, 'role']} rules={[{ required: true }]}>
                        <Radio.Group
                          options={roleOptions}
                          optionType="button"
                          buttonStyle="solid"
                          disabled={getFieldValue('agentId') || !isChangeable}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={2}
                      className={
                        getFieldValue('agentId') || !isChangeable
                          ? styles.disabledDeleteContainer
                          : styles.buttonContainer
                      }
                    >
                      <div className={styles.button} onClick={() => handleDeleteRole(index)}>
                        <DeleteOutlined className={styles.deleteIcon} />
                      </div>
                    </Col>
                  </Row>
                );
              }
            });
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={shouldUpdateAddPropertyRole}>
          {({ getFieldValue }) => {
            return (
              !getFieldValue('agentId') &&
              !isAddRoleDisabled(getFieldValue('reservaHotelUsersAttributes')) && (
                <Text underline={true} onClick={handleAddPropertyRole}>
                  {t('general.add_property_role')}
                </Text>
              )
            );
          }}
        </Form.Item>

        <Form.Item
          name="sendBookingNotificationEmail"
          label={t('user.send_booking_notification_email')}
          rules={[{ required: true }]}
          className={styles.sendBookingNotificationEmail}
        >
          <Radio.Group options={radioOptions} optionType="button" buttonStyle="solid" />
        </Form.Item>

        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={handleCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSaving}
              className={styles.saveButton}
              disabled={isSaving}
            >
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default UserForm;
