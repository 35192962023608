import React, { useCallback, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import routes from 'routing/routes';
import { BookingDataContext } from 'containers/data_context';

const HeadManager: React.FC = () => {
  const { t } = useTranslation();

  const { property } = useContext(BookingDataContext);
  const { data } = property;

  const isLoginPage = !!useRouteMatch(routes.loginPage);
  const isSearchPage = !!useRouteMatch(routes.searchPage);
  const isSearchCarsPage = !!useRouteMatch(routes.searchCarsPage);
  const isHomePage = !!useRouteMatch({ path: routes.homePage, exact: true });
  const isBookingsPage = !!useRouteMatch(routes.bookingsPage);
  const isProfilePage = !!useRouteMatch(routes.profilePage);
  const isSettingsPage = !!useRouteMatch(routes.settingsPage);
  const isContractsPage = !!useRouteMatch(routes.contractsPage);
  const isInventoryPage = !!useRouteMatch(routes.inventoryPage);
  const isAdminPage = !!useRouteMatch(routes.adminPage);
  const isApiKeysPage = !!useRouteMatch(routes.apiKeysPage);
  const isChangePasswordPage = !!useRouteMatch(routes.changePassword);
  const isHotelPage = !!useRouteMatch(routes.propertyPage);
  const isCheckoutPage = !!useRouteMatch(routes.checkoutPage);
  const isConfirmationPage = !!useRouteMatch(routes.confirmationPage);
  const isDefaultPage = !!useRouteMatch(routes.default);
  const isGiftCardStorePage = !!useRouteMatch(routes.giftCardStorePage);
  const isDefaultGiftCardStorePage = !!useRouteMatch(routes.oldGiftCardStorePage);
  const isVerifyResetPasswordEmailPage = !!useRouteMatch(routes.verifyResetPasswordEmail);

  const getDocTitle = useCallback(() => {
    if (isLoginPage) return t('general.login');

    if (isProfilePage) return t('link.profile');

    if (isSettingsPage) return t('link.settings');

    if (isContractsPage) return t('general.contracts');

    if (isInventoryPage) return t('link.inventory');

    if (isAdminPage) return t('link.admin');

    if (isApiKeysPage) return t('general.api_keys');

    if (isChangePasswordPage) return t('user.change_password');

    if (isVerifyResetPasswordEmailPage) return t('auth.token_verification.verifying');

    if (isSearchPage || isSearchCarsPage || isHomePage) return t('dashboard.search_placeholder');

    if (isBookingsPage) return t('link.bookings');

    if (isHotelPage || isCheckoutPage || isConfirmationPage) {
      return data?.title ?? t('general.other.loading');
    }

    if (isDefaultPage) return '404';

    if (isGiftCardStorePage || isDefaultGiftCardStorePage) {
      return t('general.gift_cards');
    }

    return 'Reserva';
  }, [
    data,
    t,
    isHomePage,
    isSearchCarsPage,
    isLoginPage,
    isBookingsPage,
    isProfilePage,
    isSettingsPage,
    isContractsPage,
    isInventoryPage,
    isAdminPage,
    isApiKeysPage,
    isChangePasswordPage,
    isConfirmationPage,
    isDefaultPage,
    isHotelPage,
    isCheckoutPage,
    isSearchPage,
    isGiftCardStorePage,
    isDefaultGiftCardStorePage,
  ]);

  const docTitle = getDocTitle();

  return (
    <Helmet>
      <title>{docTitle}</title>
    </Helmet>
  );
};

export default HeadManager;
