import React, { useMemo } from 'react';

import Cell from 'components/layout/cell';
import isCarProperty from 'utils/is_car_property';

import Date from './date';
import styles from './dates.module.scss';
import { IDatesProps } from './dates.types';

const Dates: React.FC<IDatesProps> = ({ checkinDate, checkoutDate, propertyType }) => {
  const isCar = useMemo(() => isCarProperty(propertyType), [propertyType]);

  return (
    <Cell>
      <div className={styles.datesWrapper}>
        <Date date={checkinDate} type={isCar ? 'pickup' : 'checkin'} showTime={isCar} />
        <Date date={checkoutDate} type={isCar ? 'dropoff' : 'checkout'} showTime={isCar} />
      </div>
    </Cell>
  );
};

export default Dates;
