import React from 'react';
import { useTranslation } from 'react-i18next';

import Currency from 'components/currency';

import InfoEntry from '../info_entry';
import { IInternetAccessPoliciesProps } from './internet_access_policies.types';

const TRANSLATION_PATH = 'hotel_policy.internet';

const InternetAccessPolicies: React.FC<IInternetAccessPoliciesProps> = ({ propertyPolicy }) => {
  const { t } = useTranslation();
  const { internetAccessType, internetAccessCost, internetAccessCoverage, currency } = propertyPolicy;

  const internetAcessPrice = internetAccessCost ? (
    <Currency amount={internetAccessCost} currency={currency} />
  ) : (
    t(`${TRANSLATION_PATH}.cost_options.free`)
  );

  const isInternetAvailable = internetAccessType !== 'none';

  return (
    <>
      <InfoEntry
        title={t(`${TRANSLATION_PATH}.type_title`)}
        text={t(`${TRANSLATION_PATH}.type_options.${internetAccessType}`)}
      />
      {isInternetAvailable && (
        <>
          <InfoEntry
            title={t(`${TRANSLATION_PATH}.coverage_title`)}
            text={t(`${TRANSLATION_PATH}.coverage_options.${internetAccessCoverage}`)}
          />
          <InfoEntry title={t(`${TRANSLATION_PATH}.cost_title`)} text={internetAcessPrice} />
        </>
      )}
    </>
  );
};

export default InternetAccessPolicies;
