import React from 'react';
import { useTranslation } from 'react-i18next';

import Currency from 'components/currency';
import Cell from 'components/layout/cell';

import styles from './total_price.module.scss';
import { ITotalPriceProps } from './total_price.types';

const TotalPrice: React.FC<ITotalPriceProps> = ({ totalPrice, currency }) => {
  const { t } = useTranslation();

  return (
    <Cell noLine>
      <h6 className={styles.total}>
        {t('hotel_page.total')}
        <Currency amount={totalPrice} currency={currency} />
      </h6>
    </Cell>
  );
};

export default TotalPrice;
