// Generated with util/create-component.js
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Button, Radio, Spin, Row, Col, notification } from 'antd';
import { LoadingOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import currencies from 'world-currencies';

import {
  PropertySettingsDataContext,
  PropertyRatePlansActionsContext,
  PropertyRatePlansDataContext,
  PropertyUnitsDataContext,
  PropertyPoliciesDataContext,
  PropertyPoliciesActionsContext,
} from 'containers/data_context';
import { IOption } from 'components/property_rate_plans_per_unit/property_rate_plans_per_unit.types';
import mealTypes from 'constants/meal_types';

import { IPropertyRatePlanFormProps } from './property_rate_plan_form.types';
import styles from './property_rate_plan_form.module.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const currenciesOptions = Object.values(currencies).map(currency => ({
  label: `${currency.name} (${currency.iso.code})`,
  value: currency.iso.code,
}));

const formItemLayout = {
  labelCol: { sm: { span: 24 }, md: { span: 8 } },
  wrapperCol: { sm: { span: 24 }, md: { span: 16 } },
};

const PropertyRatePlanForm: React.FC<IPropertyRatePlanFormProps> = ({
  onCancel,
  defaultOccupancy,
  maxOccupancy,
  ratePlanId = null,
  unitId = null,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { selectedProperty, propertyDetails } = useContext(PropertySettingsDataContext);

  const {
    cancellationPolicies: { data: cancellationPoliciesOptions, isLoading: isLoadingCancellationPolicies },
  } = useContext(PropertyPoliciesDataContext);

  const { loadPropertyCancellationPolicies } = useContext(PropertyPoliciesActionsContext);

  const {
    propertyRatePlan: { data: ratePlan, isLoading: isLoadingRatePlan },
    isUpdatingRatePlan,
  } = useContext(PropertyRatePlansDataContext);

  const { loadPropertyRatePlan, updateRatePlan, createRatePlan } = useContext(PropertyRatePlansActionsContext);

  const {
    propertyUnits: { data: units },
  } = useContext(PropertyUnitsDataContext);

  useEffect(() => {
    if (selectedProperty) {
      loadPropertyCancellationPolicies({ propertyId: selectedProperty });
    }
    if (selectedProperty && ratePlanId) {
      loadPropertyRatePlan({ propertyId: selectedProperty, ratePlanId });
    } else {
      form.setFieldsValue(defaultValues);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue(ratePlan);
  }, [ratePlan]);

  const isEdit = !!ratePlanId;

  const defaultValues = {
    primaryOccupancy: defaultOccupancy,
    title: null,
    propertyId: selectedProperty,
    unitId,
    parentRatePlanId: null,
    childrenFee: 0,
    infantFee: 0,
    options: [
      {
        derivedOption: { rate: [] },
        occupancy: defaultOccupancy,
        isPrimary: true,
        rate: 0,
      },
    ],
    currency: propertyDetails?.data?.currency,
    sellMode: 'per_room',
    rateMode: 'manual',
    autoRateSettings: {
      decreaseMode: '%',
      decreaseValue: 0,
      increaseMode: '%',
      increaseValue: 0,
    },
    cancellationPolicyId: null,
    inherit_stop_sell: false,
    inherit_rate: false,
    inherit_min_stay_through: false,
    inherit_min_stay_arrival: false,
    inherit_max_stay: false,
    inherit_closed_to_departure: false,
    inherit_closed_to_arrival: false,
    max_stay: [0, 0, 0, 0, 0, 0, 0],
    min_stay_arrival: [1, 1, 1, 1, 1, 1, 1],
    min_stay_through: [1, 1, 1, 1, 1, 1, 1],
    closed_to_arrival: [false, false, false, false, false, false, false],
    closed_to_departure: [false, false, false, false, false, false, false],
    stop_sell: [false, false, false, false, false, false, false],
    max_sell: null,
    max_availability: null,
    availability_offset: 0,
  };

  const occupancyOptions: Array<number> = [];

  let occ = maxOccupancy;

  while (occ > 0) {
    occupancyOptions.push(occ);
    occ--;
  }

  const unitOptions = units?.map((unit: { id: string; title: string }) => ({
    value: unit.id,
    label: unit.title,
  }));

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const modificatorOptions = [
    { value: 'increase_by_amount', label: t('rate_plan.modificator_option.increase_by_amount') },
    { value: 'decrease_by_amount', label: t('rate_plan.modificator_option.decrease_by_amount') },
    { value: 'increase_by_percent', label: t('rate_plan.modificator_option.increase_by_percent') },
    { value: 'decrease_by_percent', label: t('rate_plan.modificator_option.decrease_by_percent') },
  ];

  const sellModeOptions = [
    { label: t('rate_plan.sell_mode_option.per_room'), value: 'per_room' },
    { label: t('rate_plan.sell_mode_option.per_person'), value: 'per_person' },
  ];

  const rateModeOptions = [
    { label: t('rate_plan.rate_mode_option.manual'), value: 'manual' },
    { label: t('rate_plan.rate_mode_option.derived'), value: 'derived' },
    { label: t('rate_plan.rate_mode_option.auto'), value: 'auto' },
  ];

  if (isLoadingRatePlan || isLoadingCancellationPolicies) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('rate_plan.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const getAutoPerPersonModeOptions = (options: IOption[]) => {
    const decreaseMode = form.getFieldValue(['autoRateSettings', 'decreaseMode']);
    const decreaseValue = form.getFieldValue(['autoRateSettings', 'decreaseValue']);
    const increaseMode = form.getFieldValue(['autoRateSettings', 'increaseMode']);
    const increaseValue = form.getFieldValue(['autoRateSettings', 'increaseValue']);

    const primaryOccupancy = options.find((option: IOption) => option.isPrimary)?.occupancy;
    const updatedOptions = options.map((option: IOption) => {
      if (option.isPrimary) return option;
      const updatedOption = option;
      if (option.occupancy < Number(primaryOccupancy)) {
        const decreaseBy = decreaseMode !== '%' ? 'decrease_by_amount' : 'decrease_by_percent';
        updatedOption.derivedOption = {
          rate: [[decreaseBy, decreaseValue * (Number(primaryOccupancy) - option.occupancy)]],
        };
        return updatedOption;
      }

      const increaseBy = increaseMode !== '%' ? 'increase_by_amount' : 'increase_by_percent';
      updatedOption.derivedOption = {
        rate: [[increaseBy, increaseValue * (option.occupancy - Number(primaryOccupancy))]],
      };
      return updatedOption;
    });

    return updatedOptions;
  };

  const getManualPerPersonModeOptions = (options: IOption[]) => {
    const updatedOptions = options.map((option: IOption) => {
      if (option.isPrimary) {
        return {
          ...option,
          derivedOption: {
            rate: [],
          },
        };
      }
      return {
        ...option,
        derivedOption: null,
      };
    });

    return updatedOptions;
  };

  const getPerPersonModeOptions = (formValues: { options: IOption[]; rateMode: string }) => {
    switch (formValues.rateMode) {
      case 'manual':
        return getManualPerPersonModeOptions(formValues.options);
      case 'auto':
        return getAutoPerPersonModeOptions(formValues.options);
      default:
        return formValues.options;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);
    const formUpdatedValues = JSON.parse(JSON.stringify(updatedValues));
    if (formUpdatedValues.sellMode === 'per_person') {
      formUpdatedValues.options = getPerPersonModeOptions(formUpdatedValues);
    }

    try {
      if (ratePlanId) {
        await updateRatePlan(formUpdatedValues);
      } else {
        await createRatePlan(formUpdatedValues);
      }
      openNotificationWithIcon('success');
      onCancel();
    } catch (e) {
      openNotificationWithIcon('error');
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const renderModeOptions = (mode: 'increaseMode' | 'decreaseMode', options: Array<string>) => (
    <Form.Item noStyle name={['autoRateSettings', mode]}>
      <Select className="select-after">
        {options.map(option => (
          <Select.Option key={option} value={option === '%' ? option : '$'}>
            {option}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  const handleChangePrimaryOccupancy = (occupancy: IOption['occupancy']) => {
    const occupancyOptions: Array<IOption> = form.getFieldValue('options');
    const oldPrimary = occupancyOptions.findIndex(option => option.isPrimary);
    const newPrimary = occupancyOptions.findIndex(option => option.occupancy === occupancy);

    const updatedOptions = JSON.parse(JSON.stringify(occupancyOptions));
    updatedOptions[oldPrimary] = {
      ...updatedOptions[oldPrimary],
      isPrimary: false,
      derivedOption: { rate: [] },
    };

    updatedOptions[newPrimary] = {
      ...updatedOptions[newPrimary],
      derivedOption: { rate: [] },
      isPrimary: true,
    };

    form.setFieldsValue({ options: updatedOptions, primaryOccupancy: occupancy });
  };

  const handleAddModificator = (option: IOption) => {
    const options = form.getFieldValue('options');
    const optionIndex = options.findIndex((opt: IOption) => opt.occupancy === option.occupancy);
    if (options?.[optionIndex]?.derivedOption?.rate) {
      options[optionIndex].derivedOption.rate.push([]);
    }

    form.setFieldsValue({ options: options });
  };

  const handleSellModeChange = (sellMode: 'per_person' | 'per_room') => {
    if (sellMode !== 'per_person') {
      form.setFieldsValue({ sellMode });
      return;
    }
    const newOptions = form.getFieldValue('options');
    let occupancy = maxOccupancy;
    while (occupancy > 0) {
      if (newOptions.findIndex((o: IOption) => o.occupancy === occupancy) === -1) {
        newOptions.push({
          occupancy,
          rate: 0,
          isPrimary: false,
          derivedOption: { rate: [] },
        });
      }
      occupancy--;
    }

    form.setFieldsValue({
      sellMode,
      options: newOptions,
    });
  };

  const handleRemoveDerivedOptionRate = (ind: number, key: number) => {
    const options = form.getFieldValue('options');
    options[ind].derivedOption.rate.splice(key, 1);
    form.setFieldsValue({ options: options });
  };

  return (
    <div data-testid="PropertyRatePlanForm" className={styles.root}>
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={defaultValues}
        {...formItemLayout}
        className={styles.room_type_form}
        validateMessages={validateMessages}
      >
        <Form.Item name="title" label={t('general.title')} rules={[{ required: true }]}>
          <Input placeholder={t('general.title')} />
        </Form.Item>

        <Form.Item name="unitId" label={t('rates_table_hotel.unit_type')}>
          <Select
            showSearch
            placeholder={t('rates_table_hotel.unit_type')}
            options={unitOptions}
            disabled={isEdit}
            optionFilterProp="label"
            filterOption={true}
          />
        </Form.Item>
        <legend>Price Settings</legend>
        <Form.Item label={t('general.currency')} name="currency" className={styles.input_container}>
          <Select
            showSearch
            placeholder={t('general.currency')}
            options={currenciesOptions}
            optionFilterProp="label"
            filterOption={true}
            disabled={isEdit}
          />
        </Form.Item>

        <Form.Item label={t('general.sell_mode')} name="sellMode">
          <Radio.Group
            options={sellModeOptions}
            optionType="button"
            onChange={e => handleSellModeChange(e.target.value)}
            disabled={isEdit}
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.sellMode !== currentValues.sellMode}>
          {({ getFieldValue }) =>
            getFieldValue('sellMode') === 'per_person' ? (
              <Form.Item label="Rate Mode" name="rateMode">
                <Radio.Group options={rateModeOptions} optionType="button" disabled={isEdit} />
              </Form.Item>
            ) : null
          }
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.rateMode !== currentValues.rateMode || prevValues.currency !== currentValues.currency
          }
        >
          {({ getFieldValue }) => {
            switch (getFieldValue('rateMode')) {
              case 'manual':
                return null;
              case 'derived':
                return (
                  <>
                    <Form.Item label={t('general.primary_occupancy')} name="primaryOccupancy">
                      <Select
                        onChange={val => handleChangePrimaryOccupancy(val)}
                        placeholder={t('general.primary_occupancy')}
                        disabled={isEdit}
                        showSearch
                        optionFilterProp="children"
                        filterOption={true}
                      >
                        {occupancyOptions.map((occupancy: number) => (
                          <Select.Option key={occupancy} value={occupancy}>
                            {occupancy}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {getFieldValue('options')?.map((option: IOption, ind: number) => {
                      return (
                        <Form.Item
                          label={`${t('general.rate_logic_for')} ${option.occupancy} ${
                            option.occupancy === 1 ? t('general.person') : t('general.persons')
                          }`}
                          key={ind}
                        >
                          {option.isPrimary ? (
                            <div style={{ paddingLeft: 10 }}>{t('general.primary_occupancy')}</div>
                          ) : (
                            <>
                              {getFieldValue('options')[ind].derivedOption.rate.map(
                                (r: [string?, number?], key: number) => (
                                  <>
                                    <Row className={styles.modifier_container}>
                                      <Col span={10}>
                                        <Form.Item noStyle name={['options', ind, 'derivedOption', 'rate', key, 0]}>
                                          <Select
                                            showSearch
                                            optionFilterProp="label"
                                            filterOption={true}
                                            options={modificatorOptions}
                                            placeholder={t('general.choose_type')}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col span={10} offset={1}>
                                        <Form.Item noStyle name={['options', ind, 'derivedOption', 'rate', key, 1]}>
                                          <Input type="number" placeholder={t('general.enter_value')} />
                                        </Form.Item>
                                      </Col>
                                      <Col offset={1} span={2} onClick={() => handleRemoveDerivedOptionRate(ind, key)}>
                                        <DeleteOutlined className={styles.delete_icon} />
                                      </Col>
                                    </Row>
                                  </>
                                ),
                              )}

                              <div className={styles.add_modificator} onClick={() => handleAddModificator(option)}>
                                <PlusCircleOutlined /> <span>{t('general.add_modificator')}</span>
                              </div>
                            </>
                          )}
                        </Form.Item>
                      );
                    })}
                  </>
                );
              case 'auto':
                return (
                  <>
                    <Form.Item label={t('general.primary_occupancy')} name="primaryOccupancy">
                      <Select
                        onChange={val => handleChangePrimaryOccupancy(val)}
                        showSearch
                        placeholder={t('general.primary_occupancy')}
                        optionFilterProp="children"
                        filterOption={true}
                      >
                        {occupancyOptions.map(occupancy => (
                          <Select.Option key={occupancy} value={occupancy}>
                            {occupancy}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>

                    <Form.Item label={t('general.increase_by')} name={['autoRateSettings', 'increaseValue']}>
                      <Input
                        addonAfter={renderModeOptions('increaseMode', ['%', getFieldValue('currency')])}
                        placeholder={t('general.increase_by')}
                      />
                    </Form.Item>
                    <Form.Item label={t('general.decrease_by')} name={['autoRateSettings', 'decreaseValue']}>
                      <Input
                        addonAfter={renderModeOptions('decreaseMode', ['%', getFieldValue(['currency'])])}
                        placeholder={t('general.decrease_by')}
                      />
                    </Form.Item>
                  </>
                );
            }
          }}
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.sellMode !== currentValues.sellMode || prevValues.currency !== currentValues.currency
          }
        >
          {({ getFieldValue }) =>
            getFieldValue('sellMode') === 'per_person' ? (
              <>
                <Form.Item label={t('general.children_fee')} name="childrenFee">
                  <Input addonAfter={getFieldValue('currency')} placeholder={t('general.children_fee')} />
                </Form.Item>
                <Form.Item label={t('general.infant_fee')} name="infantFee">
                  <Input addonAfter={getFieldValue('currency')} placeholder={t('general.infant_fee')} />
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>

        <legend>{t('general.additional_info')}</legend>
        <Form.Item label={t('general.meal_type')} name="mealType">
          <Select showSearch placeholder={t('general.meal_type')} optionFilterProp="children" filterOption={true}>
            {mealTypes.map(mealType => (
              <Select.Option key={mealType} value={mealType}>
                {t(`meal_types.${mealType}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={t('general.cancellation_policy')} name="cancellationPolicyId">
          <Select
            showSearch
            optionFilterProp="label"
            filterOption={true}
            placeholder={t('general.cancellation_policy')}
            loading={isLoadingCancellationPolicies}
            options={cancellationPoliciesOptions}
          />
        </Form.Item>

        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={handleCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdatingRatePlan} style={{ marginLeft: 20 }}>
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PropertyRatePlanForm;
