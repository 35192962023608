import React from 'react';

import ActionButton from '../action_button';
import MissingSpaces from '../missing_spaces';
import PriceBreakdown from '../price_breakdown';
import TotalPrice from '../total_price';

import { ISummaryProps } from './summary.types';

const Summary: React.FC<ISummaryProps> = props => {
  const {
    selectedRatesPerUnit,
    isDatesSelected,
    isRateSelected,
    missingSpaces,
    totalPrice,
    currency,
    loading,
    onSearch,
    onBook,
    propertyType,
  } = props;

  return (
    <div>
      {isRateSelected && (
        <>
          <PriceBreakdown selectedRatesPerUnit={selectedRatesPerUnit} currency={currency} propertyType={propertyType} />
          <TotalPrice totalPrice={totalPrice} currency={currency} />
          <MissingSpaces missingSpaces={missingSpaces} />
        </>
      )}
      <ActionButton
        isDatesSelected={isDatesSelected}
        isRateSelected={isRateSelected}
        missingSpaces={missingSpaces}
        total={totalPrice}
        currency={currency}
        loading={loading}
        onBook={onBook}
        onSearch={onSearch}
      />
    </div>
  );
};

export default Summary;
