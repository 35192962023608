// Generated with util/create-component.js
import React, { useContext, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import notification from 'utils/notification';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';
import getUrlParams from 'utils/get_url_params';

import PageTitle from 'components/page_title/page_title';
import CancelGiftCardReservation from 'components/cancel_gift_card_reservation/cancel_gift_card_reservation';

import styles from './cancel_gift_card_store_page.module.scss';

const CancelGiftCardStorePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { cancelGiftCardBooking } = useContext(PropertyGiftCardsActionsContext);
  const { selectedProperty } = useParams<{ selectedProperty: string }>();
  const { reservation_id, checkout_date, checkin_date, last_name } = getUrlParams() as {
    reservation_id: string;
    checkout_date: string;
    checkin_date: string;
    last_name: string;
  };
  const [successfulCancel, setSuccessfulCancel] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    propertyInfo: { data },
  } = useContext(PropertyGiftCardsDataContext);

  const handleRedirectToGCPage = () => {
    const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });
    history.push(redirectPath);
  };

  const handleRedirectToUseGCPage = () => {
    const redirectPath = buildPath('', routes.useGiftCardStorePage, { selectedProperty });
    history.push(redirectPath);
  };

  const handleCancelBooking = async () => {
    try {
      setIsLoading(true);
      await cancelGiftCardBooking({ reservationId: reservation_id, lastName: last_name });
      setSuccessfulCancel(true);
      notification.withIcon('info', t('gift_card.cancel_booking_successful'));
    } catch (e) {
      console.log('error', e);
      notification.withIcon('info', t('gift_card.cancel_booking_failed'));
    }
    setIsLoading(false);
  };

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  return (
    <div data-testid="CancelGiftCardStorePage" className={styles.root}>
      <div className={styles.pageTitleWrapper}>
        <PageTitle
          steps={[
            { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
            { title: t('gift_card.cancel_reservation') },
          ]}
        />
      </div>
      <div className={styles.bookByGiftLetterWrapper}>
        <div className={classNames(styles.bookByGiftLetter, 'roundedWrapper')}>
          <CancelGiftCardReservation
            reservationId={reservation_id}
            checkinDate={checkin_date}
            checkoutDate={checkout_date}
            onConfirmCancel={handleCancelBooking}
            onViewGiftCards={handleRedirectToGCPage}
            onUseGiftCard={handleRedirectToUseGCPage}
            property={data}
            isLoading={isLoading}
            successfulCancel={successfulCancel}
          />
        </div>
      </div>
    </div>
  );
};

export default CancelGiftCardStorePage;
