import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from 'assets/logo.svg';

import routes from 'routing/routes';
import USER_ROLE from 'constants/user_role';
import { PROPERTY_CHANNELS } from 'constants/property_channels';
import MainSearch from 'components/main_search';

import {
  AppDataContext,
  AuthenticationDataContext,
  SearchActionsContext,
  SearchDataContext,
} from 'containers/data_context';

import EmptyIcon from 'static/empty-property.svg';
import styles from './search_cars_page.module.scss';

const SearchCarsPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { featureFlags } = useContext(AppDataContext);
  const { userRole } = useContext(AuthenticationDataContext);

  if (userRole !== USER_ROLE.RESELLER) {
    history.push(routes.bookingsPage);
    return null;
  }

  if (!featureFlags.searchPageIsActive) {
    return (
      <div className={styles.emptyWrapper}>
        <img src={EmptyIcon} alt={t('properties.unActiveSearchPage')} />
      </div>
    );
  }

  const { clearCarsList, loadAllCarsList } = useContext(SearchActionsContext);
  const { allCars } = useContext(SearchDataContext);
  const { data: allCarsData, isLoading } = allCars;

  return (
    <div className={styles.container}>
      <div className={styles.search_container}>
        <div className={styles.logo_container}>
          <Logo />
        </div>
        <div className={styles.subTitle}>
          <div className={styles.sub_title_text}>Search for rental cars</div>
        </div>
        <MainSearch
          propertyType={PROPERTY_CHANNELS.CAR}
          loadDataList={loadAllCarsList}
          clearDataList={clearCarsList}
          dataList={allCarsData}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default SearchCarsPage;
