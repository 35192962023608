import React from 'react';

import Caption from 'components/caption';

import { IFacilitiesContainerProps } from './facilities_container.types';

const FACILITY_DIVIDER = ' · ';

const FacilitiesContainer: React.FC<IFacilitiesContainerProps> = ({ facilities }) => {
  return <Caption variant="green">{facilities.join(FACILITY_DIVIDER)}</Caption>;
};

export default FacilitiesContainer;
