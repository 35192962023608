import React, { useCallback, useState } from "react";
import { Button } from "react-bootstrap";

import PropertyUnitDetailsModal from "./property_unit_details_modal";

import styles from "./property_unit_title.module.scss";

export default function PropertyUnitTitle({ unit }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalToggle = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  return (
    <>
      <Button variant="link" className={styles.title} onClick={handleModalToggle}>
        <strong>{unit.title}</strong>
      </Button>
      <PropertyUnitDetailsModal unit={unit} show={isModalOpen} onHide={handleModalToggle} />
    </>
  );
}
