import USER_ROLE from 'constants/user_role';

import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';

import {
  SET_ERRORS,
  SET_LOGGED_IN_USER,
  SET_FORM_LOGIN_ERROR,
  SET_FORM_LOGIN_SUBMITTING,
  SET_SUBMITTED_VALUE,
  SET_LOGOUT_USER,
  SET_IS_VERIFYING_PASSWORD_EMAIL,
} from './login_actions';

const DEFAULT_ERRORS = {};

const loginData = LocaleStorage.get('session');

export const INITIAL_STATE = {
  user: loginData?.user ? loginData.user : null,
  userRole: loginData?.user?.agent ? USER_ROLE.RESELLER : USER_ROLE.SUPPLIER,
  exp: loginData?.exp || -1,
  token: loginData?.token ? loginData.token : null,
  errors: DEFAULT_ERRORS,
  isSubmitting: false,
  isVerifyingResetPasswordEmail: false,
};

const actionHandlers = {
  [SET_SUBMITTED_VALUE]: (state, action) => {
    return { ...state, value: action.payload };
  },
  [SET_ERRORS]: (state, action) => {
    return { ...state, errors: action.payload };
  },
  [SET_FORM_LOGIN_SUBMITTING]: state => {
    return { ...state, isSubmitting: true };
  },
  [SET_LOGGED_IN_USER]: (state, action) => {
    const exp = Date.now() + action.payload.ttl * 1000;
    LocaleStorage.set('session', {
      ...action.payload,
      userRole: action.payload.user?.agent ? USER_ROLE.RESELLER : USER_ROLE.SUPPLIER,
      exp,
    });
    return {
      ...state,
      errors: DEFAULT_ERRORS,
      isSubmitting: false,
      user: action.payload.user,
      userRole: action.payload.user?.agent ? USER_ROLE.RESELLER : USER_ROLE.SUPPLIER,
      exp,
      token: action.payload.token,
    };
  },
  [SET_FORM_LOGIN_ERROR]: (state, action) => {
    return { ...state, errors: action.payload, isSubmitting: false };
  },
  [SET_LOGOUT_USER]: (state, action) => {
    LocaleStorage.remove('session');
    return {
      ...state,
      user: null,
      exp: -1,
      token: null,
    };
  },
  [SET_IS_VERIFYING_PASSWORD_EMAIL]: (state, { isVerifyingResetPasswordEmail }) => {
    return { ...state, isVerifyingResetPasswordEmail };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
