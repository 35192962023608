import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './facility.module.scss';
import { IFacilityProps } from './facility.types';

const Facility: React.FC<IFacilityProps> = ({ code }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.facility}>
      <div className={styles.facilityTitle}>{t(`facilities:${code.toLowerCase()}`)}</div>
    </div>
  );
};

export default Facility;
