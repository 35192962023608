import React, { createContext } from 'react';

import useApp from './data_handlers/app';
import useApiKeys from './data_handlers/api_keys';
import useBooking from './data_handlers/booking';
import useBookingCheckout from './data_handlers/booking_checkout';
import useSearch from './data_handlers/search';
import useAuthentication from './data_handlers/login_form';
import useBookingsList from './data_handlers/bookings';
import usePropertySettings from './data_handlers/property_settings';
import usePropertyUnits from './data_handlers/property_units';
import usePropertyRatePlans from './data_handlers/property_rate_plans';
import usePropertyPolicies from './data_handlers/property_policies';
import useResellerContracts from './data_handlers/reseller_contracts';
import useSupplierContracts from './data_handlers/supplier_contracts';
import usePropertyGiftCards from './data_handlers/property_gift_cards';
import useAdminUsers from './data_handlers/admin/users';
import useSupplierUsers from './data_handlers/supplier/users';
import useAdminResellers from './data_handlers/admin/resellers';
import useAdminProperties from './data_handlers/admin/properties';
import useGiftCampaign from './data_handlers/gift_campaign';
import useStripe from './data_handlers/stripe';
import usePaymentProviders from './data_handlers/payment_providers';

const AppDataContext = createContext();
const AppActionsContext = createContext();

const AuthenticationDataContext = createContext();
const AuthenticationActionsContext = createContext();

const BookingDataContext = createContext();
const BookingActionsContext = createContext();

const BookingCheckoutDataContext = createContext();
const BookingCheckoutActionsContext = createContext();

const SearchDataContext = createContext();
const SearchActionsContext = createContext();

const BookingsDataContext = createContext();
const BookingsActionsContext = createContext();

const PropertySettingsDataContext = createContext();
const PropertySettingsActionsContext = createContext();

const PropertyUnitsDataContext = createContext();
const PropertyUnitsActionsContext = createContext();

const PropertyRatePlansDataContext = createContext();
const PropertyRatePlansActionsContext = createContext();

const PropertyPoliciesDataContext = createContext();
const PropertyPoliciesActionsContext = createContext();

const ResellerContractsDataContext = createContext();
const ResellerContractsActionsContext = createContext();

const SupplierContractsDataContext = createContext();
const SupplierContractsActionsContext = createContext();

const PropertyGiftCardsDataContext = createContext();
const PropertyGiftCardsActionsContext = createContext();

const AdminUsersDataContext = createContext();
const AdminUsersActionsContext = createContext();

const AdminResellersDataContext = createContext();
const AdminResellersActionsContext = createContext();

const AdminPropertiesDataContext = createContext();
const AdminPropertiesActionsContext = createContext();

const SupplierUsersDataContext = createContext();
const SupplierUsersActionsContext = createContext();

const GiftCampaignDataContext = createContext();
const GiftCampaignActionsContext = createContext();

const ApiKeysDataContext = createContext();
const ApiKeysActionsContext = createContext();

const StripeDataContext = createContext();
const StripeActionsContext = createContext();

const PaymentProvidersDataContext = createContext();
const PaymentProvidersActionsContext = createContext();

const DataContextProvider = ({ children }) => {
  const { bookingData, bookingActions } = useBooking();
  const { bookingCheckoutData, bookingCheckoutActions } = useBookingCheckout();
  const { appData, appActions } = useApp();
  const { apiKeysData, apiKeysActions } = useApiKeys();
  const { searchData, searchActions } = useSearch();
  const { authenticationData, authenticationActions } = useAuthentication();
  const { bookingsData, bookingsActions } = useBookingsList();
  const { propertySettingsData, propertySettingsActions } = usePropertySettings();
  const { propertyUnitesData, propertyUnitsActions } = usePropertyUnits();
  const { propertyRatePlansData, propertyRatePlansActions } = usePropertyRatePlans();
  const { propertyPoliciesData, propertyPoliciesActions } = usePropertyPolicies();
  const { resellerContractsData, resellerContractsActions } = useResellerContracts();
  const { supplierContractsData, supplierContractsActions } = useSupplierContracts();
  const { propertyGiftCardsTypeData, propertyGiftCardTypesActions } = usePropertyGiftCards();
  const { usersData, usersActions } = useAdminUsers();
  const { supplierUsersData, supplierUsersActions } = useSupplierUsers();
  const { resellersData, resellersActions } = useAdminResellers();
  const { propertiesData, propertiesActions } = useAdminProperties();
  const { giftCampaignData, giftCampaignActions } = useGiftCampaign();
  const { stripeData, stripeActions } = useStripe();
  const { paymentProvidersData, paymentProvidersActions } = usePaymentProviders();

  return (
    <AppActionsContext.Provider value={appActions}>
      <AppDataContext.Provider value={appData}>
        <AuthenticationActionsContext.Provider value={authenticationActions}>
          <AuthenticationDataContext.Provider value={authenticationData}>
            <ApiKeysActionsContext.Provider value={apiKeysActions}>
              <ApiKeysDataContext.Provider value={apiKeysData}>
                <BookingActionsContext.Provider value={bookingActions}>
                  <BookingDataContext.Provider value={bookingData}>
                    <BookingCheckoutActionsContext.Provider value={bookingCheckoutActions}>
                      <BookingCheckoutDataContext.Provider value={bookingCheckoutData}>
                        <SearchActionsContext.Provider value={searchActions}>
                          <SearchDataContext.Provider value={searchData}>
                            <BookingsActionsContext.Provider value={bookingsActions}>
                              <BookingsDataContext.Provider value={bookingsData}>
                                <PropertySettingsActionsContext.Provider value={propertySettingsActions}>
                                  <PropertySettingsDataContext.Provider value={propertySettingsData}>
                                    <PropertyUnitsActionsContext.Provider
                                      value={propertyUnitsActions}
                                    >
                                      <PropertyUnitsDataContext.Provider value={propertyUnitesData}>
                                        <PropertyRatePlansActionsContext.Provider
                                          value={propertyRatePlansActions}
                                        >
                                          <PropertyRatePlansDataContext.Provider
                                            value={propertyRatePlansData}
                                          >
                                            <PropertyPoliciesActionsContext.Provider
                                              value={propertyPoliciesActions}
                                            >
                                              <PropertyPoliciesDataContext.Provider
                                                value={propertyPoliciesData}
                                              >
                                                <ResellerContractsDataContext.Provider value={resellerContractsData}>
                                                  <ResellerContractsActionsContext.Provider value={resellerContractsActions}>
                                                    <SupplierContractsDataContext.Provider value={supplierContractsData}>
                                                      <SupplierContractsActionsContext.Provider
                                                        value={supplierContractsActions}
                                                      >
                                                        <PropertyGiftCardsActionsContext.Provider
                                                          value={propertyGiftCardTypesActions}
                                                        >
                                                          <PropertyGiftCardsDataContext.Provider
                                                            value={propertyGiftCardsTypeData}
                                                          >
                                                            <AdminUsersDataContext.Provider value={usersData}>
                                                              <AdminUsersActionsContext.Provider
                                                                value={usersActions}
                                                              >
                                                                <AdminResellersDataContext.Provider
                                                                  value={resellersData}
                                                                >
                                                                  <AdminResellersActionsContext.Provider
                                                                    value={resellersActions}
                                                                  >
                                                                    <AdminPropertiesDataContext.Provider
                                                                      value={propertiesData}
                                                                    >
                                                                      <AdminPropertiesActionsContext.Provider
                                                                        value={propertiesActions}
                                                                      >
                                                                        <SupplierUsersDataContext.Provider value={supplierUsersData}>
                                                                          <SupplierUsersActionsContext.Provider value={supplierUsersActions}>
                                                                            <GiftCampaignActionsContext.Provider
                                                                              value={giftCampaignActions}
                                                                            >
                                                                              <GiftCampaignDataContext.Provider
                                                                                value={giftCampaignData}
                                                                              >
                                                                                <StripeActionsContext.Provider value={stripeActions}>
                                                                                  <StripeDataContext.Provider value={stripeData}>
                                                                                    <PaymentProvidersActionsContext.Provider
                                                                                      value={paymentProvidersActions}
                                                                                    >
                                                                                      <PaymentProvidersDataContext.Provider
                                                                                        value={paymentProvidersData}
                                                                                      >
                                                                                        {children}
                                                                                      </PaymentProvidersDataContext.Provider>
                                                                                    </PaymentProvidersActionsContext.Provider>
                                                                                  </StripeDataContext.Provider>
                                                                                </StripeActionsContext.Provider>
                                                                              </GiftCampaignDataContext.Provider>
                                                                            </GiftCampaignActionsContext.Provider>
                                                                          </SupplierUsersActionsContext.Provider>
                                                                        </SupplierUsersDataContext.Provider>
                                                                      </AdminPropertiesActionsContext.Provider>
                                                                    </AdminPropertiesDataContext.Provider>
                                                                  </AdminResellersActionsContext.Provider>
                                                                </AdminResellersDataContext.Provider>
                                                              </AdminUsersActionsContext.Provider>
                                                            </AdminUsersDataContext.Provider>
                                                          </PropertyGiftCardsDataContext.Provider>
                                                        </PropertyGiftCardsActionsContext.Provider>
                                                      </SupplierContractsActionsContext.Provider>
                                                    </SupplierContractsDataContext.Provider>
                                                  </ResellerContractsActionsContext.Provider>
                                                </ResellerContractsDataContext.Provider>
                                              </PropertyPoliciesDataContext.Provider>
                                            </PropertyPoliciesActionsContext.Provider>
                                          </PropertyRatePlansDataContext.Provider>
                                        </PropertyRatePlansActionsContext.Provider>
                                      </PropertyUnitsDataContext.Provider>
                                    </PropertyUnitsActionsContext.Provider>
                                  </PropertySettingsDataContext.Provider>
                                </PropertySettingsActionsContext.Provider>
                              </BookingsDataContext.Provider>
                            </BookingsActionsContext.Provider>
                          </SearchDataContext.Provider>
                        </SearchActionsContext.Provider>
                      </BookingCheckoutDataContext.Provider>
                    </BookingCheckoutActionsContext.Provider>
                  </BookingDataContext.Provider>
                </BookingActionsContext.Provider>
              </ApiKeysDataContext.Provider>
            </ApiKeysActionsContext.Provider>
          </AuthenticationDataContext.Provider>
        </AuthenticationActionsContext.Provider>
      </AppDataContext.Provider>
    </AppActionsContext.Provider>
  );
};

export {
  DataContextProvider,
  AppActionsContext,
  AppDataContext,
  AuthenticationDataContext,
  AuthenticationActionsContext,
  BookingDataContext,
  BookingActionsContext,
  BookingCheckoutDataContext,
  BookingCheckoutActionsContext,
  SearchDataContext,
  SearchActionsContext,
  BookingsDataContext,
  BookingsActionsContext,
  PropertySettingsDataContext,
  PropertySettingsActionsContext,
  PropertyUnitsDataContext,
  PropertyUnitsActionsContext,
  PropertyRatePlansDataContext,
  PropertyRatePlansActionsContext,
  PropertyPoliciesDataContext,
  PropertyPoliciesActionsContext,
  ResellerContractsDataContext,
  ResellerContractsActionsContext,
  SupplierContractsDataContext,
  SupplierContractsActionsContext,
  PropertyGiftCardsDataContext,
  PropertyGiftCardsActionsContext,
  AdminUsersDataContext,
  AdminUsersActionsContext,
  AdminResellersDataContext,
  AdminResellersActionsContext,
  AdminPropertiesDataContext,
  AdminPropertiesActionsContext,
  SupplierUsersDataContext,
  SupplierUsersActionsContext,
  GiftCampaignDataContext,
  GiftCampaignActionsContext,
  ApiKeysDataContext,
  ApiKeysActionsContext,
  StripeDataContext,
  StripeActionsContext,
  PaymentProvidersDataContext,
  PaymentProvidersActionsContext,
};
