import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from 'components/alert';

import { IRoomAvailabilityProps } from './property_unit_availability.types';

const MAX_AVAILABILITY_TO_SHOW = 3;

const PropertyUnitAvailability: React.FC<IRoomAvailabilityProps> = ({ availability }) => {
  const { t } = useTranslation();

  if (!availability || availability > MAX_AVAILABILITY_TO_SHOW) {
    return null;
  }

  const availabilityAlertText = t('rates_table:room_availability', { availability });

  return <Alert text={availabilityAlertText} variant="error" />;
};

export default PropertyUnitAvailability;
