import {
  SET_BOOKINGS_LIST,
  SET_BOOKINGS_LIST_LOADING,
  SET_UPDATED_BOOKING,
  SET_UPDATE_BOOKING_GUESTS_LOADING,
  RESET_BOOKINGS_LIST,
  SET_SUPPLIERS_LIST_LOADING,
  SET_SUPPLIERS_LIST,
  SET_BOOKERS_LIST_LOADING,
  SET_BOOKERS_LIST,
  SET_RESELLERS_LIST_LOADING,
  SET_RESELLERS_LIST,
  SET_UPDATE_BOOKING_SPECIAL_REQUEST_LOADING,
} from './bookings_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  bookings: EMPTY_ENTITY,
  isUpdatingBookingGuests: false,
  isUpdatingBookingSpecialRequest: false,
  suppliers: EMPTY_ENTITY,
  bookers: EMPTY_ENTITY,
  resellers: EMPTY_ENTITY,
};

const actionHandlers = {
  [SET_BOOKINGS_LIST_LOADING]: state => {
    const bookings = { ...state.bookings, isLoading: true };

    return { ...state, bookings };
  },
  [SET_BOOKINGS_LIST]: (state, action) => {
    const bookings = { data: action.payload, isLoading: false };

    return { ...state, bookings };
  },
  [SET_UPDATE_BOOKING_GUESTS_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingBookingGuests: params };
  },
  [SET_UPDATE_BOOKING_SPECIAL_REQUEST_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingBookingSpecialRequest: params };
  },
  [SET_UPDATED_BOOKING]: (state, { params }) => {
    const bookingIndex = state.bookings.data?.data?.findIndex(b => b.id === params.id);
    const updatedBookings = JSON.parse(JSON.stringify(state.bookings.data.data));
    updatedBookings[bookingIndex] = params;

    return {
      ...state,
      bookings: {
        ...state.bookings,
        data: {
          ...state.bookings.data,
          data: updatedBookings,
        },
      },
    };
  },
  [RESET_BOOKINGS_LIST]: () => {
    return INITIAL_STATE;
  },
  [SET_SUPPLIERS_LIST_LOADING]: state => {
    const suppliers = { ...state.suppliers, isLoading: true };

    return { ...state, suppliers };
  },
  [SET_SUPPLIERS_LIST]: (state, action) => {
    const suppliers = { data: action.payload, isLoading: false };

    return { ...state, suppliers };
  },
  [SET_BOOKERS_LIST_LOADING]: state => {
    const bookers = { ...state.bookers, isLoading: true };

    return { ...state, bookers };
  },
  [SET_BOOKERS_LIST]: (state, action) => {
    const bookers = { data: action.payload, isLoading: false };

    return { ...state, bookers };
  },
  [SET_RESELLERS_LIST_LOADING]: state => {
    const resellers = { ...state.resellers, isLoading: true };

    return { ...state, resellers };
  },
  [SET_RESELLERS_LIST]: (state, action) => {
    const resellers = { data: action.payload, isLoading: false };

    return { ...state, resellers };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
