import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useMedia } from 'react-media';
import { Button, Result, Row, Col } from 'antd';
import { CheckSquareFilled } from '@ant-design/icons';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';
import dateFormatter from 'utils/date_formatter';
import MEDIA_QUERIES from 'constants/media_queries';

import { IGiftCardStoreBookingConfirmationProps } from 'components/gift_card_store_booking_confirmation/gift_card_store_booking_confirmation.types';

import styles from './gift_card_store_booking_confirmation.module.scss';

const GiftCardStoreBookingConfirmation: React.FC<IGiftCardStoreBookingConfirmationProps> = ({
  bookingDetails,
  giftCard,
}) => {
  const { t } = useTranslation();

  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;
  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const history = useHistory();

  const renderOrders = useMemo(
    () => (
      <Row>
        {!isMobile && (
          <Col sm={24} xs={24} className={classNames(styles.header, 'highlighted')}>
            <Row className={styles.headerContent}>
              <Col sm={9} xs={24} className={styles.yourOrderTitle}>
                {t('general.your_order')}
              </Col>
              <Col xs={0} sm={3} className={styles.alignCenter}>
                {t('gift_card.number')}
              </Col>
              <Col xs={0} sm={3} className={styles.alignCenter}>
                {t('hotel_page.checkin_label')}
              </Col>
              <Col xs={0} sm={3} className={styles.alignCenter}>
                {t('hotel_page.checkout_label')}
              </Col>
              <Col xs={0} sm={6} className={styles.alignCenter}>
                {t('general.other.email')}
              </Col>
            </Row>
          </Col>
        )}
        <Col key={bookingDetails.giftCardNumber} data-testid="GiftCardOrderConfirmation" sm={24} xs={24}>
          <Row className={styles.orderRoom}>
            {isMobile && (
              <Col xs={24} className={classNames(styles.mobileHeader, 'highlighted')}>
                {t('general.your_order')}
              </Col>
            )}
            <Col sm={9} xs={24}>
              <Row>
                <Col sm={5}>
                  <img
                    className={classNames(styles.photo, 'roundedImage')}
                    src={giftCard?.photos?.[0].publicUrl}
                    alt="Room"
                  />
                </Col>
                <Col sm={18} offset={1} className="highlighted">
                  <Row>
                    <Col sm={24} className={styles.roomTitle}>
                      {giftCard?.title}
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} className={styles.roomPrice}>
                      {`${giftCard?.settings?.lengthOfStay} ${t('hotel_page.nights')}`}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col sm={3} xs={0} className={classNames(styles.roomDetails, 'highlighted')}>
              {bookingDetails.giftCardNumber}
            </Col>
            <Col sm={3} xs={0} className={styles.roomDetails}>
              {`${dateFormatter.toUi(bookingDetails?.checkinDate)}`}
            </Col>
            <Col sm={3} xs={0} className={styles.roomDetails}>
              {`${dateFormatter.toUi(bookingDetails?.checkoutDate)}`}
            </Col>
            <Col sm={6} xs={0} className={styles.emailDetails}>
              {bookingDetails?.email}
            </Col>

            <Col sm={0} xs={24} className={styles.detailsWrapper}>
              <span className={classNames(styles.roomDetailsLabel, 'highlighted')}>
                {t('hotel_page.checkin_label')}:{' '}
              </span>
              <span className={styles.roomDetails}>{`${dateFormatter.toUi(bookingDetails?.checkinDate)}`}</span>
            </Col>
            <Col sm={0} xs={24} className={styles.detailsWrapper}>
              <span className={classNames(styles.roomDetailsLabel, 'highlighted')}>
                {t('hotel_page.checkout_label')}:{' '}
              </span>
              <span className={styles.roomDetails}>{`${dateFormatter.toUi(bookingDetails?.checkoutDate)}`}</span>
            </Col>
            <Col sm={0} xs={24} className={styles.detailsWrapper}>
              <span className={classNames(styles.roomDetailsLabel, 'highlighted')}>
                {t('gift_card.certificate_number')}:{' '}
              </span>
              <span className={styles.roomDetails}>{bookingDetails.giftCardNumber}</span>
            </Col>
            <Col sm={0} xs={24} className={styles.detailsWrapper}>
              <span className={classNames(styles.roomDetailsLabel, 'highlighted')}>{t('general.other.email')}: </span>
              <span className={styles.roomDetails}>{bookingDetails.email}</span>
            </Col>
          </Row>
        </Col>
      </Row>
    ),
    [bookingDetails],
  );

  const handleViewMoreCerificates = useCallback(() => {
    const path = buildPath('', routes.giftCardStorePage, {
      selectedProperty,
    });

    history.push(path);
  }, [selectedProperty]);

  return (
    <div className={classNames(styles.root, 'roundedWrapper')} data-testid="GiftCardStoreConfirmation">
      <Result
        icon={<CheckSquareFilled className={styles.statusIcon} />}
        status="success"
        subTitle={<div className={styles.resultSubtitle}>{t('booking.order_summary_subtitle')}</div>}
        title={<div className={classNames(styles.resultTitle, 'highlighted')}>{t('booking.confirmed')}</div>}
      />
      {!isMobile && <div className={classNames(styles.orderSummary, 'highlighted')}>{t('general.order_summary')}</div>}

      {renderOrders}
      <div className={styles.buttonWrapper}>
        <Button
          size="large"
          className={classNames(styles.storeButton, 'highlighted')}
          key="button"
          onClick={handleViewMoreCerificates}
        >
          {t('general.continue_browsing')}
        </Button>
      </div>
    </div>
  );
};

export default GiftCardStoreBookingConfirmation;
