import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/gift_campaign';

export default () => {
  const [giftCampaignData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const getGiftCampaigns = useCallback(
    params => {
      return actions.getGiftCampaigns(dispatch, params);
    },
    [dispatch],
  );

  const getGiftCampaignOptions = useCallback(
    params => {
      return actions.getGiftCampaignOptions(dispatch, params);
    },
    [dispatch],
  );

  const saveGiftCampaignRecipient = useCallback(
    params => {
      return actions.saveGiftCampaignRecipient(dispatch, params);
    },
    [dispatch],
  );

  const createGiftCampaign = useCallback(
    params => {
      return actions.createGiftCampaign(dispatch, params);
    },
    [dispatch],
  );

  const updateGiftCampaign = useCallback(
    params => {
      return actions.updateGiftCampaign(dispatch, params);
    },
    [dispatch],
  );

  const createGiftCampaignOption = useCallback(
    params => {
      return actions.createGiftCampaignOption(dispatch, params);
    },
    [dispatch],
  );

  const updateGiftCampaignOption = useCallback(
    params => {
      return actions.updateGiftCampaignOption(dispatch, params);
    },
    [dispatch],
  );

  const giftCampaignActions = useMemo(
    () => ({
      getGiftCampaigns,
      getGiftCampaignOptions,
      saveGiftCampaignRecipient,
      createGiftCampaign,
      updateGiftCampaign,
      createGiftCampaignOption,
      updateGiftCampaignOption,
    }),
    [
      getGiftCampaigns,
      getGiftCampaignOptions,
      saveGiftCampaignRecipient,
      createGiftCampaign,
      updateGiftCampaign,
      createGiftCampaignOption,
      updateGiftCampaignOption,
    ],
  );

  return useMemo(() => ({ giftCampaignData, giftCampaignActions }), [giftCampaignData, giftCampaignActions]);
};
