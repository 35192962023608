import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import PropertiesItem from './properties_item';
import PropertiesSkeleton from './properties_skeleton';

import styles from './properties.module.scss';
import { IPropertiesListProps } from './properties_list.types';

const PropertiesList: React.FC<IPropertiesListProps> = props => {
  const {
    properties,
    loading,
    isLoadingAdditionalProperties,
    currency,
    propertyType,
    highlightedProperties,
    showBestOffer,
    onPropertyMouseOver,
    onPropertyMouseOut,
  } = props;

  const { t } = useTranslation();
  const skeleton_count = 9;

  const listClassName = classNames(styles.list, {
    [`${styles.loading}`]: loading || isLoadingAdditionalProperties,
    [`${styles.listGrid}`]: loading || isLoadingAdditionalProperties || (properties ? properties.length > 0 : true),
  });

  const renderSkeleton = () =>
    Array(skeleton_count)
      .fill(0)
      .map((_n, i) => <PropertiesSkeleton key={i} />);

  const renderContent = () => {
    if (loading || (!loading && !properties?.length && isLoadingAdditionalProperties)) {
      return renderSkeleton();
    }

    if (properties?.length) {
      return (
        <>
          {properties.map((item, index) => (
            <PropertiesItem
              key={`${item.id}-${index}`}
              property={item}
              currency={currency}
              isHighlighted={highlightedProperties[item.id]}
              onMouseOver={onPropertyMouseOver}
              onMouseOut={onPropertyMouseOut}
              showBestOffer={showBestOffer}
              propertyType={propertyType}
            />
          ))}
          {isLoadingAdditionalProperties && renderSkeleton()}
        </>
      );
    }

    return (
      <div className={styles.emptyWrapper}>
        <p className={styles.empty}>{t('properties.no_search_results')}</p>
      </div>
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={listClassName}>{renderContent()}</div>
    </div>
  );
};

export default PropertiesList;
