import ApiActions from 'api_actions';

export const SET_PROPERTIES_LIST = 'SET_PROPERTIES_LIST';
export const SET_PROPERTIES_LOADING = 'SET_PROPERTIES_LOADING';
export const SET_PROPERTY_IS_UPDATING = 'SET_PROPERTY_IS_UPDATING';
export const SET_UPDATED_PROPERTY = 'SET_UPDATED_PROPERTY';
export const SET_PROPERTY_IS_CREATING = 'SET_PROPERTY_IS_CREATING';

const setSuppliers = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTIES_LIST, payload });
};

const setSuppliersLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTIES_LOADING, params });
};

const loadProperties = async (dispatch, params) => {
  try {
    setSuppliersLoading(dispatch);

    const data = await ApiActions.getSuppliers(params);

    setSuppliers(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setSuppliersLoading(dispatch, false);
  }
};

const setSupplierIsCreating = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_IS_CREATING, params });
};

const createProperty = async (dispatch, params) => {
  try {
    setSupplierIsCreating(dispatch);

    const data = await ApiActions.createProperty(params);
    setUpdatedSupplier(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setSupplierIsCreating(dispatch, false);
  }
};

const setSupplierIsUpdating = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_IS_UPDATING, params });
};

const setUpdatedSupplier = (dispatch, params) => {
  return dispatch({ type: SET_UPDATED_PROPERTY, params });
};

const updateProperty = async (dispatch, params) => {
  try {
    setSupplierIsUpdating(dispatch);

    const data = await ApiActions.updateProperty(params);
    setUpdatedSupplier(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setSupplierIsUpdating(dispatch, false);
  }
};

const lockOverrideSupplier = async (dispatch, params) => {
  try {
    setSupplierIsUpdating(dispatch);

    const data = await ApiActions.putSupplierLockOverride(params);
    setUpdatedSupplier(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setSupplierIsUpdating(dispatch, false);
  }
};

export const actions = {
  setSuppliers,
  setSuppliersLoading,
  loadProperties,
  updateProperty,
  createProperty,
  setSupplierIsCreating,
  setUpdatedSupplier,
  setSupplierIsUpdating,
  lockOverrideSupplier,
};
