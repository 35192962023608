import React from 'react';

import styles from './rates_table_header_column.module.scss';
import { IRatesTableHeaderColumnProps } from './rates_table_header_column.types';

const RatesTableHeaderColumn: React.FC<IRatesTableHeaderColumnProps> = ({ className, children }) => {
  return (
    <div className={[styles.column, className].join(' ')}>
      <span className={styles.columnContent}>
        <span className={styles.columnLabel}>{children}</span>
      </span>
    </div>
  );
};

export default RatesTableHeaderColumn;
