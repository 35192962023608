import ApiActions from "api_actions";

import { DEFAULT_CURRENCY } from "constants/defaults";
import dateFormatter from "utils/date_formatter";
import getChannelId from "utils/get_channel_id";
import isCarProperty from "utils/is_car_property";

export const SET_CHANNEL_ID = "SET_CHANNEL_ID";
export const SET_PROPERTY_LOADING = "SET_PROPERTY_LOADING";
export const SET_PROPERTY_DATA = "SET_PROPERTY_DATA";
export const SET_PROPERTY_TYPE = "SET_PROPERTY_TYPE";
export const SET_PROPERTY_UNITS_LOADING = "SET_PROPERTY_UNITS_LOADING";
export const SET_PROPERTY_UNITS_DATA = "SET_PROPERTY_UNITS_DATA";
export const SET_CLOSED_DATES_LOADING = "SET_CLOSED_DATES_LOADING";
export const SET_CLOSED_DATES_DATA = "SET_CLOSED_DATES_DATA";
export const SET_PARAMS = "SET_PARAMS";
export const SET_PROPERTY_UNIT_REQUEST_PARAMS = "SET_PROPERTY_UNIT_REQUEST_PARAMS";
export const RESET_PARAMS = "RESET_PARAMS";
export const SET_BEST_OFFER = "SET_BEST_OFFER";
export const SET_PROPERTY_CHANNELS = "SET_PROPERTY_CHANNELS";
export const SET_PROPERTY_CHANNELS_LOADING = "SET_PROPERTY_CHANNELS_LOADING";
export const SET_ALL_PARAMS = 'SET_ALL_PARAMS';

const setChannelId = (dispatch, payload) => {
  return dispatch({ type: SET_CHANNEL_ID, payload });
};

const setPropertyLoading = (dispatch) => {
  return dispatch({ type: SET_PROPERTY_LOADING });
};

const setPropertyData = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_DATA, payload });
};

const setPropertyType = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_TYPE, payload });
};

const setPropertyUnitsLoading = (dispatch) => {
  return dispatch({ type: SET_PROPERTY_UNITS_LOADING });
};

const setPropertyUnitsData = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_UNITS_DATA, payload });
};

const setClosedDatesLoading = (dispatch) => {
  return dispatch({ type: SET_CLOSED_DATES_LOADING });
};

const setClosedDatesData = (dispatch, payload) => {
  return dispatch({ type: SET_CLOSED_DATES_DATA, payload });
};

const setParams = (dispatch, payload) => {
  return dispatch({ type: SET_PARAMS, payload });
};

const setAllParams = (dispatch, payload) => {
  return dispatch({ type: SET_ALL_PARAMS, payload });
};

const setPropertyUnitRequestParams = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_UNIT_REQUEST_PARAMS, payload });
};

const resetParams = (dispatch) => {
  return dispatch({ type: RESET_PARAMS });
};

const setBestOffer = (dispatch, payload) => {
  return dispatch({ type: SET_BEST_OFFER, payload });
};

const loadBestOffer = async (dispatch, channelId, type, params) => {
  try {
    const newMinPriceParams = await ApiActions.getBestOffer(channelId, type, params);

    setBestOffer(dispatch, newMinPriceParams);
  } catch (_e) {
    setBestOffer(dispatch, null);
  }
};

const loadProperty = async (dispatch, channelId, type) => {
  if (!channelId) {
    return {};
  }

  setPropertyLoading(dispatch);
  try {
    const data = await ApiActions.getPropertyInfo(channelId, type);

    setPropertyData(dispatch, data);

    return data;
  } catch (error) {
    setPropertyData(dispatch, null);

    if (error.status === 404) {
      // TODO move exeptions to a separate file (if there will be more than 1-2)
      throw Error("PROPERTY_NOT_FOUND");
    }

    return {};
  }
};

const setChannels = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_CHANNELS, payload });
}

const setChannelsLoading = (dispatch) => {
  return dispatch({ type: SET_PROPERTY_CHANNELS_LOADING });
}

const loadChannelsFromProperty = async (dispatch, propertyId) => {
  if (!propertyId) {
    return [];
  }

  setChannelsLoading(dispatch);
  try {
    const data = await ApiActions.getChannelsFromProperty(propertyId);
    setChannels(dispatch, data);
  } catch (error) {
    setChannels(dispatch, null);
  }
}

const loadPropertyUnitsInfo = async (dispatch, channelId, params) => {
  if (!channelId) {
    return;
  }

  setPropertyUnitsLoading(dispatch);

  setPropertyUnitRequestParams(dispatch, params);

  try {
    const data = await ApiActions.getPropertyUnitsInfo(channelId, params);
    setPropertyUnitsData(dispatch, data, params);
  } catch (error) {
    setPropertyUnitsData(dispatch, null, params);
  }
};

const clearPropertyUnitsInfo = (dispatch) => {
  setPropertyUnitsData(dispatch, null);
}

const loadClosedDates = async (dispatch, channelId, type) => {
  if (!channelId) {
    return;
  }

  setClosedDatesLoading(dispatch);

  try {
    const data = await ApiActions.getClosedDates(channelId, type);

    setClosedDatesData(dispatch, data);
  } catch (error) {
    setClosedDatesData(dispatch, null);
  }
};

const setParamsAndLoadPropertyUnitsInfo = (dispatch, channelId, bookingParams) => {
  setParams(dispatch, bookingParams);

  if (bookingParams.pickupDate) {
    bookingParams.pickupDate = dateFormatter.toApi(bookingParams.pickupDate);
  }

  if (bookingParams.dropoffDate) {
    bookingParams.dropoffDate = dateFormatter.toApi(bookingParams.dropoffDate);
  }

  return loadPropertyUnitsInfo(dispatch, channelId, bookingParams);
};

const mergeBookingParams = (channelId, bookingQueryParams, savedBookingData) => {
  if (!savedBookingData || channelId !== savedBookingData.channelId) {
    return bookingQueryParams;
  }

  const { params } = savedBookingData;

  return { ...params, ...bookingQueryParams };
};

const initBookingData = (dispatch, bookingQueryParams, savedBookingData) => {
  const type = bookingQueryParams.type;
  const channelId = getChannelId(type);

  if (!channelId) {
    throw Error("PROPERY_NOT_FOUND");
  }

  const bookingParams = mergeBookingParams(channelId, bookingQueryParams, savedBookingData);
  setChannelId(dispatch, channelId);
  setPropertyType(dispatch, type);

  if (!isCarProperty(type)) {
    return loadProperty(dispatch, channelId, type).then((property) => {
      const { currency: propertyCurrency = DEFAULT_CURRENCY, exactMatch = false } = property;
      const { currency = propertyCurrency } = bookingParams;
      const updatedParams = { ...bookingParams, currency, exactMatch };

      return setParamsAndLoadPropertyUnitsInfo(dispatch, channelId, updatedParams);
    });
  } else {
    setAllParams(dispatch, savedBookingData);
    setParams(dispatch, bookingParams);
  }
};

export const actions = {
  loadProperty,
  loadPropertyUnitsInfo,
  loadClosedDates,
  setParams,
  setAllParams,
  resetParams,
  setParamsAndLoadPropertyUnitsInfo,
  initBookingData,
  loadBestOffer,
  loadChannelsFromProperty,
  clearPropertyUnitsInfo,
};
