// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Popconfirm, Tooltip, MenuProps } from 'antd';
import { CheckOutlined, DeleteOutlined, DownOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';

import paymentProviders from 'constants/payment_providers';
import dateFormatter from 'utils/date_formatter';
import { IPaymentProvider } from 'components/payment_providers_page/payment_providers_page.types';

import { IPaymentProvidersListProps } from './payment_providers_list.types';
import styles from './payment_providers_list.module.scss';

const COLUMN_WIDTH = 150;

const PaymentProvidersList: React.FC<IPaymentProvidersListProps> = ({ isLoading, providers, onEdit, onDelete }) => {
  const { t } = useTranslation();

  const renderMenu = (paymentProvider: IPaymentProvider): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => onEdit(paymentProvider),
      },
      {
        key: 'delete',
        label: (
          <Tooltip title={paymentProvider?.default ? 'Default payment provider can not be deleted' : 'Delete'}>
            <Popconfirm
              title={
                <>
                  <p>{t('general.confirm')}</p>
                  {`Are you sure you want to delete ${paymentProvider.name} payment provider?`}
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => onDelete(paymentProvider)}
            >
              {t('general.delete')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <DeleteOutlined />,
        disabled: paymentProvider.default,
      },
    ];
    return { items: menuItems };
  };

  const renderActions = (menu: MenuProps) => {
    return (
      <Dropdown menu={menu}>
        <div className={styles.actions_link}>
          <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const redirectToStripeConnect = (paymentProvider: IPaymentProvider) => {
    const redirectUrl = paymentProvider.details.onboardingAccountUrl;
    redirectUrl && window.location.replace(redirectUrl);
  };

  const renderStripeMenu = (paymentProvider: IPaymentProvider): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => onEdit(paymentProvider),
      },
      {
        key: 'connect',
        icon: !paymentProvider.details.accountActive && (
          <Tooltip title={t('general.payment_providers.stripe_warning')}>
            {<WarningOutlined className={styles.warningIcon} />}
          </Tooltip>
        ),
        label: <div>{t('general.payment_providers.connect')}</div>,
        disabled: paymentProvider.details.accountActive,
        onClick: () => redirectToStripeConnect(paymentProvider),
      },
      {
        key: 'update',
        label: t('general.payment_providers.update'),
        disabled: !paymentProvider.details.accountActive,
        onClick: () => redirectToStripeConnect(paymentProvider),
      },
    ];

    return { items: menuItems };
  };

  const renderDefault = (isDefault: boolean) => {
    if (!isDefault) return null;
    return <CheckOutlined className={styles.checkIcon} />;
  };

  const columns = useMemo(
    () => [
      {
        title: 'Payment Provider',
        dataIndex: 'name',
        key: 'name',
        width: COLUMN_WIDTH,
      },
      {
        title: t('general.created_at'),
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (createdAt: string) => <div>{dateFormatter.toUiFullMonth(createdAt)}</div>,
        width: COLUMN_WIDTH,
      },
      {
        title: t('general.updated_at'),
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (updatedAt: string) => <div>{dateFormatter.toUiFullMonth(updatedAt)}</div>,
        width: COLUMN_WIDTH,
      },
      {
        title: t('general.default'),
        dataIndex: 'default',
        key: 'default',
        render: renderDefault,
        width: COLUMN_WIDTH,
      },
      {
        title: t('general.actions'),
        key: 'actions',
        render: (provider: IPaymentProvider) => {
          if (provider.name === paymentProviders.STRIPE) return renderActions(renderStripeMenu(provider));

          return renderActions(renderMenu(provider));
        },
        width: COLUMN_WIDTH,
      },
    ],
    [],
  );
  return (
    <div data-testid="PaymentProvidersList" className={styles.root}>
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={providers}
        rowKey="id"
        scroll={{ y: window.innerHeight - 320 }}
        pagination={false}
      />
    </div>
  );
};

export default PaymentProvidersList;
