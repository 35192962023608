import React, { ForwardedRef, forwardRef, useMemo } from 'react';
import { Popover } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import getCancellationPolicyText from 'utils/get_cancellation_policy_text';
import getPaymentPolicyText from 'utils/get_payment_policy_text';

import PolicySection from './policy_section';

import styles from './policies_breakdown.module.scss';
import { IPoliciesBreakdownProps } from './policies_breakdown.types';

import isCarProperty from 'utils/is_car_property';

function PoliciesBreakdown(
  { ratePlan, className, propertyType, ...popoverProps }: IPoliciesBreakdownProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { t } = useTranslation();
  const { cancellationPolicy, mealType } = ratePlan;

  const popoverClassName = [className, styles.popover].join(' ');

  const paymentPolicyText = useMemo(() => getPaymentPolicyText(cancellationPolicy), [cancellationPolicy]);

  const cancellationPolicyText = useMemo(() => getCancellationPolicyText(cancellationPolicy), [cancellationPolicy]);

  return (
    <Popover
      className={popoverClassName}
      ref={ref}
      // OverlayTrigger add bunch of props that should be bypassed to popover
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...popoverProps}
    >
      <Popover.Content className={styles.popoverContent}>
        {isCarProperty(propertyType) && <PolicySection title={t('rates_table.liability')} text="CDW" />}
        {!isCarProperty(propertyType) && (
          <PolicySection title={t('rates_table.meals')} text={t(`meal_types.${mealType}`)} />
        )}
        {cancellationPolicyText && (
          <PolicySection title={t('rates_table.cancellation')} text={cancellationPolicyText} />
        )}
        {paymentPolicyText && <PolicySection title={t('rates_table.prepayment')} text={paymentPolicyText} />}
      </Popover.Content>
    </Popover>
  );
}

export default forwardRef(PoliciesBreakdown);
