import React, { useCallback, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';

import getOpenDirection from 'utils/get_open_direction';

import styles from './tooltip.module.scss';
import { IOverlayDirection, ITooltipProps } from './tooltip.types';

const OVERLAY_POSITIONS = { top: 'top', bottom: 'bottom' };

const Tooltip: React.FC<ITooltipProps> = ({ className = '', children }) => {
  const [isShown, setIsShown] = useState(false);
  const [target, setTarget] = useState<HTMLElement | null>(null);
  const [overlayPlacement, setOverlayPlacement] = useState(OVERLAY_POSITIONS.top);
  const ref = useRef<HTMLHeadingElement>(null);

  const handleShow = useCallback(
    (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      const openDirection = getOpenDirection(ref, OVERLAY_POSITIONS);

      setIsShown(!isShown);
      setTarget(event.currentTarget);
      setOverlayPlacement(openDirection);
    },
    [ref, isShown],
  );

  const handleHide = () => {
    setIsShown(false);
  };

  return (
    <div className={[styles.tooltipContainer, className].join(' ')} ref={ref}>
      <div
        role="button"
        tabIndex={0}
        className={styles.tooltipIcon}
        onMouseEnter={handleShow}
        onMouseLeave={handleHide}
        onKeyDown={handleShow}
        onClick={handleShow}
      />
      <Overlay
        rootClose
        show={isShown}
        target={target}
        rootCloseEvent="click"
        placement={overlayPlacement as IOverlayDirection}
        container={ref.current}
        onHide={handleHide}
      >
        {children}
      </Overlay>
    </div>
  );
};
export default Tooltip;
