// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Form, Select } from 'antd';

import { IGiftCardPdfDetailsProps, IPdfTemplate } from './gift_card_pdf_details.types';
import styles from './gift_card_pdf_details.module.scss';
import GiftCardPdfMultipleForms from './gift_card_pdf_multiple_forms/gift_card_pdf_multiple_forms';
import Loading from 'components/loading';

const GiftCardPdfDetails: React.FC<IGiftCardPdfDetailsProps> = ({
  giftCard,
  pdfTemplates,
  isLoadingPdfTemplates,
  filledSections,
  isLoadingFilledSections,
  selectedTemplate,
  isDefaultTemplate,
  onSelectTemplate,
  form,
}) => {
  const { t } = useTranslation();

  const pdfTemplateOptions = useMemo(
    () =>
      pdfTemplates?.map((pdfTemplate: IPdfTemplate) => ({
        label: pdfTemplate.name,
        value: pdfTemplate.id,
      })),
    [pdfTemplates],
  );

  const pdfSections = useMemo(
    () => pdfTemplates?.find((pt: IPdfTemplate) => pt.id === selectedTemplate)?.pdfSections || [],
    [pdfTemplates, selectedTemplate],
  );

  return (
    <div data-testid="GiftCardPdfDetails" className={styles.root}>
      {isLoadingPdfTemplates ? (
        <Loading />
      ) : (
        <Form.Item label={t('gift_card.pdf_template')}>
          <Select
            data-testid="PdfTemplates"
            showSearch
            defaultValue={selectedTemplate}
            placeholder={t('gift_card.pdf_template')}
            optionFilterProp="label"
            onChange={onSelectTemplate}
            filterOption={true}
            options={pdfTemplateOptions}
          />
        </Form.Item>
      )}

      {isLoadingFilledSections && <Loading />}

      {filledSections && selectedTemplate && !isLoadingPdfTemplates && !isLoadingFilledSections && pdfTemplates && (
        <GiftCardPdfMultipleForms
          pdfSections={pdfSections}
          filledSections={filledSections}
          isDefaultTemplate={isDefaultTemplate}
          form={form}
          giftCard={giftCard}
        />
      )}
    </div>
  );
};

export default GiftCardPdfDetails;
