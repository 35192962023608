import React from 'react';
import { useMedia } from 'react-media';

import MEDIA_QUERIES from 'constants/media_queries';

import MobileSummary from './mobile_summary';
import Summary from './summary';

import styles from './booking_summary.module.scss';
import { IBookingSummaryProps } from './booking_summary.types';

const BookingSummary: React.FC<IBookingSummaryProps> = ({ total, params, property, onBook, selectedRatesPerUnit }) => {
  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;
  const SummaryComponent = isMobile ? MobileSummary : Summary;

  return (
    <>
      <SummaryComponent
        params={params}
        total={total}
        selectedRatesPerUnit={selectedRatesPerUnit}
        onBook={onBook}
        property={property}
      />
      <div className={styles.summaryPoliciesWrapper} />
    </>
  );
};

export default BookingSummary;
