import React from 'react';

import styles from './section_title.module.scss';
import { ISectionTitleProps } from './section_title.types';

const SectionTitle: React.FC<ISectionTitleProps> = ({ children }) => {
  return <h4 className={styles.sectionTitle}>{children}</h4>;
};

export default SectionTitle;
