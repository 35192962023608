import React from 'react';

import BookButton from './book_button';
import SearchButton from './search_button';

import styles from './action_button.module.scss';
import { IActionButtonProps } from './action_button.types';

const ActionButton: React.FC<IActionButtonProps> = props => {
  const { isRateSelected, isDatesSelected, missingSpaces, currency, loading, total, onBook, onSearch } = props;

  const buttonComponent = isRateSelected ? (
    <BookButton total={total} currency={currency} disabled={!isDatesSelected || !!missingSpaces} onClick={onBook} />
  ) : (
    <SearchButton loading={loading} disabled={!isDatesSelected} onClick={onSearch} />
  );

  return <div className={styles.buttonContainer}>{buttonComponent}</div>;
};

export default ActionButton;
