import React from "react";

import UnitPriceBreakdown from "./unit_price_breakdown";
import TaxesBreakdown from "./taxes_breakdown";

export default function PriceBreakdown({ selectedRatesPerUnit, currency, propertyType }) {
  return (
    <>
      {Object.keys(selectedRatesPerUnit).map((roomId) => (
        <UnitPriceBreakdown
          key={roomId}
          unitWithSelectedRates={selectedRatesPerUnit[roomId]}
          currency={currency}
          propertyType={propertyType}
        />
      ))}
      <TaxesBreakdown selectedRatesPerUnit={selectedRatesPerUnit} currency={currency} />
    </>
  );
}
