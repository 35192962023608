import React from 'react';

import CurrencyConversionWarning from 'components/currency_conversion_warning';
import SubmitBookingButton from 'components/booking_checkout_form/submit_booking_button';

import styles from './submit_section.module.scss';
import { ISubmitSectionProps } from './submit_section.types';

const SubmitSection: React.FC<ISubmitSectionProps> = ({ onSubmit }) => {
  return (
    <div className={styles.container}>
      <CurrencyConversionWarning />
      <SubmitBookingButton onSubmit={onSubmit} />
    </div>
  );
};

export default SubmitSection;
