// Generated with util/create-component.js
import React from 'react';

import GiftCampaignOption from 'components/gift_campaign_option/gift_campaign_option';
import { IGiftCampaignOption } from 'components/gift_campaign_option/gift_campaign_option.types';

import { IGiftCampaignOptionsListProps } from './gift_campaign_options_list.types';
import styles from './gift_campaign_options_list.module.scss';

const GiftCampaignOptionsList: React.FC<IGiftCampaignOptionsListProps> = ({ options, onChooseOption }) => {
  return (
    <div data-testid="GiftCampaignOptionsList" className={styles.root}>
      {options.map((option: IGiftCampaignOption, index: number) => (
        <div key={`gift-campaign-option-${index}`} className={styles.optionContainer}>
          <GiftCampaignOption option={option} onChooseOption={onChooseOption} />
        </div>
      ))}
    </div>
  );
};

export default GiftCampaignOptionsList;
