import React from 'react';

import styles from './policy_section.module.scss';
import { IPolicySectionProps } from './policy_section.types';

const PolicySection: React.FC<IPolicySectionProps> = ({ title, text }) => {
  return (
    <div className={styles.sectionContainer}>
      <strong>{title}: </strong>
      <span>{text}</span>
    </div>
  );
};

export default PolicySection;
