import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './booking_notes.module.scss';
import { IBookingNotesProps } from './booking_notes.types';

const BookingNotes: React.FC<IBookingNotesProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="BookingNotes">
      {booking.notes && (
        <>
          <legend>{t('general.notes')}</legend>
          <Row>
            <Col span={24} className={styles.notes}>
              {booking.notes}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default BookingNotes;
