import React, { useCallback } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';

import CurrencySelect from 'components/inputs/currency_select';

import routes from 'routing/routes';

import HotelLogo from './hotel_logo';
import HotelTitle from './hotel_title';

import styles from './header.module.scss';
import { IHeaderProps } from './header.types';

const Header: React.FC<IHeaderProps> = ({ property = {} }) => {
  const matchHotelPage = useRouteMatch({
    path: routes.propertyPage,
    strict: true,
  });

  const matchNotFoundPage = useRouteMatch({
    path: routes.default,
    strict: true,
  });

  const history = useHistory();

  const handleGoBack = useCallback(() => {
    const { location } = history;
    if (location && location.search) {
      history.push(`/search/${location.search}`);
    }
  }, [history]);

  const isCurrencySelectShown = matchHotelPage?.isExact && !matchNotFoundPage;
  const { title = '', logo = '', hideLogo = false, hideTitle = false } = property;

  return (
    <div className={styles.header}>
      <div className={styles.titleSection}>
        {isCurrencySelectShown && (
          <Button
            type="primary"
            shape="circle"
            icon={<LeftOutlined />}
            className={styles.back_button}
            onClick={handleGoBack}
          />
        )}
        <HotelLogo logo={logo} title={title} hideLogo={hideLogo} />
        <HotelTitle title={title} hideTitle={hideTitle} />
      </div>
      <div className={styles.selectSection}>{isCurrencySelectShown && <CurrencySelect />}</div>
    </div>
  );
};

export default Header;
