import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/button';

import { BookingCheckoutDataContext } from 'containers/data_context';

import PolicyCheckbox from './policy_checkbox';

import { ISubmitBookingButtonProps } from './submit_booking_button.types';

const SubmitBookingButton: React.FC<ISubmitBookingButtonProps> = ({ onSubmit = () => null }) => {
  const { t } = useTranslation();
  const [isPolicyAccepted, setIsPolicyAccepted] = useState(false);
  const { isSubmitting } = useContext(BookingCheckoutDataContext);
  const isSubmitDisabled = !isPolicyAccepted || isSubmitting;

  return (
    <>
      <PolicyCheckbox value={isPolicyAccepted} onChange={setIsPolicyAccepted} />
      <Button loading={isSubmitting} disabled={isSubmitDisabled} onClick={onSubmit}>
        {t('payment_page.agree_and_book')}
      </Button>
    </>
  );
};

export default SubmitBookingButton;
