import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/property_rate_plans';

export default () => {
  const [propertyRatePlansData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadPropertyRatePlans = useCallback(
    params => {
      return actions.loadPropertyRatePlans(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyRatePlan = useCallback(
    params => {
      return actions.loadPropertyRatePlan(dispatch, params);
    },
    [dispatch],
  );

  const updateRatePlan = useCallback(
    params => {
      return actions.updateRatePlan(dispatch, params);
    },
    [dispatch],
  );

  const createRatePlan = useCallback(
    params => {
      return actions.createRatePlan(dispatch, params);
    },
    [dispatch],
  );

  const removeRatePlan = useCallback(
    params => {
      return actions.removeRatePlan(dispatch, params);
    },
    [dispatch],
  );

  const clearSelectedRatePlan = useCallback(
    params => {
      return actions.clearSelectedRatePlan(dispatch, params);
    },
    [dispatch],
  );

  const loadAllPropertyRatePlans = useCallback(
    params => {
      return actions.loadAllPropertyRatePlans(dispatch, params)
    },
    [dispatch]
  );

  const resetPropertyRatePlans = useCallback(
    () => {
      return actions.resetPropertyRatePlans(dispatch)
    },
    [dispatch]
  );

  const propertyRatePlansActions = useMemo(
    () => ({
      loadAllPropertyRatePlans,
      loadPropertyRatePlans,
      loadPropertyRatePlan,
      updateRatePlan,
      createRatePlan,
      removeRatePlan,
      clearSelectedRatePlan,
      resetPropertyRatePlans,
    }),
    [
      loadAllPropertyRatePlans,
      loadPropertyRatePlans,
      loadPropertyRatePlan,
      updateRatePlan,
      createRatePlan,
      removeRatePlan,
      clearSelectedRatePlan,
      resetPropertyRatePlans,
    ],
  );

  return useMemo(
    () => ({ propertyRatePlansData, propertyRatePlansActions }),
    [propertyRatePlansData, propertyRatePlansActions],
  );
};
