import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';

import locales from 'locales';
import getUrlParams from 'utils/get_url_params';
import setUrlParams from 'utils/set_url_params';

import { ILocaleSelectMobileProps } from './locale_select_mobile.types';
import styles from './locale_select_mobile.module.scss';

const LocaleSelectMobile: React.FC<ILocaleSelectMobileProps> = ({ isChangingUrlParam }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const history = useHistory();

  const localeOptions = locales.map(locale => ({
    value: locale.code,
    label: locale.name,
  }));

  const { locale: defaultLanguage } = getUrlParams() as { locale: string };

  let defaultSelectedLanguage = 'en';

  /* Only take the language, not the full locale */
  const usersLocaleLanguage = language.includes('-') ? language.split('-')[0] : language;

  if (localeOptions.find(locale => locale.value === defaultLanguage)) {
    defaultSelectedLanguage = defaultLanguage;
  } else if (localeOptions.find(locale => locale.value === usersLocaleLanguage)) {
    defaultSelectedLanguage = usersLocaleLanguage;
  } else {
    defaultSelectedLanguage = 'en';
  }

  useEffect(() => {
    if (defaultSelectedLanguage) {
      i18n.changeLanguage(defaultSelectedLanguage);
    }
  }, [defaultSelectedLanguage]);

  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    if (isChangingUrlParam) {
      const params: { locale?: string } = getUrlParams();
      params.locale = code;

      setUrlParams(params, history);
    }
  };

  return (
    <div data-testid="LocaleSelectMobile" className={styles.root}>
      <Select
        className={styles.select}
        showSearch
        optionFilterProp="label"
        defaultValue={defaultSelectedLanguage}
        onChange={handleLanguageChange}
        options={localeOptions}
      />
    </div>
  );
};

export default LocaleSelectMobile;
