import { ReactComponent as AvisLogo } from 'assets/logo/avis.svg';
import { ReactComponent as HertzLogo } from 'assets/logo/hertz.svg';
import EuropcarLogo from 'assets/logo/europcar.svg';

export const renderProviderLogo = provider => {
  switch (provider) {
    case 'Avis':
      return <AvisLogo />;
    case 'Hertz':
      return <HertzLogo />;
    case 'Europcar':
      return <img src={EuropcarLogo} alt="Europcar Logo" />
    default:
      return null;
  }
}