import ApiActions from 'api_actions';

export const SET_STRIPE_NOTIFICATION = 'SET_STRIPE_NOTIFICATION';
export const RESET_STRIPE_NOTIFICATION = 'RESET_STRIPE_NOTIFICATION';

const setStripeNotification = (dispatch, payload) => {
  return dispatch({ type: SET_STRIPE_NOTIFICATION, payload });
};

const resetStripeNotification = (dispatch) => {
  return dispatch({ type: RESET_STRIPE_NOTIFICATION });
};

const checkResellerPaymentSource = async (dispatch) => {
  try {
    const data = await ApiActions.getCheckResellerPaymentSource();
    if (data) setStripeNotification(dispatch, data);
  } catch (e) {
    resetStripeNotification(dispatch);
  }
};

const checkSupplierPaymentSource = async (dispatch, propertyId) => {
  try {
    const data = await ApiActions.getCheckSupplierPaymentSource(propertyId);
    if (data) setStripeNotification(dispatch, data);
  } catch (e) {
    resetStripeNotification(dispatch);
  }
};

export const actions = {
  checkResellerPaymentSource,
  checkSupplierPaymentSource,
};