// Generated with util/create-component.js
import React, { useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, notification } from 'antd';

import {
  ResellerContractsDataContext,
  ResellerContractsActionsContext,
  AuthenticationDataContext,
  SupplierContractsDataContext,
  SupplierContractsActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import USER_ROLE from 'constants/user_role';
import CONTRACT_STATUS from 'constants/contract_status';
import DRAWER_SIZE from 'constants/drawer_size';
import { PROPERTY_CHANNELS } from 'constants/property_channels';

import PendingContractDetails from 'components/contracts/pending/pending_contract_details';
import ContractsList from 'components/contracts/contracts_list/contracts_list';
import { IPaginationParams, IContractsFilters } from 'components/contracts/contracts_list/contracts_list.types';
import SearchInput from 'components/search_input';

import { IContract, IContractStatus } from './pending_contracts.types';
import styles from './pending_contracts.module.scss';

const DEFAULT_PAGINATION = { page: 1, limit: 10 };

const PendingContracts: React.FC = () => {
  const [openedContractDetails, toogleContractDetails] = useState<boolean>(false);
  const [contractDetails, setContractDetails] = useState<IContract | null>(null);
  const [filters, setFilters] = useState<IContractsFilters>({
    pagination: DEFAULT_PAGINATION,
    term: '',
    propertyType: PROPERTY_CHANNELS.CAR,
  });

  const { t } = useTranslation();

  const { userRole } = useContext(AuthenticationDataContext);

  if (!userRole) return null;

  const isReseller = useMemo(() => userRole === USER_ROLE.RESELLER, [userRole]);

  const {
    contracts: {
      data: {
        proposed: { contracts, meta },
      },
      isLoading,
      isAcceptingContract,
      isDecliningContract,
      isCancelingContractProposal,
    },
  } = useContext(userRole === USER_ROLE.RESELLER ? ResellerContractsDataContext : SupplierContractsDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const { loadContractsList, acceptContract, declineContract, cancelContractProposal } = useContext(
    userRole === USER_ROLE.RESELLER ? ResellerContractsActionsContext : SupplierContractsActionsContext,
  );

  useEffect(() => {
    handleLoadContractsList(filters);
  }, [filters]);

  useEffect(() => {
    setFilters({ ...filters, pagination: DEFAULT_PAGINATION });
  }, [selectedProperty]);

  const handleLoadContractsList = useCallback(
    (additionalParams = {}) => {
      const params: { status: IContractStatus; propertyId?: string } = {
        status: CONTRACT_STATUS.PROPOSED as IContractStatus,
      };
      if (selectedProperty) {
        params.propertyId = selectedProperty;
      }
      loadContractsList({ ...params, ...additionalParams });
    },
    [loadContractsList, selectedProperty, filters],
  );

  const handlePageChange = useCallback(
    (pagination: IPaginationParams) => {
      setFilters({ ...filters, pagination });
    },
    [loadContractsList, filters],
  );

  const handleSearchTermChange = useCallback(
    (term: string) => {
      setFilters({ ...filters, pagination: DEFAULT_PAGINATION, term });
    },
    [loadContractsList, filters],
  );

  const openNotificationWithIcon = useCallback((type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('contracts.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  }, []);

  const handleAcceptContract = useCallback(
    async (contract: IContract) => {
      await handleResponseContract(acceptContract, contract);
    },
    [acceptContract, selectedProperty],
  );

  const handleDeclineContract = useCallback(
    async (contract: IContract) => {
      await handleResponseContract(declineContract, contract);
    },
    [declineContract, selectedProperty],
  );

  const handleResponseContract = useCallback(
    async (
      contractAction: ({
        contractId,
        propertyType,
        propertyId,
      }: {
        contractId: IContract['id'];
        propertyType: IContract['propertyType'];
        propertyId?: string | undefined;
      }) => Promise<void>,
      contract: IContract,
    ) => {
      const params: {
        contractId: IContract['id'];
        propertyId?: IContract['propertyChannelId'];
        propertyType: IContract['propertyType'];
      } = {
        contractId: contract.id,
        propertyType: contract.propertyType,
      };

      if (selectedProperty) {
        params.propertyId = selectedProperty;
      }

      try {
        await contractAction(params);
        handleCloseContractDetails();
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    },
    [selectedProperty],
  );

  const handleCancelContractProposal = useCallback(
    async (contract: IContract) => {
      await handleResponseContract(cancelContractProposal, contract);
    },
    [cancelContractProposal, selectedProperty],
  );

  const handleCloseContractDetails = useCallback(() => {
    toogleContractDetails(false);
    setContractDetails(null);
  }, []);

  const handleOpenContractDetails = useCallback((contract: IContract) => {
    toogleContractDetails(true);
    setContractDetails(contract);
  }, []);

  const modalTitle = useMemo(
    () => (contractDetails ? (isReseller ? contractDetails.reservaHotelName : contractDetails.agentName) : ''),
    [contractDetails, isReseller],
  );

  return (
    <div data-testid="PendingContracts" className={styles.root}>
      <div className={styles.searchInputContainer}>
        <SearchInput onSearch={handleSearchTermChange} placeholder={t('contracts.search_for_contract')} />
      </div>
      <ContractsList
        onPageChange={handlePageChange}
        userRole={userRole}
        contracts={contracts}
        pagination={meta}
        isLoading={isLoading}
        onOpenContractDetails={handleOpenContractDetails}
      />
      <Drawer
        title={modalTitle}
        open={openedContractDetails}
        onClose={handleCloseContractDetails}
        width={window.innerWidth > 900 ? DRAWER_SIZE.SMALL : window.innerWidth}
        destroyOnClose={true}
      >
        {contractDetails && (
          <PendingContractDetails
            contract={contractDetails}
            onAcceptContract={handleAcceptContract}
            onDeclineContract={handleDeclineContract}
            onCancelContractProposal={handleCancelContractProposal}
            isAcceptingContract={isAcceptingContract}
            isDecliningContract={isDecliningContract}
            isCancelingContractProposal={isCancelingContractProposal}
            userRole={userRole}
          />
        )}
      </Drawer>
    </div>
  );
};

export default PendingContracts;
