// Generated with util/create-component.js
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import notification from 'utils/notification';
import DRAWER_SIZE from 'constants/drawer_size';
import PaymentProviderForm from 'components/payment_provider_form/payment_provider_form';
import PaymentProvidersList from 'components/payment_providers_list/payment_providers_list';

import {
  PropertySettingsDataContext,
  PaymentProvidersDataContext,
  PaymentProvidersActionsContext,
} from 'containers/data_context';

import styles from './payment_providers_page.module.scss';
import { IPaymentProvider } from './payment_providers_page.types';

const PaymentProvidersPage: React.FC = () => {
  const { t } = useTranslation();

  const { loadPaymentProviders, createPaymentProvider, updatePaymentProvider, deletePaymentProvider } =
    useContext(PaymentProvidersActionsContext);

  const {
    paymentProviders: { data: providers, isLoading },
    isCreatingPaymentProvider,
    isUpdatingPaymentProvider,
  } = useContext(PaymentProvidersDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const [openedPPForm, tooglePPForm] = useState<boolean>(false);
  const [paymentProvider, setPaymentProvider] = useState<IPaymentProvider | null>(null);

  const handleOpenPPForm = (paymentProvider: IPaymentProvider) => {
    setPaymentProvider(paymentProvider);
    tooglePPForm(true);
  };

  const handleClosePPForm = () => {
    setPaymentProvider(null);
    tooglePPForm(false);
  };

  const handleDeletePaymentProvider = async (paymentProvider: IPaymentProvider) => {
    try {
      await deletePaymentProvider({ propertyId: selectedProperty, id: paymentProvider.id });
      notification.withIcon('success', 'Payment Provider successfully deleted');
    } catch {
      notification.withIcon('error', 'Something went wrong. Please try again');
    }

    handleClosePPForm();
  };

  const handleSave = async (provider: IPaymentProvider) => {
    let providerResponse = null;
    try {
      if (paymentProvider) {
        providerResponse = await updatePaymentProvider({
          propertyId: selectedProperty,
          provider: { ...provider, id: paymentProvider?.id },
        });
        notification.withIcon('success', 'Payment Provider is successfully updated.');
      } else {
        providerResponse = await createPaymentProvider({ propertyId: selectedProperty, provider });
        notification.withIcon('success', 'Payment Provider is successfully created.');
      }
    } catch {
      notification.withIcon('error', 'Something went wrong. Please try again');
    }

    if (providerResponse.details.onboardingAccountUrl && !paymentProvider)
      window.location.replace(providerResponse.details.onboardingAccountUrl);

    handleClosePPForm();
  };

  useEffect(() => {
    if (selectedProperty) {
      loadPaymentProviders(selectedProperty);
    }
  }, [selectedProperty]);

  const isSaving = useMemo(
    () => isCreatingPaymentProvider || isUpdatingPaymentProvider,
    [isCreatingPaymentProvider, isUpdatingPaymentProvider],
  );

  return (
    <div data-testid="PaymentProvidersPage" className={styles.root}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{t('general.payment_providers')}</div>
        <Button
          onClick={() => tooglePPForm(true)}
          type="primary"
          icon={<PlusCircleOutlined />}
          className={styles.createButton}
          size="large"
        >
          {t('general.create')}
        </Button>
      </div>
      <PaymentProvidersList
        providers={providers}
        isLoading={isLoading}
        onEdit={handleOpenPPForm}
        onDelete={handleDeletePaymentProvider}
      />

      <Drawer
        open={openedPPForm}
        title={paymentProvider ? t('general.buttons.edit') : t('general.tooltip.create')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleClosePPForm}
        destroyOnClose={true}
      >
        <PaymentProviderForm
          onCancel={handleClosePPForm}
          onSave={handleSave}
          paymentProvider={paymentProvider}
          isSaving={isSaving}
        />
      </Drawer>
    </div>
  );
};

export default PaymentProvidersPage;
