import {
  SET_API_KEYS,
  SET_IS_LOADING_API_KEYS,
  SET_IS_CREATING_API_KEY,
  SET_IS_DISABLING_API_KEY,
} from './api_keys_actions';

export const INITIAL_STATE = {
  apiKeys: {
    isLoading: false,
    data: [],
  },
  isCreating: false,
  isDisabling: false,
};

const actionHandlers = {
  [SET_API_KEYS]: (state, action) => {
    const apiKeys = { data: action.payload, isLoading: false };

    return { ...state, apiKeys };
  },
  [SET_IS_LOADING_API_KEYS]: (state, action) => {
    const apiKeys = { ...state.apiKeys, isLoading: action.payload };
    return { ...state, apiKeys };
  },
  [SET_IS_CREATING_API_KEY]: (state, action) => {
    return { ...state, isCreating: action.payload };
  },
  [SET_IS_DISABLING_API_KEY]: (state, action) => {
    return { ...state, isDisabling: action.payload };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
