// Generated with util/create-component.js
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import DRAWER_SIZE from 'constants/drawer_size';

import ApiKeysList from 'components/api_keys_list/api_keys_list';
import CreateApiKeyForm from 'components/create_api_key_form/create_api_key_form';
import { IApiKey } from 'components/api_keys_list/api_keys_list.types';

import { ApiKeysActionsContext, ApiKeysDataContext } from 'containers/data_context';

import styles from './api_keys_page.module.scss';

const ApiKeysPage: React.FC = () => {
  const { t } = useTranslation();

  const { getApiKeys, createApiKey, disableApiKey } = useContext(ApiKeysActionsContext);

  const {
    apiKeys: { isLoading, data },
    isCreating,
  } = useContext(ApiKeysDataContext);

  const [openCreateApiKeyDrawer, toggleCreateApiKeyDrawer] = useState<boolean>(false);

  useEffect(() => {
    async function fetchApiKeys() {
      await getApiKeys({
        page: 1,
        limit: 10,
      });
    }

    fetchApiKeys();
  }, []);

  const handlePageChange = (pagination: { page: number; limit: number }) => {
    getApiKeys({ pagination });
  };

  const handleCreateApiKey = async (apiKey: { apiKey: { name: string } }): Promise<{ name: string; token: string }> => {
    const apiKeyDetails = await createApiKey(apiKey);

    const pagination = {
      page: data?.meta?.page || 1,
      limit: data?.meta?.limit || 10,
    };

    getApiKeys(pagination);

    return apiKeyDetails;
  };

  const handleDisableApiKey = (apiKeyId: IApiKey['id']) => {
    return disableApiKey(apiKeyId);
  };

  return (
    <div data-testid="ApiKeysPage" className={styles.root}>
      <Button
        onClick={() => toggleCreateApiKeyDrawer(true)}
        type="primary"
        icon={<PlusCircleOutlined />}
        className={styles.createButton}
      >
        {t('general.create')}
      </Button>
      <ApiKeysList
        isLoading={isLoading}
        apiKeys={data}
        onPageChange={handlePageChange}
        onDisableApiKey={handleDisableApiKey}
      />
      {openCreateApiKeyDrawer && (
        <Drawer
          open={openCreateApiKeyDrawer}
          title={t('api_key.create')}
          width={window.innerWidth > 900 ? DRAWER_SIZE.MEDIUM : window.innerWidth}
          onClose={() => toggleCreateApiKeyDrawer(false)}
          destroyOnClose={true}
        >
          <CreateApiKeyForm
            onCreate={handleCreateApiKey}
            isCreating={isCreating}
            onCancel={() => toggleCreateApiKeyDrawer(false)}
          />
        </Drawer>
      )}
    </div>
  );
};

export default ApiKeysPage;
