import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'components/layout/section_wrapper';
import RatesTable from 'components/rates_table';
import SectionTitle from 'components/section_title';

import { IPropertyRatesSectionProps } from './property_rates_section.types';

const PropertyRatesSection: React.FC<IPropertyRatesSectionProps> = ({ propertyType }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper theme="dark">
      <Col xs="12" lg="8">
        <SectionTitle>{t(`rates_table_${propertyType}.title`)}</SectionTitle>
        <RatesTable propertyType={propertyType} />
      </Col>
    </SectionWrapper>
  );
};

export default PropertyRatesSection;
