// Generated with util/create-component.js
import React from 'react';

import { IInventoryProps } from './inventory.types';
import styles from './inventory.module.scss';

const Inventory: React.FC<IInventoryProps> = ({ token, propertyId }) => {
  if (!token || !propertyId) return null;

  return (
    <div data-testid="Inventory" className={styles.root}>
      <iframe
        src={`${process.env.REACT_APP_INVENTORY_URL}/auth/exchange?oauth_session_key=${token}&app_mode=headless&redirect_to=/inventory&property_id=${propertyId}`}
        className={styles.inventory_container}
        id="inventory-iframe"
      ></iframe>
    </div>
  );
};

export default Inventory;
