import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/search_data';

export default () => {
  const [searchData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadSuppliersList = useCallback(
    (type, params, filter) => {
      return actions.loadSuppliersList(dispatch, type, params, filter);
    },
    [dispatch],
  );

  const clearPropertiesList = useCallback(() => {
    return actions.clearPropertiesList(dispatch);
  }, [dispatch]);

  const clearCarsList = useCallback(() => {
    return actions.clearCarsList(dispatch);
  }, [dispatch]);

  const loadAllPropertiesList = useCallback(
    (params, filter) => {
      return actions.loadAllPropertiesList(dispatch, params, filter);
    },
    [dispatch],
  );

  const loadAllCarsList = useCallback(
    (params, filter) => {
      return actions.loadAllCarsList(dispatch, params, filter);
    },
    [dispatch],
  );

  const clearAllPropertiesList = useCallback(() => {
    return actions.clearAllPropertiesList(dispatch);
  }, [dispatch]);

  const searchActions = useMemo(
    () => ({
      loadSuppliersList,
      clearPropertiesList,
      clearCarsList,
      loadAllPropertiesList,
      loadAllCarsList,
      clearAllPropertiesList,
    }),
    [loadSuppliersList,
     clearCarsList,
     clearPropertiesList,
     loadAllPropertiesList,
     clearAllPropertiesList,
     loadAllCarsList
    ],
  );

  return useMemo(() => ({ searchData, searchActions }), [searchData, searchActions]);
};
