import React from 'react';

import Footer from 'components/footer';
import Header from 'components/header';

import NotFoundMessage from './not_found_message';

const NotFoundPage: React.FC = () => {
  return (
    <div>
      <Header />
      <NotFoundMessage />
      <Footer />
    </div>
  );
};

export default NotFoundPage;
