import { useReducer, useCallback, useMemo } from 'react';
import { actions, INITIAL_STATE, reducer } from './reducers/supplier_contracts_data';

export default () => {
  const [supplierContractsData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadSupplierDiscoverContractsList = useCallback(
    params => {
      return actions.loadSupplierDiscoverContractsList(dispatch, params);
    },
    [dispatch],
  );

  const loadSupplierRatePlansList = useCallback(
    params => {
      return actions.loadSupplierRatePlansList(dispatch, params);
    },
    [dispatch],
  );

  const clearSupplierAllUnitTypesList = useCallback(
    params => {
      return actions.clearSupplierAllUnitTypesList(dispatch, params);
    },
    [dispatch],
  );

  const supplierCreateContract = useCallback(
    params => {
      return actions.supplierCreateContract(dispatch, params);
    },
    [dispatch],
  );

  const loadContractsList = useCallback(
    params => {
      return actions.loadSupplierContractsList(dispatch, params);
    },
    [dispatch],
  );

  const acceptContract = useCallback(
    params => {
      return actions.acceptSupplierContract(dispatch, params);
    },
    [dispatch],
  );

  const terminateContract = useCallback(
    params => {
      return actions.terminateSupplierContract(dispatch, params);
    },
    [dispatch],
  );

  const declineContract = useCallback(
    params => {
      return actions.declineSupplierContract(dispatch, params);
    },
    [dispatch],
  );

  const cancelContractProposal = useCallback(
    params => {
      return actions.cancelSupplierContractProposal(dispatch, params);
    },
    [dispatch],
  );

  const resetContracts = useCallback(
    () => {
      return actions.resetContracts(dispatch);
    },
    [dispatch],
  );

  const supplierContractsActions = useMemo(
    () => ({
      loadSupplierDiscoverContractsList,
      loadSupplierRatePlansList,
      clearSupplierAllUnitTypesList,
      supplierCreateContract,
      loadContractsList,
      acceptContract,
      terminateContract,
      declineContract,
      cancelContractProposal,
      resetContracts,
    }),
    [
      loadSupplierDiscoverContractsList,
      loadSupplierRatePlansList,
      clearSupplierAllUnitTypesList,
      supplierCreateContract,
      loadContractsList,
      acceptContract,
      terminateContract,
      declineContract,
      cancelContractProposal,
      resetContracts,
    ],
  );

  return useMemo(() => ({ supplierContractsData, supplierContractsActions }), [supplierContractsData, supplierContractsActions]);
};
