import React from 'react';

import styles from './hotel_logo.module.scss';
import { IHotelLogoProps } from './hotel_logo.types';

const HotelLogo: React.FC<IHotelLogoProps> = ({ logo, title, hideLogo }) => {
  if (!logo || hideLogo) {
    return null;
  }

  return (
    <div className={styles.logoContainer}>
      <img className={styles.logo} src={`${logo}-/resize/80x/80.jpg`} alt={title} />
    </div>
  );
};

export default HotelLogo;
