import { SET_STRIPE_NOTIFICATION, RESET_STRIPE_NOTIFICATION } from './stripe_actions';
  

export const INITIAL_STATE = {
  stripeNotification: null,
};

const actionHandlers = {
  [SET_STRIPE_NOTIFICATION]: (state, action) => {
    return { ...state, stripeNotification: action.payload.paymentSourceMissing };
  },
  [RESET_STRIPE_NOTIFICATION]: (state) => {
    return { ...state, stripeNotification: null};
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
