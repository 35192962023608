import ApiActions from 'api_actions';
import CONTRACT_STATUS from 'constants/contract_status';

export const SET_RESELLER_DISCOVER_CONTRACTS_LIST_LOADING = 'SET_RESELLER_DISCOVER_CONTRACTS_LIST_LOADING';
export const SET_RESELLER_DISCOVER_CONTRACTS_LIST = 'SET_RESELLER_DISCOVER_CONTRACTS_LIST';
export const SET_RESELLER_CONTRACTS_RATES_LOADING = 'SET_RESELLER_CONTRACTS_RATES_LOADING';
export const SET_RESELLER_CONTRACTS_RATES_LIST = 'SET_RESELLER_CONTRACTS_RATES_LIST';
export const SET_RESELLER_CREATE_CONTRACT_LOADING = 'SET_RESELLER_CREATE_CONTRACT_LOADING';
export const RESET_RESELLER_CONTRACTS_OPEN_UNIT_TYPES_LIST = 'RESET_RESELLER_CONTRACTS_OPEN_UNIT_TYPES_LIST';

export const SET_RESELLER_CONTRACTS_LIST_LOADING = 'SET_RESELLER_CONTRACTS_LIST_LOADING';
export const SET_RESELLER_CONTRACTS_LIST = 'SET_RESELLER_CONTRACTS_LIST';

export const SET_RESELLER_ACCEPT_CONTRACT_LOADING = 'SET_RESELLER_ACCEPT_CONTRACT_LOADING';
export const SET_RESELLER_TERMINATING_CONTRACT_LOADING = 'SET_RESELLER_TERMINATING_CONTRACT_LOADING';
export const SET_RESELLER_DECLINING_CONTRACT_LOADING = 'SET_RESELLER_DECLINING_CONTRACT_LOADING';
export const SET_RESELLER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING = 'SET_RESELLER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING';

const setResellerContractsListLoading = dispatch => {
  return dispatch({ type: SET_RESELLER_DISCOVER_CONTRACTS_LIST_LOADING });
};

const setResellerContractsList = (dispatch, payload) => {
  return dispatch({ type: SET_RESELLER_DISCOVER_CONTRACTS_LIST, payload });
};

const setResellerContractsRatesLoading = dispatch => {
  return dispatch({ type: SET_RESELLER_CONTRACTS_RATES_LOADING });
};

const setResellerContractsRatesList = (dispatch, payload) => {
  return dispatch({ type: SET_RESELLER_CONTRACTS_RATES_LIST, payload });
};

const setResellerCreateContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_CREATE_CONTRACT_LOADING, params });
};

const loadResellerDiscoverContractsList = async (dispatch, params) => {
  setResellerContractsListLoading(dispatch);
  const data = await ApiActions.getContractsDiscoverSuppliers(params);
  setResellerContractsList(dispatch, data);
};

const loadResellerContractsUnitRatesList = async (dispatch, params) => {
  setResellerContractsRatesLoading(dispatch);

  const data = await ApiActions.getContractPropertyRates(params);
  setResellerContractsRatesList(dispatch, data);
};

const clearResellerOpenUnitTypesList = dispatch => {
  return dispatch({ type: RESET_RESELLER_CONTRACTS_OPEN_UNIT_TYPES_LIST });
};

const createContractRequest = async (dispatch, params) => {
  setResellerCreateContractLoading(dispatch);

  try {
      await ApiActions.createContractRequest(params);
      setResellerCreateContractLoading(dispatch, false);
  } catch (e) {
      setResellerCreateContractLoading(dispatch, false);
      throw e;
  }
}

const resellerCreateContract = async (dispatch, params) => {
  setResellerCreateContractLoading(dispatch);

  try {
    await ApiActions.createResellerInitiatedContract(params);
    setResellerCreateContractLoading(dispatch, false);

    const proposedContracts = await ApiActions.getResellerContracts({ status: CONTRACT_STATUS.PROPOSED });
    const approvedContracts = await ApiActions.getResellerContracts({ status: CONTRACT_STATUS.APPROVED });

    setResellerContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
    setResellerContracts(dispatch, {
      status: CONTRACT_STATUS.APPROVED,
      contracts: { contracts: approvedContracts?.data, meta: approvedContracts?.meta },
    });
  } catch (e) {
    setResellerCreateContractLoading(dispatch, false);
    throw e;
  }
};

const setAgentContractsLoading = dispatch => {
  return dispatch({ type: SET_RESELLER_CONTRACTS_LIST_LOADING });
};

const setResellerContracts = (dispatch, payload) => {
  return dispatch({ type: SET_RESELLER_CONTRACTS_LIST, payload });
};

const loadResellerContractsList = async (dispatch, params) => {
  setAgentContractsLoading(dispatch);

  const data = await ApiActions.getResellerContracts(params);

  setResellerContracts(dispatch, { status: params.status, contracts: { contracts: data?.data, meta: data?.meta } });
};

const setResellerAcceptContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_ACCEPT_CONTRACT_LOADING, params });
};

const acceptResellerContract = async (dispatch, params) => {
  try {
    setResellerAcceptContractLoading(dispatch);

    await ApiActions.approveResellerContract(params);
    const proposedContracts = await ApiActions.getResellerContracts({ status: CONTRACT_STATUS.PROPOSED });
    const approvedContracts = await ApiActions.getResellerContracts({ status: CONTRACT_STATUS.APPROVED });

    setResellerContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
    setResellerContracts(dispatch, {
      status: CONTRACT_STATUS.APPROVED,
      contracts: { contracts: approvedContracts?.data, meta: approvedContracts?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setResellerAcceptContractLoading(dispatch, false);
  }
};

const setResellerTerminateContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_TERMINATING_CONTRACT_LOADING, params });
};

const terminateResellerContract = async (dispatch, params) => {
  try {
    setResellerTerminateContractLoading(dispatch);

    await ApiActions.terminateResellerContract(params);
    const status = CONTRACT_STATUS.APPROVED;
    const approvedContracts = await ApiActions.getResellerContracts({ status });

    setResellerContracts(dispatch, {
      status,
      contracts: { contracts: approvedContracts?.data, meta: approvedContracts?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setResellerTerminateContractLoading(dispatch, false);
  }
};

const setResellerDeclineContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_DECLINING_CONTRACT_LOADING, params });
};

const declineResellerContract = async (dispatch, params) => {
  try {
    setResellerDeclineContractLoading(dispatch);

    await ApiActions.declineResellerContract(params);
    const status = CONTRACT_STATUS.PROPOSED;
    const proposedContracts = await ApiActions.getResellerContracts({ status });

    setResellerContracts(dispatch, {
      status,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setResellerDeclineContractLoading(dispatch, false);
  }
};

const setResellerCancelContractProposalLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RESELLER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING, params });
};

const cancelResellerContractProposal = async (dispatch, params) => {
  try {
    setResellerCancelContractProposalLoading(dispatch);

    await ApiActions.cancelResellerContractProposal(params);
    const status = CONTRACT_STATUS.PROPOSED;
    const proposedContracts = await ApiActions.getResellerContracts({ status });

    setResellerContracts(dispatch, {
      status,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setResellerCancelContractProposalLoading(dispatch, false);
  }
};

export const actions = {
  loadResellerDiscoverContractsList,
  loadResellerContractsUnitRatesList,
  resellerCreateContract,
  clearResellerOpenUnitTypesList,
  loadResellerContractsList,
  acceptResellerContract,
  terminateResellerContract,
  declineResellerContract,
  cancelResellerContractProposal,
  createContractRequest,
};
