import { useEffect, useState } from 'react';

interface ICookieConsent {
  'strictly-necessary': boolean;
  functionality: boolean;
  tracking: boolean;
  targeting: boolean;
}

const useTrackingCookieConsent = (): boolean => {
  const [isTrackingAllowed, setTrackingAllowed] = useState<boolean>(false);

  useEffect(() => {
    const decodeCookieValue = (cookieValue: string): ICookieConsent | null => {
      try {
        const decodedValue = decodeURIComponent(cookieValue);
        const jsonValue: ICookieConsent = JSON.parse(decodedValue);
        return jsonValue;
      } catch (error) {
        console.error('Error decoding cookie value:', error);
        return null;
      }
    };

    const getCookieValueByName = (name: string): string | null => {
      const cookies = document.cookie.split(';');
      const cookie = cookies.find(c => c.trim().startsWith(name + '='));
      return cookie ? cookie.split('=')[1] : null;
    };

    const cookieName = 'cookie_consent_level';
    const cookieValue = getCookieValueByName(cookieName);

    if (cookieValue) {
      const decodedCookieValue = decodeCookieValue(cookieValue);
      const trackingAllowed = decodedCookieValue && decodedCookieValue.tracking;
      setTrackingAllowed(!!trackingAllowed);
    }
  }, []);

  return isTrackingAllowed;
};

export default useTrackingCookieConsent;
