// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'antd';

import styles from './admin_page.module.scss';
import UsersList from 'components/admin/users/users_list/users_list';
import ResellersList from 'components/admin/resellers/resellers_list/resellers_list';
import PropertiesList from 'components/admin/properties/properties_list/properties_list';

const AdminPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState('users');

  const { t } = useTranslation();

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const tabs = [
    {
      label: t('general.users'),
      key: 'users',
      children: <UsersList />,
    },
    {
      label: t('general.resellers'),
      key: 'resellers',
      children: <ResellersList />,
    },
    {
      label: t('general.properties'),
      key: 'properties',
      children: <PropertiesList />,
    },
  ];

  return (
    <div data-testid="AdminPage" className={styles.root}>
      <Tabs activeKey={selectedTab} onTabClick={handleTabClick} centered className={styles.tab_root} items={tabs} />
    </div>
  );
};

export default AdminPage;
