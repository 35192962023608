import React, { useMemo, useState } from 'react';
import { Button, Steps, Row, Col } from 'antd';

import { IStepPanelProps } from './step_panel.types';
import styles from './step_panel.module.scss';
import { useTranslation } from 'react-i18next';

const StepPanel: React.FC<IStepPanelProps> = ({ steps, onNext }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);

  const next = async () => {
    const resp = await onNext();
    if (resp) {
      const nextStep = activeStep + 1;
      setActiveStep(nextStep);
    }
  };

  const allSteps = useMemo(() => {
    return steps.map(({ title }: { title: string }) => ({ title, key: title, ['data-testid']: title }));
  }, [steps]);

  return (
    <div data-testid="StepPanel" className={styles.root}>
      <Row>
        <Col sm={24} md={6}>
          <Steps current={activeStep} direction="vertical" items={allSteps}></Steps>
        </Col>
        <Col sm={24} md={18}>
          <div className="steps-content">{steps?.[activeStep]?.content}</div>
        </Col>
        <div className="steps-action">
          {activeStep < steps.length - 1 && (
            <div className={styles.footer}>
              <Button type="primary" onClick={next} className={styles.submit_button}>
                {t('general.next')}
              </Button>
            </div>
          )}
          {activeStep === steps.length - 1 && (
            <div className={styles.footer}>
              <Button type="primary" htmlType="submit" className={styles.submit_button}>
                {t('general.finish')}
              </Button>
            </div>
          )}
        </div>
      </Row>
    </div>
  );
};

export default StepPanel;
