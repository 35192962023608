interface IRoutes {
  [key: string]: string;
}

const settingsPages: IRoutes = {
  settingsPagePropertyDetails: '/settings/property-details',
  settingsPageRoomsAndRates: '/settings/rooms-and-rates',
  settingsPagePolicies: '/settings/policies',
  settingsPageStores: '/settings/stores',
  settingsPagePaymentProviders: '/settings/payment-providers',
  settingsPageAdmin: '/settings/admin',
  settingsPage: '/settings',
};

const routes: IRoutes = {
  homePage: '/',
  searchCarsPage: '/search_cars',
  searchPage: '/search',
  propertyPage: '/property/:channelId',
  checkoutPage: '/property/:channelId/booking_info',
  confirmationPage: '/property/:channelId/confirmation/:bookingId',
  default: '/404',
  loginPage: '/login',
  bookingsPage: '/bookings',
  profilePage: '/profile',
  ...settingsPages,
  contractsPage: '/contracts',
  inventoryPage: '/inventory',
  ordersPage: '/orders',
  productsPage: '/products',
  adminPage: '/admin',
  apiKeysPage: '/api_keys',
  changePassword: '/changePassword',
  verifyResetPasswordEmail: '/verify_reset_password_email',
  giftCardPage: '/giftcards',
  giftCardPropertiesPage: '/giftcards/properties/:giftCardId',
  giftCardStorePage: '/:selectedProperty',
  giftCardStoreCartPage: '/:selectedProperty/cart',
  useGiftCardStorePage: '/:selectedProperty/useGiftCard',
  cancelGiftCardStorePage: '/:selectedProperty/cancelGiftCard',
  giftCardStoreCheckoutPage: '/:selectedProperty/checkout',
  giftCardStorePaymentPage: '/:selectedProperty/payment',
  giftCardStoreBookingPage: '/:selectedProperty/booking/:giftCardNumber',
  giftCardStoreDetailPage: '/:selectedProperty/:giftCardId',
  giftCardOrderPage: '/giftcards/orders',
  giftCardPurchasePage: '/giftcards/purchases',
  giftCardStoreConfirmationPage: '/:selectedProperty/confirmation',
  giftCardStoreBookingCheckoutPage: '/:selectedProperty/booking/:giftCardNumber/checkout',
  giftCardStoreBookingConfirmationPage: '/:selectedProperty/booking/:giftCardNumber/confirmation',
  oldGiftCardStorePage: '/giftcards/store/:selectedProperty',
  oldGiftCardStoreCartPage: '/giftcards/store/:selectedProperty/cart',
  oldUseGiftCardStorePage: '/giftcards/store/:selectedProperty/useGiftCard',
  oldGiftCardStoreCheckoutPage: '/giftcards/store/:selectedProperty/checkout/:receiver',
  oldGiftCardStorePaymentPage: '/giftcards/store/:selectedProperty/payment',
  oldGiftCardStoreBookingPage: '/giftcards/store/:selectedProperty/booking/:giftCardNumber',
  oldGiftCardStoreDetailPage: '/giftcards/store/:selectedProperty/:giftCardId',
  oldGiftCardStoreConfirmationPage: '/giftcards/store/:selectedProperty/confirmation',
  oldGiftCardStoreBookingCheckoutPage: '/giftcards/store/:selectedProperty/booking/:giftCardNumber/checkout',
  oldGiftCardStoreBookingConfirmationPage: '/giftcards/store/:selectedProperty/booking/:bookingId/confirmation',
  giftCampaigns: '/gift-campaigns',
  giftCampaign: '/gift-campaign/:giftCampaignId',
};

export default routes;
