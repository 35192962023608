import React, { useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { CalendarOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select, Col, Row, Checkbox } from 'antd';

import countries from 'constants/countries';
import dateFormatter from 'utils/date_formatter';

import styles from './gift_card_store_booking_checkout.module.scss';
import { IGiftCardStoreBookingCheckoutProps } from './gift_card_store_booking_checkout.types';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
};

const GiftCardStoreBookingCheckout: React.FC<IGiftCardStoreBookingCheckoutProps> = ({
  property,
  propertyUnit,
  checkinDate,
  checkoutDate,
  bookerDetails,
  isSavingBooking,
  onSave,
  isUpsell,
  isLoadingCheckout,
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    setIsChecked(true);
    form.setFieldsValue({
      useBookerPaymentDetails: true,
    });
  }, []);

  const handleCheckboxChange = (e: CheckboxChangeEvent) => {
    setIsChecked(e.target.checked);
    form.setFieldsValue({
      useBookerPaymentDetails: e.target.checked,
    });
  };

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  const address = useMemo(() => {
    return `${property?.address}, ${property?.zipCode} ${property?.city}`;
  }, [property]);

  const isMobile = window.innerWidth <= 767;

  return (
    <div data-testid="GiftCardStoreBookingCheckout" className={styles.root}>
      <Form onFinish={onSave} form={form} {...formItemLayout} initialValues={bookerDetails}>
        <Row className={styles.checkoutContainer}>
          <Col
            xs={24}
            sm={12}
            className={classNames(styles.generateContainer, 'roundedWrapper')}
            data-testid="Information"
          >
            <div>
              <div className={classNames(styles.infoHeader, 'highlighted')}>{t('general.information')}</div>
              <Form.Item
                name="name"
                rules={[...requiredRules, { max: 80 }]}
                label={t('payment_page.payment_form.customer_info.first_name')}
              >
                <Input placeholder={t('payment_page.payment_form.customer_info.first_name')} />
              </Form.Item>
              <Form.Item
                name="surname"
                rules={[...requiredRules, { max: 80 }]}
                label={t('payment_page.payment_form.customer_info.last_name')}
              >
                <Input placeholder={t('payment_page.payment_form.customer_info.last_name')} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[...requiredRules, { type: 'email', message: 'Please enter valid Email' }, { max: 80 }]}
                label={t('general.other.email')}
                getValueFromEvent={e => e.target.value.trim()}
              >
                <Input placeholder={t('general.other.email')} />
              </Form.Item>

              <Form.Item name="nationality" label={t('payment_page.payment_form.guest_info.nationality')}>
                <Select showSearch optionFilterProp="children" filterOption={true}>
                  {countries.map(country => (
                    <Select.Option key={country.label} value={country.label}>
                      {country.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {isUpsell && (
                <Form.Item name="address" rules={requiredRules} label={t('general.address')}>
                  <Input />
                </Form.Item>
              )}

              <Form.Item name="phone" rules={requiredRules} label={t('general.phone')}>
                <Input type="number" placeholder={t('general.phone')} />
              </Form.Item>

              <Form.Item name="specialRequest" label={t('booking.comments')}>
                <TextArea rows={5} name="specialRequest" placeholder={t('general.write_your_answer')} />
              </Form.Item>

              {isUpsell && <legend>{t('gift_card.payment_details')}</legend>}

              {isUpsell && (
                <Form.Item name="useBookerPaymentDetails">
                  <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                    {t('gift_card.same_as_booker')}
                  </Checkbox>
                </Form.Item>
              )}

              {isUpsell && (
                <Form.Item
                  name="payerFirstName"
                  rules={[{ required: isChecked ? false : true, message: t('validation_messages.required'), max: 160 }]}
                  label={t('general.payer_first_name')}
                  hidden={isChecked}
                >
                  <Input placeholder={t('general.payer_first_name')} />
                </Form.Item>
              )}
              {isUpsell && (
                <Form.Item
                  name="payerLastName"
                  rules={[{ required: isChecked ? false : true, message: t('validation_messages.required'), max: 160 }]}
                  label={t('general.payer_last_name')}
                  hidden={isChecked}
                >
                  <Input placeholder={t('general.payer_last_name')} />
                </Form.Item>
              )}
              {isUpsell && (
                <Form.Item
                  name="payerOptionalAddress"
                  rules={[{ required: isChecked ? false : true, message: t('validation_messages.required'), max: 160 }]}
                  label={t('general.payer_address')}
                  hidden={isChecked}
                >
                  <Input />
                </Form.Item>
              )}
              {isUpsell && (
                <Form.Item
                  name="payerOptionalPhone"
                  rules={[{ required: isChecked ? false : true, message: t('validation_messages.required'), max: 160 }]}
                  label={t('general.payer_number')}
                  hidden={isChecked}
                >
                  <Input type="number" />
                </Form.Item>
              )}
              {isUpsell && (
                <Form.Item
                  name="payerEmail"
                  rules={[{ required: isChecked ? false : true, message: t('validation_messages.required'), max: 160 }]}
                  label={t('general.payer_email')}
                  hidden={isChecked}
                >
                  <Input />
                </Form.Item>
              )}
            </div>
          </Col>

          <Col xs={24} sm={12} data-testid="BookingSummary" className={styles.bookingSummaryContainer}>
            <div className={classNames(styles.bookingSummaryTitle, 'highlighted')}>{t('booking.summary')}</div>
            <img
              src={property?.content.photos[0].url}
              alt="Property"
              className={classNames(styles.bookingPhoto, 'roundedWrapper')}
            ></img>
            <div className={styles.summaryInfoContainer}>
              <div className={classNames(styles.propertyTitle, 'highlighted')}>{property?.title}</div>
              <div className={styles.propertyAddress}>{address}</div>
              <div className={styles.dateContainer}>
                <Row className={styles.dateTitle}>
                  <Col sm={12} xs={24} className={classNames(styles.checkInTitle, 'highlighted')}>
                    <div>{t('hotel_page.checkin_label')}:</div>
                    <div className={styles.checkinDetails}>
                      <span>{dateFormatter.toClient(checkinDate)}</span>
                      <CalendarOutlined />
                    </div>
                  </Col>
                  <Col sm={12} xs={24} className={classNames(styles.checkOutTitle, 'highlighted')}>
                    <div>{t('hotel_page.checkout_label')}:</div>
                    <div className={styles.checkinDetails}>
                      <span>{dateFormatter.toClient(checkoutDate)}</span>
                      <CalendarOutlined />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className={classNames(styles.checkInTitle, 'highlighted')}>{t('rates_table_hotel.unit_type')}:</div>
              <div className={classNames(styles.roomTypeTitle, 'highlighted')}>{propertyUnit?.title}</div>
              {!isMobile && (
                <Button
                  className={styles.submitButton}
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isSavingBooking || isLoadingCheckout}
                >
                  {isUpsell ? t('gift_card.proceed_upsell_payment') : t('booking.confirm')}
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {isMobile && (
          <Row>
            <Col xs={24}>
              <Button
                className={styles.submitButton}
                type="primary"
                htmlType="submit"
                size="large"
                loading={isSavingBooking || isLoadingCheckout}
              >
                {isUpsell ? t('gift_card.proceed_upsell_payment') : t('booking.confirm')}
              </Button>
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};

export default GiftCardStoreBookingCheckout;
