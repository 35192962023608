// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UploadChangeParam, UploadFile } from 'antd/lib/upload';
import { Form, Input, Button, DatePicker, Upload, Popconfirm, Tooltip, Divider } from 'antd';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import { IGiftCampaignOptionFormProps, IPhoto } from './gift_campaign_option_form.types';
import styles from './gift_campaign_option_form.module.scss';

const GiftCampaignOptionForm: React.FC<IGiftCampaignOptionFormProps> = ({ field, index, option, onRemove }) => {
  const { t } = useTranslation();

  const [photos, setPhotos] = useState<UploadFile<IPhoto>[] | { originFileObj: string }[]>(option?.photos || []);

  const handlePhotoRemove = () => {
    handleUpdatePhoto({ file: { name: 'Empty', uid: 'empty' }, fileList: [] });
  };

  const renderPhoto = (photo: IPhoto) => {
    return (
      <div
        data-testid="GiftCardPhoto"
        className={styles.gift_card_photo_container}
        onDragOver={event => event.preventDefault()}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={handlePhotoRemove}
          >
            <div className={styles.delete_photo_button}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={photo.thumbUrl} className={styles.gift_card_photo} alt="Gift Card" />
      </div>
    );
  };

  const handleUpdatePhoto = (photo: UploadChangeParam<UploadFile<IPhoto>>) => {
    let fileList = [...photo.fileList];

    fileList = fileList.slice(-1);
    setPhotos(fileList);
  };

  const getFile = (e: { fileList: UploadFile<IPhoto>[] }) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const requredRules = [{ required: true }];

  return (
    <div data-testid="GiftCampaignOptionForm" className={styles.root} key={`option-${field.key}`}>
      <Form.Item name={[index, 'name']} label={t('general.other.name')} rules={requredRules}>
        <Input placeholder={t('general.other.name')} />
      </Form.Item>
      <Form.Item name={[index, 'description']} label={t('general.description')} rules={requredRules}>
        <Input placeholder={t('general.description')} />
      </Form.Item>

      <Form.Item name={[index, 'expirationDate']} label={t('general.expiration__date')} rules={requredRules}>
        <DatePicker />
      </Form.Item>

      <Form.Item
        name={[index, 'photos']}
        label="Photo"
        getValueFromEvent={getFile}
        rules={[
          {
            required: true,
          },
          {
            validator() {
              if (photos.length) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('required'));
            },
          },
        ]}
      >
        <Upload
          beforeUpload={() => false}
          itemRender={(_originNode, file) => renderPhoto(file)}
          accept={'.png,.jpeg,.jpg'}
          onChange={handleUpdatePhoto}
          multiple={false}
          listType="picture-card"
          defaultFileList={photos as UploadFile<IPhoto>[]}
          fileList={photos as UploadFile<IPhoto>[]}
          maxCount={1}
        >
          {!photos.length && (
            <div className={styles.add_new_photo}>
              <PlusCircleOutlined className={styles.add_photo} />
              <div>{t('photo.add_new')}</div>
            </div>
          )}
        </Upload>
      </Form.Item>

      {!option && (
        <Button type="primary" className={styles.removeButton} onClick={() => onRemove(field.name)}>
          {t('general.remove')}
        </Button>
      )}
      <Divider dashed={true} />
    </div>
  );
};

export default GiftCampaignOptionForm;
