// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, notification, Tooltip } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import { ICreateApiKeyFormProps } from './create_api_key_form.types';
import styles from './create_api_key_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const CreateApiKeyForm: React.FC<ICreateApiKeyFormProps> = ({ isCreating, onCreate, onCancel }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [apiKeyToken, setApiKeyToken] = useState<string>('');

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const handleCopy = (value: string) => navigator.clipboard.writeText(value);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleFinish = async () => {
    const apiKey = form.getFieldsValue(true);

    try {
      const apiKeyDetails = await onCreate(apiKey);
      if (apiKeyDetails) {
        setApiKeyToken(apiKeyDetails.token);
      }
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  return (
    <div data-testid="CreateApiKeyForm" className={styles.root}>
      <Form
        form={form}
        initialValues={{ name: '' }}
        onFinish={handleFinish}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        {!apiKeyToken && (
          <Form.Item label={t('general.other.name')} name="name" rules={[{ required: true }]}>
            <Input placeholder={t('general.other.name')} />
          </Form.Item>
        )}
        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={onCancel}>
              {apiKeyToken ? t('general.buttons.close') : t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isCreating}
              disabled={isCreating || !!apiKeyToken}
              className={styles.saveButton}
            >
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
      {apiKeyToken && (
        <>
          <div className={styles.noteContainer}>{t('api_key.save_generated_note')}</div>
          <div className={styles.apiTokenWrapper}>
            <div className={styles.apiToken}>
              {apiKeyToken}
              <Tooltip title={t('general.copy')}>
                <CopyOutlined className={styles.copyOption} onClick={() => handleCopy(apiKeyToken)} />
              </Tooltip>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CreateApiKeyForm;
