import caseConverter from 'utils/case_converter';
import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';

import attributesExtractor from './attributes_extractor';
import stringifyArguments from './stringify_arguments';
import notification from 'utils/notification';
import errors from 'constants/errors';

const API_URL = process.env.REACT_APP_API_URL;

const validateSession = () => {
  const session = LocaleStorage.get('session');
  if (session && (session.exp || -1) < Date.now()) {
    LocaleStorage.remove('session');
    location.replace('/login');
    return;
  }
};

const formatApiResponse = async payload => {
  const parsedPayload = await payload?.json();
  const convertedPayload = caseConverter.convertToCamelCase(parsedPayload);

  return convertedPayload;
};

const handleApiResponse = async (payload, convertResponseToJson) => {
  if (!convertResponseToJson) {
    const response = await payload?.blob();
    return response;
  }
  const formattedPayload = await formatApiResponse(payload);
  const { data, errors, meta, pendingData } = formattedPayload;

  if (data) {
    if (meta) {
      return {
        data: attributesExtractor(data),
        meta,
      };
    }
    if (pendingData) {
      return {
        data: attributesExtractor(data),
        pendingData,
      };
    }
    return attributesExtractor(data);
  }

  if (errors) {
    if (errors instanceof Error) {
      throw errors;
    }
    if (typeof errors === 'string') {
      throw Error(errors);
    }
  }

  if (!payload?.ok) {
    throw payload;
  }

  return formattedPayload;
};

const getUrl = (apiUrl, path, params) => {
  const urlParams = stringifyArguments(params);

  return `${apiUrl}${path}${urlParams}`;
};

const getRequestOptions = (method, params, jsonParams) => {
  const session = LocaleStorage.get('session');
  const headers = {
    Accept: '*/*',
  };

  if (jsonParams) {
    headers['Content-Type'] = 'application/json';
  }

  if (session) {
    headers.Authorization = session?.token;
  }

  const requestOptions = {
    method,
    headers,
  };

  if (params) {
    requestOptions.body = jsonParams ? JSON.stringify(params) : params;
  }

  return requestOptions;
};

const isStripeLocked = (message) => {
  return message.includes('Stripe locked');
};

const request = async (method, apiUrl, path, payload, queryParams = null, jsonParams, convertResponseToJson = true) => {
  const formattedPayload = jsonParams ? caseConverter.convertToSnakeCase(payload) : payload;
  const formattedQueryParams = jsonParams ? caseConverter.convertToSnakeCase(queryParams) : queryParams;

  const url = jsonParams || queryParams ? getUrl(apiUrl, path, formattedQueryParams) : `${apiUrl}${path}`;
  const requestOptions = getRequestOptions(method, formattedPayload, jsonParams);

  const response = await fetch(url, requestOptions);

  if (response.status === 440) {
    LocaleStorage.remove('session');
    location.replace('/login');

    return;
  }

  if (response.status === 404) {
    notification.withIcon('warning', errors.not_found());
    throw Error(errors.not_found());
  }

  if (response.status > 299) {
    if (typeof response?.errors === 'string') {
      throw Error(response?.errors);

    } else if ([400, 401].includes(response?.status)) {
      const message = await response.json().then((errorObj) => errorObj?.errors || errorObj?.message || errorObj?.reason);
      if (isStripeLocked(message)) return notification.withIcon('error', errors.stripe_locked());

      throw Error(message);

    } else if (typeof response?.errors === 'string') {
      throw Error(response?.errors);

    } else if (typeof response?.statusText === 'string' && response.statusText !== '') {
      throw Error(response.statusText);
    }
  }
  return handleApiResponse(response, convertResponseToJson);
};

const requestWithBodyParams =
  (method, apiUrl) =>
  (path, payload, queryParams = null, jsonParams = true, convertResponseToJson = true) => {
    validateSession();
    return request(method, apiUrl, path, payload, queryParams, jsonParams, convertResponseToJson);
  };

const requestWithoutBodyParams =
  (method, apiUrl) =>
  (path, queryParams, jsonParams = true, convertResponseToJson = true) => {
    validateSession();
    return request(method, apiUrl, path, null, queryParams, jsonParams, convertResponseToJson);
  };

export default {
  get: requestWithoutBodyParams('GET', API_URL),
  delete: requestWithoutBodyParams('DELETE', API_URL),
  post: requestWithBodyParams('POST', API_URL),
  postWithoutBodyParams: requestWithoutBodyParams('POST', API_URL),
  put: requestWithBodyParams('PUT', API_URL),
  patch: requestWithBodyParams('PATCH', API_URL),
  options: requestWithBodyParams('OPTIONS', API_URL),
};
