import React from 'react';
import { Row, Col, Tag } from 'antd';
import { useTranslation } from 'react-i18next';

import dateFormatter from 'utils/date_formatter';
import { BOOKING_STATUS } from 'constants/booking_statuses';

import styles from './booking_status.module.scss';
import { IBookingStatusProps } from './booking_status.types';

const BookingStatus: React.FC<IBookingStatusProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="BookingStatus">
      <Row>
        <Col span={7} className={styles.label}>
          {t('bookings_list.status_title')}:
        </Col>
        <Col span={16} offset={1}>
          {BOOKING_STATUS.ACTIVE === booking.status && <Tag color="green">{t('bookings_list.active_status')}</Tag>}
          {BOOKING_STATUS.MODIFIED === booking.status && <Tag color="orange">{t('bookings_list.modified_status')}</Tag>}
          {BOOKING_STATUS.CANCELLED === booking.status && <Tag color="red">{t('bookings_list.cancelled_status')}</Tag>}
        </Col>
      </Row>
      {booking.reservationName && (
        <Row>
          <Col span={7} className={styles.label}>
            {t('general.reservation_name')}:
          </Col>
          <Col span={16} offset={1}>
            {booking.reservationName}
          </Col>
        </Row>
      )}
      {booking.reservationId && (
        <Row>
          <Col span={7} className={styles.label}>
            {t('bookings_list.reservation_id_title')}:
          </Col>
          <Col span={16} offset={1}>
            {booking.reservationId}
          </Col>
        </Row>
      )}
      {booking.externalReferenceId && (
        <Row>
          <Col span={7} className={styles.label}>
            {t('bookings_list.external_id_title')}:
          </Col>
          <Col span={16} offset={1}>
            {booking.externalReferenceId}
          </Col>
        </Row>
      )}
      <Row>
        <Col span={7} className={styles.label}>
          {t('booking.booked_at')}:
        </Col>
        <Col span={16} offset={1}>
          {dateFormatter.toUIDateWithDay(booking.createdAt)}
        </Col>
      </Row>
      <Row>
        <Col span={7} className={styles.label}>
          {t('general.property_title')}:
        </Col>
        <Col span={16} offset={1}>
          {booking.hotelName}
        </Col>
      </Row>
    </div>
  );
};

export default BookingStatus;
