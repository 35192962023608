const formatNumber = (netPrice: number | null | undefined, locale = 'de-DE'): string => {
  // Check if undefined
  if (netPrice === undefined) return '';

  // Check if netPrice is null or undefined
  if (netPrice === null) return '0';

  // Check if netPrice is not a number
  if (typeof netPrice !== 'number') {
    throw new Error('netPrice must be a number');
  }

  // Check if locale is not a string
  if (typeof locale !== 'string') {
    throw new Error('locale must be a string');
  }

  // Handle negative numbers
  if (netPrice < 0) {
    return '-' + Math.abs(netPrice).toLocaleString(locale, { minimumFractionDigits: 0 });
  }

  try {
    return netPrice.toLocaleString(locale, { minimumFractionDigits: 0 });
  } catch (error) {
    console.error(error);
    return 'Error formatting number';
  }
};

export default formatNumber;
