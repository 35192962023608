import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { ILoadingProps } from './loading.types';
import styles from './loading.module.scss';

const Loading: React.FC<ILoadingProps> = ({ size = 24, className }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size }} spin />;

  return (
    <div className={styles.spinnerContainer} data-testid="Loading">
      <Spin indicator={antIcon} className={className} />
    </div>
  );
};

export default Loading;
