// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Button, Form, Row, Col } from 'antd';
import classNames from 'classnames';

import { IBookByGiftLetterNumberProps } from './book_by_gift_letter_number.types';
import styles from './book_by_gift_letter_number.module.scss';

const BookByGiftLetterNumber: React.FC<IBookByGiftLetterNumberProps> = ({ onSubmit, onValidateGiftCard }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  return (
    <div data-testid="BookByGiftLetterNumber" className={styles.root}>
      <Row justify="center" gutter={[10, 10]}>
        <Col span={24}>
          <div className={classNames(styles.title, 'highlighted')}>{t('gift_card.use_gift_card')}</div>
        </Col>
        <Col span={24}>
          <div className={styles.description}>{t('gift_card.enter_certificate_number')}</div>
        </Col>
        <Col span={24}>
          <Form
            name="giftCardNumberModal"
            id="modalForm"
            onFinish={values => onSubmit?.(values.giftCardNumber)}
            form={form}
            layout="vertical"
          >
            <Form.Item
              name="giftCardNumber"
              validateTrigger={false}
              validateFirst={true}
              label={<div className={styles.inputLabel}>{t('gift_card.number')}</div>}
              rules={[
                { required: true, message: t('validation_messages.required') },
                { validator: (_, value) => onValidateGiftCard?.(value) },
              ]}
            >
              <Input size="large" placeholder={t('gift_card.enter_certificate_number')} />
            </Form.Item>
          </Form>
        </Col>
        <Col span={24} className={styles.center}>
          <Button
            size="large"
            className={classNames(styles.confirmButton, 'blackButton', 'highlighted')}
            form="modalForm"
            key="submit"
            type="primary"
            htmlType="submit"
          >
            {t('general.confirm')}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default BookByGiftLetterNumber;
