export default [
  { value: 'Asia/Kabul', label: 'Asia/Kabul (GMT +04:30)' },
  { value: 'Europe/Tirane', label: 'Europe/Tirane	(GMT +01:00)' },
  { value: 'Africa/Algiers', label: 'Africa/Algiers (GMT +01:00)' },
  { value: 'Pacific/Pago_Pago', label: 'Pacific/Pago Pago (GMT -11:00)' },
  { value: 'Europe/Andorra', label: 'Europe/Andorra (GMT +01:00)' },
  { value: 'Africa/Luanda', label: 'Africa/Luanda (GMT +01:00)' },
  { value: 'America/Anguilla', label: 'America/Anguilla (GMT -04:00)' },
  { value: 'Antarctica/Casey', label: 'Antarctica/Casey (GMT +11:00)' },
  { value: 'Antarctica/Davis', label: 'Antarctica/Davis (GMT +07:00))' },
  { value: 'Antarctica/DumontDUrville', label: 'Antarctica/DumontDUrville	(GMT +10:00)' },
  { value: 'Antarctica/Mawson', label: 'Antarctica/Mawson (GMT +05:00)' },
  { value: 'Antarctica/McMurdo', label: 'Antarctica/McMurdo (GMT +13:00)' },
  { value: 'Antarctica/Palmer', label: 'Antarctica/Palmer (GMT -03:00)' },
  { value: 'Antarctica/Rothera', label: 'Antarctica/Rothera (GMT -03:00)' },
  { value: 'Antarctica/Syowa', label: 'Antarctica/Syowa (GMT +03:00)' },
  { value: 'Antarctica/Troll', label: 'Antarctica/Troll (GMT)' },
  { value: 'Antarctica/Vostok', label: 'Antarctica/Vostok (GMT +06:00)' },
  { value: 'America/Antigua', label: 'America/Antigua (GMT -04:00)' },
  { value: 'America/Argentina/Buenos_Aires', label: 'America/Argentina/Buenos Aires (GMT -03:00)' },
  { value: 'America/Argentina/Catamarca', label: 'America/Argentina/Catamarca (GMT -03:00)' },
  { value: 'America/Argentina/Cordoba', label: 'America/Argentina/Cordoba (GMT -03:00)' },
  { value: 'America/Argentina/Jujuy', label: 'America/Argentina/Jujuy (GMT -03:00)' },
  { value: 'America/Argentina/La_Rioja', label: 'America/Argentina/La Rioja (GMT -03:00)' },
  { value: 'America/Argentina/Mendoza', label: 'America/Argentina/Mendoza (GMT -03:00)' },
  { value: 'America/Argentina/Rio_Gallegos', label: 'America/Argentina/Rio Gallegos (GMT -03:00)' },
  { value: 'America/Argentina/Salta', label: 'America/Argentina/Salta (GMT -03:00)' },
  { value: 'America/Argentina/San_Juan', label: 'America/Argentina/San Juan (GMT -03:00)' },
  { value: 'America/Argentina/San_Luis', label: 'America/Argentina/San Lui (GMT -03:00)' },
  { value: 'America/Argentina/Tucuman', label: 'America/Argentina/Tucuman (GMT -03:00)' },
  { value: 'America/Argentina/Ushuaia', label: 'America/Argentina/Ushuaia (GMT -03:00)' },
  { value: 'Asia/Yerevan', label: 'Asia/Yerevan (GMT +04:00)' },
  { value: 'America/Aruba', label: 'America/Aruba (GMT -04:00)' },
  { value: 'Antarctica/Macquarie', label: 'Antarctica/Macquarie (GMT +11:00)' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide (GMT +10:30)' },
  { value: 'Australia/Brisbane', label: 'Australia/Brisbane (GMT +10:00)' },
  { value: 'Australia/Broken_Hill', label: 'Australia/Broken Hill (GMT +10:30)' },
  { value: 'Australia/Darwin', label: 'Australia/Darwin (GMT +09:30)' },
  { value: 'Australia/Eucla', label: 'Australia/Eucla (GMT +08:45' },
  { value: 'Australia/Hobart', label: 'Australia/Hobart (GMT +11:00)' },
  { value: 'Australia/Lindeman', label: 'Australia/Lindeman (GMT +10:00)' },
  { value: 'Australia/Lord_Howe', label: 'Australia/Lord Howe (GMT +11:00)' },
  { value: 'Australia/Melbourne', label: 'Australia/Melbourne (GMT +11:00)' },
  { value: 'Australia/Perth', label: 'Australia/Perth (GMT +08:00)' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney (GMT +11:00)' },
  { value: 'Europe/Vienna', label: 'Europe/Vienna (GMT +01:00)' },
  { value: 'Asia/Baku', label: 'Asia/Baku (GMT +04:00)' },
  { value: 'America/Nassau', label: 'America/Nassau (GMT -05:00)' },
  { value: 'Asia/Bahrain', label: 'Asia/Bahrain (GMT +03:00)' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka (GMT +06:00)' },
  { value: 'America/Barbados', label: 'America/Barbados (GMT -04:00)' },
  { value: 'Europe/Minsk', label: 'Europe/Minsk (GMT +03:00)' },
  { value: 'Europe/Brussels', label: 'Europe/Brussels (GMT +01:00)' },
  { value: 'America/Belize', label: 'America/Belize (GMT -06:00)' },
  { value: 'Africa/Porto-Novo', label: 'Africa/Porto-Novo (GMT +01:00)' },
  { value: 'Atlantic/Bermuda', label: 'Atlantic/Bermuda (GMT -04:00)' },
  { value: 'Asia/Thimphu', label: 'Asia/Thimphu (GMT +06:00)' },
  { value: 'America/La_Paz', label: 'America/La Paz (GMT -04:00)' },
  { value: 'America/Kralendijk', label: 'America/Kralendijk 	(GMT -04:00)' },
  { value: 'Europe/Sarajevo', label: 'Europe/Sarajevo (GMT +01:00)' },
  { value: 'Africa/Gaborone', label: 'Africa/Gaborone (GMT +02:00)' },
  { value: 'America/Araguaina', label: 'America/Araguaina (GMT -03:00)' },
  { value: 'America/Bahia', label: 'America/Bahia (GMT -03:00)' },
  { value: 'America/Belem', label: 'America/Belem (GMT -03:00)' },
  { value: 'America/Boa_Vista', label: 'America/Boa Vista (GMT -04:00)' },
  { value: 'America/Campo_Grande', label: 'America/Campo Grande	(GMT -04:00)' },
  { value: 'America/Cuiaba', label: 'America/Cuiaba (GMT -04:00)' },
  { value: 'America/Eirunepe', label: 'America/Eirunepe (GMT -05:00)' },
  { value: 'America/Fortaleza', label: 'America/Fortaleza (GMT -03:00)' },
  { value: 'America/Maceio', label: 'America/Maceio (GMT -03:00)' },
  { value: 'America/Manaus', label: 'America/Manaus (GMT -04:00)' },
  { value: 'America/Noronha', label: 'America/Noronha (GMT -02:00)' },
  { value: 'America/Porto_Velho', label: 'America/Porto_Velho (GMT -04:00)' },
  { value: 'America/Recife', label: 'America/Recife (GMT -03:00)' },
  { value: 'America/Rio_Branco', label: 'America/Rio Branco (GMT -05:00)' },
  { value: 'America/Santarem', label: 'America/Santarem (GMT -03:00)' },
  { value: 'America/Sao_Paulo', label: 'America/Sao Paulo (GMT -03:00)' },
  { value: 'Indian/Chagos', label: 'Indian/Chagos (GMT +06:00)' },
  { value: 'Asia/Brunei', label: 'Asia/Brunei (GMT +08:00)' },
  { value: 'Europe/Sofia', label: 'Europe/Sofia (GMT +02:00)' },
  { value: 'Africa/Ouagadougou', label: 'Africa/Ouagadougou (GMT)' },
  { value: 'Africa/Bujumbura', label: 'Africa/Bujumbura (GMT +02:00)' },
  { value: 'Asia/Phnom_Penh', label: 'Asia/Phnom Penh (GMT +07:00)' },
  { value: 'Africa/Douala', label: 'Africa/Douala (GMT +01:00)' },
  { value: 'America/Atikokan', label: 'America/Atikokan (GMT -05:00)' },
  { value: 'America/Blanc-Sablon', label: 'America/Blanc-Sablon (GMT -04:00)' },
  { value: 'America/Cambridge_Bay', label: 'America/Cambridge Bay (GMT -07:00)' },
  { value: 'America/Creston', label: 'America/Creston (GMT -07:00)' },
  { value: 'America/Dawson', label: 'America/Dawson (GMT -07:00)' },
  { value: 'America/Dawson_Creek', label: 'America/Dawson Creek (GMT -07:00)' },
  { value: 'America/Edmonton', label: 'America/Edmonton (GMT -07:00)' },
  { value: 'America/Fort_Nelson', label: 'America/Fort Nelson (GMT -07:00)' },
  { value: 'America/Glace_Bay', label: 'America/Glace Bay (GMT -04:00)' },
  { value: 'America/Goose_Bay', label: 'America/Goose Bay (GMT -04:00)' },
  { value: 'America/Halifax', label: 'America/Halifax (GMT -04:00)' },
  { value: 'America/Inuvik', label: 'America/Inuvik (GMT -07:00)' },
  { value: 'America/Iqaluit', label: 'America/Iqaluit (GMT -05:00)' },
  { value: 'America/Moncton', label: 'America/Moncton (GMT -04:00)' },
  { value: 'America/Nipigon', label: 'America/Nipigon (GMT -05:00)' },
  { value: 'America/Pangnirtung', label: 'America/Pangnirtung (GMT -05:00)' },
  { value: 'America/Rainy_River', label: 'America/Rainy River (GMT -06:00)' },
  { value: 'America/Rankin_Inlet', label: 'America/Rankin Inlet (GMT -06:00)' },
  { value: 'America/Regina', label: 'America/Regina (GMT -06:00)' },
  { value: 'America/Resolute', label: 'America/Resolute (GMT -06:00)' },
  { value: 'America/St_Johns', label: 'America/St_Johns (GMT -03:30)' },
  { value: 'America/Swift_Current', label: 'America/Swift Current (GMT -06:00)' },
  { value: 'America/Thunder_Bay', label: 'America/Thunder Bay (GMT -05:00)' },
  { value: 'America/Toronto', label: 'America/Toronto (GMT -05:00)' },
  { value: 'America/Vancouver', label: 'America/Vancouver (GMT -08:00)' },
  { value: 'America/Whitehorse', label: 'America/Whitehorse (GMT -07:00)' },
  { value: 'America/Winnipeg', label: 'America/Winnipeg	(GMT -06:00)' },
  { value: 'America/Yellowknife', label: 'America/Yellowknife (GMT -07:00)' },
  { value: 'Atlantic/Cape_Verde', label: 'Atlantic/Cape Verde (GMT -01:00)' },
  { value: 'America/Cayman', label: 'America/Cayman (GMT -05:00)' },
  { value: 'Africa/Bangui', label: 'Africa/Bangui (GMT +01:00)' },
  { value: 'Africa/Ndjamena', label: 'Africa/Ndjamena (GMT +01:00)' },
  { value: 'America/Punta_Arenas', label: 'America/Punta Arenas (GMT -03:00)' },
  { value: 'America/Santiago', label: 'America/Santiago (GMT -03:00)' },
  { value: 'Pacific/Easter', label: 'Pacific/Easter (GMT -05:00)' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai (GMT +08:00)' },
  { value: 'Asia/Urumqi', label: 'Asia/Urumqi (GMT +06:00)' },
  { value: 'Indian/Christmas', label: 'Indian/Christmas (GMT +07:00)' },
  { value: 'Indian/Cocos', label: 'Indian/Cocos (GMT +06:30)' },
  { value: 'America/Bogota', label: 'America/Bogota (GMT -05:00)' },
  { value: 'Indian/Comoro', label: 'Indian/Comoro (GMT +03:00)' },
  { value: 'Africa/Brazzaville', label: 'Africa/Brazzaville (GMT +01:00)' },
  { value: 'Africa/Kinshasa', label: 'Africa/Kinshasa (GMT +01:00)' },
  { value: 'Africa/Lubumbashi', label: 'Africa/Lubumbashi (GMT +02:00)' },
  { value: 'Pacific/Rarotonga', label: 'Pacific/Rarotonga (GMT -10:00)' },
  { value: 'America/Costa_Rica', label: 'America/Costa Rica (GMT -06:00)' },
  { value: 'Europe/Zagreb', label: 'Europe/Zagreb (GMT +01:00)' },
  { value: 'America/Havana', label: 'America/Havana (GMT -05:00)' },
  { value: 'America/Curacao', label: 'America/Curacao (GMT -04:00)' },
  { value: 'Asia/Famagusta', label: 'Asia/Famagusta (GMT +02:00)' },
  { value: 'Asia/Nicosia', label: 'Asia/Nicosia (GMT +02:00)' },
  { value: 'Europe/Prague', label: 'Europe/Prague (GMT +01:00)' },
  { value: 'Africa/Abidjan', label: 'Africa/Abidjan (GMT)' },
  { value: 'Europe/Copenhagen', label: 'Europe/Copenhagen (GMT +01:00)' },
  { value: 'Africa/Djibouti', label: 'Africa/Djibouti (GMT +03:00)' },
  { value: 'America/Dominica', label: 'America/Dominica (GMT -04:00)' },
  { value: 'America/Santo_Domingo', label: 'America/Santo Domingo (GMT -04:00)' },
  { value: 'America/Guayaquil', label: 'America/Guayaquil (GMT -05:00)' },
  { value: 'Pacific/Galapagos', label: 'Pacific/Galapagos (GMT -06:00)' },
  { value: 'Africa/Cairo', label: 'Africa/Cairo (GMT +02:00)' },
  { value: 'America/El_Salvador', label: 'America/El Salvador (GMT -06:00)' },
  { value: 'Africa/Malabo', label: 'Africa/Malabo (GMT +01:00)' },
  { value: 'Africa/Asmara', label: 'Africa/Asmara (GMT +03:00)' },
  { value: 'Europe/Tallinn', label: 'Europe/Tallinn	(GMT +02:00)' },
  { value: 'Africa/Addis_Ababa', label: 'Africa/Addis Ababa	(GMT +03:00)' },
  { value: 'Atlantic/Stanley', label: 'Atlantic/Stanley	(GMT -03:00)' },
  { value: 'Atlantic/Faroe', label: 'Atlantic/Faroe	(GMT)' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji	(GMT +12:00)' },
  { value: 'Europe/Helsinki', label: 'Europe/Helsinki	(GMT +02:00)' },
  { value: 'Europe/Paris', label: 'Europe/Paris	(GMT +01:00)' },
  { value: 'America/Cayenne', label: 'America/Cayenne	(GMT -03:00)' },
  { value: 'Pacific/Gambier', label: 'Pacific/Gambier	(GMT -09:00)' },
  { value: 'Pacific/Marquesas', label: 'Pacific/Marquesas	(GMT -09:30)' },
  { value: 'Pacific/Tahiti', label: 'Pacific/Tahiti	(GMT -10:00)' },
  { value: 'Indian/Kerguelen', label: 'Indian/Kerguelen	(GMT +05:00)' },
  { value: 'Africa/Libreville', label: 'Africa/Libreville	(GMT +01:00)' },
  { value: 'Africa/Banjul', label: 'Africa/Banjul	 (GMT)' },
  { value: 'Asia/Tbilisi', label: 'Asia/Tbilisi	(GMT +04:00)' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin	(GMT +01:00)' },
  { value: 'Europe/Busingen', label: 'Europe/Busingen	(GMT +01:00)' },
  { value: 'Africa/Accra', label: 'Africa/Accra	 (GMT)' },
  { value: 'Europe/Gibraltar', label: 'Europe/Gibraltar	(GMT +01:00)' },
  { value: 'Europe/Athens', label: 'Europe/Athens	(GMT +02:00)' },
  { value: 'America/Danmarkshavn', label: 'America/Danmarkshavn	 (GMT)' },
  { value: 'America/Nuuk', label: 'America/Nuuk	(GMT -03:00)' },
  { value: 'America/Scoresbysund', label: 'America/Scoresbysund	(GMT -01:00)' },
  { value: 'America/Thule', label: 'America/Thule	(GMT -04:00)' },
  { value: 'America/Grenada', label: 'America/Grenada	(GMT -04:00)' },
  { value: 'America/Guadeloupe', label: 'America/Guadeloupe	(GMT -04:00)' },
  { value: 'Pacific/Guam', label: 'Pacific/Guam	(GMT +10:00)' },
  { value: 'America/Guatemala', label: 'America/Guatemala	(GMT -06:00)' },
  { value: 'Europe/Guernsey', label: 'Europe/Guernsey	 (GMT)' },
  { value: 'Africa/Conakry', label: 'Africa/Conakry	 (GMT)' },
  { value: 'Africa/Bissau', label: 'Africa/Bissau	 (GMT)' },
  { value: 'America/Guyana', label: 'America/Guyana	(GMT -04:00)' },
  { value: 'America/Port-au-Prince', label: 'America/Port-au-Prince	(GMT -05:00)' },
  { value: 'Europe/Vatican', label: 'Europe/Vatican	(GMT +01:00)' },
  { value: 'America/Tegucigalpa', label: 'America/Tegucigalpa	(GMT -06:00)' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong Kong	(GMT +08:00)' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest	(GMT +01:00)' },
  { value: 'Iceland', label: 'Iceland	(GMT +00:00)' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata	(GMT +05:30)' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta	(GMT +07:00)' },
  { value: 'Asia/Jayapura', label: 'Asia/Jayapura	(GMT +09:00)' },
  { value: 'Asia/Makassar', label: 'Asia/Makassar	(GMT +08:00)' },
  { value: 'Asia/Pontianak', label: 'Asia/Pontianak	(GMT +07:00)' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran	(GMT +03:30)' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad	(GMT +03:00)' },
  { value: 'Europe/Dublin', label: 'Europe/Dublin	 (GMT)' },
  { value: 'Europe/Isle_of_Man', label: 'Europe/Isle of Man	 (GMT)' },
  { value: 'Asia/Jerusalem', label: 'Asia/Jerusalem	(GMT +02:00)' },
  { value: 'Europe/Rome', label: 'Europe/Rome	(GMT +01:00)' },
  { value: 'America/Jamaica', label: 'America/Jamaica	(GMT -05:00)' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo	(GMT +09:00)' },
  { value: 'Europe/Jersey', label: 'Europe/Jersey	(GMT)' },
  { value: 'Asia/Amman', label: 'Asia/Amman	(GMT +02:00)' },
  { value: 'Asia/Almaty', label: 'Asia/Almaty	(GMT +06:00)' },
  { value: 'Asia/Aqtau', label: 'Asia/Aqtau	(GMT +05:00)' },
  { value: 'Asia/Aqtobe', label: 'Asia/Aqtobe	(GMT +05:00)' },
  { value: 'Asia/Atyrau', label: 'Asia/Atyrau	(GMT +05:00)' },
  { value: 'Asia/Oral', label: 'Asia/Oral	(GMT +05:00)' },
  { value: 'Asia/Qostanay', label: 'Asia/Qostanay	(GMT +06:00)' },
  { value: 'Asia/Qyzylorda', label: 'Asia/Qyzylorda	(GMT +05:00)' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi	(GMT +03:00)' },
  { value: 'Pacific/Kanton', label: 'Pacific/Kanton	(GMT +13:00)' },
  { value: 'Pacific/Kiritimati', label: 'Pacific/Kiritimati	(GMT +14:00)' },
  { value: 'Pacific/Tarawa', label: 'Pacific/Tarawa	(GMT +12:00)' },
  { value: 'Asia/Pyongyang', label: 'Asia/Pyongyang	(GMT +09:00)' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul	(GMT +09:00)' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait	(GMT +03:00)' },
  { value: 'Asia/Bishkek', label: 'Asia/Bishkek	(GMT +06:00)' },
  { value: 'Asia/Vientiane', label: 'Asia/Vientiane	(GMT +07:00)' },
  { value: 'Europe/Riga', label: 'Europe/Riga	(GMT +02:00)' },
  { value: 'Asia/Beirut', label: 'Asia/Beirut	(GMT +02:00)' },
  { value: 'Africa/Maseru', label: 'Africa/Maseru	(GMT +02:00)' },
  { value: 'Africa/Monrovia', label: 'Africa/Monrovia	 (GMT)' },
  { value: 'Africa/Tripoli', label: 'Africa/Tripoli	(GMT +02:00)' },
  { value: 'Europe/Vaduz', label: 'Europe/Vaduz	(GMT +01:00)' },
  { value: 'Europe/Vilnius', label: 'Europe/Vilnius	(GMT +02:00)' },
  { value: 'Europe/Luxembourg', label: 'Europe/Luxembourg	(GMT +01:00)' },
  { value: 'Asia/Macau', label: 'Asia/Macau	(GMT +08:00)' },
  { value: 'Europe/Skopje', label: 'Europe/Skopje	(GMT +01:00)' },
  { value: 'Indian/Antananarivo', label: 'Indian/Antananarivo	(GMT +03:00)' },
  { value: 'Africa/Blantyre', label: 'Africa/Blantyre	(GMT +02:00)' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala_Lumpur	(GMT +08:00)' },
  { value: 'Asia/Kuching', label: 'Asia/Kuching	(GMT +08:00)' },
  { value: 'Indian/Maldives', label: 'Indian/Maldives	(GMT +05:00)' },
  { value: 'Africa/Bamako', label: 'Africa/Bamako	 (GMT)' },
  { value: 'Europe/Malta', label: 'Europe/Malta	(GMT +01:00)' },
  { value: 'Pacific/Kwajalein', label: 'Pacific/Kwajalein	(GMT +12:00)' },
  { value: 'Pacific/Majuro', label: 'Pacific/Majuro	(GMT +12:00)' },
  { value: 'America/Martinique', label: 'America/Martinique	(GMT -04:00)' },
  { value: 'Africa/Nouakchott', label: 'Africa/Nouakchott	 (GMT)' },
  { value: 'Indian/Mauritius', label: 'Indian/Mauritius	(GMT +04:00)' },
  { value: 'Mayotte	Indian/Mayotte', label: 'Mayotte	Indian/Mayotte	(GMT +03:00)' },
  { value: 'America/Bahia_Banderas', label: 'America/Bahia_Banderas	(GMT -06:00)' },
  { value: 'America/Cancun', label: 'America/Cancun	(GMT -05:00)' },
  { value: 'America/Chihuahua', label: 'America/Chihuahua	(GMT -07:00)' },
  { value: 'America/Hermosillo', label: 'America/Hermosillo	(GMT -07:00)' },
  { value: 'America/Matamoros', label: 'America/Matamoros	(GMT -06:00)' },
  { value: 'America/Mazatlan', label: 'America/Mazatlan	(GMT -07:00)' },
  { value: 'America/Merida', label: 'America/Merida	(GMT -06:00)' },
  { value: 'America/Mexico_City', label: 'America/Mexico_City	(GMT -06:00)' },
  { value: 'America/Monterrey', label: 'America/Monterrey	(GMT -06:00)' },
  { value: 'America/Ojinaga', label: 'America/Ojinaga	(GMT -07:00)' },
  { value: 'America/Tijuana', label: 'America/Tijuana	(GMT -08:00)' },
  { value: 'Pacific/Chuuk', label: 'Pacific/Chuuk	(GMT +10:00)' },
  { value: 'Pacific/Kosrae', label: 'Pacific/Kosrae	(GMT +11:00)' },
  { value: 'Pacific/Pohnpei', label: 'Pacific/Pohnpei	(GMT +11:00)' },
  { value: 'Europe/Chisinau', label: 'Europe/Chisinau	(GMT +02:00)' },
  { value: 'Europe/Monaco', label: 'Europe/Monaco	(GMT +01:00)' },
  { value: 'Asia/Choibalsan', label: 'Asia/Choibalsan	(GMT +08:00)' },
  { value: 'Asia/Hovd', label: 'Asia/Hovd	(GMT +07:00)' },
  { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar	(GMT +08:00)' },
  { value: 'Europe/Podgorica', label: 'Europe/Podgorica	(GMT +01:00)' },
  { value: 'America/Montserrat', label: 'America/Montserrat	(GMT -04:00)' },
  { value: 'Africa/Casablanca', label: 'Africa/Casablanca	(GMT +01:00)' },
  { value: 'Africa/Maputo', label: 'Africa/Maputo	(GMT +02:00)' },
  { value: 'Asia/Yangon', label: 'Asia/Yangon	(GMT +06:30)' },
  { value: 'Africa/Windhoek', label: 'Africa/Windhoek	(GMT +02:00)' },
  { value: 'Pacific/Nauru', label: 'Pacific/Nauru (GMT +12:00)' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu (GMT +05:45)' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam	(GMT +01:00)' },
  { value: 'Pacific/Noumea', label: 'Pacific/Noumea	(GMT +11:00)' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland	(GMT +13:00)' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham	(GMT +13:45)' },
  { value: 'America/Managua', label: 'America/Managua	(GMT -06:00)' },
  { value: 'Africa/Niamey', label: 'Africa/Niamey	(GMT +01:00)' },
  { value: 'Africa/Lagos', label: 'Africa/Lagos	(GMT +01:00)' },
  { value: 'Pacific/Niue', label: 'Pacific/Niue	(GMT -11:00)' },
  { value: 'Pacific/Norfolk', label: 'Pacific/Norfolk	(GMT +12:00)' },
  { value: 'Pacific/Saipan', label: 'Pacific/Saipan	(GMT +10:00)' },
  { value: 'Europe/Oslo', label: 'Europe/Oslo	(GMT +01:00)' },
  { value: 'Asia/Muscat', label: 'Asia/Muscat	(GMT +04:00)' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi	(GMT +05:00)' },
  { value: 'Pacific/Palau', label: 'Pacific/Palau	(GMT +09:00)' },
  { value: 'Asia/Gaza', label: 'Asia/Gaza	(GMT +02:00)' },
  { value: 'Asia/Hebron', label: 'Asia/Hebron	(GMT +02:00)' },
  { value: 'America/Panama', label: 'America/Panama	(GMT -05:00)' },
  { value: 'Pacific/Bougainville', label: 'Pacific/Bougainville	(GMT +11:00)' },
  { value: 'Pacific/Port_Moresby', label: 'Pacific/Port Moresby	(GMT +10:00)' },
  { value: 'America/Asuncion', label: 'America/Asuncion	(GMT -03:00)' },
  { value: 'America/Lima', label: 'America/Lima	(GMT -05:00)' },
  { value: 'Asia/Manila', label: 'Asia/Manila	(GMT +08:00)' },
  { value: 'Pacific/Pitcairn', label: 'Pacific/Pitcairn	(GMT -08:00)' },
  { value: 'Europe/Warsaw', label: 'Europe/Warsaw	(GMT +01:00)' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores	(GMT -01:00)' },
  { value: 'Atlantic/Madeira', label: 'Atlantic/Madeira	 (GMT)' },
  { value: 'Europe/Lisbon', label: 'Europe/Lisbon	 (GMT)' },
  { value: 'America/Puerto_Rico', label: 'America/Puerto Rico	(GMT -04:00)' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar	(GMT +03:00)' },
  { value: 'Europe/Bucharest', label: 'Europe/Bucharest	(GMT +02:00)' },
  { value: 'Asia/Anadyr', label: 'Asia/Anadyr	(GMT +12:00)' },
  { value: 'Asia/Barnaul', label: 'Asia/Barnaul	(GMT +07:00)' },
  { value: 'Asia/Chita', label: 'Asia/Chita	(GMT +09:00)' },
  { value: 'Asia/Irkutsk', label: 'Asia/Irkutsk	(GMT +08:00)' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka	(GMT +12:00)' },
  { value: 'Asia/Khandyga', label: 'Asia/Khandyga	(GMT +09:00)' },
  { value: 'Asia/Krasnoyarsk', label: 'Asia/Krasnoyarsk	(GMT +07:00)' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan	(GMT +11:00)' },
  { value: 'Asia/Novokuznetsk', label: 'Asia/Novokuznetsk	(GMT +07:00)' },
  { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk	(GMT +07:00)' },
  { value: 'Asia/Omsk', label: 'Asia/Omsk	(GMT +06:00)' },
  { value: 'Asia/Sakhalin', label: 'Asia/Sakhalin	(GMT +11:00)' },
  { value: 'Asia/Srednekolymsk', label: 'Asia/Srednekolymsk	(GMT +11:00)' },
  { value: 'Asia/Tomsk', label: 'Asia/Tomsk	(GMT +07:00)' },
  { value: 'Asia/Ust-Nera', label: 'Asia/Ust-Nera	(GMT +10:00)' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok	(GMT +10:00)' },
  { value: 'Asia/Yakutsk', label: 'Asia/Yakutsk	(GMT +09:00)' },
  { value: 'Asia/Yekaterinburg', label: 'Asia/Yekaterinburg	(GMT +05:00)' },
  { value: 'Europe/Astrakhan', label: 'Europe/Astrakhan	(GMT +04:00)' },
  { value: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad	(GMT +02:00)' },
  { value: 'Europe/Kirov', label: 'Europe/Kirov	(GMT +03:00)' },
  { value: 'Europe/Moscow', label: 'Europe/Moscow	(GMT +03:00)' },
  { value: 'Europe/Samara', label: 'Europe/Samara	(GMT +04:00)' },
  { value: 'Europe/Saratov', label: 'Europe/Saratov	(GMT +04:00)' },
  { value: 'Europe/Ulyanovsk', label: 'Europe/Ulyanovsk	(GMT +04:00)' },
  { value: 'Europe/Volgograd', label: 'Europe/Volgograd	(GMT +03:00)' },
  { value: 'Africa/Kigali', label: 'Africa/Kigali	(GMT +02:00)' },
  { value: 'Indian/Reunion', label: 'Indian/Reunion	(GMT +04:00)' },
  { value: 'America/St_Barthelemy', label: 'America/St Barthelemy	(GMT -04:00)' },
  { value: 'Atlantic/St_Helena', label: 'Atlantic/St Helena	 (GMT)' },
  { value: 'America/St_Kitts', label: 'America/St Kitts	(GMT -04:00)' },
  { value: 'America/St_Lucia', label: 'America/St Lucia	(GMT -04:00)' },
  { value: 'America/Marigot', label: 'America/Marigot	(GMT -04:00)' },
  { value: 'America/Miquelon', label: 'America/Miquelon	(GMT -03:00)' },
  { value: 'America/St_Vincent', label: 'America/St Vincent	(GMT -04:00)' },
  { value: 'Pacific/Apia', label: 'Pacific/Apia	(GMT +13:00)' },
  { value: 'Europe/San_Marino', label: 'Europe/San Marino	(GMT +01:00)' },
  { value: 'Africa/Sao_Tome', label: 'Africa/Sao Tome	 (GMT)' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh	(GMT +03:00)' },
  { value: 'Africa/Dakar', label: 'Africa/Dakar	 (GMT)' },
  { value: 'Europe/Belgrade', label: 'Europe/Belgrade	(GMT +01:00)' },
  { value: 'Indian/Mahe', label: 'Indian/Mahe	(GMT +04:00)' },
  { value: 'Africa/Freetown', label: 'Africa/Freetown	 (GMT)' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore	(GMT +08:00)' },
  { value: 'America/Lower_Princes', label: 'America/Lower Princes	(GMT -04:00)' },
  { value: 'Europe/Bratislava', label: 'Europe/Bratislava	(GMT +01:00)' },
  { value: 'Europe/Ljubljana', label: 'Europe/Ljubljana	(GMT +01:00)' },
  { value: 'Pacific/Guadalcanal', label: 'Pacific/Guadalcanal	(GMT +11:00)' },
  { value: 'Africa/Mogadishu', label: 'Africa/Mogadishu	(GMT +03:00)' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg	(GMT +02:00)' },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South Georgia	(GMT -02:00)' },
  { value: 'Africa/Juba', label: 'Africa/Juba	(GMT +02:00)' },
  { value: 'Africa/Ceuta', label: 'Africa/Ceuta	(GMT +01:00)' },
  { value: 'Atlantic/Canary', label: 'Atlantic/Canary	 (GMT)' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid	(GMT +01:00)' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo	(GMT +05:30)' },
  { value: 'Africa/Khartoum', label: 'Africa/Khartoum	(GMT +02:00)' },
  { value: 'America/Paramaribo', label: 'America/Paramaribo	(GMT -03:00)' },
  { value: 'Arctic/Longyearbyen', label: 'Arctic/Longyearbyen	(GMT +01:00)' },
  { value: 'Africa/Mbabane', label: 'Africa/Mbabane	(GMT +02:00)' },
  { value: 'Europe/Stockholm', label: 'Europe/Stockholm	(GMT +01:00)' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich	(GMT +01:00)' },
  { value: 'Asia/Damascus', label: 'Asia/Damascus	(GMT +02:00)' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei	(GMT +08:00)' },
  { value: 'Asia/Dushanbe', label: 'Asia/Dushanbe	(GMT +05:00)' },
  { value: 'Africa/Dar_es_Salaam', label: 'Africa/Dar es Salaam	(GMT +03:00)' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok	(GMT +07:00)' },
  { value: 'Asia/Dili', label: 'Asia/Dili	(GMT +09:00)' },
  { value: 'Africa/Lome', label: 'Africa/Lome	 (GMT)' },
  { value: 'Pacific/Fakaofo', label: 'Pacific/Fakaofo	(GMT +13:00)' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu	(GMT +13:00)' },
  { value: 'America/Port_of_Spain	', label: 'America/Port of Spain	(GMT -04:00)' },
  { value: 'Africa/Tunis', label: 'Africa/Tunis (GMT +01:00)' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul	(GMT +03:00)' },
  { value: 'Asia/Ashgabat', label: 'Asia/Ashgabat	(GMT +05:00)' },
  { value: 'America/Grand_Turk', label: 'America/Grand Turk	(GMT -05:00)' },
  { value: 'Pacific/Funafuti', label: 'Pacific/Funafuti	(GMT +12:00)' },
  { value: 'Africa/Kampala', label: 'Africa/Kampala	(GMT +03:00)' },
  { value: 'Europe/Kiev', label: 'Europe/Kiev	(GMT +02:00)' },
  { value: 'Europe/Simferopol', label: 'Europe/Simferopol	(GMT +03:00)' },
  { value: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod	(GMT +02:00)' },
  { value: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye	(GMT +02:00)' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai	(GMT +04:00)' },
  { value: 'Europe/London', label: 'Europe/London	 (GMT)' },
  { value: 'America/Adak', label: 'America/Adak	(GMT -10:00)' },
  { value: 'America/Anchorage', label: 'America/Anchorage	(GMT -09:00)' },
  { value: 'America/Boise', label: 'America/Boise	(GMT -07:00)' },
  { value: 'America/Chicago', label: 'America/Chicago	(GMT -06:00)' },
  { value: 'America/Denver', label: 'America/Denver	(GMT -07:00)' },
  { value: 'America/Detroit', label: 'America/Detroit	(GMT -05:00)' },
  { value: 'America/Indiana/Indianapolis', label: 'America/Indiana/Indianapolis	(GMT -05:00)' },
  { value: 'America/Indiana/Knox', label: 'America/Indiana/Knox	(GMT -06:00)' },
  { value: 'America/Indiana/Marengo', label: 'America/Indiana/Marengo	(GMT -05:00)' },
  { value: 'America/Indiana/Petersburg', label: 'America/Indiana/Petersburg	(GMT -05:00)' },
  { value: 'America/Indiana/Tell_City', label: 'America/Indiana/Tell City	(GMT -06:00)' },
  { value: 'America/Indiana/Vevay', label: 'America/Indiana/Vevay (GMT -05:00)' },
  { value: 'America/Indiana/Vincennes	', label: 'America/Indiana/Vincennes	(GMT -05:00)' },
  { value: 'America/Indiana/Winamac', label: 'America/Indiana/Winamac	(GMT -05:00)' },
  { value: 'America/Juneau', label: 'America/Juneau	(GMT -09:00)' },
  { value: 'America/Kentucky/Louisville	', label: 'America/Kentucky/Louisville	(GMT -05:00)' },
  { value: 'America/Kentucky/Monticello', label: 'America/Kentucky/Monticello	(GMT -05:00)' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles	(GMT -08:00)' },
  { value: 'America/Menominee', label: 'America/Menominee	(GMT -06:00)' },
  { value: 'America/Metlakatla', label: 'America/Metlakatla	(GMT -09:00)' },
  { value: 'America/New_York', label: 'America/New York	(GMT -05:00)' },
  { value: 'America/Nome', label: 'America/Nome	(GMT -09:00)' },
  { value: 'America/North_Dakota/Beulah', label: 'America/North Dakota/Beulah	(GMT -06:00)' },
  { value: 'America/North_Dakota/Center', label: 'America/North Dakota/Center	(GMT -06:00)' },
  { value: 'America/North_Dakota/New_Salem', label: 'America/North Dakota/New Salem	(GMT -06:00)' },
  { value: 'America/Phoenix', label: 'America/Phoenix	(GMT -07:00)' },
  { value: 'America/Sitka', label: 'America/Sitka	(GMT -09:00)' },
  { value: 'America/Yakutat', label: 'America/Yakutat	(GMT -09:00)' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu (GMT -10:00)' },
  { value: 'Pacific/Midway', label: 'Pacific/Midway	(GMT -11:00)' },
  { value: 'Pacific/Wake', label: 'Pacific/Wake (GMT +12:00)' },
  { value: 'America/Montevideo', label: 'America/Montevideo	(GMT -03:00)' },
  { value: 'Asia/Samarkand', label: 'Asia/Samarkand	(GMT +05:00)' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent	(GMT +05:00)' },
  { value: 'Pacific/Efate', label: 'Pacific/Efate	(GMT +11:00)' },
  { value: 'America/Caracas', label: 'America/Caracas	(GMT -04:00)' },
  { value: 'Asia/Ho_Chi_Minh', label: 'Asia/Ho Chi Minh	(GMT +07:00)' },
  { value: 'British	America/Tortola', label: 'British	America/Tortola	(GMT -04:00)' },
  { value: 'America/St_Thomas', label: 'America/St Thomas (GMT -04:00)' },
  { value: 'Pacific/Wallis', label: 'Pacific/Wallis	(GMT +12:00)' },
  { value: 'Africa/El_Aaiun', label: 'Africa/El Aaiun	(GMT +01:00)' },
  { value: 'Asia/Aden', label: 'Asia/Aden	(GMT +03:00)' },
  { value: 'Africa/Lusaka', label: 'Africa/Lusaka	(GMT +02:00)' },
  { value: 'Africa/Harare', label: 'Africa/Harare	(GMT +02:00)' },
  { value: 'Europe/Mariehamn', label: 'Europe/Mariehamn	(GMT +02:00)' },
];
