// Generated with util/create-component.js
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Drawer, Tooltip, Popconfirm, notification, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';

import DRAWER_SIZE from 'constants/drawer_size';

import {
  PropertyRatePlansDataContext,
  PropertyRatePlansActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import PropertyRatePlanForm from 'components/property_rate_plan_form/property_rate_plan_form';

import { IRatePlanPerRoomType, IPropertyRatePlansPerUnitProps } from './property_rate_plans_per_unit.types';
import styles from './property_rate_plans_per_unit.module.scss';

const PropertyRatePlansPerUnit: React.FC<IPropertyRatePlansPerUnitProps> = ({
  unitId,
  defaultOccupancy,
  maxOccupancy,
}) => {
  const { t } = useTranslation();

  const { propertyRatePlans } = useContext(PropertyRatePlansDataContext);

  const { loadPropertyRatePlans, removeRatePlan, clearSelectedRatePlan } = useContext(PropertyRatePlansActionsContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const [openedRatePlanForm, toogleRatePlanForm] = useState<boolean>(false);
  const [ratePlanId, setRatePlanId] = useState<IRatePlanPerRoomType['id'] | null>(null);

  useEffect(() => {
    if (unitId && selectedProperty && !propertyRatePlans?.[unitId]?.data?.length) {
      loadPropertyRatePlans({ propertyId: selectedProperty, roomTypeId: unitId });
    }
  }, []);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('rate_plan.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleRemoveRatePlan = async (record: IRatePlanPerRoomType) => {
    if (selectedProperty && record?.id) {
      try {
        await removeRatePlan({ propertyId: selectedProperty, ratePlanId: record.id, roomTypeId: unitId });
        openNotificationWithIcon('success');
      } catch (e) {
        openNotificationWithIcon('error');
      }
    }
  };

  const renderMenu = (record: IRatePlanPerRoomType): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenEditRatePlanForm(record),
      },
      {
        key: 'remove',
        label: (
          <Tooltip title={t('rate_plan.remove')}>
            <Popconfirm
              title={
                <>
                  <p>{t('bookings_list.confirm')}</p>
                  <p>{`${t('general.are_you_sure')} ${record?.title} ${t('general.rate_plan')}?`}</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => handleRemoveRatePlan(record)}
            >
              {t('general.remove')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <DeleteOutlined />,
      },
    ];
    return { items: menuItems };
  };

  const renderRatePlanRow = (record: IRatePlanPerRoomType) => {
    return (
      <div style={{ textAlign: 'left' }}>{`${record?.title} (${
        record?.sellMode === 'per_room' ? t('rate_plan.sell_mode_option.per_room') : t('general.per_person_multiple')
      }, ${record?.currency})`}</div>
    );
  };

  const renderActionButtons = (record: IRatePlanPerRoomType) => {
    return (
      <Dropdown menu={renderMenu(record)}>
        <div className={styles.actions_link}>
          <span style={{ marginRight: 5 }}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  const handleOpenEditRatePlanForm = (record: IRatePlanPerRoomType) => {
    toogleRatePlanForm(true);
    setRatePlanId(record.id);
  };

  const handleCloseEditRatePlanForm = () => {
    toogleRatePlanForm(false);
    setRatePlanId(null);
    clearSelectedRatePlan();
  };

  const ratePlanPerRoomTypeColumns: ColumnsType<IRatePlanPerRoomType> = [
    {
      key: 'title',
      render: (record: IRatePlanPerRoomType) => renderRatePlanRow(record),
    },
    {
      key: 'actions',
      render: (record: IRatePlanPerRoomType) => renderActionButtons(record),
    },
  ];

  return (
    <div data-testid="PropertyRatePlansPerUnit" className={styles.root}>
      <Table
        loading={propertyRatePlans?.[unitId]?.isLoading}
        dataSource={propertyRatePlans?.[unitId]?.data}
        columns={ratePlanPerRoomTypeColumns}
        rowKey="id"
        pagination={false}
        showHeader={false}
      />

      <Drawer
        open={openedRatePlanForm}
        title={t('rate_plan.edit')}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleCloseEditRatePlanForm}
        destroyOnClose={true}
      >
        <PropertyRatePlanForm
          onCancel={handleCloseEditRatePlanForm}
          ratePlanId={ratePlanId}
          unitId={unitId}
          defaultOccupancy={defaultOccupancy}
          maxOccupancy={maxOccupancy}
        />
      </Drawer>
    </div>
  );
};

export default PropertyRatePlansPerUnit;
