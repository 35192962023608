import React from 'react';
import { useTranslation } from 'react-i18next';

import Cell from 'components/layout/cell';
import Alert from 'components/alert';

import CancellationPolicy from './cancellation_policy';
import MealPolicy from './meal_policy';
import PaymentPolicy from './payment_policy';
import PoliciesInfo from './policies_info';
import isCarProperty from 'utils/is_car_property';
import styles from './rate_plan_policies.module.scss';
import { IRatePlanPoliciesProps } from './rate_plan_policies.types';

const RatePlanPolicies: React.FC<IRatePlanPoliciesProps> = ({
  ratePlan,
  propertyPolicy,
  checkinDate,
  propertyType,
}) => {
  const { t } = useTranslation();

  return (
    <Cell className={styles.policyCell}>
      <div className={styles.policyContainer}>
        <div className={styles.mealContainer}>
          <MealPolicy mealPolicy={ratePlan.mealType} />
          <PoliciesInfo ratePlan={ratePlan} propertyType={propertyType} />
        </div>
        {isCarProperty(propertyType) ? <Alert text={t('rates_table.liability_cdw')} variant="success" /> : null}
        <CancellationPolicy
          cancellationPolicy={ratePlan.cancellationPolicy}
          checkinDate={checkinDate}
          propertyPolicy={propertyPolicy}
        />
        <PaymentPolicy cancellationPolicy={ratePlan.cancellationPolicy} />
      </div>
    </Cell>
  );
};

export default RatePlanPolicies;
