import React from 'react';

import styles from './hotel_title.module.scss';
import { IHotelTitleProps } from './hotel_title.types';

const HotelTitle: React.FC<IHotelTitleProps> = ({ title, hideTitle }) => {
  if (hideTitle) {
    return null;
  }

  return <div className={styles.hotelTitle}>{title}</div>;
};

export default HotelTitle;
