import { useCallback, useMemo, useReducer } from 'react';
import { actions, INITIAL_STATE, reducer } from './reducers/property_policies';

export default () => {
  const [propertyPoliciesData, dispatch] = useReducer(reducer, INITIAL_STATE);

  // Cancellation Policies

  const loadPropertyCancellationPolicies = useCallback(
    params => {
      return actions.loadPropertyCancellationPolicies(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyCancellationPolicy = useCallback(
    params => {
      return actions.loadPropertyCancellationPolicy(dispatch, params);
    },
    [dispatch],
  );

  const updateCancellationPolicy = useCallback(
    params => {
      return actions.updateCancellationPolicy(dispatch, params);
    },
    [dispatch],
  );

  const createCancellationPolicy = useCallback(
    params => {
      return actions.createCancellationPolicy(dispatch, params);
    },
    [dispatch],
  );

  const removeCancellationPolicy = useCallback(
    params => {
      return actions.removeCancellationPolicy(dispatch, params);
    },
    [dispatch],
  );

  const clearSelectedCancellationPolicy = useCallback(
    params => {
      return actions.clearSelectedCancellationPolicy(dispatch, params);
    },
    [dispatch],
  );

  // Property Policies

  const loadPropertyPolicies = useCallback(
    params => {
      return actions.loadPropertyPolicies(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyPolicy = useCallback(
    params => {
      return actions.loadPropertyPolicy(dispatch, params);
    },
    [dispatch],
  );

  const updatePropertyPolicy = useCallback(
    params => {
      return actions.updatePropertyPolicy(dispatch, params);
    },
    [dispatch],
  );

  const createPropertyPolicy = useCallback(
    params => {
      return actions.createPropertyPolicy(dispatch, params);
    },
    [dispatch],
  );

  const removePropertyPolicy = useCallback(
    params => {
      return actions.removePropertyPolicy(dispatch, params);
    },
    [dispatch],
  );

  const clearSelectedPropertyPolicy = useCallback(
    params => {
      return actions.clearSelectedPropertyPolicy(dispatch, params);
    },
    [dispatch],
  );

  const resetPropertyPolicies = useCallback(
    () => {
      return actions.resetPropertyPolicies(dispatch);
    },
    [dispatch],
  );

  const propertyPoliciesActions = useMemo(
    () => ({
      loadPropertyCancellationPolicies,
      loadPropertyCancellationPolicy,
      createCancellationPolicy,
      updateCancellationPolicy,
      removeCancellationPolicy,
      clearSelectedCancellationPolicy,

      loadPropertyPolicies,
      loadPropertyPolicy,
      createPropertyPolicy,
      updatePropertyPolicy,
      removePropertyPolicy,
      clearSelectedPropertyPolicy,
      resetPropertyPolicies,
    }),
    [
      loadPropertyCancellationPolicies,
      loadPropertyCancellationPolicy,
      createCancellationPolicy,
      updateCancellationPolicy,
      removeCancellationPolicy,
      clearSelectedCancellationPolicy,

      loadPropertyPolicies,
      loadPropertyPolicy,
      createPropertyPolicy,
      updatePropertyPolicy,
      removePropertyPolicy,
      clearSelectedPropertyPolicy,
      resetPropertyPolicies,
    ],
  );

  return useMemo(
    () => ({ propertyPoliciesData, propertyPoliciesActions }),
    [propertyPoliciesData, propertyPoliciesActions],
  );
};
