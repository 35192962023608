import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
// import env from './env';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_ID,
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: 'latest',
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    keySeparator: false,
    fallbackLng: 'en',
    interpolation: { escapeValue: false }, // not needed for react as it escapes by default
    backend: locizeOptions,
    defaultNS: 'client',
    ns: ['client'],
  });

export default i18n;
