// Generated with util/create-component.js
import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import countries from 'constants/countries';

import { IResellerDetailsProps } from './reseller_details.types';
import styles from './reseller_details.module.scss';

const ResellerDetails: React.FC<IResellerDetailsProps> = ({ reseller }) => {
  const { t } = useTranslation();

  const { email, phone, address_1, city, zipCode, country, taxId, governmentId, legalName, emailInvoice } = reseller;

  const countryName = useMemo(() => countries.find(c => c.value === country)?.label, [country]);

  const formatLabel = useCallback((label: string) => `${t(label)}:`, []);

  return (
    <div data-testid="ResellerDetails" className={styles.root}>
      {email && (
        <Row role="email" className={styles.row}>
          <Col span={8}>{formatLabel('general.other.email')}</Col>
          <Col span={16}>{email}</Col>
        </Row>
      )}
      {phone && (
        <Row role="phone" className={styles.row}>
          <Col span={8}>{formatLabel('general.phone')}</Col>
          <Col span={16}>{phone}</Col>
        </Row>
      )}
      {address_1 && (
        <Row role="address" className={styles.row}>
          <Col span={8}>{formatLabel('table.address')}</Col>
          <Col span={16}>{address_1}</Col>
        </Row>
      )}
      {city && (
        <Row role="city" className={styles.row}>
          <Col span={8}>{formatLabel('table.city')}</Col>
          <Col span={16}>{city}</Col>
        </Row>
      )}
      {zipCode && (
        <Row role="zipCode" className={styles.row}>
          <Col span={8}>{formatLabel('general.zip_code')}</Col>
          <Col span={16}>{zipCode}</Col>
        </Row>
      )}
      {country && (
        <Row role="country" className={styles.row}>
          <Col span={8}>{formatLabel('general.country')}</Col>
          <Col span={16}>{countryName}</Col>
        </Row>
      )}
      {taxId && (
        <Row role="taxId" className={styles.row}>
          <Col span={8}>{formatLabel('general.tax_id')}</Col>
          <Col span={16}>{taxId}</Col>
        </Row>
      )}
      {governmentId && (
        <Row role="governmentId" className={styles.row}>
          <Col span={8}>{formatLabel('general.government_id')}</Col>
          <Col span={16}>{governmentId}</Col>
        </Row>
      )}
      {legalName && (
        <Row role="legalName" className={styles.row}>
          <Col span={8}>{formatLabel('general.legal_name')}</Col>
          <Col span={16}>{legalName}</Col>
        </Row>
      )}
      {emailInvoice && (
        <Row role="emailInvoice" className={styles.row}>
          <Col span={8}>{formatLabel('general.email_invoice')}</Col>
          <Col span={16}>{emailInvoice}</Col>
        </Row>
      )}
    </div>
  );
};

export default ResellerDetails;
