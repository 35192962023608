import {
  SET_PROPERTY_CANCELLATION_POLICIES,
  SET_PROPERTY_CANCELLATION_POLICIES_LOADING,
  SET_PROPERTY_CANCELLATION_POLICY_LOADING,
  SET_PROPERTY_CANCELLATION_POLICY,
  SET_CANCELLATION_POLICY_UPDATE_LOADING,
  REMOVE_SELECTED_CANCELLATION_POLICY,
  SET_REMOVE_CANCELLATION_POLICY_LOADING,
  SET_PROPERTY_POLICY_LOADING,
  SET_PROPERTY_POLICY,
  SET_PROPERTY_POLICY_UPDATE_LOADING,
  REMOVE_SELECTED_POLICY,
  SET_REMOVE_POLICY_LOADING,
  SET_PROPERTY_POLICIES,
  SET_PROPERTY_POLICIES_LOADING,
  RESET_PROPERTY_POLICIES,
} from './property_policies_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  cancellationPolicies: EMPTY_ENTITY,
  propertyPolicies: EMPTY_ENTITY,
  cancellationPolicy: EMPTY_ENTITY,
  propertyPolicy: EMPTY_ENTITY,
  isUpdatingCancellationPolicy: false,
  isUpdatingPropertyPolicy: false,
  isRemovingCancellationPolicy: false,
  isRemovingPropertyPolicy: false,
};

const actionHandlers = {
  [SET_PROPERTY_CANCELLATION_POLICIES]: (state, action) => {
    const { data } = action.payload;

    const cancellationPolicies = { data, isLoading: false };

    return { ...state, cancellationPolicies };
  },
  [SET_PROPERTY_CANCELLATION_POLICIES_LOADING]: state => {
    const cancellationPolicies = { ...state.cancellationPolicies, isLoading: true };

    return { ...state, cancellationPolicies };
  },
  [SET_PROPERTY_CANCELLATION_POLICY_LOADING]: state => {
    const cancellationPolicy = { ...state.cancellationPolicy, isLoading: true };

    return { ...state, cancellationPolicy };
  },
  [SET_PROPERTY_CANCELLATION_POLICY]: (state, action) => {
    const cancellationPolicy = { data: action.payload, isLoading: false };

    return { ...state, cancellationPolicy };
  },
  [SET_CANCELLATION_POLICY_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingCancellationPolicy: params };
  },
  [REMOVE_SELECTED_CANCELLATION_POLICY]: (state, action) => {
    const cancellationPolicy = EMPTY_ENTITY;

    return { ...state, cancellationPolicy };
  },
  [SET_REMOVE_CANCELLATION_POLICY_LOADING]: (state, { params }) => {
    return { ...state, isRemovingCancellationPolicy: params };
  },
  [SET_PROPERTY_POLICIES]: (state, action) => {
    const { data } = action.payload;

    const propertyPolicies = { data, isLoading: false };

    return { ...state, propertyPolicies };
  },
  [SET_PROPERTY_POLICIES_LOADING]: state => {
    const propertyPolicies = { ...state.propertyPolicies, isLoading: true };

    return { ...state, propertyPolicies };
  },
  [SET_PROPERTY_POLICY_LOADING]: state => {
    const propertyPolicy = { ...state.propertyPolicy, isLoading: true };

    return { ...state, propertyPolicy };
  },
  [SET_PROPERTY_POLICY]: (state, action) => {
    const propertyPolicy = { data: action.payload, isLoading: false };

    return { ...state, propertyPolicy };
  },
  [SET_PROPERTY_POLICY_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingPropertyPolicy: params };
  },
  [REMOVE_SELECTED_POLICY]: (state, action) => {
    const propertyPolicy = EMPTY_ENTITY;

    return { ...state, propertyPolicy };
  },
  [SET_REMOVE_POLICY_LOADING]: (state, { params }) => {
    return { ...state, isRemovingPropertyPolicy: params };
  },
  [RESET_PROPERTY_POLICIES]: () => {
    return INITIAL_STATE;
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
