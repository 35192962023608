// Generated with util/create-component.js
import React from 'react';

import { IGeneralPageLayoutProps } from './general_page_layout.types';
import styles from './general_page_layout.module.scss';

const GeneralPageLayout: React.FC<IGeneralPageLayoutProps> = ({ header, children, className = '' }) => {
  return (
    <div data-testid="GeneralPageLayout" className={className}>
      <header>{header}</header>
      <section id="general-page-layout-section" className={styles.giftCardStoreContent}>
        {children}
      </section>
    </div>
  );
};

export default GeneralPageLayout;
