import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './booking_expenses.module.scss';
import { IBookingExpensesProps } from './booking_expenses.types';

const BookingExpenses: React.FC<IBookingExpensesProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="BookingExpenses">
      <legend>{t('booking.expenses')}</legend>
      <Row>
        <Col span={7} className={styles.label}>
          {t('general.total_amount')}:
        </Col>
        <Col span={16} offset={1}>
          {booking.price}
        </Col>
      </Row>
      <Row>
        <Col span={7} className={styles.label}>
          {t('general.payment_method')}:
        </Col>
        <Col span={16} offset={1}>
          {booking.guarantee ? t('general.credit_card') : t('general.cash_other')}
        </Col>
      </Row>
    </div>
  );
};

export default BookingExpenses;
