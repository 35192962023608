import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/bookings_data';

export default () => {
  const [bookingsData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadBookingsList = useCallback(
    params => {
      return actions.loadBookingsList(dispatch, params);
    },
    [dispatch],
  );

  const loadBookingInfo = useCallback(
    params => {
      return actions.loadBookingInfo(dispatch, params);
    },
    [dispatch],
  );

  const resetBookingsList = useCallback(() => {
    return actions.resetBookingsList(dispatch);
  }, [dispatch]);

  const cancelBooking = useCallback(
    params => {
      return actions.cancelBooking(dispatch, params);
    },
    [dispatch],
  );

  const cancelPropertyBooking = useCallback(
    params => {
      return actions.cancelPropertyBooking(dispatch, params);
    },
    [dispatch],
  );

  const updateBookingGuests = useCallback(
    params => {
      return actions.updateBookingGuests(dispatch, params);
    },
    [dispatch],
  );

  const updateBookingSpecialRequest = useCallback(
    params => {
      return actions.updateBookingSpecialRequest(dispatch, params);
    },
    [dispatch],
  );

  const loadSuppliersList = useCallback(
    params => {
      return actions.loadSuppliersList(dispatch, params);
    },
    [dispatch],
  );

  const loadBookersList = useCallback(
    params => {
      return actions.loadBookersList(dispatch, params);
    },
    [dispatch],
  );

  const loadResellersList = useCallback(
    params => {
      return actions.loadResellersList(dispatch, params);
    },
    [dispatch],
  );

  const deleteBookingUnits = useCallback(
    params => {
      return actions.deleteBookingUnits(dispatch, params);
    },
    [dispatch]
  );

  const bookingsActions = useMemo(
    () => ({
      loadBookingsList,
      loadBookingInfo,
      resetBookingsList,
      cancelBooking,
      updateBookingGuests,
      updateBookingSpecialRequest,
      cancelPropertyBooking,
      loadSuppliersList,
      loadBookersList,
      loadResellersList,
      deleteBookingUnits,
    }),
    [
      loadBookingsList,
      loadBookingInfo,
      resetBookingsList,
      cancelBooking,
      updateBookingGuests,
      updateBookingSpecialRequest,
      cancelPropertyBooking,
      loadSuppliersList,
      loadBookersList,
      loadResellersList,
      deleteBookingUnits,
    ],
  );

  return useMemo(() => ({ bookingsData, bookingsActions }), [bookingsData, bookingsActions]);
};
