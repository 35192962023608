import { useCallback, useMemo, useReducer } from 'react';
import { actions, INITIAL_STATE, reducer } from './reducers/stripe';

export default () => {
  const [stripeData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const checkResellerPaymentSource = useCallback(
    () => {
      return actions.checkResellerPaymentSource(dispatch);
    },
    [dispatch],
  );

  const checkSupplierPaymentSource = useCallback(
    params => {
      return actions.checkSupplierPaymentSource(dispatch, params);
    },
    [dispatch],
  );

  const stripeActions = useMemo(
    () => ({
      checkResellerPaymentSource,
      checkSupplierPaymentSource,
    }),
    [
      checkResellerPaymentSource,
      checkSupplierPaymentSource,
    ],
  );

  return useMemo(() => ({ stripeData, stripeActions }), [ stripeData, stripeActions ]);
};
