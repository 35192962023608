import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import { Form, Row, Col, Input, Select } from 'antd';

import countries from 'constants/countries';

import styles from './guest.module.scss';
import { IGuestProps } from './guest.types';

const TRANSLATION_PATH = 'payment_page.payment_form.guest_info';

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

const columnSize = 7;

const Guest: React.FC<IGuestProps> = ({ unitIndex, guestIndex, readOnly, isDeleteEnabled, onDelete, isCar }) => {
  const { t } = useTranslation();

  const requiredRules = isCar ? [{ required: true, message: t('validation_messages.required') }] : [];

  return (
    <Row gutter={24} align="middle" className={styles.guest_info_wrapper} data-testid="Guest">
      <Col xs={24} sm={columnSize}>
        <Form.Item
          {...formItemLayout}
          name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'name']}
          label={t(`${TRANSLATION_PATH}.first_name`)}
          className={styles.guest_info}
          rules={requiredRules}
        >
          <Input disabled={readOnly} size="large" />
        </Form.Item>
      </Col>
      <Col xs={24} sm={columnSize}>
        <Form.Item
          {...formItemLayout}
          name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'surname']}
          label={t(`${TRANSLATION_PATH}.last_name`)}
          className={styles.guest_info}
          rules={requiredRules}
        >
          <Input disabled={readOnly} size="large" />
        </Form.Item>
      </Col>
      {!isCar && (
        <Col xs={isDeleteEnabled ? 21 : 24} sm={columnSize}>
          <Form.Item
            {...formItemLayout}
            name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'nationality']}
            label={t(`${TRANSLATION_PATH}.nationality`)}
            className={styles.guest_info}
          >
            <Select size="large" showSearch optionFilterProp="children" filterOption={true}>
              {countries.map(country => (
                <Select.Option key={country.label} value={country.label}>
                  {country.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
      {isCar && (
        <>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'email']}
              label={t('general.other.email')}
              className={styles.guest_info}
              rules={requiredRules}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'phone']}
              label={t('general.phone')}
              className={styles.guest_info}
              rules={requiredRules}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <legend>{t('general.address')}</legend>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'address_line_1']}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'address_line_2']}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'address_line_3']}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'city']}
              label={t('general.city')}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'state']}
              label={t('general.state')}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'postalCode']}
              label={t('general.postal_code')}
              className={styles.guest_info}
            >
              <Input disabled={readOnly} size="large" />
            </Form.Item>
          </Col>
          <Col xs={isDeleteEnabled ? 21 : 24} sm={columnSize}>
            <Form.Item
              {...formItemLayout}
              name={['unitsOccupancy', unitIndex, 'guests', guestIndex, 'address', 'countryCode']}
              label={t(`${TRANSLATION_PATH}.nationality`)}
              className={styles.guest_info}
              rules={requiredRules}
            >
              <Select
                size="large"
                showSearch
                optionFilterProp="label"
                filterOption={true}
                options={countries}
                disabled
              />
            </Form.Item>
          </Col>
        </>
      )}
      {isDeleteEnabled && (
        <Col span={3} className={styles.button_container}>
          <div className={styles.button} onClick={() => onDelete(guestIndex)} data-testid="GuestDelete">
            <DeleteOutlined className={styles.deleteIcon} />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default Guest;
