import React from 'react';

import Cell from 'components/layout/cell';
import { ITitleProps } from './title.types';

const Title: React.FC<ITitleProps> = ({ property }) => {
  return (
    <Cell>
      <h5>{property.title}</h5>
    </Cell>
  );
};

export default Title;
