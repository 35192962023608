import React from 'react';
import { Col } from 'react-bootstrap';

import PropertyLocation from 'components/property_location';
import SectionWrapper from 'components/layout/section_wrapper';
import SectionTitle from 'components/section_title';

import { PROPERTY_INFO_SECTION } from 'constants/element_ids';

import styles from './property_info_section.module.scss';
import { IPropertyInfoSectionProps } from './property_info_section.types';

const PropertyInfoSection: React.FC<IPropertyInfoSectionProps> = ({ property }) => {
  const { description, title } = property;

  return (
    <SectionWrapper id={PROPERTY_INFO_SECTION} theme="light">
      <Col xs="12" lg="8">
        <div className={styles.propertyTitleSection}>
          <SectionTitle>{title}</SectionTitle>
          <PropertyLocation property={property} />
        </div>
        {description && <pre className={styles.propertyDescription}>{description}</pre>}
      </Col>
    </SectionWrapper>
  );
};

export default PropertyInfoSection;
