import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Button, notification, Row, Col, InputNumber } from 'antd';

import buildPath from 'utils/build_path';
import routes from 'routing/routes';
import months from 'constants/months';
import years from 'constants/years';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_store_payment.module.scss';
import { IGiftCardInfo, IGiftCard } from './gift_card_store_payment.types';

const GiftCardStorePayment: React.FC = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const { saveDataToStorage, clearDataFromStorage, getDataFromStorage, createGiftCard } = useContext(
    PropertyGiftCardsActionsContext,
  );
  const { createdGiftCard } = useContext(PropertyGiftCardsDataContext);

  const [giftCardInfo, setGiftCardInfo] = useState<IGiftCardInfo>();
  const [isSuccess, setIsSuccess] = useState(false);

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  useEffect(() => {
    const savedGiftCardsParams = getDataFromStorage();

    if (savedGiftCardsParams && savedGiftCardsParams.params.giftCardInfo) {
      setGiftCardInfo(savedGiftCardsParams.params.giftCardInfo);
    } else {
      onErrorRedirect();
    }
  }, []);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: 'Gift certificates have been purchased.',
      });
    } else {
      onErrorRedirect();
      return notification['error']({
        message: 'An error occurred.',
        description: 'Sorry, the product is sold out.',
      });
    }
  };

  useEffect(() => {
    const savedGiftCardsParams = getDataFromStorage();
    if (isSuccess && createdGiftCard?.data) {
      localStorage.removeItem(`cart-items-${selectedProperty}`);
      saveDataToStorage({ ...savedGiftCardsParams, createdGiftCard });

      const confirmationId = createdGiftCard?.data.map((item: IGiftCard) => item['generatedId']).join('-');

      const redirectPath = buildPath('', routes.giftCardStoreConfirmationPage, { selectedProperty, confirmationId });

      history.push(redirectPath);
    }
  }, [isSuccess]);

  const onFinish = async (): Promise<void> => {
    const giftCardParams = { giftCardArray: giftCardInfo };

    try {
      await createGiftCard({ propertyId: selectedProperty, giftCardParams });
      openNotificationWithIcon('success');
      setIsSuccess(true);
    } catch (e) {
      openNotificationWithIcon('error');
      setIsSuccess(false);
    }
  };

  const onErrorRedirect = () => {
    clearDataFromStorage();
    localStorage.removeItem(`cart-items-${selectedProperty}`);
    const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });

    history.push(redirectPath);
  };

  return (
    <div className={styles.root} data-testid="GiftCardStorePayment">
      <Form onFinish={onFinish} layout="vertical" form={form} className={styles.form}>
        <div className={styles.title}>{t('general.payment_information')}</div>
        <Form.Item
          className={styles.formItem}
          name="cc_number"
          validateTrigger="onBlur"
          rules={[...requiredRules, { len: 16, message: 'Credit card number must have 16 digits.' }]}
          label={t('general.card_number')}
        >
          <InputNumber
            controls={false}
            stringMode={true}
            style={{ width: '100%' }}
            formatter={value => `${value}`.replace(/(\d{4})(?=\d)/g, '$1 ')}
          />
        </Form.Item>

        <Row className={styles.wrapper}>
          <Col span={7}>
            <Form.Item className={styles.formItem} name="month" rules={requiredRules} label="Month">
              <Select options={months} />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item className={styles.formItem} name="year" rules={requiredRules} label={t('general.year')}>
              <Select>
                {years.map(year => (
                  <Select.Option key={year.label} value={year.label}>
                    {year.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              className={styles.form_item}
              name="cvc"
              rules={[...requiredRules, { len: 3, message: 'CVC must have 3 digits.' }]}
              label="CVC"
            >
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        <Button htmlType="submit" size="large" className={styles.submit_button}>
          {t('general.pay')}
        </Button>
      </Form>
    </div>
  );
};

export default GiftCardStorePayment;
