import ApiActions from 'api_actions';

export const SET_API_KEYS = 'SET_API_KEYS';
export const SET_IS_LOADING_API_KEYS = 'SET_IS_LOADING_API_KEYS';
export const SET_IS_CREATING_API_KEY = 'SET_IS_CREATING_API_KEY';
export const SET_IS_DISABLING_API_KEY = 'SET_IS_DISABLING_API_KEY';

const setApiKeys = (dispatch, payload) => {
  return dispatch({ type: SET_API_KEYS, payload });
};

const setIsLoadingApiKeys = (dispatch, payload) => {
  return dispatch({ type: SET_IS_LOADING_API_KEYS, payload });
};

const getApiKeys = async (dispatch, params) => {
  try {
    setIsLoadingApiKeys(dispatch, true);
    const apiKeys = await ApiActions.getApiKeys(params);

    setApiKeys(dispatch, apiKeys);
  } catch (e) {
    setApiKeys(dispatch, []);
    throw e;
  } finally {
    setIsLoadingApiKeys(dispatch, false);
  }
};

const setIsCreatingApiKey = (dispatch, payload) => {
  return dispatch({ type: SET_IS_CREATING_API_KEY, payload });
};

const createApiKey = async (dispatch, params) => {
  try {
    setIsCreatingApiKey(dispatch, true);
    const apiKey = await ApiActions.createApiKey(params);
    return apiKey;
  } catch (e) {
    throw e;
  } finally {
    setIsCreatingApiKey(dispatch, false);
  }
};

const setIsDisablingApiKey = (dispatch, payload) => {
  return dispatch({ type: SET_IS_DISABLING_API_KEY, payload });
};

const disableApiKey = async (dispatch, params) => {
  try {
    setIsDisablingApiKey(dispatch, true);
    await ApiActions.disableApiKey(params);
  } catch (e) {
    throw e;
  } finally {
    setIsDisablingApiKey(dispatch, false);
  }
};

export const actions = {
  getApiKeys,
  createApiKey,
  disableApiKey,
};
