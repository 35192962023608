import React from 'react';

import styles from './mobile_summary_container.module.scss';
import { IMobileSummaryContainerProps } from './mobile_summary_container.types';

const MobileSummaryContainer: React.FC<IMobileSummaryContainerProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default MobileSummaryContainer;
