import { useReducer, useCallback, useMemo } from 'react';
import { actions, INITIAL_STATE, reducer } from '../reducers/admin/resellers_data';

export default () => {
  const [resellersData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadResellers = useCallback(
    params => {
      return actions.loadResellers(dispatch, params);
    },
    [dispatch],
  );

  const createReseller = useCallback(
    params => {
      return actions.createReseller(dispatch, params);
    },
    [dispatch],
  );

  const updateReseller = useCallback(
    params => {
      return actions.updateReseller(dispatch, params);
    },
    [dispatch],
  );

  const lockOverrideReseller = useCallback(
    params => {
      return actions.lockOverrideReseller(dispatch, params);
    },
    [dispatch],
  );

  const resellersActions = useMemo(
    () => ({
      loadResellers,
      createReseller,
      updateReseller,
      lockOverrideReseller,
    }),
    [loadResellers, createReseller, updateReseller],
  );

  return useMemo(() => ({ resellersData, resellersActions }), [resellersData, resellersActions]);
};
