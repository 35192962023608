import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Row, Col } from 'antd';

import styles from './gift_card_store_billing_info.module.scss';

const GiftCardStoreBillingInfo: React.FC = () => {
  const { t } = useTranslation();

  const requiredRules = [{ required: true, message: t('validation_messages.required') }];

  return (
    <div data-testid="GiftCardStoreBillingInfo">
      <Row gutter={16} className={styles.itemRow}>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'name']} rules={requiredRules} label={t('general.payer_name')}>
            <Input />
          </Form.Item>
          <Form.Item name={['billing', 'phone']} rules={requiredRules} label={t('general.payer_number')}>
            <Input type="number" />
          </Form.Item>
        </Col>
        <Col xs={24} sm={12}>
          <Form.Item name={['billing', 'address']} rules={requiredRules} label={t('general.payer_address')}>
            <Input />
          </Form.Item>
          <Form.Item
            name={['billing', 'email']}
            rules={[...requiredRules, { type: 'email', message: t('general.please_enter_valid_email') }]}
            label={t('general.payer_email')}
            getValueFromEvent={e => e.target.value.trim()}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardStoreBillingInfo;
