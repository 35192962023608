import { useCallback, useMemo, useReducer } from 'react';

import { actions, INITIAL_STATE, reducer } from './reducers/property_units';

export default () => {
  const [propertyUnitesData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadPropertyUnits = useCallback(
    params => {
      return actions.loadPropertyUnits(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyUnit = useCallback(
    params => {
      return actions.loadPropertyUnit(dispatch, params);
    },
    [dispatch],
  );

  const loadPropertyUnitFacilities = useCallback(
    params => {
      return actions.loadPropertyUnitFacilities(dispatch, params);
    },
    [dispatch],
  );

  const updatePropertyUnit = useCallback(
    params => {
      return actions.updatePropertyUnit(dispatch, params);
    },
    [dispatch],
  );

  const createPropertyUnit = useCallback(
    params => {
      return actions.createPropertyUnit(dispatch, params);
    },
    [dispatch],
  );

  const removePropertyUnit = useCallback(
    params => {
      return actions.removePropertyUnit(dispatch, params);
    },
    [dispatch],
  );

  const clearSelectedPropertyUnit = useCallback(
    params => {
      return actions.clearSelectedPropertyUnit(dispatch, params);
    },
    [dispatch],
  );

  const resetPropertyUnits = useCallback(
    params => {
      return actions.resetPropertyUnits(dispatch, params);
    },
    [dispatch],
  );


  const propertyUnitsActions = useMemo(
    () => ({
      loadPropertyUnits,
      loadPropertyUnit,
      loadPropertyUnitFacilities,
      createPropertyUnit,
      updatePropertyUnit,
      removePropertyUnit,
      clearSelectedPropertyUnit,
      resetPropertyUnits,
    }),
    [
      loadPropertyUnits,
      loadPropertyUnit,
      loadPropertyUnitFacilities,
      createPropertyUnit,
      updatePropertyUnit,
      removePropertyUnit,
      clearSelectedPropertyUnit,
      resetPropertyUnits,
    ],
  );

  return useMemo(
    () => ({ propertyUnitesData, propertyUnitsActions }),
    [propertyUnitesData, propertyUnitsActions],
  );
};
