import React from 'react';
import { useTranslation } from 'react-i18next';

import InfoEntry from '../info_entry';
import { IGeneralPoliciesProps } from './general_policies.types';

const TRANSLATION_PATH = 'hotel_policy';

const GeneralPolicies: React.FC<IGeneralPoliciesProps> = ({ propertyPolicy }) => {
  const { t } = useTranslation();
  const { checkinFromTime, checkinToTime, checkoutFromTime, checkoutToTime } = propertyPolicy;

  return (
    <>
      {checkinFromTime && checkinToTime && (
        <InfoEntry title={t(`${TRANSLATION_PATH}.checkin_time`)} text={`${checkinFromTime} - ${checkinToTime}`} />
      )}
      {checkoutFromTime && checkoutToTime && (
        <InfoEntry title={t(`${TRANSLATION_PATH}.checkout_time`)} text={`${checkoutFromTime} - ${checkoutToTime}`} />
      )}
    </>
  );
};

export default GeneralPolicies;
