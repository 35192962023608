import React from 'react';
import classNames from 'classnames';

import Nav from 'components/nav/nav';

import { IPageLayoutProps } from './page_layout.types';
import styles from './page_layout.module.scss';

const PageLayout: React.FC<IPageLayoutProps> = ({ children, className }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <Nav />
      <section className={styles.content}>{children}</section>
    </div>
  );
};

export default PageLayout;
