import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/buttons/button';

import { PROPERTY_INFO_SECTION } from 'constants/element_ids';
import scrollToElementById from 'utils/scroll_to_element_by_id';

import { ISearchButtonProps } from './search_button.types';

const SearchButton: React.FC<ISearchButtonProps> = ({ loading, disabled, onClick }) => {
  const { t } = useTranslation();

  const handleClick = useCallback(async () => {
    await onClick();
    scrollToElementById(PROPERTY_INFO_SECTION);
  }, [onClick]);

  return (
    <Button loading={loading} disabled={disabled} onClick={handleClick}>
      {t('hotel_page.search')}
    </Button>
  );
};

export default SearchButton;
