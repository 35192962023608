import ApiActions from 'api_actions';
import { ENETRESET } from 'constants';

export const SET_GIFT_CAMPAIGNS_LOADING = 'SET_GIFT_CAMPAIGNS_LOADING';
export const SET_GIFT_CAMPAIGNS = 'SET_GIFT_CAMPAIGNS';
export const SET_GIFT_CAMPAIGN_OPTIONS = 'SET_GIFT_CAMPAIGN_OPTIONS';
export const SET_GIFT_CAMPAIGN_OPTIONS_LOADING = 'SET_GIFT_CAMPAIGN_OPTIONS_LOADING';
export const SET_GIFT_CAMPAIGN_IS_CREATING = 'SET_GIFT_CAMPAIGN_IS_CREATING';
export const SET_GIFT_CAMPAIGN_IS_UPDATING = 'SET_GIFT_CAMPAIGN_IS_UPDATING';
export const SET_GIFT_CAMPAIGN_OPTION_IS_CREATING = 'SET_GIFT_CAMPAIGN_OPTION_IS_CREATING';
export const SET_GIFT_CAMPAIGN_OPTION_IS_UPDATING = 'SET_GIFT_CAMPAIGN_OPTION_IS_UPDATING';

const setGiftCampaignsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CAMPAIGNS_LOADING, params });
};

const setGiftCampaigns = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CAMPAIGNS, payload });
};

const getGiftCampaigns = async (dispatch, params) => {
  try {
    setGiftCampaignsLoading(dispatch);

    const data = await ApiActions.getGiftCampaigns(params);

    setGiftCampaigns(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignsLoading(dispatch, false);
  }
};

const setGiftCampaignOptionsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_OPTIONS_LOADING, params });
};

const setGiftCampaignOptions = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_OPTIONS, payload });
};

const getGiftCampaignOptions = async (dispatch, params) => {
  try {
    setGiftCampaignOptionsLoading(dispatch);

    const data = await ApiActions.getGiftCampaignOptions(params);

    setGiftCampaignOptions(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignOptionsLoading(dispatch, false);
  }
};

const saveGiftCampaignRecipient = async (dispatch, params) => {
  try {
    const data = await ApiActions.saveGiftCampaignRecipient(params);
    return data;
  } catch (e) {
    throw e;
  }
};

const setGiftCampaignIsCreating = (dispatch, params) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_IS_CREATING, params });
};

const createGiftCampaign = async (dispatch, params) => {
  try {
    setGiftCampaignIsCreating(dispatch);

    const data = await ApiActions.createGiftCampaign(params);
    return data;
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignIsCreating(dispatch, false);
  }
};

const setGiftCampaignIsUpdating = (dispatch, params) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_IS_UPDATING, params });
};

const updateGiftCampaign = async (dispatch, params) => {
  try {
    setGiftCampaignIsUpdating(dispatch);

    await ApiActions.updateGiftCampaign(params);
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignIsUpdating(dispatch, false);
  }
};

const setGiftCampaignOptionIsCreating = (dispatch, params) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_OPTION_IS_CREATING, params });
};

const createGiftCampaignOption = async (dispatch, params) => {
  try {
    setGiftCampaignOptionIsCreating(dispatch);

    await ApiActions.createGiftCampaignOption(params);
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignOptionIsCreating(dispatch, false);
  }
};

const setGiftCampaignOptionIsUpdating = (dispatch, params) => {
  return dispatch({ type: SET_GIFT_CAMPAIGN_OPTION_IS_UPDATING, params });
};

const updateGiftCampaignOption = async (dispatch, params) => {
  try {
    setGiftCampaignOptionIsUpdating(dispatch);

    await ApiActions.updateGiftCampaignOption(params);
  } catch (e) {
    throw e;
  } finally {
    setGiftCampaignOptionIsUpdating(dispatch, false);
  }
};

export const actions = {
  getGiftCampaigns,
  getGiftCampaignOptions,
  saveGiftCampaignRecipient,
  createGiftCampaign,
  updateGiftCampaign,
  createGiftCampaignOption,
  updateGiftCampaignOption,
};
