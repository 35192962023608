import React from 'react';

import Caption from 'components/caption';

import { IDescriptionProps } from './description.types';

const Description: React.FC<IDescriptionProps> = ({ description }) => {
  if (!description) {
    return null;
  }

  return <Caption>{description}</Caption>;
};

export default Description;
