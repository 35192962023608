import React from 'react';

import Overlay from 'components/layout/overlay';
import SearchButton from 'components/search_section/action_button/search_button';

import styles from './reload_container.module.scss';
import { IReloadContainerProps } from './reload_container.types';

const ReloadContainer: React.FC<IReloadContainerProps> = ({ children, disabled, active, onRefresh }) => {
  return (
    <div className={styles.container}>
      <Overlay active={active}>
        <>
          <div className={styles.content}>
            <SearchButton disabled={disabled} onClick={onRefresh} />
          </div>
        </>
      </Overlay>
      {children}
    </div>
  );
};

export default ReloadContainer;
