import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import CurrencyConversionWarning from 'components/currency_conversion_warning';
import ExpandableContainer from 'components/layout/expandable_container';
import MobileSummaryContainer from 'components/layout/mobile_summary_container';
import SubmitBookingButton from 'components/booking_checkout_form/submit_booking_button';
import PriceBreakdown from 'components/search_section/price_breakdown';
import TotalPrice from 'components/search_section/total_price';

import { renderProviderLogo } from 'utils/vehicle';
import isAccommodationProperty from 'utils/is_accommodation_property';

import Dates from '../dates';
import Guests from '../guests';

import styles from './mobile_summary.module.scss';
import { IMobileSummaryProps } from './mobile_summary.types';

const MobileSummary: React.FC<IMobileSummaryProps> = ({ params, selectedRatesPerUnit, total, property }) => {
  const { t } = useTranslation();
  const { checkinDate, checkoutDate, currency } = params;

  const providerLogo = useMemo(() => renderProviderLogo(property.provider), [property.provider]);

  return (
    <MobileSummaryContainer>
      <>
        <ExpandableContainer title={t('payment_page.booking_summary.title')}>
          <>
            <Dates checkinDate={checkinDate} checkoutDate={checkoutDate} propertyType={params.type} />
            {isAccommodationProperty(params.type) && <Guests params={params} />}
            <PriceBreakdown
              selectedRatesPerUnit={selectedRatesPerUnit}
              currency={currency}
              propertyType={params.type}
            />
            <TotalPrice totalPrice={total} currency={currency} />
            {providerLogo && (
              <>
                <Divider className={styles.divider} />
                <div className={styles.providerLogo}>
                  <div>{providerLogo}</div>
                </div>
                <Divider />
              </>
            )}
          </>
        </ExpandableContainer>
        <CurrencyConversionWarning />
        <SubmitBookingButton />
      </>
    </MobileSummaryContainer>
  );
};

export default MobileSummary;
