import ApiActions from 'api_actions';

export const SET_SUBMITTED_VALUE = 'SET_SUBMITTED_VALUE';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_FORM_LOGIN_SUBMITTING = 'SET_FORM_LOGIN_SUBMITTING';
export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export const SET_LOGOUT_USER = 'SET_LOGOUT_USER';
export const SET_FORM_LOGIN_ERROR = 'SET_FORM_LOGIN_SUBMIT_ERROR';
export const SET_IS_VERIFYING_PASSWORD_EMAIL = 'SET_IS_VERIFYING_PASSWORD_EMAIL';

const setErrors = (dispatch, payload) => {
  return dispatch({ type: SET_ERRORS, payload });
};

const setFormSubmitting = dispatch => {
  return dispatch({ type: SET_FORM_LOGIN_SUBMITTING });
};

const setLoggedInUser = (dispatch, payload) => {
  return dispatch({ type: SET_LOGGED_IN_USER, payload });
};

const setFormSubmitError = (dispatch, payload) => {
  return dispatch({ dispatch, payload });
};

const login = async (dispatch, formParams) => {
  try {
    const params = {
      ...formParams,
    };

    const loggedInUser = await ApiActions.login(params);
    return loggedInUser;
  } catch (error) {
    setFormSubmitError(dispatch, error);

    throw error;
  }
};

const reload = async (dispatch) => {
  try {
    const reloadData = await ApiActions.reload();

    if (reloadData.session) {
      setLoggedInUser(dispatch, reloadData.session);
    }
    return reloadData;
  } catch (error) {
    setErrors(dispatch, error);

    throw error;
  }
};

const logout = dispatch => {
  return dispatch({ type: SET_LOGOUT_USER });
};

const resetPassword = async (dispatch, formParams) => {
  try {
    await ApiActions.resetPassword(formParams);
    return;
  } catch (error) {
    setFormSubmitError(dispatch, error);

    throw error;
  }
};

const updatePassword = async (dispatch, params) => {
  try {
    await ApiActions.updatePassword(params);
    return;
  } catch (error) {
    throw error;
  }
};

const setIsVerifyingResetPasswordEmail = (dispatch, isVerifyingResetPasswordEmail) => {
  return dispatch({ type: SET_IS_VERIFYING_PASSWORD_EMAIL, isVerifyingResetPasswordEmail });
};

const verifyResetPasswordEmail = async (dispatch, params) => {
  try {
    setIsVerifyingResetPasswordEmail(dispatch, true);
    const authorizationData = await ApiActions.verifyResetPasswordEmail(params);
    return authorizationData;
  } catch (error) {
    throw error;
  } finally {
    setIsVerifyingResetPasswordEmail(dispatch, false);
  }
};

export const actions = {
  setErrors,
  setFormSubmitting,
  setLoggedInUser,
  login,
  reload,
  logout,
  resetPassword,
  updatePassword,
  verifyResetPasswordEmail,
  setIsVerifyingResetPasswordEmail,
};
