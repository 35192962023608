import React from 'react';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import SectionWrapper from 'components/layout/section_wrapper';
import SectionTitle from 'components/section_title';

import GeneralPolicies from './policies/general_policies/general_policies';
import InternetAccessPolicies from './policies/internet_access_policies/internet_access_policies';
import ParkingPolicies from './policies/parking_policies/parking_policies';
import PetsPolicies from './policies/pets_policies/pets_policies';
import SmokingPolicies from './policies/smoking_policies/smoking_policies';

import { IPropertyInfoSectionProps } from './supplier_additional_info_section.types';

const PropertyInfoSection: React.FC<IPropertyInfoSectionProps> = ({ property }) => {
  const { t } = useTranslation();
  const { propertyPolicy } = property;

  if (!propertyPolicy) {
    return null;
  }

  return (
    <SectionWrapper theme="dark">
      <Col xs="12" lg="8">
        <SectionTitle>{t('hotel_page.additional_information')}</SectionTitle>
        <GeneralPolicies propertyPolicy={propertyPolicy} />
        <InternetAccessPolicies propertyPolicy={propertyPolicy} />
        <ParkingPolicies propertyPolicy={propertyPolicy} />
        <SmokingPolicies propertyPolicy={propertyPolicy} />
        <PetsPolicies propertyPolicy={propertyPolicy} />
      </Col>
    </SectionWrapper>
  );
};

export default PropertyInfoSection;
