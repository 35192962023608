import ApiActions from 'api_actions';
import { PROPERTY_CHANNELS } from 'constants/property_channels';
import isCarProperty from 'utils/is_car_property';
import { formatPropertyOffers, formatPropertyPlaces, formatVehiclePropertyPlaces, formatVehicleUnits } from './utils/format_property';

export const SET_PROPERTIES_LOADING = 'SET_PROPERTIES_LOADING';
export const SET_PROPERTIES_LIST = 'SET_PROPERTIES_LIST';
export const CLEAR_PROPERTIES_LIST = 'CLEAR_PROPERTIES_LIST';
export const CLEAR_CARS_LIST = 'CLEAR_CARS_LIST';

export const SET_ALL_PROPERTIES_LOADING = 'SET_ALL_PROPERTIES_LOADING';
export const SET_ALL_CARS_LOADING = 'SET_ALL_CARS_LOADING';
export const SET_ALL_PROPERTIES_LIST = 'SET_ALL_PROPERTIES_LIST';
export const SET_ALL_CARS_LIST = 'SET_ALL_CARS_LIST';
export const CLEAR_ALL_PROPERTIES_LIST = 'CLEAR_ALL_PROPERTIES_LIST';
export const SET_ADDITIONAL_PROPERTIES_LIST_LOADING = 'SET_ADDITIONAL_PROPERTIES_LIST_LOADING';
export const SET_ADDITIONAL_PROPERTIES_LIST = 'SET_ADDITIONAL_PROPERTIES_LIST';

const setPropertiesListLoading = dispatch => {
  return dispatch({ type: SET_PROPERTIES_LOADING });
};

const setPropertiesList = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTIES_LIST, payload });
};

const setAdditionalPropertiesListLoading = (dispatch, payload = false) => {
  return dispatch({ type: SET_ADDITIONAL_PROPERTIES_LIST_LOADING, payload });
};

const setAdditionalPropertiesList = (dispatch, payload) => {
  return dispatch({ type: SET_ADDITIONAL_PROPERTIES_LIST, payload });
};

const loadAdditionalVehiclesList = async (dispatch, type, filter, queryId) => {
  setAdditionalPropertiesListLoading(dispatch, true);
  const data = await ApiActions.getAdditionalVehiclesList(type, queryId, filter);

  if (!data.isFinished) {
    setTimeout(() => loadAdditionalVehiclesList(dispatch, type, filter, queryId), 2000);
  } else {
    const formattedProperties = formatPropertyOffers(data?.results);

    setAdditionalPropertiesList(dispatch, formattedProperties);
    setAdditionalPropertiesListLoading(dispatch, false);
  }

  return data;
}

const loadSuppliersList = async (dispatch, type, params, filter) => {
  setPropertiesListLoading(dispatch);

  let data;
  if (isCarProperty(type)) {
    const { data: propertyUnits, pendingData } = await ApiActions.getVehiclesList(type, params, filter);

    data = formatVehicleUnits(propertyUnits);

    await loadAdditionalVehiclesList(dispatch, type, filter, pendingData.queryId);
  } else {
    data = await ApiActions.getPropertiesList(type, params, filter);
  }

  setPropertiesList(dispatch, data);
};

const clearPropertiesList = dispatch => {
  return dispatch({ type: CLEAR_PROPERTIES_LIST });
};

const clearCarsList = dispatch => {
  return dispatch({ type: CLEAR_CARS_LIST });
};

const setAllPropertiesListLoading = dispatch => {
  return dispatch({ type: SET_ALL_PROPERTIES_LOADING });
};

const setAllCarsListLoading = dispatch => {
  return dispatch({ type: SET_ALL_CARS_LOADING });
};

const setAllPropertiesList = (dispatch, payload) => {
  return dispatch({ type: SET_ALL_PROPERTIES_LIST, payload });
};

const setAllCarsList = (dispatch, payload) => {
  return dispatch({ type: SET_ALL_CARS_LIST, payload });
};

const loadAllPropertiesList = async (dispatch, params, filter) => {
  setAllPropertiesListLoading(dispatch);

  try {
    const data = await ApiActions.getPropertiesList(PROPERTY_CHANNELS.HOTEL, params, filter);
    const formattedData = formatPropertyPlaces(data);
    setAllPropertiesList(dispatch, formattedData);
  } catch (error) {
    setAllPropertiesList(dispatch, []);
    throw error;
  }
};

const loadAllCarsList = async (dispatch, params, filter) => {
  setAllCarsListLoading(dispatch);

  try {
    const data = await ApiActions.loadPropertiesPlaces(PROPERTY_CHANNELS.CAR, { term: params || '' });

    const formattedData = formatVehiclePropertyPlaces(data);
    setAllCarsList(dispatch, formattedData);
  } catch (error) {
    setAllCarsList(dispatch, []);
    throw error;
  }
};

const clearAllPropertiesList = dispatch => {
  return dispatch({ type: CLEAR_ALL_PROPERTIES_LIST });
};

export const actions = {
  loadSuppliersList,
  clearPropertiesList,
  loadAllPropertiesList,
  loadAllCarsList,
  clearCarsList,
  clearAllPropertiesList,
};
