import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './booking_guarantee.module.scss';
import { IBookingGuaranteeProps } from './booking_guarantee.types';

const BookingGuarantee: React.FC<IBookingGuaranteeProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="BookingGuarantee">
      <legend>{t('general.guarantee')}</legend>
      {booking.guarantee ? (
        <div>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.card_type')}:
            </Col>
            <Col span={16} offset={1} className={styles.card_type}>
              {booking.guarantee.cardType}
            </Col>
          </Row>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.card_number')}:
            </Col>
            <Col span={16} offset={1}>
              {booking.guarantee.cardNumber}
            </Col>
          </Row>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.expiration_date')}:
            </Col>
            <Col span={16} offset={1}>
              {booking.guarantee.expirationDate}
            </Col>
          </Row>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.cardholder_name')}:
            </Col>
            <Col span={16} offset={1}>
              {booking.guarantee.cardholderName}
            </Col>
          </Row>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.cvc')}:
            </Col>
            <Col span={16} offset={1}>
              {booking.guarantee.cvv ? t('bookings_list.cvv_present') : t('bookings_list.cvv_not_present')}
            </Col>
          </Row>
          <Row>
            <Col span={7} className={styles.label}>
              {t('general.isVirtual')}:
            </Col>
            <Col span={16} offset={1}>
              {booking.guarantee.isVirtual ? t('bookings_list.is_virtual') : t('bookings_list.is_not_virtual')}
            </Col>
          </Row>
        </div>
      ) : (
        <div>{t('general.no_credit_card_guarantee')}</div>
      )}
    </div>
  );
};

export default BookingGuarantee;
