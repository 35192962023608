// Generated with util/create-component.js
import React, { useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { PropertySettingsDataContext, PropertySettingsActionsContext } from 'containers/data_context';
import Inventory from 'components/inventory';

import styles from './inventory_page.module.scss';

const InventoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { selectedProperty, propertyAuthToken } = useContext(PropertySettingsDataContext);
  const { loadOneTimeHotelPropertyAuthToken } = useContext(PropertySettingsActionsContext);

  useEffect(() => {
    handleLoadOneTimeAuthToken();
  }, [selectedProperty]);

  const handleLoadOneTimeAuthToken = useCallback(async () => {
    if (!selectedProperty) return;
    try {
      await loadOneTimeHotelPropertyAuthToken(selectedProperty);
    } catch (e) {
      return notification['error']({
        message: t('general.error_message'),
      });
    }
  }, [selectedProperty]);

  return (
    <div data-testid="InventoryPage" className={styles.root}>
      <Inventory token={propertyAuthToken} propertyId={selectedProperty} />
    </div>
  );
};

export default InventoryPage;
