// Generated with util/create-component.js
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Radio, Select, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import currencies from 'world-currencies';

import StepPanel from 'components/step_panel/step_panel';
import {
  PropertyPoliciesDataContext,
  PropertySettingsDataContext,
  PropertyPoliciesActionsContext,
} from 'containers/data_context';

import { ICancellationPolicyFormProps } from './cancellation_policy_form.types';
import styles from './cancellation_policy_form.module.scss';

const defaultValues = {
  cancellationPolicyDeadline: null,
  cancellationPolicyDeadlineType: 'days',
  cancellationPolicyLogic: 'deadline',
  cancellationPolicyMode: 'percent',
  cancellationPolicyPenalty: 0,
  currency: null,
  guaranteePaymentAmount: 0,
  guaranteePaymentPolicy: 'percent_based',
  nonShowPolicy: 'total_price',
  title: null,
};

const currenciesOptions = Object.values(currencies).map(currency => ({
  label: `${currency.name} (${currency.iso.code})`,
  value: currency.iso.code,
}));

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const CancellationPolicyForm: React.FC<ICancellationPolicyFormProps> = ({ onCancel, cancellationPolicyId }) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const {
    cancellationPolicy: { data: cancellationPolicyData, isLoading: isLoadingCancellationPolicy },
    isUpdatingCancellationPolicy,
  } = useContext(PropertyPoliciesDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const {
    loadPropertyCancellationPolicy,
    updateCancellationPolicy,
    createCancellationPolicy,
    clearSelectedCancellationPolicy,
  } = useContext(PropertyPoliciesActionsContext);

  useEffect(() => {
    if (selectedProperty && cancellationPolicyId) {
      loadPropertyCancellationPolicy({ propertyId: selectedProperty, cancellationPolicyId });
    } else {
      form.setFieldsValue(defaultValues);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue(cancellationPolicyData);
  }, [cancellationPolicyData]);

  const paymentTypeOptions = [
    { value: 'none', label: t('cancellation_policies.payment_type.non_required') },
    {
      value: 'nights_based',
      label: t('cancellation_policies.payment_type.nights_based'),
    },
    {
      value: 'percent_based',
      label: t('cancellation_policies.payment_type.percent_based'),
    },
    { value: 'fixed_per_booking', label: t('cancellation_policies.payment_type.fixed_per_booking') },
    { value: 'fixed_per_room', label: t('cancellation_policies.payment_type.fixed_per_room') },
  ];

  const cancellationPolicyOptions = [
    { value: 'free', label: t('cancellation_policies.type.free') },
    { value: 'non_refundable', label: t('cancellation_policies.type.non_refundable') },
    { value: 'deadline', label: t('cancellation_policies.type.deadline') },
  ];

  const deadlineOptions = [
    {
      value: 'days',
      label: t('general.days'),
    },
    { value: 'hours', label: t('general.hours') },
  ];

  const policyModeOptions = [
    { value: 'percent', label: t('general.percent') },
    { value: 'nights', label: t('general.nights') },
  ];

  const policyTypeOptions = [
    { value: 'default', label: t('general.default') },
    { value: 'total_price', label: t('general.total_price') },
  ];

  const validateMessages = {
    required: 'required',
  };

  const isEdit = !!cancellationPolicyData;

  const handleSetCancellationPolicyLogic = (cancellationPolicyLogic: 'deadline' | 'free' | 'non_refundable') => {
    if (cancellationPolicyLogic === 'deadline') {
      form.setFieldsValue({
        cancellationPolicyLogic,
        cancellationPolicyMode: 'percent',
        cancellationPolicyDeadlineType: 'days',
      });
      return;
    }

    form.setFieldsValue({
      cancellationPolicyLogic,
    });
  };

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('cancellation_policies.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    clearSelectedCancellationPolicy();
    onCancel();
  };

  const onFinishFailed = ({ errorFields }: { errorFields: Array<{ name: string }> }) => {
    form.scrollToField(errorFields[0].name);
  };

  const handleSubmitSection = async () => {
    try {
      await form.validateFields();
      return true;
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (errors: any) {
      onFinishFailed(errors);
      return false;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);
    const cancellationPolicyData = JSON.parse(JSON.stringify(updatedValues));
    if (selectedProperty) {
      cancellationPolicyData.propertyId = selectedProperty;
    }

    try {
      if (cancellationPolicyId) {
        await updateCancellationPolicy(cancellationPolicyData);
      } else {
        await createCancellationPolicy(cancellationPolicyData);
      }
      openNotificationWithIcon('success');
      handleCancel();
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  const renderGuaranteePayment = (
    <>
      <Form.Item label={t('general.title')} name="title" rules={[{ required: true }]}>
        <Input placeholder={t('general.title')} />
      </Form.Item>

      <Form.Item label={t('general.currency')} name="currency" rules={[{ required: true }]}>
        <Select
          placeholder={t('general.currency')}
          showSearch
          optionFilterProp="label"
          options={currenciesOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item label={t('general.payment_type')} name="guaranteePaymentPolicy" rules={[{ required: true }]}>
        <Select
          placeholder={t('general.payment_type')}
          showSearch
          optionFilterProp="label"
          options={paymentTypeOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.currency !== currentValues.currency ||
          prevValues.guaranteePaymentPolicy !== currentValues.guaranteePaymentPolicy
        }
      >
        {({ getFieldValue }) => (
          <Form.Item
            label={t('general.amount')}
            name="guaranteePaymentAmount"
            hidden={getFieldValue('guaranteePaymentPolicy') === 'none'}
            rules={[{ required: getFieldValue('guaranteePaymentPolicy') !== 'none' }]}
          >
            <Input
              type="number"
              placeholder={t('general.amount')}
              addonAfter={
                ['fixed_per_booking', 'fixed_per_room'].includes(getFieldValue('guaranteePaymentPolicy'))
                  ? getFieldValue('currency')
                  : null
              }
            />
          </Form.Item>
        )}
      </Form.Item>
    </>
  );

  const renderCancellationPolicy = (
    <>
      <Form.Item label={t('general.type')} name="cancellationPolicyLogic" rules={[{ required: true }]}>
        <Select
          placeholder={t('general.type')}
          onChange={handleSetCancellationPolicyLogic}
          showSearch
          optionFilterProp="label"
          options={cancellationPolicyOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.cancellationPolicyLogic !== currentValues.cancellationPolicyLogic
        }
      >
        {({ getFieldValue }) => (
          <>
            {getFieldValue('cancellationPolicyLogic') === 'deadline' && (
              <>
                <Form.Item label={t('general.deadline')} name="cancellationPolicyDeadline" rules={[{ required: true }]}>
                  <Input
                    placeholder={t('general.deadline')}
                    type="number"
                    addonAfter={
                      <Form.Item noStyle name="cancellationPolicyDeadlineType" rules={[{ required: true }]}>
                        <Select options={deadlineOptions} />
                      </Form.Item>
                    }
                  />
                </Form.Item>

                <Form.Item label={t('general.mode')} name="cancellationPolicyMode" rules={[{ required: true }]}>
                  <Radio.Group options={policyModeOptions} optionType="button" />
                </Form.Item>

                <Form.Item label={t('general.penalty')} name="cancellationPolicyPenalty" rules={[{ required: true }]}>
                  <Input type="number" placeholder={t('general.penalty')} />
                </Form.Item>
              </>
            )}
          </>
        )}
      </Form.Item>
    </>
  );

  const renderNonShowPolicy = (
    <Form.Item label={t('general.policy_type')} name="nonShowPolicy" rules={[{ required: true }]}>
      <Radio.Group options={policyTypeOptions} optionType="button" />
    </Form.Item>
  );

  const formSteps = [
    {
      title: t('cancellation_policies.guarantee_payment'),
      content: renderGuaranteePayment,
    },
    {
      title: t('cancellation_policies.cancellation_policy'),
      content: renderCancellationPolicy,
    },
    {
      title: t('cancellation_policies.non_show_policy'),
      content: renderNonShowPolicy,
    },
  ];

  if (isLoadingCancellationPolicy) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="CancellationPolicyForm" className={styles.root}>
      <Form
        form={form}
        initialValues={defaultValues}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        {isEdit ? (
          <>
            {renderGuaranteePayment}
            {renderCancellationPolicy}
            {renderNonShowPolicy}
            <div className={styles.footer}>
              <Form.Item>
                <Button type="default" onClick={handleCancel} name="Cancel">
                  {t('link.cancel')}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isUpdatingCancellationPolicy}
                  className={styles.save_button}
                  name="save"
                >
                  {t('link.save_changes')}
                </Button>
              </Form.Item>
            </div>
          </>
        ) : (
          <StepPanel onNext={handleSubmitSection} steps={formSteps} />
        )}
      </Form>
    </div>
  );
};

export default CancellationPolicyForm;
