import React from 'react';
import { useTranslation } from 'react-i18next';

import Currency from 'components/currency';

import { PROPERTY_INFO_SECTION } from 'constants/element_ids';
import scrollToElementById from 'utils/scroll_to_element_by_id';

import styles from './min_price_panel.module.scss';
import { IMinPricePanelProps } from './min_price_panel.types';

const handleClick = (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault();

  scrollToElementById(PROPERTY_INFO_SECTION);
};

const MinPricePanel: React.FC<IMinPricePanelProps> = ({ bestOffer, params }) => {
  const { t } = useTranslation();
  const { checkinDate, checkoutDate } = params;
  const hasEnteredDates = checkinDate && checkoutDate;

  if (bestOffer?.errors) return null;

  return (
    <a className={styles.minPriceContainer} onClick={handleClick} href="/">
      {bestOffer && (
        <div className={styles.minPriceContent}>
          {t('hotel_page.price_from')}
          <Currency className={styles.minPrice} amount={bestOffer.offer} currency={bestOffer.currency} />
          {!hasEnteredDates && t('hotel_page.price_per_night')}
        </div>
      )}
    </a>
  );
};

export default MinPricePanel;
