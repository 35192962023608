import React, { useEffect, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useMedia } from 'react-media';

import MEDIA_QUERIES from 'constants/media_queries';
import USER_ROLE from 'constants/user_role';

import freshChat from 'utils/fresh_chat';
import notification from 'utils/notification';
import stripeSubscription from 'utils/stripe_subscription';

import {
  AuthenticationDataContext,
  AuthenticationActionsContext,
  SupplierContractsActionsContext,
  PropertyPoliciesActionsContext,
  PropertySettingsDataContext,
  PropertySettingsActionsContext,
  BookingsActionsContext,
  PropertyUnitsActionsContext,
  PropertyRatePlansActionsContext,
  StripeDataContext,
} from 'containers/data_context';
import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';
import ApiActions from 'api_actions';

import NavDesktop from './nav_desktop/nav_desktop';
import NavMobile from './nav_mobile/nav_mobile';

const Nav: React.FC = () => {
  const { i18n } = useTranslation();
  const { loadSuppliersList } = useContext(PropertySettingsActionsContext);
  const propertySettingsData = useContext(PropertySettingsDataContext);
  const { user, userRole } = useContext(AuthenticationDataContext);
  const { logout, reloadLoggedInUser } = useContext(AuthenticationActionsContext);
  const { stripeNotification } = useContext(StripeDataContext);

  const isReseller = useMemo(() => userRole === USER_ROLE.RESELLER, [userRole]);

  useEffect(() => {
    if (window?.Beamer?.init && document.getElementById('beamer-newsfeed-button')) {
      window.Beamer.init();
    }
  }, []);

  useEffect(() => {
    if (user) {
      freshChat.init(user);
    }
  }, [user]);

  useEffect(() => {
    if (user && window?.Beamer?.update) {
      window.Beamer.update({
        product_id: process.env.REACT_APP_BEAMER_ID,
        user_firstname: user.name,
        user_email: user.email,
        user_id: user.id,
        multi_user: true,
      });
    }
  }, [user]);

  const {
    propertiesList: { data: properties },
    selectedProperty,
  } = propertySettingsData;

  const { setSelectedProperty, clearSuppliersList, resetSupplierSettings, loadPropertyGiftCardsStores } =
    useContext(PropertySettingsActionsContext);
  const { resetBookingsList } = useContext(BookingsActionsContext);
  const { resetContracts } = useContext(SupplierContractsActionsContext);
  const { resetPropertyPolicies } = useContext(PropertyPoliciesActionsContext);
  const { resetPropertyUnits } = useContext(PropertyUnitsActionsContext);
  const { resetPropertyRatePlans } = useContext(PropertyRatePlansActionsContext);

  useEffect(() => {
    window.onbeforeunload = function () {
      window.localStorage.setItem('nav_reloaded', 'true');
    };

    if (!properties?.length && user && userRole === USER_ROLE.SUPPLIER) {
      loadSuppliersList();
    }
  }, []);

  useEffect(() => {
    async function getGiftCardsStores() {
      if (!isReseller && selectedProperty) {
        try {
          await loadPropertyGiftCardsStores(selectedProperty);
        } catch (e) {
          notification.withIcon('error', 'Something went wrong. Please try again');
          console.error('error', e);
        }
      }
    }
    getGiftCardsStores();
  }, [loadPropertyGiftCardsStores, selectedProperty, isReseller]);

  const handleReset = () => {
    resetContracts();
    resetBookingsList();
    resetPropertyPolicies();
    resetSupplierSettings();
    resetPropertyUnits();
    resetPropertyRatePlans();
  };

  const handleSwitchProperty = (propertyId: string) => {
    handleReset();
    setSelectedProperty(propertyId);
    LocaleStorage.set('selectedProperty', propertyId);
    reloadLoggedInUser();
  };

  const handleLogout = async () => {
    if (window?.Beamer?.destroy) {
      window.Beamer.destroy();
    }
    if (window.fcWidget) {
      await freshChat.reset();
    }

    setSelectedProperty(null);
    handleReset();
    clearSuppliersList();
    LocaleStorage.set('selectedProperty', null);
    logout();
    window.location.href = '/login';
  };

  const checkSubscriptionLock = (propertyId: string) => {
    return stripeSubscription.checkLock(user, userRole, propertyId);
  };

  const checkSubscriptionWarning = (propertyId: string) => {
    return stripeSubscription.checkWarning(user, userRole, propertyId);
  };

  const redirectToStripeCustomerPortal = async () => {
    let response;

    try {
      if (isReseller) {
        response = await ApiActions.getResellerStripeCustomerPortalUrl();
      } else {
        response = await ApiActions.getSupplierStripeCustomerPortalUrl(selectedProperty);
      }

      window.open(response.stripeCustomerPortalSessionUrl, '_blank');
    } catch (e) {
      notification.withIcon('error', i18n.t('general.stripe_cutomer_portal_error_message'));
    }
  };

  const matchedQueries = useMedia({ queries: MEDIA_QUERIES });
  const isMobile = matchedQueries.xs || matchedQueries.sm || matchedQueries.md;

  if (!user) return null;
  if (window.localStorage.getItem('nav_reloaded') === 'true') {
    reloadLoggedInUser();
    window.localStorage.setItem('nav_reloaded', '');
  }

  return isMobile ? (
    <NavMobile
      user={user}
      userRole={userRole}
      onSwitchProperty={handleSwitchProperty}
      onLogout={handleLogout}
      checkLock={checkSubscriptionLock}
      checkWarning={checkSubscriptionWarning}
      isReseller={isReseller}
      stripeRedirect={redirectToStripeCustomerPortal}
      stripeNotification={stripeNotification}
    />
  ) : (
    <NavDesktop
      user={user}
      onSwitchProperty={handleSwitchProperty}
      onLogout={handleLogout}
      checkLock={checkSubscriptionLock}
      checkWarning={checkSubscriptionWarning}
      isReseller={isReseller}
      stripeRedirect={redirectToStripeCustomerPortal}
      stripeNotification={stripeNotification}
    />
  );
};

export default Nav;
