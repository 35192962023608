import React from 'react';
import { useTranslation } from 'react-i18next';
import isCarProperty from 'utils/is_car_property';

import { IPriceColumnTitleProps } from './price_column_title.types';

const PriceColumnTitle: React.FC<IPriceColumnTitleProps> = ({ residenceTime, propertyType }) => {
  const { t } = useTranslation();

  let priceColumnTitle;
  if (isCarProperty(propertyType)) {
    priceColumnTitle =
      residenceTime > 1 ? t('rates_table.price_multiple_days', { residenceTime }) : t('rates_table_car.price');
  } else {
    priceColumnTitle =
      residenceTime > 1 ? t('rates_table.price_multiple_nights', { residenceTime }) : t('rates_table_hotel.price');
  }

  return <>{priceColumnTitle}</>;
};

export default PriceColumnTitle;
