import React from 'react';

import styles from './footer_link_container.module.scss';
import { IFooterLinkContainerProps } from './footer_link_container.types';

const FooterLinkContainer: React.FC<IFooterLinkContainerProps> = ({ children }) => {
  return <div className={styles.container}>{children}</div>;
};

export default FooterLinkContainer;
