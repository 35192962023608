export default [
  { value: 2022, label: '22' },
  { value: 2023, label: '23' },
  { value: 2024, label: '24' },
  { value: 2025, label: '25' },
  { value: 2026, label: '26' },
  { value: 2027, label: '27' },
  { value: 2028, label: '28' },
  { value: 2029, label: '29' },
  { value: 2030, label: '30' },
  { value: 2031, label: '31' },
  { value: 2032, label: '32' },
  { value: 2033, label: '33' },
];
