export const formatPropertySettings = propertyData => {
  if (!propertyData) return {};
  const { relationships, attributes, ...params } = propertyData;

  const formattedData = {
    ...attributes,
    ...params,
    facilities: formatFacilitiesList(relationships?.facilities),
  };

  if (formattedData.logoUrl) {
    formattedData.logo = [{ url: formattedData.logoUrl }];
  } else {
    formattedData.logo = [];
  }
  return formattedData;
};

export const formatPropertyPolicies = propertyData => {
  if (!propertyData) return [];
  const {
    hotelPolicies: { data: hPolicies = [] },
    cancellationPolicies: { data: cPolicies = [] },
  } = propertyData?.relationships;

  const cancellationPolicies = cPolicies?.map(formatPolicy);
  const propertyPolicies = hPolicies?.map(formatPolicy);

  return { cancellationPolicies, propertyPolicies };
};

export const formatPolicy = policy => {
  if (!policy) return;
  return {
    value: policy?.attributes?.id,
    label: policy?.attributes?.title,
  };
};

export const formatPropertyFacilities = facilities => {
  return facilities?.reduce((list, facility) => {
    const {
      id,
      title,
      category,
    } = facility?.attributes;

    if (!list?.[category]) {
      list[category] = [];
    }
    list[category].push({ value: id, label: title });
    return list;
  }, {});
};

export const formatFacilitiesList = facilities => {
  return facilities.data.reduce((list, facility) => {
    list.push({ value: facility?.attributes?.id, label: facility?.attributes?.title });
    return list;
  }, []);
};

export const formatPropertiesList = properties => {
  return properties?.reduce((list, property) => {
    list.push({
      value: property?.propertyId,
      label: property?.title,
      hasPaymentProvider: property?.hasPaymentProvider,
      storeSetting: property?.storeSetting,
      activeSlug: property?.activeSlug,
      propertyType: property?.propertyType,
    });
    return list;
  }, []);
};
