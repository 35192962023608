// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';

import { ReactComponent as Logo } from 'assets/logo.svg';

import styles from './gift_campaign_navbar.module.scss';

const GiftCampaignNavbar: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="GiftCampaignNavbar" className={styles.root}>
      <Row justify="center" className={styles.navbarContainer}>
        <Col sm={12}>
          <Logo className={styles.logo} data-testid="Logo" />
        </Col>
        <Col sm={12} className={styles.poweredByLabel}>
          <div>{`${t('footer.get_free_channel')} Reserva`}</div>
        </Col>
      </Row>
    </div>
  );
};

export default GiftCampaignNavbar;
