// Generated with util/create-component.js
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Dropdown, Popconfirm, Tooltip, Drawer, Button, MenuProps } from 'antd';
import { DownOutlined, EditOutlined, DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';

import DRAWER_SIZE from 'constants/drawer_size';

import {
  PropertyPoliciesDataContext,
  PropertyPoliciesActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import CancellationPolicyForm from 'components/cancellation_policy_form/cancellation_policy_form';

import { ICancellationPoliciesListProps, ICancellationPolicy } from './cancellation_policies_list.types';
import styles from './cancellation_policies_list.module.scss';

const CancellationPoliciesList: React.FC<ICancellationPoliciesListProps> = ({ cancellationPolicies }) => {
  const { t } = useTranslation();
  const [openedCPForm, toogleCPForm] = useState<boolean>(false);
  const [cancellationPolicyId, setCPId] = useState<ICancellationPolicy['value'] | null>(null);

  const { selectedProperty } = useContext(PropertySettingsDataContext);
  const { clearSelectedCancellationPolicy, removeCancellationPolicy } = useContext(PropertyPoliciesActionsContext);

  const { isRemovingCancellationPolicy } = useContext(PropertyPoliciesDataContext);

  const handleRemoveCP = (record: ICancellationPolicy) => {
    removeCancellationPolicy({ propertyId: selectedProperty, cancellationPolicyId: record.value });
  };

  const handleCloseCPForm = () => {
    clearSelectedCancellationPolicy();
    toogleCPForm(false);
    setCPId(null);
  };

  const handleOpenCPForm = (cancellationPolicyId: ICancellationPolicy['value'] | null) => {
    toogleCPForm(true);
    setCPId(cancellationPolicyId);
  };

  const renderMenu = (record: ICancellationPolicy): MenuProps => {
    const menuItems: MenuProps['items'] = [
      {
        key: 'edit',
        label: t('general.edit'),
        icon: <EditOutlined />,
        onClick: () => handleOpenCPForm(record.value),
      },
      {
        key: 'remove',
        label: (
          <Tooltip title={`${t('general.remove')} ${record.label}`}>
            <Popconfirm
              title={
                <>
                  <p>{t('bookings_list.confirm')}</p>
                  <p>{`${t('general.are_you_sure')} ${record.label}`}?</p>
                </>
              }
              cancelText={t('link.cancel')}
              okText={t('link.yes')}
              placement="bottomRight"
              onConfirm={() => handleRemoveCP(record)}
              disabled={isRemovingCancellationPolicy}
            >
              {t('general.remove')}
            </Popconfirm>
          </Tooltip>
        ),
        icon: <DeleteOutlined />,
      },
    ];
    return { items: menuItems };
  };

  const cancellationPolicyColumns = [
    {
      key: 'label',
      dataIndex: 'label',
    },
    {
      key: 'actions',
      render: (record: ICancellationPolicy) => renderActionButtons(record),
    },
  ];

  const renderActionButtons = (record: ICancellationPolicy) => {
    return (
      <Dropdown menu={renderMenu(record)}>
        <div className={styles.actions_link}>
          <span className={styles.action_link_text}>{t('general.actions')}</span> <DownOutlined />
        </div>
      </Dropdown>
    );
  };

  return (
    <div data-testid="CancellationPoliciesList" className={styles.root}>
      <Button
        onClick={() => handleOpenCPForm(null)}
        type="primary"
        icon={<PlusCircleOutlined />}
        className={styles.create_button}
      >
        {t('general.create')}
      </Button>

      <Table
        dataSource={cancellationPolicies}
        columns={cancellationPolicyColumns}
        rowKey="value"
        pagination={false}
        showHeader={false}
        className={styles.cp_list}
      />

      <Drawer
        open={openedCPForm}
        title={`${cancellationPolicyId ? t('general.buttons.edit') : t('general.tooltip.create')} ${t(
          'general.cancellation_policy',
        )}`}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleCloseCPForm}
        destroyOnClose={true}
      >
        <CancellationPolicyForm onCancel={handleCloseCPForm} cancellationPolicyId={cancellationPolicyId} />
      </Drawer>
    </div>
  );
};

export default CancellationPoliciesList;
