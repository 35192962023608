// Generated with util/create-component.js
import React from 'react';

import UsersList from 'components/supplier/users_list/users_list';

import styles from './supplier_manager_page.module.scss';

const SupplierManagerPage: React.FC = () => {
  return (
    <div data-testid="SupplierManagerPage" className={styles.root}>
      <UsersList />
    </div>
  );
};

export default SupplierManagerPage;
