export default () => {
  const urlParams = new URLSearchParams(window.location.search);

  const searchParams = {};
  for (var [name, value] of urlParams.entries()) {
    searchParams[name] = value;
  }

  return searchParams;
};
