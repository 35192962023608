import React, { useContext, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import dateFormatter from 'utils/date_formatter';
import buildPath from 'utils/build_path';
import getUrlParams from 'utils/get_url_params';
import routes from 'routing/routes';

import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import Loading from 'components/loading';
import GiftCardStoreBooking from 'components/gift_card_store_booking/gift_card_store_booking';
import { IDatesRange } from 'components/date_range_picker/date_range_picker.types';
import PageTitle from 'components/page_title/page_title';

import styles from './gift_card_store_booking_page.module.scss';
import { IRatePlan, IPropertyUnit } from '../../components/gift_card_store_booking/gift_card_store_booking.types';

const GiftCardStoreBookingPage: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const { selectedProperty, giftCardNumber } = useParams<{ selectedProperty: string; giftCardNumber: string }>();
  const { selected_property } = getUrlParams() as { selected_property: string };
  const {
    getGiftCardById,
    getPropertyInfo,
    saveDataToStorage,
    setParams,
    getGiftCardDetails,
    getGiftCardClosedDates,
    loadGiftCardUnitsInfo,
    resetGiftCardStoreBooking,
  } = useContext(PropertyGiftCardsActionsContext);

  const propertyGiftCardsData = useContext(PropertyGiftCardsDataContext);

  const {
    giftCardDetails: { data: giftCard, isLoading: isLoadingGiftCard },
    propertyInfo: { data: propertyInfo, isLoading: isLoadingPropertynfo },
    giftCardUnitsInfo: { data: giftCardUnits, isLoading: isLoadingGiftCardUnitsData },
    params: { checkinDate, checkoutDate },
    giftCardClosedDates: { data: giftCardClosedDatesData, isLoading: isLoadingGiftCardClosedDatesData },
  } = propertyGiftCardsData;

  useEffect(() => {
    async function initApp() {
      try {
        const giftCardDetails: { giftCardId: string; propertyId?: string } = { giftCardId: giftCardNumber };
        if (selected_property) {
          giftCardDetails.propertyId = selectedProperty;
        }
        await getGiftCardDetails(giftCardDetails);
      } catch (e) {
        console.log('error', e);
      }
    }
    initApp();
  }, [getGiftCardById, selectedProperty]);

  const handleRedirectToStore = (description: string) => {
    const redirectPath = buildPath('', routes.giftCardStorePage, { selectedProperty });
    history.push(redirectPath);

    return notification['error']({
      message: 'An error occurred',
      description,
    });
  };

  useEffect(() => {
    if (giftCard) {
      if (giftCard.booked) {
        handleRedirectToStore('Gift certificates have already been used');
        return;
      } else if (giftCard.multiProperty) {
        resetGiftCardStoreBooking();
        if (!selected_property) {
          const path = buildPath('', routes.giftCardPropertiesPage, { giftCardId: giftCardNumber });
          history.push(path);
          return;
        }
      }

      if (giftCard.channelId) {
        getGiftCardClosedDates(giftCard.channelId);
      }
      getPropertyInfo({ propertyId: selectedProperty });
    }
  }, [giftCard]);

  const handleDatesChange = useCallback(
    (dates: IDatesRange['dateRange']) => {
      const allParams = {
        ...propertyGiftCardsData.params,
        checkinDate: dates?.[0] || null,
        checkoutDate: dates?.[1] || null,
      };
      saveDataToStorage(allParams);
      setParams(allParams);
      if (dates?.[0] && dates?.[1]) {
        loadGiftCardUnitsInfo({
          propertyChannelId: giftCard.channelId,
          checkinDate: dateFormatter.toApi(dates[0]),
          checkoutDate: dateFormatter.toApi(dates[1]),
        });
      }
    },
    [setParams, loadGiftCardUnitsInfo, giftCard],
  );

  const handleBook = useCallback(
    (propertyUnit: IPropertyUnit, ratePlan: IRatePlan, isUpsell: boolean) => {
      const params = {
        ...propertyGiftCardsData.params,
        propertyUnit: propertyUnit,
        ratePlan: ratePlan,
        isUpsell: isUpsell,
      };
      setParams(params);
      saveDataToStorage(params);

      const redirectPath = buildPath('', routes.giftCardStoreBookingCheckoutPage, { selectedProperty, giftCardNumber });
      history.push(redirectPath);
    },
    [saveDataToStorage, history, selectedProperty, giftCardNumber, propertyGiftCardsData.params],
  );

  const isLoading = useMemo(
    () => isLoadingGiftCard || isLoadingPropertynfo || isLoadingGiftCardClosedDatesData,
    [isLoadingGiftCard, isLoadingPropertynfo, isLoadingGiftCardClosedDatesData],
  );

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  if (isLoading) {
    return (
      <div className={styles.root} data-testid="GiftCardStoreBookingPage">
        <Loading />
      </div>
    );
  }

  return (
    <div className={styles.root} data-testid="GiftCardStoreBookingPage">
      <PageTitle
        steps={[
          { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
          { title: t('gift_card'), link: '' },
          { title: propertyInfo?.title, link: '' },
        ]}
      />
      <GiftCardStoreBooking
        giftCard={giftCard}
        units={giftCardUnits}
        isLoadingGiftCardUnitsData={isLoadingGiftCardUnitsData}
        propertyInfo={propertyInfo}
        onDatesChange={handleDatesChange}
        onBook={handleBook}
        dates={[checkinDate, checkoutDate]}
        closedDates={giftCardClosedDatesData}
      />
    </div>
  );
};

export default GiftCardStoreBookingPage;
