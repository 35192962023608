import isCarProperty from "./is_car_property";

export default (propertyRooms, ratesOccupancyPerUnit, params) => {
  if (!propertyRooms || !ratesOccupancyPerUnit) {
    return null;
  }

  let newTotalPrice = 0;
  const newSelectedRatesByRoom = {};

  const isCar = isCarProperty(params?.type);

  Object.keys(ratesOccupancyPerUnit).forEach(roomId => {
    Object.keys(ratesOccupancyPerUnit[roomId]).forEach(rateId => {
      const amount = Number(ratesOccupancyPerUnit[roomId][rateId]);
      const room = propertyRooms.find(propertyRoom => propertyRoom.id === roomId);
      const rate = room?.ratePlans.find(roomRatePlan => roomRatePlan.id === rateId);

      const rateTotalPrice = Number(rate?.totalPrice);
      const rateNetPrice = Number(rate?.netPrice);

      if (amount) {
        const selectedRate = { ...rate, amount };

        if (isCar && (params?.pickupTime < params?.dropoffTime)) {
          selectedRate.lengthOfStay = selectedRate.lengthOfStay + 1;
        }

        const newRoom = { ...room, selectedRates: [], total: 0 };
        const { [room?.id]: selectedRoom = newRoom } = newSelectedRatesByRoom;

        selectedRoom.selectedRates.push(selectedRate);
        selectedRoom.total += amount * rateNetPrice;

        newSelectedRatesByRoom[selectedRoom.id] = selectedRoom;

        newTotalPrice += rateTotalPrice * amount;
      }
    });
  });

  return {
    total: newTotalPrice,
    selectedRatesPerUnit: newSelectedRatesByRoom,
  };
};
