import React from 'react';

import { ILabelProps } from './label.types';
import styles from './label.module.scss';

const Label: React.FC<ILabelProps> = ({ className = '', children }) => {
  return <div className={[styles.label, className].join(' ')}>{children}</div>;
};

export default Label;
