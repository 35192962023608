import {
  SET_RESELLER_DISCOVER_CONTRACTS_LIST_LOADING,
  SET_RESELLER_DISCOVER_CONTRACTS_LIST,
  SET_RESELLER_CONTRACTS_LIST,
  SET_RESELLER_CONTRACTS_LIST_LOADING,
  SET_RESELLER_ACCEPT_CONTRACT_LOADING,
  SET_RESELLER_TERMINATING_CONTRACT_LOADING,
  SET_RESELLER_DECLINING_CONTRACT_LOADING,
  SET_RESELLER_CONTRACTS_RATES_LOADING,
  SET_RESELLER_CONTRACTS_RATES_LIST,
  SET_RESELLER_CREATE_CONTRACT_LOADING,
  SET_RESELLER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING,
  RESET_RESELLER_CONTRACTS_OPEN_UNIT_TYPES_LIST,
} from './reseller_contracts_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  discoverSuppliers: EMPTY_ENTITY,
  contracts: {
    data: {
      proposed: [],
      approved: [],
    },
    isLoading: false,
  },
  isAcceptingContract: false,
  isTerminatingContract: false,
  isDecliningContract: false,
  isCancelingContractProposal: false,
  supplierUnits: EMPTY_ENTITY,
  resellerCreateContractIsLoading: false,
};

const actionHandlers = {
  [SET_RESELLER_DISCOVER_CONTRACTS_LIST_LOADING]: state => {
    const discoverSuppliers = { ...state.discoverSuppliers, isLoading: true };

    return { ...state, discoverSuppliers };
  },
  [SET_RESELLER_DISCOVER_CONTRACTS_LIST]: (state, action) => {
    const discoverSuppliers = { data: action.payload, isLoading: false };

    return { ...state, discoverSuppliers };
  },
  [SET_RESELLER_CONTRACTS_LIST_LOADING]: state => {
    const contracts = { ...state.contracts, isLoading: true };

    return { ...state, contracts };
  },
  [SET_RESELLER_ACCEPT_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isAcceptingContract: params };
  },
  [SET_RESELLER_TERMINATING_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isTerminatingContract: params };
  },
  [SET_RESELLER_DECLINING_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isDecliningContract: params };
  },
  [SET_RESELLER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, isCancelingContractProposal: params };
  },
  [SET_RESELLER_CONTRACTS_LIST]: (state, action) => {
    const { status, contracts } = action.payload;

    const dataUpdated = JSON.parse(JSON.stringify(state.contracts.data));
    dataUpdated[status] = contracts;
    const updatedContracts = { data: dataUpdated, isLoading: false };

    return { ...state, contracts: updatedContracts };
  },
  [SET_RESELLER_CONTRACTS_RATES_LOADING]: state => {
    const supplierUnits = { ...state.supplierUnits, isLoading: true };

    return { ...state, supplierUnits };
  },
  [SET_RESELLER_CONTRACTS_RATES_LIST]: (state, action) => {
    const supplierUnits = { data: action.payload, isLoading: false };

    return { ...state, supplierUnits };
  },
  [RESET_RESELLER_CONTRACTS_OPEN_UNIT_TYPES_LIST]: state => {
    const supplierUnits = EMPTY_ENTITY;

    return { ...state, supplierUnits };
  },
  [SET_RESELLER_CREATE_CONTRACT_LOADING]: (state, { params }) => {
    return { ...state, resellerCreateContractIsLoading: params };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
