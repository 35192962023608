import {
  SET_PROPERTIES_LIST,
  SET_PROPERTIES_LOADING,
  SET_UPDATED_PROPERTY,
  SET_PROPERTY_IS_CREATING,
  SET_PROPERTY_IS_UPDATING,
} from './properties_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  properties: EMPTY_ENTITY,
  property: {
    isCreating: false,
    isUpdating: false,
  },
};

const actionHandlers = {
  [SET_PROPERTIES_LOADING]: (state, { params }) => {
    const properties = { ...state.properties, isLoading: params };

    return { ...state, properties };
  },
  [SET_PROPERTIES_LIST]: (state, action) => {
    const properties = { data: action.payload, isLoading: false };

    return { ...state, properties };
  },
  [SET_PROPERTY_IS_UPDATING]: (state, action) => {
    const property = { ...state.property, isUpdating: action.params };

    return { ...state, property };
  },
  [SET_UPDATED_PROPERTY]: (state, { params }) => {
    const propertiesData = [...state.properties.data.reservaHotels];
    const propertyIndex = state.properties.data.reservaHotels.findIndex(rh => rh.id === params.id);
    if (propertyIndex !== -1) {
      propertiesData[propertyIndex] = params;
    } else {
      propertiesData.push(params);
    }

    const properties = { ...state.properties, data: { ...state.properties.data, reservaHotels: propertiesData } };

    return { ...state, properties };
  },
  [SET_PROPERTY_IS_CREATING]: (state, action) => {
    const property = { ...state.property, isCreating: action.params };

    return { ...state, property };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
