import React from 'react';

import Tooltip from 'components/tooltip';

import PoliciesBreakdown from './policies_breakdown';

import { IPoliciesInfoProps } from './policies_info.types';

const PoliciesInfo: React.FC<IPoliciesInfoProps> = ({ ratePlan, propertyType }) => {
  return (
    <Tooltip>
      <PoliciesBreakdown ratePlan={ratePlan} id={''} propertyType={propertyType} />
    </Tooltip>
  );
};

export default PoliciesInfo;
