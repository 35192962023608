import {
  SET_GIFT_CAMPAIGN_OPTIONS,
  SET_GIFT_CAMPAIGN_OPTIONS_LOADING,
  SET_GIFT_CAMPAIGNS,
  SET_GIFT_CAMPAIGNS_LOADING,
  SET_GIFT_CAMPAIGN_IS_CREATING,
  SET_GIFT_CAMPAIGN_IS_UPDATING,
} from './gift_campaign_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  giftCampaigns: EMPTY_ENTITY,
  giftCampaignOptions: EMPTY_ENTITY,
  isCreating: false,
  isUpdating: false,
};

const actionHandlers = {
  [SET_GIFT_CAMPAIGN_OPTIONS]: (state, action) => {
    const giftCampaignOptions = { data: action.payload, isLoading: false };

    return { ...state, giftCampaignOptions };
  },
  [SET_GIFT_CAMPAIGN_OPTIONS_LOADING]: (state, { params }) => {
    const giftCampaignOptions = { ...state.giftCampaignOptions, isLoading: params };

    return { ...state, giftCampaignOptions };
  },
  [SET_GIFT_CAMPAIGNS]: (state, action) => {
    const giftCampaigns = { data: action.payload, isLoading: false };

    return { ...state, giftCampaigns };
  },
  [SET_GIFT_CAMPAIGNS_LOADING]: (state, { params }) => {
    const giftCampaigns = { ...state.giftCampaigns, isLoading: params };

    return { ...state, giftCampaigns };
  },
  [SET_GIFT_CAMPAIGN_IS_CREATING]: (state, { params }) => {
    return { ...state, isCreating: params };
  },
  [SET_GIFT_CAMPAIGN_IS_UPDATING]: (state, { params }) => {
    return { ...state, isUpdating: params };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
