
import countries from 'constants/countries';

export const formatPropertyOffers = (properties) => {
  const formattedProperties = properties?.map(p => {
    return p.offers?.map(o => {
      const { id, station, totalPrice, make, model, imageUrl, ...rest } = o;
      return {
        ...rest,
        address: station?.title,
        bestOffer: totalPrice,
        totalPrice,
        city: null,
        country: null,
        description: '',
        id: o?.id,
        stationChannelId: station?.id,
        stationTitle: station.title,
        latitude: station?.location?.latitude,
        longitude: station?.location?.longitude,
        photos: [
          {
            author: null,
            description: null,
            url: imageUrl
          }],
        state: null,
        timezone: 'UTC',
        title: `${make} ${model}`,
        provider: p.provider,
      }
    })
  });

  return formattedProperties.flat(1);
}

export const formatVehicleUnits = (units) => {
  const vehicles = [];

  units.map(p => {
    const { stationLatitude, stationLongitude, make, model, ...rest } = p;
    const data = {
      ...rest,
      title: `${make} ${model}`,
      latitude: stationLatitude,
      longitude: stationLongitude,
    }

    p?.ratePlans?.map(rp => {
      vehicles.push({
        ...data,
        currency: rp.currency,
        totalPrice: rp.totalPrice,
        netPrice: rp.netPrice,
        id: rp.id,

      })
    });
  });

  return vehicles;
}

export const formatPropertyPlaces = (properties) => {
  if (!properties?.length) return { property: [], city: [], state: [], country: [] };

  const formattedProperties = properties.reduce(
    (data, property) => {
      const { title, city, state, country, id } = property;

      const countryName = country ? countries.find(c => c.value === country)?.label : '';

      if (title && !data.property.find(c => c.title === title)) {
        data.property.push({
          title: title,
          info: `${city ? city : ''}${state ? (city ? ', ' : '') + state : ''}${countryName ? ((city && !state) || state ? ', ' : '') + countryName : ''
            }`,
          id
        });
      }

      if (city && !data.city.find(c => c.title === city)) {
        data.city.push({
          title: city,
          info: `${state ? state : ''}${countryName ? (state ? ', ' : '') + countryName : ''}`,
        });
      }

      if (countryName && !data.country.find(c => c.title === countryName)) {
        data.country.push({ title: countryName, info: '' });
      }

      return data;
    },
    { property: [], city: [], state: [], country: [] },
  );

  return formattedProperties;
}

export const formatVehiclePropertyPlaces = (places) => {
  const {
    cities,
    states,
    countries
  } = places;

  const city = cities.map(c => ({ title: c, info: '' }));
  const state = states.map(c => ({ title: c, info: '' }));
  const country = countries.map(c => ({ title: c, info: '' }));

  return {
    city,
    state,
    country,
    property: []
  }

}