// Generated with util/create-component.js
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Button, notification } from 'antd';

import countries from 'constants/countries';
import { AdminResellersDataContext, AdminResellersActionsContext } from 'containers/data_context';

import { IResellerFormProps } from './reseller_form.types';
import styles from './reseller_form.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const validateMessages = {
  required: 'required',
};

const initialValues = {
  email: '',
  name: '',
  phone: '',
  address_1: '',
  country: '',
  city: '',
  zipCode: '',
  globalId: '',
  taxId: '',
  governmentId: '',
  legalName: '',
  emailInvoice: '',
};

const ResellerForm: React.FC<IResellerFormProps> = ({ reseller, onCancel }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const { updateReseller, createReseller } = useContext(AdminResellersActionsContext);

  const {
    reseller: { isUpdating, isCreating },
  } = useContext(AdminResellersDataContext);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);

    try {
      if (reseller) {
        await updateReseller(updatedValues);
      } else {
        await createReseller(updatedValues);
      }
      openNotificationWithIcon('success');
      onCancel();
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  return (
    <div data-testid="ResellerForm" className={styles.root}>
      <Form
        form={form}
        initialValues={reseller ? reseller : initialValues}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        <Form.Item label={t('reseller.name')} name="name" rules={[{ required: true }]}>
          <Input placeholder={t('reseller.name')} />
        </Form.Item>
        <Form.Item
          label={t('general.other.email')}
          name="email"
          rules={[{ required: true }, { type: 'email', message: t('general.email.valid_message') }]}
        >
          <Input placeholder={t('general.other.email')} />
        </Form.Item>
        <Form.Item label={t('general.phone')} name="phone" rules={[{ required: true }]}>
          <Input placeholder={t('general.phone')} type="number" />
        </Form.Item>
        <Form.Item label={t('table.address')} name="address_1">
          <Input placeholder={t('table.address')} />
        </Form.Item>
        <Form.Item label={t('table.city')} name="city">
          <Input placeholder={t('table.city')} />
        </Form.Item>
        <Form.Item label={t('general.zip_code')} name="zipCode">
          <Input placeholder={t('general.zip_code')} />
        </Form.Item>
        <Form.Item label={t('general.country')} name="country" rules={[{ required: true }]}>
          <Select
            showSearch
            placeholder={t('general.country')}
            optionFilterProp="label"
            filterOption={true}
            options={countries}
            allowClear
          />
        </Form.Item>
        {reseller && (
          <Form.Item label={t('general.global_id')} name="globalId">
            <Input placeholder={t('general.global_id')} disabled={true} />
          </Form.Item>
        )}
        <Form.Item label={t('general.tax_id')} name="taxId">
          <Input placeholder={t('general.tax_id')} />
        </Form.Item>
        <Form.Item label={t('general.government_id')} name="governmentId">
          <Input placeholder={t('general.government_id')} />
        </Form.Item>
        <Form.Item label={t('general.legal_name')} name="legalName">
          <Input placeholder={t('general.legal_name')} />
        </Form.Item>
        <Form.Item
          label={t('general.email_invoice')}
          name="emailInvoice"
          rules={[{ type: 'email', message: t('general.email.valid_message') }]}
        >
          <Input placeholder={t('general.email_invoice')} />
        </Form.Item>

        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={handleCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={reseller ? isUpdating : isCreating}
              disabled={reseller ? isUpdating : isCreating}
              className={styles.save_button}
            >
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default ResellerForm;
