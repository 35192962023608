import {
  SET_USERS_LIST,
  SET_USERS_LOADING,
  SET_USER_IS_UPDATING,
  SET_UPDATED_USER,
  SET_USER_IS_CREATING,
} from './users_actions';

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  users: EMPTY_ENTITY,
  user: {
    isUpdating: false,
    isUpdatingStatus: false,
    isCreating: false,
  },
};

const actionHandlers = {
  [SET_USERS_LOADING]: (state, { params }) => {
    const users = { ...state.users, isLoading: params };

    return { ...state, users };
  },
  [SET_USERS_LIST]: (state, action) => {
    const users = { data: action.payload, isLoading: false };

    return { ...state, users };
  },
  [SET_USER_IS_UPDATING]: (state, action) => {
    const user = { ...state.user, isUpdating: action.params };

    return { ...state, user };
  },
  [SET_UPDATED_USER]: (state, { params }) => {
    const usersData = [...state.users.data.users];
    const userIndex = state.users.data.users.findIndex(user => user.id === params.id);
    if (userIndex !== -1) {
      usersData[userIndex] = params;
    }

    const users = { ...state.users, data: { ...state.users.data, users: usersData } };

    return { ...state, users };
  },
  [SET_USER_IS_CREATING]: (state, action) => {
    const user = { ...state.user, isCreating: action.params };

    return { ...state, user };
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
