import React from 'react';

import styles from './placeholder.module.scss';
import { IPlaceholderProps } from './placeholder.types';

const Placeholder: React.FC<IPlaceholderProps> = ({ text, icon }) => {
  const styledIcon = React.cloneElement(icon, { className: styles.icon });

  return (
    <div className={styles.container}>
      {styledIcon}
      <div>{text}</div>
    </div>
  );
};

export default Placeholder;
