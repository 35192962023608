import {
  SET_PAYMENT_PROVIDERS_LOADING,
  SET_PAYMENT_PROVIDERS,
  SET_PAYMENT_PROVIDER_UPDATE_LOADING,
  SET_PAYMENT_PROVIDER_CREATE_LOADING,
  SET_DELETE_PAYMENT_PROVIDER_LOADING,
  RESET_PAYMENT_PROVIDERS,
} from './payment_providers_actions';
const EMPTY_ENTITIES = {
  data: [],
  isLoading: false,
}

export const INITIAL_STATE = {
  paymentProviders: EMPTY_ENTITIES,
  isCreatingPaymentProvider: false,
  isUpdatingPaymentProvider: false,
  isDeletingPaymentProvider: false,
};

const actionHandlers = {
  [SET_PAYMENT_PROVIDERS]: (state, action) => {
    const { data } = action.payload;

    const paymentProviders = { data, isLoading: false };

    return { ...state, paymentProviders };
  },
  [SET_PAYMENT_PROVIDERS_LOADING]: state => {
    const paymentProviders = { ...state.paymentProviders, isLoading: true };

    return { ...state, paymentProviders };
  },
  [SET_PAYMENT_PROVIDER_UPDATE_LOADING]: (state, { params }) => {
    return { ...state, isUpdatingPaymentProvider: params };
  },
  [SET_PAYMENT_PROVIDER_CREATE_LOADING]: (state, { params }) => {
    return { ...state, isCreatingPaymentProvider: params };
  },
  [SET_DELETE_PAYMENT_PROVIDER_LOADING]: (state, { params }) => {
    return { ...state, isDeletingPaymentProvider: params };
  },
  [RESET_PAYMENT_PROVIDERS]: () => {
    return INITIAL_STATE;
  },
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
