import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';

import routes from 'routing/routes';
import { ReactComponent as Logo } from 'assets/logo.svg';
import { AuthenticationActionsContext, AuthenticationDataContext } from 'containers/data_context';
import LoginForm from 'components/login_form/login_form';

import styles from './login_page.module.scss';

const LoginPage: React.FC = () => {
  const { login, setLoggedInUser, resetPassword } = useContext(AuthenticationActionsContext);
  const { user } = useContext(AuthenticationDataContext);

  if (user) {
    const from = { pathname: routes.bookingsPage };

    return <Redirect to={from} />;
  }

  const handleLogin = async (email: string, password: string) => {
    const loginData = await login({ email, password });
    if (loginData.session) {
      setLoggedInUser(loginData.session);
    }
  };

  const handlePasswordReset = async (email: string) => {
    await resetPassword(email);
  };

  return (
    <div data-testid="LoginPage" className={styles.root}>
      <div className={styles.login_container}>
        <div className={styles.logo_container}>
          <Logo />
        </div>
        <LoginForm onLogin={handleLogin} onPasswordReset={handlePasswordReset} />
      </div>
    </div>
  );
};

export default LoginPage;
