import React from 'react';
import { useTranslation } from 'react-i18next';

import Label from 'components/label';

import IconCheckIn from 'static/icons-checkin.svg';
import IconCheckOut from 'static/icons-checkout.svg';

import dateFormatter from 'utils/date_formatter';

import styles from './date.module.scss';
import { IDateProps } from './date.types';

const DATE_FORMAT = 'DD MMM';
const CHECKIN_DATE = 'checkin';

const Date: React.FC<IDateProps> = ({ date, type = CHECKIN_DATE, showTime = false }) => {
  if (!date) return null;

  const { t } = useTranslation();

  const dateConfig = {
    checkin: {
      label: t('payment_page.booking_summary.check_in'),
      className: styles.dateFrom,
      iconSrc: IconCheckIn,
    },
    pickup: {
      label: t('payment_page.booking_summary.pick_up'),
      className: styles.dateFrom,
      iconSrc: IconCheckOut,
    },
    checkout: {
      label: t('payment_page.booking_summary.check_out'),
      className: styles.dateTo,
      iconSrc: IconCheckOut,
    },
    dropoff: {
      label: t('payment_page.booking_summary.drop_off'),
      className: styles.dateTo,
      iconSrc: IconCheckIn,
    },
  };

  const dateParams = dateConfig[type];

  return (
    <div className={styles.datesFromTo}>
      <Label>{dateParams.label}</Label>
      <div className={dateParams.className}>
        <img className={styles.icon} src={dateParams.iconSrc} alt={type} />
        <div className={styles.dateDetails}>
          <strong>{date.format(DATE_FORMAT)}</strong>
          {showTime && <strong>{dateFormatter.toTime(date)}</strong>}
        </div>
      </div>
    </div>
  );
};

export default Date;
