import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Overlay from 'components/layout/overlay';

import styles from './loading_container.module.scss';
import { ILoadingContainerProps } from './loading_container.types';

const LoadingContainer: React.FC<ILoadingContainerProps> = ({ loading, children }) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Overlay active={loading}>
        <>
          <Spinner animation="border" />
          <div className={styles.overlayText}>{t('general.other.loading')}</div>
        </>
      </Overlay>
      {children}
    </div>
  );
};

export default LoadingContainer;
