// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Input, Select, Col, Tooltip, Modal, Typography } from 'antd';
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import countries from 'constants/countries';
import EMPTY_GUEST from 'constants/empty_guest';

import isCarProperty from 'utils/is_car_property';
import { IBookingGuest, IBookingUnit } from 'components/bookings_list/bookings_list.types';

import { IBookingUnitGuestsProps } from './booking_guests.types';
import styles from './booking_guests.module.scss';

const { Text } = Typography;

const BookingGuests: React.FC<IBookingUnitGuestsProps> = ({
  guests,
  maxOccupancy,
  unitTitle,
  unitIndex,
  propertyType,
  canBeModified,
  onUpdateBookingGuests,
}) => {
  const { t } = useTranslation();

  const handleUpdateBookingGuests = (guests: IBookingUnit['guests']) => {
    onUpdateBookingGuests && onUpdateBookingGuests(guests, unitIndex);
  };

  const handleCopy = (field: keyof IBookingGuest, index: number) => {
    const value = guests[index][field];
    const updatedGuests = guests.map(guest => ({
      ...guest,
      [field]: value,
    }));

    handleUpdateBookingGuests(updatedGuests);
  };

  const handleAddGuest = () => {
    const guestsData = JSON.parse(JSON.stringify(guests));
    handleUpdateBookingGuests([...guestsData, EMPTY_GUEST]);
  };

  const handleGuestUpdate = (value: string, index: number, guestInfo: keyof IBookingGuest) => {
    const updatedGuests = JSON.parse(JSON.stringify(guests));
    updatedGuests[index][guestInfo] = value;

    handleUpdateBookingGuests(updatedGuests);
  };

  const handleDeleteGuest = (index: number) => {
    const updatedGuests = JSON.parse(JSON.stringify(guests));
    updatedGuests.splice(index, 1);

    handleUpdateBookingGuests(updatedGuests);
  };

  const getTabularData = (data: string) => {
    const rows = data.split('\n');
    return rows.map((s: string) => s.split('\t'));
  };

  const handleFillGuestsInfo = (index: number, tabularData: string[][], dataIndex: string) => {
    const remainingGuests = maxOccupancy - guests.length;
    let guestsData = JSON.parse(JSON.stringify(guests));
    if (0 < remainingGuests) {
      for (let i = 0; i < remainingGuests; i++) {
        const newGuest: IBookingGuest = EMPTY_GUEST;
        guestsData = [...guestsData, newGuest];
      }
    }
    tabularData.forEach((row: string[], i: number) => {
      if (guestsData[index + i]) {
        guestsData[index + i][dataIndex] = row[0];
      }
    });
    handleUpdateBookingGuests(guestsData);
  };

  const handlePaste = (event: React.ClipboardEvent, index: number, dataIndex: string) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.clipboardData) {
      const clipboardText = event.clipboardData.getData('text');
      const tabularData = getTabularData(clipboardText);
      const guestsData = JSON.parse(JSON.stringify(guests));
      const hasMoreRows = tabularData.length + index > guestsData.length;
      if (hasMoreRows) {
        Modal.confirm({
          title: unitTitle,
          icon: <ExclamationCircleOutlined />,
          content: t('room_type.more_rows_copied'),
          okText: t('general.yes'),
          cancelText: t('general.buttons.cancel'),
          onOk: () => handleFillGuestsInfo(index, tabularData, dataIndex),
        });
      } else {
        tabularData.forEach((row: string[], i: number) => {
          guestsData[index + i][dataIndex] = row[0];
        });
        handleUpdateBookingGuests(guestsData);
      }
    }
  };

  const isAddGuestsEnabled = canBeModified && guests.length < maxOccupancy;

  return (
    <div data-testid="BookingGuests" className={styles.root}>
      {guests?.map((guest: IBookingGuest, index) => (
        <div key={`guest-info-${index}`} className={styles.guest_container} data-testid="GuestInfo">
          <Col span={6}>
            <Input
              placeholder={t('payment_page.payment_form.guest_info.first_name')}
              size="small"
              value={guest?.name || ''}
              onChange={e => handleGuestUpdate(e.target.value, index, 'name')}
              onPaste={e => handlePaste(e, index, 'name')}
              disabled={!canBeModified}
            />
          </Col>
          <Col span={1} className={styles.icon}>
            <Tooltip title={t('general.copy')}>
              {canBeModified && <CopyOutlined onClick={() => handleCopy('name', index)} />}
            </Tooltip>
          </Col>
          <Col span={6}>
            <Input
              placeholder={t('payment_page.payment_form.guest_info.last_name')}
              size="small"
              value={guest?.surname || ''}
              onChange={e => handleGuestUpdate(e.target.value, index, 'surname')}
              onPaste={e => handlePaste(e, index, 'surname')}
              disabled={!canBeModified}
            />
          </Col>
          <Col span={1} className={styles.icon}>
            <Tooltip title={t('general.copy')}>
              {canBeModified && <CopyOutlined onClick={() => handleCopy('surname', index)} />}
            </Tooltip>
          </Col>
          <Col span={7}>
            <Select
              size="small"
              showSearch
              optionFilterProp="children"
              filterOption={true}
              value={guest?.nationality}
              onChange={val => handleGuestUpdate(val, index, 'nationality')}
              className={styles.nationality}
              placeholder={t('payment_page.payment_form.guest_info.nationality')}
              data-testid="GuestNationality"
              disabled={!canBeModified}
            >
              {countries.map(country => (
                <Select.Option key={country.label} value={country.value}>
                  {country.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col span={1} className={styles.icon}>
            <Tooltip title={t('general.copy')}>
              {canBeModified && <CopyOutlined onClick={() => handleCopy('nationality', index)} />}
            </Tooltip>
          </Col>
          {!isCarProperty(propertyType) && (
            <Col span={1} className={styles.icon}>
              <Tooltip title={t('general.delete')}>
                {canBeModified && <DeleteOutlined onClick={() => handleDeleteGuest(index)} />}
              </Tooltip>
            </Col>
          )}
        </div>
      ))}
      {isAddGuestsEnabled && !isCarProperty(propertyType) && (
        <div>
          <Text className={styles.add_guest_button} underline={true} onClick={handleAddGuest}>
            {t('payment_page.payment_form.guest_info.add_guest')}
          </Text>
        </div>
      )}
    </div>
  );
};

export default BookingGuests;
