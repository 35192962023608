import { decode, encode } from 'js-base64';

const get = (key: string): Record<string, string | number> | null | string | number => {
  const item = window.localStorage.getItem(`tbf_reserva_${key}`);

  if (!item) {
    return null;
  }

  return JSON.parse(decode(item));
};

const set = (key: string, data: Record<string, string | number> | null | string | number): void => {
  if (data === undefined) {
    return window.localStorage.removeItem(`tbf_reserva_${key}`);
  }

  window.localStorage.setItem(`tbf_reserva_${key}`, encode(JSON.stringify(data)));
};

const remove = (key: string): void => window.localStorage.removeItem(`tbf_reserva_${key}`);

export default {
  get,
  set,
  remove,
};
