// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Radio } from 'antd';
import classNames from 'classnames';

import IconGuestChild from 'static/icons-guest-child.svg';
import IconGuestDouble from 'static/icons-guest-double.svg';
import IconGuestSingle from 'static/icons-guest-single.svg';
import IconGuestInfant from 'static/icons-guest-infant.svg';

import formatNumber from 'utils/format_price_locale';

import { IGiftCardRatePlanProps } from './gift_card_rate_plan.types';
import styles from './gift_card_rate_plan.module.scss';

const GiftCardRatePlan: React.FC<IGiftCardRatePlanProps> = ({
  ratePlan,
  propertyUnit,
  numberOfNights,
  isSelected,
  onBook,
  isUpsell,
}) => {
  const { t } = useTranslation();
  const { adults, children, infants } = ratePlan.occupancy;
  const adultsOccupancyIcon = adults >= 2 ? IconGuestDouble : IconGuestSingle;

  return (
    <div data-testid="GiftCardRatePlan">
      <Row className={styles.roomTypeInfo} align="middle" justify="center" gutter={16}>
        <Col sm={2} xs={24}>
          {propertyUnit.photos?.[0]?.url && (
            <img
              className={classNames(styles.roomTypePhoto, 'roundedWrapper')}
              src={propertyUnit.photos[0].url}
              alt="Room"
            />
          )}
        </Col>
        <Col sm={propertyUnit?.upsellPrice ? 7 : 10} xs={24} className={styles.roomTypeTitleContainer}>
          <div className={classNames(styles.roomTypeTitle, 'highlighted')}>{propertyUnit.title}</div>
          <div className={styles.roomTypeDescription}>{propertyUnit.description}</div>
        </Col>

        {propertyUnit?.upsellPrice && (
          <Col sm={3} xs={8} className={styles.wrapper}>
            <div className={classNames(styles.roomTypeNights, 'highlighted')}>
              {formatNumber(propertyUnit.upsellPrice)} kr
            </div>
          </Col>
        )}

        <Col sm={4} xs={8} className={styles.roomTypeOccupancy}>
          {0 < adults && (
            <>
              <div className={classNames(styles.occupancyCaption, 'highlighted')}>{adults}</div>
              <img src={adultsOccupancyIcon} alt="adults" className={styles.myImage} />
            </>
          )}

          {0 < children && (
            <>
              <div className={classNames(styles.occupancyCaption, 'highlighted')}>{children}</div>
              <img src={IconGuestChild} alt="children" />
            </>
          )}

          {0 < infants && (
            <>
              <div className={classNames(styles.occupancyCaption, 'highlighted')}>{infants}</div>
              <img src={IconGuestInfant} alt="infants" />
            </>
          )}
        </Col>

        <Col sm={4} xs={8} className={styles.wrapper}>
          <div className={classNames(styles.roomTypeNights, 'highlighted')}>
            {numberOfNights} {1 < numberOfNights ? t('general.nights') : t('general.night')}
          </div>
        </Col>
        <Col sm={4} xs={8} className={styles.selectWrapper}>
          <Radio
            value={ratePlan.id}
            onChange={() => onBook(propertyUnit, ratePlan, isUpsell)}
            checked={isSelected}
            className={styles.selectButton}
          />
        </Col>
      </Row>
    </div>
  );
};

export default GiftCardRatePlan;
