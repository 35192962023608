export default [
  'none',
  'all_inclusive',
  'breakfast',
  'lunch',
  'dinner',
  'american',
  'bed_and_breakfast',
  'buffet_breakfast',
  'carribean_breakfast',
  'continental_breakfast',
  'english_breakfast',
  'european_plan',
  'family_plan',
  'full_board',
  'full_breakfast',
  'half_board',
  'room_only',
  'self_catering',
  'bermuda',
  'dinner_bed_and_breakfast_plan',
  'family_american',
  'breakfast_and_lunch',
  'lunch_and_dinner',
];
