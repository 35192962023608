import ApiActions from 'api_actions';
import { formatUnit, formatUnitFacilities } from './utils/property_units_format';

export const SET_PROPERTY_UNITS_LOADING = 'SET_PROPERTY_UNITS_LOADING';
export const SET_PROPERTY_UNITS = 'SET_PROPERTY_UNITS';

export const SET_PROPERTY_UNIT_LOADING = 'SET_PROPERTY_UNIT_LOADING';
export const SET_PROPERTY_UNIT = 'SET_PROPERTY_UNIT';

export const SET_UNIT_FACILITIES_LOADING = 'SET_UNIT_FACILITIES_LOADING';
export const SET_UNIT_FACILITIES = 'SET_UNIT_FACILITIES';

export const SET_UNIT_UPDATE_LOADING = 'SET_UNIT_UPDATE_LOADING';

export const REMOVE_SELECTED_UNIT = 'REMOVE_SELECTED_UNIT';

export const SET_REMOVE_UNIT_LOADING = 'SET_REMOVE_UNIT_LOADING';

export const RESET_PROPERTY_UNITS = 'RESET_PROPERTY_UNITS';

const setPropertyUnitsLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_UNITS_LOADING });
};

const setPropertyUnits = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_UNITS, payload });
};

const loadPropertyUnits = async (dispatch, params) => {
  setPropertyUnitsLoading(dispatch);

  const data = await ApiActions.getPropertyUnits(params);
  setPropertyUnits(dispatch, data);
};

const setPropertyUnitLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_UNIT_LOADING });
};

const setPropertyUnit = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_UNIT, payload });
};

const loadPropertyUnit = async (dispatch, params) => {
  setPropertyUnitLoading(dispatch);

  const data = await ApiActions.getPropertyUnit(params);
  const formattedData = formatUnit(data);
  setPropertyUnit(dispatch, formattedData);
};

const setRoomTypeFacilitiesLoading = dispatch => {
  return dispatch({ type: SET_UNIT_FACILITIES_LOADING });
};

const setPropertyUnitFacilities = (dispatch, payload) => {
  return dispatch({ type: SET_UNIT_FACILITIES, payload });
};

const loadPropertyUnitFacilities = async (dispatch, params) => {
  setRoomTypeFacilitiesLoading(dispatch);

  const data = await ApiActions.getPropertyUnitFacilities(params);
  const formattedData = formatUnitFacilities(data);

  setPropertyUnitFacilities(dispatch, formattedData);
};

const createPropertyUnit = async (dispatch, params) => {
  setUpdateUnitLoading(dispatch);

  try {
    await ApiActions.createPropertyUnit({ propertyId: params.propertyId, roomType: params });
    setUpdateUnitLoading(dispatch, false);
    await loadPropertyUnits(dispatch, params.propertyId);
  } catch (e) {
    setUpdateUnitLoading(dispatch, false);
    throw e;
  }
};

const setUpdateUnitLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UNIT_UPDATE_LOADING, params });
};

const updatePropertyUnit = async (dispatch, params) => {
  setUpdateUnitLoading(dispatch);

  try {
    await ApiActions.updatePropertyUnit({ propertyId: params.propertyId, roomType: params });
    setUpdateUnitLoading(dispatch, false);
    await loadPropertyUnits(dispatch, params.propertyId);
  } catch (e) {
    setUpdateUnitLoading(dispatch, false);
    throw e;
  }
};

const setRemoveUnitLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_REMOVE_UNIT_LOADING, params });
};

const removePropertyUnit = async (dispatch, params) => {
  setRemoveUnitLoading(dispatch);

  try {
    await ApiActions.deletePropertyUnit(params);
    setRemoveUnitLoading(dispatch, false);
    await loadPropertyUnits(dispatch, params.propertyId);
  } catch (e) {
    setRemoveUnitLoading(dispatch, false);
    throw e;
  }
};

const clearSelectedPropertyUnit = (dispatch, payload) => {
  return dispatch({ type: REMOVE_SELECTED_UNIT, payload });
};

const resetPropertyUnits = (dispatch) => {
  return dispatch({ type: RESET_PROPERTY_UNITS });
};

export const actions = {
  loadPropertyUnits,
  loadPropertyUnit,
  loadPropertyUnitFacilities,
  clearSelectedPropertyUnit,
  createPropertyUnit,
  updatePropertyUnit,
  removePropertyUnit,
  resetPropertyUnits,
};
