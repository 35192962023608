import { DEFAULT_CURRENCY } from "../../../../../constants/defaults";

import {
  RESET_PARAMS,
  SET_BEST_OFFER,
  SET_CHANNEL_ID,
  SET_CLOSED_DATES_DATA,
  SET_CLOSED_DATES_LOADING,
  SET_PARAMS,
  SET_PROPERTY_DATA,
  SET_PROPERTY_TYPE,
  SET_PROPERTY_LOADING,
  SET_PROPERTY_UNITS_DATA,
  SET_PROPERTY_UNITS_LOADING,
  SET_PROPERTY_UNIT_REQUEST_PARAMS,
  SET_PROPERTY_CHANNELS,
  SET_PROPERTY_CHANNELS_LOADING,
  SET_ALL_PARAMS,
} from "./booking_actions";

const EMPTY_ENTITY = {
  data: null,
  isLoading: false,
};

export const INITIAL_STATE = {
  channelId: null,
  propertyType: null,
  property: EMPTY_ENTITY,
  propertyUnitsInfo: EMPTY_ENTITY,
  closedDates: EMPTY_ENTITY,
  propertyChannels: EMPTY_ENTITY,
  params: {
    children: 0,
    adults: 1,
    currency: DEFAULT_CURRENCY,
  },
  unitRequestParams: {},
};

const actionHandlers = {
  [SET_CHANNEL_ID]: (state, action) => {
    return { ...state, channelId: action.payload };
  },
  [SET_PROPERTY_LOADING]: (state) => {
    const property = { ...state.property, isLoading: true };

    return { ...state, property };
  },
  [SET_PROPERTY_DATA]: (state, action) => {
    const property = { data: action.payload, isLoading: false };

    return { ...state, property };
  },
  [SET_PROPERTY_TYPE]: (state, action) => {
    return { ...state, propertyType: action.payload };
  },
  [SET_PROPERTY_UNITS_LOADING]: (state) => {
    const propertyUnitsInfo = { ...state.propertyUnitsInfo, isLoading: true };

    return { ...state, propertyUnitsInfo };
  },
  [SET_PROPERTY_UNITS_DATA]: (state, action) => {
    const propertyUnitsInfo = { data: action.payload, isLoading: false };

    return { ...state, propertyUnitsInfo };
  },
  [SET_CLOSED_DATES_LOADING]: (state) => {
    const closedDates = { ...state.closedDates, isLoading: true };

    return { ...state, closedDates };
  },
  [SET_CLOSED_DATES_DATA]: (state, action) => {
    const closedDates = { data: action.payload, isLoading: false };

    return { ...state, closedDates };
  },
  [SET_PARAMS]: (state, action) => {
    return { ...state, params: action.payload };
  },
  [RESET_PARAMS]: (state) => {
    return { ...state, params: INITIAL_STATE.params };
  },
  [SET_PROPERTY_UNIT_REQUEST_PARAMS]: (state, action) => {
    return { ...state, unitRequestParams: action.payload };
  },
  [SET_BEST_OFFER]: (state, action) => {
    return { ...state, bestOffer: action.payload };
  },
  [SET_PROPERTY_CHANNELS]: (state, action) => {
    const propertyChannels = { data: action.payload, isLoading: false };

    return { ...state, propertyChannels };
  },
  [SET_PROPERTY_CHANNELS_LOADING]: (state) => {
    const propertyChannels = { ...state.propertyChannels, isLoading: true };

    return { ...state, propertyChannels };
  },
  [SET_ALL_PARAMS]: (state, action) => {
    const updatedState = {...state, ...action.payload };
    return { ...updatedState };
  }
};

export const reducer = (state, action) => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
