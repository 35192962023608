import React, { useContext, useEffect, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Footer from "components/footer";
import Header from "components/header";
import SectionWrapper from "components/layout/section_wrapper";
import Loading from "components/loading";
import Navigation from "components/navigation";

import { ReactComponent as AvisLogo } from 'assets/logo/avis.svg';
import { ReactComponent as HertzLogo } from 'assets/logo/hertz.svg';

import {
  AppActionsContext,
  BookingActionsContext,
  BookingDataContext,
  BookingCheckoutDataContext,
} from "containers/data_context";

import styles from './confirmation_page.module.scss';

import NewBookingLink from "./new_booking_link";
import ThankPanel from "./thank_panel";
import isCarProperty from "utils/is_car_property";
import { renderProviderLogo } from "utils/vehicle";

export default function ConfirmationPage() {
  const { value } = useContext(BookingCheckoutDataContext);
  const { channelId, property, propertyType } = useContext(BookingDataContext);
  const bookingActions = useContext(BookingActionsContext);
  const { init } = useContext(AppActionsContext);
  const { bookingId } = useParams();
  const { data: propertyData, isLoading } = property;
  const isPropertyPresent = propertyData && !isLoading;
  const email = value?.customer?.mail;

  useEffect(
    function initApp() {
      const savedBookingParams = bookingActions.getDataFromStorage();

      init(bookingActions, savedBookingParams);
    },
    [bookingActions, init],
  );

  const logo = useMemo(() => renderProviderLogo(propertyData?.provider), [propertyData?.provider])

  const isCar = isCarProperty(propertyType);

  if (!isPropertyPresent) {
    return <Loading />;
  }

  return (
    <div>
      <Header property={propertyData} />
      <SectionWrapper theme="light">
        <Col xs="12">
          <Navigation />
        </Col>
        <Col xs="12">
          <ThankPanel bookingId={bookingId} email={email} />
          {!isCar && <NewBookingLink channelId={channelId} propertyType={propertyType} />}
          {logo && <div className={styles.logoContainer}>
            <div className={styles.logo}>{logo}</div>
          </div>}
        </Col>
      </SectionWrapper>
      <Footer property={propertyData} />
    </div>
  );
}
