// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Button, FormListFieldData, Radio } from 'antd';

import GiftCampaignOptionForm from 'components/gift_campaign_option_form/gift_campaign_option_form';
import { IGiftCampaignOption } from 'components/gift_campaign_option/gift_campaign_option.types';

import GIFT_CAMPAIGN_STATUS from 'constants/gift_campaign_status';

import { IGiftCampaignFormProps } from './gift_campaign_form.types';
import styles from './gift_campaign_form.module.scss';

const { TextArea } = Input;

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const initialValues = {
  name: '',
  description: '',
  status: GIFT_CAMPAIGN_STATUS.CREATED,
  giftOptions: [],
};

const GiftCampaignForm: React.FC<IGiftCampaignFormProps> = ({ giftCampaign, onCancel, isSaving, onSave }) => {
  const { t } = useTranslation();

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const [form] = Form.useForm();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSave = async () => {
    const formValues = form.getFieldsValue(true);

    await onSave(formValues);
  };

  const statusOptions = [
    { value: GIFT_CAMPAIGN_STATUS.CREATED, label: t('general.no') },
    { value: GIFT_CAMPAIGN_STATUS.PUBLISHED, label: t('general.yes') },
  ];

  return (
    <div data-testid="GiftCampaignForm" className={styles.root}>
      <Form
        form={form}
        initialValues={giftCampaign ? giftCampaign : initialValues}
        onFinish={handleSave}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        <Form.Item label={t('general.other.name')} name="name" rules={[{ required: true }]}>
          <Input placeholder={t('general.other.name')} />
        </Form.Item>
        <Form.Item label={t('general.description')} name="description" rules={[{ required: true }]}>
          <TextArea rows={4} placeholder={t('general.description')} />
        </Form.Item>
        {!giftCampaign && (
          <Form.Item name="status" rules={[{ required: true }]} label={t('gift_card.publish_when_created')}>
            <Radio.Group
              options={statusOptions}
              optionType="button"
              buttonStyle="solid"
              data-testid="PublishWhenCreated"
            />
          </Form.Item>
        )}

        <legend>{t('gift_campaign.gift_options')}</legend>
        <Form.List name="giftOptions">
          {(giftOptions, { add, remove }) => {
            return (
              <div>
                {giftOptions.map((field: FormListFieldData, index) => (
                  <GiftCampaignOptionForm
                    key={field.key}
                    field={field}
                    index={index}
                    onRemove={() => remove(field.name)}
                    option={giftCampaign?.giftOptions?.[index] as IGiftCampaignOption}
                  />
                ))}
                <Form.Item>
                  <Button type="primary" onClick={add}>
                    {t('gift_campaign.add_gift_option')}
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={handleCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isSaving}
              disabled={isSaving}
              className={styles.saveButton}
            >
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default GiftCampaignForm;
