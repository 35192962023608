import { useReducer, useCallback, useMemo } from 'react';
import { actions, INITIAL_STATE, reducer } from '../reducers/supplier/users_data';

export default () => {
  const [supplierUsersData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadUsers = useCallback(
    params => {
      return actions.loadUsers(dispatch, params);
    },
    [dispatch],
  );

  const updateUser = useCallback(
    params => {
      return actions.updateUser(dispatch, params);
    },
    [dispatch],
  );

  const createUser = useCallback(
    params => {
      return actions.createUser(dispatch, params);
    },
    [dispatch],
  );

  const updateUserStatus = useCallback(
    params => {
      return actions.updateUserStatus(dispatch, params);
    },
    [dispatch],
  );

  const supplierUsersActions = useMemo(
    () => ({
      loadUsers,
      updateUser,
      createUser,
      updateUserStatus,
    }),
    [loadUsers, updateUser, createUser, updateUserStatus],
  );

  return useMemo(() => ({ supplierUsersData, supplierUsersActions }), [supplierUsersData, supplierUsersActions]);
};
