import React from 'react';

import styles from './overlay.module.scss';
import { IOverlayProps } from './overlay.types';

const Overlay: React.FC<IOverlayProps> = ({ active, children }) => {
  const overlayStyle = [styles.overlay];

  if (!active) {
    overlayStyle.push(styles.hiddenOverlay);
  }

  return (
    <div className={overlayStyle.join(' ')}>
      <div className={styles.overlayContent}>{children}</div>
    </div>
  );
};

export default Overlay;
