import { notification } from 'antd';

const withIcon = (type, message, desc = null) => {
  return notification[type]({ message: message, description: desc });
};

const withPlacement = (type, message, desc, placement) => {
  notification[type]({message: message, description: desc, placement });
};

export default {
  withIcon,
  withPlacement
};
