import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Caption from 'components/caption';
import Currency from 'components/currency';
import Label from 'components/label';

import PropertyUnitDetailsSection from './property_unit_details_section';
import PropertyUnitPhotosSection from './property_unit_photos_section';

import styles from './property_unit_details_modal.module.css';

const FACILITY_DIVIDER = ' · ';

const getMinPrice = ratePlans => {
  if (!ratePlans.length) {
    return null;
  }

  const prices = ratePlans.map(rate => rate.totalPrice);

  return Math.min(...prices);
};

export default function PropertyUnitDetailsModal({ unit, show, onHide }) {
  const { t } = useTranslation();
  const { photos, description, title, facilities, ratePlans = [] } = unit;

  const unitFacilities = useMemo(
    () =>
      facilities?.reduce((acc, facilityGroup) => [...acc, ...facilityGroup.facilities], [])
        .map(facility => {
          return t(`facilities.${facility.toLowerCase()}`);
        })
        .join(FACILITY_DIVIDER),
    [facilities, t],
  );

  const isFacilitiesPresent = Boolean(unitFacilities?.length);
  const minPrice = getMinPrice(ratePlans);
  const currency = ratePlans[0]?.currency;

  return (
    <Modal dialogClassName={styles.modal} show={show} onHide={onHide}>
      <Modal.Body className={styles.modalBody}>
        <PropertyUnitPhotosSection className={styles.photosDesktop} photos={photos} />
        <div className={styles.contentContainer}>
          <Modal.Header className={styles.modalHeader} closeButton>
            <h5>{title}</h5>
          </Modal.Header>
          <PropertyUnitPhotosSection className={styles.photosMobile} photos={photos} />
          {minPrice && (
            <div>
              <Label className={styles.priceLabel}>{t('rates_table.price_from')}</Label>
              <div>
                <Currency className={styles.roomPrice} amount={minPrice} currency={currency} />
              </div>
              <Caption>{t('rates_table.taxes_note')}</Caption>
            </div>
          )}
          {description && (
            <PropertyUnitDetailsSection>
              <div className={styles.description}>{description}</div>
            </PropertyUnitDetailsSection>
          )}
          {isFacilitiesPresent && (
            <PropertyUnitDetailsSection lable={t('rates_table.room_facilities')}>
              <div className={styles.facilities}>{unitFacilities}</div>
            </PropertyUnitDetailsSection>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}
