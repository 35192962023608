import ApiActions from 'api_actions';

export const SET_USERS_LIST = 'SET_USERS_LIST';
export const SET_USERS_LOADING = 'SET_USERS_LOADING';
export const SET_USER_IS_UPDATING = 'SET_USER_IS_UPDATING';
export const SET_UPDATED_USER = 'SET_UPDATED_USER';
export const SET_USER_IS_CREATING = 'SET_USER_IS_CREATING';

const setUsers = (dispatch, payload) => {
  return dispatch({ type: SET_USERS_LIST, payload });
};

const setUsersLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_USERS_LOADING, params });
};

const loadUsers = async (dispatch, params) => {
  try {
    setUsersLoading(dispatch);

    const data = await ApiActions.getUsers(params);

    setUsers(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setUsersLoading(dispatch, false);
  }
};

const setUserIsUpdating = (dispatch, params = true) => {
  return dispatch({ type: SET_USER_IS_UPDATING, params });
};

const setUpdatedUser = (dispatch, params) => {
  return dispatch({ type: SET_UPDATED_USER, params });
};

const updateUser = async (dispatch, params) => {
  try {
    setUserIsUpdating(dispatch);

    const data = await ApiActions.updateUser(params);
    setUpdatedUser(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setUserIsUpdating(dispatch, false);
  }
};

const setUserIsCreating = (dispatch, params = true) => {
  return dispatch({ type: SET_USER_IS_CREATING, params });
};

const createUser = async (dispatch, params) => {
  try {
    setUserIsCreating(dispatch);

    await ApiActions.createUser(params);
  } catch (e) {
    throw e;
  } finally {
    setUserIsCreating(dispatch, false);
  }
};

const updateUserStatus = async (dispatch, params) => {
  try {
    setUserIsUpdating(dispatch);

    const data = await ApiActions.updateUser(params);
    setUpdatedUser(dispatch, data);
  } catch (e) {
    throw e;
  } finally {
    setUserIsUpdating(dispatch, false);
  }
};

export const actions = {
  setUsers,
  setUsersLoading,
  loadUsers,
  updateUser,
  setUpdatedUser,
  setUserIsUpdating,
  setUserIsCreating,
  createUser,
  updateUserStatus,
};
