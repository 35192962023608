// Generated with util/create-component.js
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Dropdown, MenuProps } from 'antd';

import locales from 'locales';

import { ReactComponent as Language } from 'assets/language.svg';

import setUrlParams from 'utils/set_url_params';
import getUrlParams from 'utils/get_url_params';

import { ILocaleSelectDesktopProps } from './locale_select_desktop.types';
import styles from './locale_select_desktop.module.scss';

const LocaleSelectDesktop: React.FC<ILocaleSelectDesktopProps> = ({
  isChangingUrlParam = false,
  showLngIcon = true,
}) => {
  const { i18n } = useTranslation();
  const { language } = i18n;
  const history = useHistory();
  const { locale: defaultLanguage } = getUrlParams() as { locale: string };

  useEffect(() => {
    if (defaultLanguage) {
      i18n.changeLanguage(defaultLanguage);
    }
  }, []);

  let defaultSelectedLanguage = 'en';

  /* Only take the language, not the full locale */
  const usersLocaleLanguage = language.includes('-') ? language.split('-')[0] : language;

  if (locales.find(locale => locale.code === defaultLanguage)) {
    defaultSelectedLanguage = defaultLanguage;
  } else if (locales.find(locale => locale.code === usersLocaleLanguage)) {
    defaultSelectedLanguage = usersLocaleLanguage;
  } else {
    defaultSelectedLanguage = 'en';
  }

  const renderLanguages = (): MenuProps => {
    const menuItems: MenuProps['items'] = [];
    locales.map(({ code, flag, name }) => {
      menuItems.push({
        key: code,
        icon: flag,
        label: name,
        onClick: () => handleLanguageChange(code),
      });
    });

    return { items: menuItems };
  };

  const handleLanguageChange = (code: string) => {
    i18n.changeLanguage(code);
    if (isChangingUrlParam) {
      const params: { locale?: string } = getUrlParams();
      params.locale = code;
      setUrlParams(params, history);
    }
  };

  return (
    <div data-testid="LocaleSelectDesktop" className={styles.root}>
      <Dropdown menu={renderLanguages()}>
        {showLngIcon ? <Language /> : <div className={styles.defaultLanguage}>{defaultSelectedLanguage}</div>}
      </Dropdown>
    </div>
  );
};

export default LocaleSelectDesktop;
