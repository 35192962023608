import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollManager: React.FC = () => {
  const { pathname } = useLocation();

  useEffect(
    function handlePageChange() {
      window.scrollTo(0, 0);
    },
    [pathname],
  );

  return null;
};

export default ScrollManager;
