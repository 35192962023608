import React from "react";

import Cell from "components/layout/cell";

import BedOptions from "./bed_options";
import Description from "./description";
import PropertyUnitAvailability from "./property_unit_availability";
import PropertyUnitFacilities from "./property_unit_facilities";
import PropertyUnitTitle from "./property_unit_title";

import styles from "./property_unit_info.module.scss";

export default function PropertyUnitInfo({ unit }) {
  const { availability, bedOptions, facilities, description } = unit;

  return (
    <Cell className={styles.roomContainer}>
      <PropertyUnitTitle unit={unit} />
      <Description description={description} />
      <PropertyUnitAvailability availability={availability} />
      <BedOptions bedOptions={bedOptions} />
      <PropertyUnitFacilities facilities={facilities} />
    </Cell>
  );
}
