// Generated with util/create-component.js
import React from 'react';

import GiftCardRatePlan from './gift_card_rate_plan/gift_card_rate_plan';
import { IGiftCardUnitProps } from './gift_card_unit.types';

const GiftCardUnit: React.FC<IGiftCardUnitProps> = ({ propertyUnit, numberOfNights, isSelected, onBook, isUpsell }) => {
  return (
    <div data-testid="GiftCardUnit">
      <GiftCardRatePlan
        ratePlan={propertyUnit.ratePlans[0]}
        propertyUnit={propertyUnit}
        onBook={onBook}
        numberOfNights={numberOfNights}
        isSelected={isSelected}
        isUpsell={isUpsell}
      />
    </div>
  );
};

export default GiftCardUnit;
