import React, { useContext, useState } from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import USER_ROLE from 'constants/user_role';

import ContractDiscover from 'components/contracts/contract_discover/contract_discover';
import ActiveContracts from 'components/contracts/active/active_contracts';
import PendingContracts from 'components/contracts/pending/pending_contracts';

import { AuthenticationDataContext } from 'containers/data_context';

import styles from './contracts_page.module.scss';

const contractsTranslationPrefix = 'contracts';

const ContractsPage: React.FC = () => {
  const { t } = useTranslation();

  const { userRole } = useContext(AuthenticationDataContext);

  const [selectedTab, setSelectedTab] = useState('discover');

  const handleTabClick = (tab: string) => {
    setSelectedTab(tab);
  };

  const tabs = [
    {
      label: t(`${contractsTranslationPrefix}.discover`),
      key: 'discover',
      children: <ContractDiscover />,
    },
    {
      label: t(`${contractsTranslationPrefix}.active_contracts`),
      key: 'active',
      children: <ActiveContracts />,
    },
    {
      label: t(`${contractsTranslationPrefix}.pending_contracts`),
      key: 'pending',
      children: <PendingContracts />,
    },
  ];

  return (
    <div className={styles.root}>
      {userRole === USER_ROLE.RESELLER ? (
        <Tabs activeKey={selectedTab} onTabClick={handleTabClick} centered items={tabs} />
      ) : (
        <ActiveContracts />
      )}
    </div>
  );
};

export default ContractsPage;
