// Generated with util/create-component.js
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Form, Input, Select, Button, Menu, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { IPhoto } from 'components/property_photo_details/property_photo_details.types';
import PropertyPhotoGallery from 'components/property_photo_gallery/property_photo_gallery';
import {
  PropertyUnitsDataContext,
  PropertyUnitsActionsContext,
  PropertySettingsDataContext,
} from 'containers/data_context';

import { IPropertyUnitFormProps } from './property_unit_form.types';
import styles from './property_unit_form.module.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const defaultValues = {
  capacity: null,
  content: {
    description: null,
    photos: [],
  },
  countOfRooms: null,
  occAdults: null,
  occChildren: null,
  occInfants: null,
  roomKind: 'room',
  title: null,
  facilities: [],
};

const formItemLayout = {
  labelCol: { sm: { span: 24 }, md: { span: 7 } },
  wrapperCol: { sm: { span: 24 }, md: { span: 17 } },
};

const { TextArea } = Input;
const { Option, OptGroup } = Select;

const PropertyUnitForm: React.FC<IPropertyUnitFormProps> = ({ unitId, onCancel }) => {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [selectedTab, setSelectedTab] = useState('general');

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const {
    unitFacilities: { data: facilities, isLoading: isLoadingFacilities },
    propertyUnit: { data: propertyUnit, isLoading: isLoadingPropertyUnit },
    isUpdatingUnit,
  } = useContext(PropertyUnitsDataContext);

  const getSortedPhotos = (photos: IPhoto[]) => {
    const sortedPhotos = photos
      ?.filter((p: IPhoto) => !p.isRemoved)
      ?.sort((p1: IPhoto, p2: IPhoto) => Number(p1.position) - Number(p2.position));
    return sortedPhotos;
  };

  const [photos, setPhotos] = useState<IPhoto[]>(getSortedPhotos(propertyUnit?.content?.photo));

  const { loadPropertyUnit, loadPropertyUnitFacilities, updatePropertyUnit, createPropertyUnit } =
    useContext(PropertyUnitsActionsContext);

  useEffect(() => {
    if (!selectedProperty) return;
    if (!facilities) {
      loadPropertyUnitFacilities(selectedProperty);
    }
    if (unitId) {
      loadPropertyUnit({ propertyId: selectedProperty, roomTypeId: unitId });
    } else {
      form.setFieldsValue(defaultValues);
    }
  }, []);

  useEffect(() => {
    const newValues = JSON.parse(JSON.stringify(propertyUnit));
    form.setFieldsValue({ ...newValues });
    form.setFieldsValue({
      content: {
        ...newValues?.content,
        photos: {
          ...getSortedPhotos(newValues?.content?.photos || []),
        },
      },
    });
    setPhotos(getSortedPhotos(newValues?.content?.photos || []));
  }, [propertyUnit]);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('room_type.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);
    const roomTypeData = JSON.parse(JSON.stringify(updatedValues));
    if (selectedProperty) {
      roomTypeData.propertyId = selectedProperty;
    }

    if (updatedValues.facilities?.length) {
      roomTypeData.facilities = updatedValues.facilities?.map((f: { value?: string; label?: string }) => {
        if (f.value) return f.value;
        return f;
      });
    }

    roomTypeData.content.photos = photos.map((photo: IPhoto) => ({
      ...photo,
      propertyId: selectedProperty,
      roomTypeId: updatedValues.id,
    }));

    try {
      if (unitId) {
        await updatePropertyUnit(roomTypeData);
      } else {
        await createPropertyUnit(roomTypeData);
      }
      openNotificationWithIcon('success');
      onCancel();
    } catch (e) {
      openNotificationWithIcon('error');
    }
  };

  const validateMessages = {
    required: t('validation_messages.required'),
  };

  const roomKindOptions = [
    { value: 'room', label: t('room_kind.room') },
    { value: 'dorm', label: t('room_kind.dorm') },
  ];

  const menuItems = useMemo(
    () => [
      {
        key: 'general',
        label: t('general.general'),
      },
      {
        key: 'content',
        label: t('general.content'),
      },
    ],
    [],
  );

  if (isLoadingPropertyUnit || isLoadingFacilities || !selectedProperty) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="PropertyUnitForm" className={styles.root}>
      <Menu onClick={e => setSelectedTab(e.key)} selectedKeys={[selectedTab]} mode="horizontal" items={menuItems} />
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={defaultValues}
        {...formItemLayout}
        className={styles.room_type_form}
        validateMessages={validateMessages}
      >
        {selectedTab === 'general' && (
          <>
            <Form.Item name="title" label="Title" rules={[{ required: true }]}>
              <Input placeholder="Title" />
            </Form.Item>
            <Form.Item name="roomKind" label={t('general.room_type')}>
              <Select
                showSearch
                placeholder={t('general.room_type')}
                optionFilterProp="label"
                filterOption={true}
                options={roomKindOptions}
              />
            </Form.Item>
            <Form.Item name="countOfRooms" label="Count of Rooms" rules={[{ required: true }]}>
              <Input type="number" placeholder="Count of Rooms" />
            </Form.Item>
            <legend>{t('general.occupancy_settings')}</legend>
            <div className={styles.occupancy_general_description}>
              <div>{t('general.occupancy_beds')}</div>
              <div className={styles.occupancy_example_description}>{t('general.occupancy_beds_example')}</div>
            </div>
            <Form.Item
              name="occAdults"
              label={t('general.adult_spaces')}
              rules={[{ required: true }]}
              extra={<div className={styles.occupancy_description}>{t('general.occupancy_adults')}</div>}
            >
              <Input type="number" placeholder={t('general.adult_spaces')} />
            </Form.Item>
            <Form.Item
              name="occChildren"
              label={t('general.children_spaces')}
              rules={[{ required: true }]}
              extra={<div className={styles.occupancy_description}>{t('general.occupancy_children')}</div>}
            >
              <Input type="number" placeholder={t('general.children_spaces')} />
            </Form.Item>
            <Form.Item
              name="occInfants"
              label={t('general.cot_spaces')}
              rules={[{ required: true }]}
              extra={<div className={styles.occupancy_description}>{t('general.occupancy_cot')}</div>}
            >
              <Input type="number" placeholder={t('general.cot_spaces')} />
            </Form.Item>
          </>
        )}

        {selectedTab === 'content' && (
          <>
            <Form.Item name={['content', 'description']} label={t('general.description')}>
              <TextArea rows={4} placeholder={t('general.description')} />
            </Form.Item>
            {facilities && (
              <Form.Item label={t('general.facilities')} name="facilities">
                <Select mode="multiple" allowClear showSearch filterOption={true} optionFilterProp="children">
                  {Object.keys(facilities).map((category: string, ind: number) => (
                    <OptGroup label={category.replace('_', ' ')} key={`${category}-${ind}`}>
                      {facilities?.[category]?.map((option: { label: string; value: string }, ind: number) => (
                        <Option key={`${option.label}-${ind}`} value={option.value}>
                          {option.label}
                        </Option>
                      ))}
                    </OptGroup>
                  ))}
                </Select>
              </Form.Item>
            )}
            <legend>{t('general.photos')}</legend>
            <Form.Item noStyle>
              <PropertyPhotoGallery photos={photos} onChange={setPhotos} />
            </Form.Item>
          </>
        )}
        <div className={styles.footer}>
          <Form.Item>
            <Button type="default" onClick={onCancel}>
              {t('link.cancel')}
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdatingUnit} style={{ marginLeft: 20 }}>
              {t('link.save_changes')}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export default PropertyUnitForm;
