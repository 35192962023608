import React, { useContext, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { BookingDataContext } from "containers/data_context";

import getCancellationPolicyText from "utils/get_cancellation_policy_text";
import getPropertyPolicyText from "utils/get_property_policy_text";
import getPaymentPolicyText from "utils/get_payment_policy_text";

import PolicyInfoSection from "./policy_info_section";

const buildSelectedRatesList = (ratesOccupancyPerUnit, roomsData) => {
  if (!ratesOccupancyPerUnit || !roomsData) {
    return [];
  }

  return Object.keys(ratesOccupancyPerUnit).reduce((acc, roomId) => {
    const selectedRoom = roomsData?.find((room) => room.id === roomId);
    const selectedRatesOccupancyById = ratesOccupancyPerUnit[roomId];

    const selectedRates = selectedRoom?.ratePlans?.filter(
      (rate) => selectedRatesOccupancyById[rate.id],
    );

    return [...acc, ...(selectedRates || [])];
  }, []);
};

const buildPoliciesList = (selectedRatesList) => {
  return selectedRatesList
    .map(({ cancellationPolicy }) => {
      if (!cancellationPolicy) {
        return null;
      }

      const cancellationPolicyText = getCancellationPolicyText(cancellationPolicy);
      const paymentPolicyText = getPaymentPolicyText(cancellationPolicy);
      const policyText = [cancellationPolicyText, paymentPolicyText].join(". ");

      return {
        id: cancellationPolicy.id,
        text: policyText,
      };
    })
    .filter(Boolean);
};

const groupPoliciesBySimilarContent = (policiesList) => {
  const groupedPoliciesByContent = policiesList.reduce((acc, policy) => {
    const matchingPolicy = acc[policy.text];

    if (!matchingPolicy) {
      return { ...acc, [policy.text]: policy };
    }

    return acc;
  }, {});

  return Object.values(groupedPoliciesByContent);
};

export default function PolicyInfoModal({ show, onHide }) {
  const { property, propertyUnitsInfo, params } = useContext(BookingDataContext);
  const { t } = useTranslation();

  const { data: roomsData } = propertyUnitsInfo;
  const { data: propertyData } = property;
  const { ratesOccupancyPerUnit } = params;

  const selectedRatesPolicies = useMemo(() => {
    const selectedRatesList = buildSelectedRatesList(ratesOccupancyPerUnit, roomsData);
    const policiesList = buildPoliciesList(selectedRatesList);
    const groupedPoliciesList = groupPoliciesBySimilarContent(policiesList);

    return groupedPoliciesList.map(({ id, text }, index) => (
      <PolicyInfoSection key={`${id}-${index}`} title={t("payment_page.policy_info.policy")} text={text} />
    ));
  }, [t, roomsData, ratesOccupancyPerUnit]);

  const hotelPolicyText = getPropertyPolicyText(propertyData?.propertyPolicy);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <h6>{t("payment_page.policy_info.modal_header")}</h6>
      </Modal.Header>
      <Modal.Body>
        <PolicyInfoSection
          title={t("payment_page.policy_info.hotel_policy")}
          text={hotelPolicyText}
        />
        {selectedRatesPolicies}
      </Modal.Body>
    </Modal>
  );
}
