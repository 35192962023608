import React from "react";

import Separator from "components/layout/separator";

import styles from "./property_unit_details_section.module.css";

export default function PropertyUnitDetailsSection({ lable, children }) {
  return (
    <>
      <div className={styles.unitDetailsContainer}>
        <h6 className={styles.unitDetailsHeader}>{lable}</h6>
        <Separator />
        <div className={styles.unitDetailsContent}>{children}</div>
      </div>
    </>
  );
}
