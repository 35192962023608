import { useReducer, useCallback, useMemo } from 'react';
import { actions, INITIAL_STATE, reducer } from './reducers/reseller_contracts_data';

export default () => {
  const [resellerContractsData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadResellerDiscoverContractsList = useCallback(
    params => {
      return actions.loadResellerDiscoverContractsList(dispatch, params);
    },
    [dispatch],
  );

  const clearResellerOpenUnitTypesList = useCallback(
    params => {
      return actions.clearResellerOpenUnitTypesList(dispatch, params);
    },
    [dispatch],
  );

  const createContractRequest = useCallback(
    params => {
        return actions.createContractRequest(dispatch, params);
    },
    [dispatch],
    );

  const resellerCreateContract = useCallback(
    params => {
      return actions.resellerCreateContract(dispatch, params);
    },
    [dispatch],
  );

  const loadResellerContractsUnitRatesList = useCallback(
    params => {
      return actions.loadResellerContractsUnitRatesList(dispatch, params);
    },
    [dispatch],
  );

  const loadContractsList = useCallback(
    params => {
      return actions.loadResellerContractsList(dispatch, params);
    },
    [dispatch],
  );

  const acceptContract = useCallback(
    params => {
      return actions.acceptResellerContract(dispatch, params);
    },
    [dispatch],
  );

  const terminateContract = useCallback(
    params => {
      return actions.terminateResellerContract(dispatch, params);
    },
    [dispatch],
  );

  const declineContract = useCallback(
    params => {
      return actions.declineResellerContract(dispatch, params);
    },
    [dispatch],
  );

  const cancelContractProposal = useCallback(
    params => {
      return actions.cancelResellerContractProposal(dispatch, params);
    },
    [dispatch],
  );

  const resetContracts = useCallback(() => {
    return actions.resetContracts(dispatch);
  }, [dispatch]);

  const resellerContractsActions = useMemo(
    () => ({
      loadResellerDiscoverContractsList,
      loadResellerContractsUnitRatesList,
      clearResellerOpenUnitTypesList,
      resellerCreateContract,
      loadContractsList,
      acceptContract,
      terminateContract,
      declineContract,
      cancelContractProposal,
      createContractRequest,
    }),
    [
      loadResellerDiscoverContractsList,
      loadResellerContractsUnitRatesList,
      clearResellerOpenUnitTypesList,
      resellerCreateContract,
      loadContractsList,
      acceptContract,
      terminateContract,
      declineContract,
      cancelContractProposal,
      createContractRequest,
    ],
  );

  return useMemo(() => ({ resellerContractsData, resellerContractsActions }), [resellerContractsData, resellerContractsActions]);
};
