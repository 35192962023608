import React from 'react';

import { ICurrencyProps } from './currency.types';

const DECIMAL_PLACES = 2;

const Currency: React.FC<ICurrencyProps> = ({ amount, currency = '', className = '' }) => {
  const fixedAmount = Number(amount).toFixed(DECIMAL_PLACES);

  return <span className={className}>{`${fixedAmount} ${currency}`}</span>;
};

export default Currency;
