import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';

import isCarProperty from 'utils/is_car_property';

import LinkButton from 'components/buttons/link_button';
import Panel from 'components/layout/panel';
import { IOccupancy } from 'components/bookings_list/bookings_list.types';

import Guest from './guest';
import { IGuestInfoProps } from './guest_info.types';
import styles from './guest_info.module.scss';

const TRANSLATION_PATH = 'payment_page.payment_form.guest_info';

const GuestInfo: React.FC<IGuestInfoProps> = ({ units, addGuestForRoom, deleteGuest, propertyType }) => {
  const { t } = useTranslation();
  const isCar = useMemo(() => isCarProperty(propertyType), [propertyType]);

  const guestCanBeAdded = (ind: number, occupancy?: IOccupancy) => {
    if (isCar || !occupancy) return false;
    const { adults = 0, children = 0, infants = 0 } = occupancy;
    return Number(units?.[ind]?.guests?.length) < adults + children + infants;
  };

  return (
    <Panel title={t(`${TRANSLATION_PATH}_${propertyType}.title`)}>
      <>
        {units.map((unit, unitIndex) => (
          <Card
            key={`unit=${unitIndex}`}
            title={unit.title}
            className={styles.roomContainer}
            headStyle={{ fontSize: 14, fontWeight: 500, height: 40 }}
          >
            {unit?.guests?.map((_guest, guestIndex) => (
              <div key={`guest-container-${unitIndex}-${guestIndex}`}>
                <Guest
                  key={`guest-${unitIndex}-${guestIndex}`}
                  isDeleteEnabled={!isCar && Boolean(guestIndex)}
                  unitIndex={unitIndex}
                  guestIndex={guestIndex}
                  readOnly={false}
                  onDelete={() => deleteGuest(unitIndex, guestIndex)}
                  isCar={isCar}
                />
              </div>
            ))}
            {guestCanBeAdded(unitIndex, unit?.occupancy) && (
              <LinkButton onClick={() => addGuestForRoom(unitIndex)}>
                {isCar ? t(`${TRANSLATION_PATH}.add_driver`) : t(`${TRANSLATION_PATH}.add_guest`)}
              </LinkButton>
            )}
          </Card>
        ))}
      </>
    </Panel>
  );
};

export default GuestInfo;
