export const formatUnit = roomTypeData => {
  if (!roomTypeData) return;
  const { relationships, ...params } = roomTypeData;

  const formattedData =  {
    ...params,
    facilities: formatFacilitiesList(relationships?.facilities),
  }
  return formattedData;
}

export const formatFacilitiesList = facilities => {
  return facilities?.data?.reduce((list, facility) => {
    list.push({ value: facility?.attributes?.id, label: facility?.attributes?.title });
    return list;
  }, []);
};

export const formatUnitFacilities = facilities => {
  return facilities?.reduce((list, facility) => {
    if (!list?.[facility?.category]) {
      list[facility?.category] = [];
    }
    list[facility.category].push({ value: facility?.id, label: facility?.title });
    return list;
  }, {});
};
