// Generated with util/create-component.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'antd';

import isAccommodationProperty from 'utils/is_accommodation_property';

import { IEditableBookingNotesProps } from './editable_booking_notes.types';
import styles from './editable_booking_notes.module.scss';

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 6 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 18 } },
};

const { TextArea } = Input;

const EditableBookingNotes: React.FC<IEditableBookingNotesProps> = ({ booking, onEdit }) => {
  const { propertyType, notes } = booking;

  const { t } = useTranslation();

  const [form] = Form.useForm();

  const delimiterIndex = notes?.indexOf('External ID');
  const bookingDetails = notes?.substring(0, delimiterIndex);
  let bookingUnitsDetails = '';

  const isAccommodation = isAccommodationProperty(propertyType);

  if (isAccommodation) {
    const delimiterIndex = notes?.indexOf('Room/s');
    bookingUnitsDetails = notes?.substring(delimiterIndex);
  } else {
    const delimiterIndex = notes?.indexOf('Car/s');
    bookingUnitsDetails = notes?.substring(delimiterIndex);
  }

  const initialValues = {
    externalReferenceId: booking.externalReferenceId,
    reservationName: booking.reservationName,
    specialRequest: booking.specialRequest,
  };

  const handleEdit = async (): Promise<void> => {
    const bookingNotes = form.getFieldsValue(true);

    onEdit(bookingNotes);
  };

  return (
    <div data-testid="EditableBookingNotes" className={styles.root}>
      {bookingDetails}
      <Form form={form} initialValues={initialValues} {...formItemLayout} labelAlign="left" onChange={handleEdit}>
        <Form.Item label={t('bookings_list.external_id_title')} name="externalReferenceId">
          <Input placeholder={t('bookings_list.external_id_title')} />
        </Form.Item>
        <Form.Item label={t('general.reservation_name')} name="reservationName">
          <Input placeholder={t('general.reservation_name')} />
        </Form.Item>
        <Form.Item label={t('payment_page.payment_form.customer_info.special_request')} name="specialRequest">
          <TextArea
            rows={4}
            name="specialRequest"
            placeholder={t('payment_page.payment_form.customer_info.special_request')}
          />
        </Form.Item>
      </Form>
      <div>{bookingUnitsDetails}</div>
    </div>
  );
};

export default EditableBookingNotes;
