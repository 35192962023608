// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Row, Col, Button } from 'antd';
import classNames from 'classnames';

import buildPath from 'utils/build_path';
import routes from 'routing/routes';

import { IGiftCardPropertiesListProps, IGiftCardProperty } from './gift_card_properties_list.types';
import styles from './gift_card_properties_list.module.scss';

const GiftCardPropertiesList: React.FC<IGiftCardPropertiesListProps> = ({ properties, giftCardId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getItemSize = () => {
    if (window.innerWidth <= 750) return 24;
    if (750 <= window.innerWidth && window.innerWidth < 1100) return 12;
    if (1500 <= window.innerWidth && window.innerWidth <= 1700) return 6;
    if (1000 <= window.innerWidth && window.innerWidth <= 1950) return 8;
    if (1850 < window.innerWidth && window.innerWidth < 2000) return 6;

    return 6;
  };

  const [itemSize, setItemSize] = useState<number>(getItemSize());

  const handleRedirectToBookingPage = (propertyId: IGiftCardProperty['propertyId']) => {
    const path = buildPath('?selected_property=true', routes.giftCardStoreBookingPage, {
      selectedProperty: propertyId,
      giftCardNumber: giftCardId,
    });

    history.push(path);
  };

  window.addEventListener('resize', () => {
    const size = getItemSize();
    setItemSize(size);
  });

  return (
    <div data-testid="GiftCardPropertiesList" className={styles.root}>
      <Row>
        {properties?.map((property: IGiftCardProperty) => (
          <Col key={`property-${property.propertyId}`} md={itemSize} data-testid="PropertyDetails">
            <Row className={styles.propertyContainer} justify="space-between">
              <Col span={24}>
                <Row>
                  <Col span={24} className={styles.imageContainer}>
                    <img src={property.photoUrl} alt="Property" />
                  </Col>
                  <Col span={24} className={classNames(styles.propertyName, 'highlighted')}>
                    {property.name}
                  </Col>
                  <Col span={24} className={styles.propertyDescription}>
                    {property.description}
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Row className={styles.buttonContainer}>
                  <Col span={24}>
                    <Button
                      shape="round"
                      type="primary"
                      className={styles.chooseButton}
                      onClick={() => handleRedirectToBookingPage(property.propertyId)}
                    >
                      {t('general.choose')}
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default GiftCardPropertiesList;
