import USER_ROLE from 'constants/user_role';

const checkLockSupplier = (user, selectedProperty) => {
  let selectedSupplier;
  
  if (selectedProperty) {
    selectedSupplier = user.suppliers.filter( supp => supp.propertyId === selectedProperty)[0];
  } else {
    selectedSupplier = user.suppliers[0];
  }
  return selectedSupplier.stripeLocked;
};

const checkWarningSupplier = (user, selectedProperty) => {
  let selectedSupplier;
  
  if (selectedProperty) {
    selectedSupplier = user.suppliers.filter( supp => supp.propertyId === selectedProperty)[0];
  } else {
    selectedSupplier = user.suppliers[0];
  }
  return selectedSupplier.stripeWarning;
};

const checkLockReseller = (user) => {
  const selectedReseller = user?.reseller;
  return selectedReseller?.stripeLocked;
};

const checkWarningReseller = (user) => {
  const selectedReseller = user?.reseller;
  return selectedReseller?.stripeWarning;
};

const checkLock = (user, userRole, propertyId) => {
  if (user?.role === USER_ROLE.ADMIN) return false;
  if (userRole === USER_ROLE.RESELLER) return checkLockReseller(user);
  return checkLockSupplier(user, propertyId);
};

const checkWarning = (user, userRole, propertyId) => {
  if (userRole === USER_ROLE.RESELLER) return checkWarningReseller(user);
  return checkWarningSupplier(user, propertyId);
};


export default {
  checkLockSupplier,
  checkLockReseller,
  checkWarningReseller,
  checkWarningSupplier,
  checkLock,
  checkWarning,
};