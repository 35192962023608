import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { notification } from 'antd';

import countries from 'constants/countries';
import HeaderSearch from 'components/header_search';
import PropertiesList from 'components/properties_list';
import PropertiesSearchMap from 'components/properties_search_map';
import PropertyPreview from 'components/property_preview';

import { AppDataContext, SearchActionsContext, SearchDataContext, BookingActionsContext } from 'containers/data_context';

import routes from 'routing/routes';

import { DEFAULT_CURRENCY } from 'constants/defaults';
import dateFormatter from 'utils/date_formatter';
import isCarProperty from 'utils/is_car_property';
import getBookingParamsFromUrl from 'utils/get_booking_params_from_url';
import { encodeMapParams } from 'utils/map_params';
import setUrlParams from 'utils/set_url_params';

import styles from './search_page.module.scss';

const DEBOUNCE_MAP_TIME = 500;

export default function SearchPage() {
  const { t } = useTranslation();
  const { featureFlags } = useContext(AppDataContext);
  const [selectProperty, setSelectProperty] = useState(null);
  const [searchParams, setSearchParams] = useState(null);
  const [highlightedProperties, setHighlightedProperties] = useState({});
  const [shouldUpdateCoordinates, setShouldUpdateCoordinates] = useState(false);

  const history = useHistory();
  const { loadSuppliersList, clearPropertiesList } = useContext(SearchActionsContext);
  const { properties, allProperties, allCars } = useContext(SearchDataContext);

  const { data: propertiesData, isLoading: isLoadingProperties, isLoadingAdditionalProperties } = properties;
  const { data: allPropertiesData, isLoading: isLoadingAllProperties } = allProperties;
  const { data: allCarsData, isLoading: isLoadingAllCars } = allCars;

  useEffect(() => {
    clearPropertiesList();
    return () => {
      clearPropertiesList();
      setUrlParams({}, history, ['mapCoordinates']);
    };
  }, []);

  const isCarSearch = useMemo(() => isCarProperty(searchParams?.type), [searchParams?.type]);


  const onSearch = useCallback(
    _.debounce(requestParams => {
      const {
        mapCoordinates,
        type,
        title,
        city,
        state,
        country,
        pickupDate,
        dropoffDate,
        checkinDate,
        checkoutDate,
        ...restParams
      } = requestParams;

      let filter = {};
      if (mapCoordinates) filter = { ...mapCoordinates };
      if (title) filter.title = { has: title };
      if (city) filter.city = city;
      if (state) filter.state = state;
      if (country) filter.country = country;

      const formattedDates = {};

      if (isCarProperty(type)) {
        if (pickupDate) {
          formattedDates.pickupDate = dateFormatter.toApi(pickupDate);
        }

        if (dropoffDate) {
          formattedDates.dropoffDate = dateFormatter.toApi(dropoffDate);
        }
      } else {
        if (checkinDate) {
          formattedDates.checkinDate = dateFormatter.toApi(checkinDate);
        }

        if (checkoutDate) {
          formattedDates.checkoutDate = dateFormatter.toApi(checkoutDate);
        }
      }

      loadSuppliersList(type, { ...restParams, ...formattedDates }, filter).catch(() =>
        notification['error']({
          message: t('general.error_message'),
          description: t('general.error_description'),
        }),
      );
    }, DEBOUNCE_MAP_TIME),
    [loadSuppliersList],
  );

  useEffect(
    function initParamsFromUrl() {
      if (searchParams) {
        return;
      }

      const parsedParams = getBookingParamsFromUrl();

      const activeCurrency = parsedParams.currency || DEFAULT_CURRENCY;

      const newSearchParams = { ...parsedParams, currency: activeCurrency };
      setSearchParams(newSearchParams);
      onSearch(newSearchParams);
    },
    [searchParams, onSearch],
  );

  const handleCoordinatesChange = marginBounds => {
    if (!shouldUpdateCoordinates) {
      setShouldUpdateCoordinates(true);
      return;
    }

    const isSameLocation = _.isEqual(marginBounds, searchParams.mapCoordinates);
    const newSearchParams = { ...searchParams, mapCoordinates: marginBounds };
    const mapCoordinates = encodeMapParams(marginBounds);

    if (isSameLocation) {
      return;
    }

    setSearchParams(newSearchParams);
    setUrlParams({ mapCoordinates }, history);

    if (!searchParams.mapCoordinates) return;

    onSearch(newSearchParams);
  };

  const onClearSelectProperty = useCallback(() => {
    setSelectProperty(null);
  }, [setSelectProperty]);

  const handleDatesChange = useCallback(
    dates => {
      const newSearchParams = {
        ...searchParams,
      };

      const formattedDates = {};
      const removeParams = [];

      if (dates?.[0]) {
        const formattedDate = dateFormatter.toApi(dates[0]);
        newSearchParams.checkinDate = formattedDate;
        formattedDates.checkinDate = formattedDate;
      } else {
        delete newSearchParams.checkinDate;
        removeParams.push('checkinDate');
      }

      if (dates?.[1]) {
        const formattedDate = dateFormatter.toApi(dates[1]);
        newSearchParams.checkoutDate = formattedDate;
        formattedDates.checkoutDate = formattedDate;
      } else {
        if (isCarSearch && dates?.[0]) {
          // 'rooms_list' endpoint fails unless both checkinDate and checkoutDate are defined
          // default checkoutDate will be set to checkingDate + 1 day if not defined

          const formattedDate = dateFormatter.toApi(dates[0].clone().add(7, 'd'));
          newSearchParams.checkoutDate = formattedDate;
          formattedDates.checkoutDate = formattedDate;
        } else {
          delete newSearchParams.checkoutDate;
          removeParams.push('checkoutDate');
        }
      }

      setSearchParams(newSearchParams);
      setUrlParams(formattedDates, history, removeParams);

      if ((newSearchParams.checkinDate && newSearchParams.checkoutDate)
        || (!newSearchParams.checkinDate && !newSearchParams.checkoutDate)) {
        onSearch(newSearchParams);
      }
    },
    [searchParams, history, onSearch],
  );

  const handleChangeOccupancy = useCallback(
    (value, name) => {
      const newSearchParams = { ...searchParams, [name]: value };

      setUrlParams({ [name]: value }, history);
      setSearchParams(newSearchParams);
      onSearch(newSearchParams);
    },
    [searchParams, onSearch, history],
  );

  const handleCurrencyChange = useCallback(
    currency => {
      const newSearchParams = { ...searchParams, currency };

      setUrlParams({ currency }, history);
      setSearchParams(newSearchParams);
      onSearch(newSearchParams);
    },
    [searchParams, onSearch, history],
  );

  const handlePlaceChange = useCallback(
    (value, option) => {
      setShouldUpdateCoordinates(false);
      const placeOptions = ['title', 'city', 'state', 'country', 'mapCoordinates'];
      const removeParams = placeOptions.filter(place => place !== option.type);

      const newSearchParams = { ...searchParams };
      placeOptions.map(place => delete newSearchParams[place]);
      if (option.type) {
        if (option.type === 'country') {
          const countryCode = countries.find(c => c.name === value)?.value;
          setUrlParams({ [option.type]: countryCode }, history, removeParams);
          newSearchParams[option.type] = countryCode;
        } else {
          setUrlParams({ [option.type]: value }, history, removeParams);
          newSearchParams[option.type] = value;
        }
      } else {
        setUrlParams({}, history, removeParams);
      }

      setSearchParams(newSearchParams);
      onSearch(newSearchParams);
    },
    [searchParams, onSearch, history],
  );

  const handlePropertyHighlight = useCallback(
    item => {
      setHighlightedProperties({ ...highlightedProperties, [item.id]: true });
    },
    [highlightedProperties],
  );

  const handlePropertyShadow = useCallback(
    item => {
      setHighlightedProperties({ ...highlightedProperties, [item.id]: false });
    },
    [highlightedProperties],
  );

  if (!searchParams) {
    return null;
  }

  if (!featureFlags.searchPageIsActive) {
    return <Redirect to={routes.homePage} />;
  }

  return (
    <div className={styles.root}>
      <HeaderSearch
        searchParams={searchParams}
        handleDatesChange={handleDatesChange}
        handleCurrencyChange={handleCurrencyChange}
        handleChangeOccupancy={handleChangeOccupancy}
        handlePlaceChange={handlePlaceChange}
        isLoadingProperties={isCarSearch ? isLoadingAllCars : isLoadingAllProperties}
        propertiesData={isCarSearch ? allCarsData : allPropertiesData}
      />
      <div className={styles.wrapper}>
        {!isCarSearch && <div className={styles.left}>
          {selectProperty && (
            <PropertyPreview
              currency={searchParams.currency}
              property={selectProperty}
              onClearSelectProperty={onClearSelectProperty}
            />
          )}
          <PropertiesSearchMap
            defaultBounds={searchParams.mapCoordinates}
            properties={propertiesData}
            onChangeCallback={handleCoordinatesChange}
            onSelectProperty={setSelectProperty}
            highlightedProperties={highlightedProperties}
            onMarkerMouseOver={handlePropertyHighlight}
            onMarkerMouseOut={handlePropertyShadow}
          />
        </div>}
        <div className={styles.right}>
          <PropertiesList
            loading={isLoadingProperties}
            isLoadingAdditionalProperties={isLoadingAdditionalProperties}
            currency={searchParams.currency}
            properties={propertiesData}
            onSelectProperty={setSelectProperty}
            highlightedProperties={highlightedProperties}
            onPropertyMouseOver={handlePropertyHighlight}
            onPropertyMouseOut={handlePropertyShadow}
            showBestOffer={searchParams.checkinDate && searchParams.checkoutDate}
            propertyType={searchParams.type}
          />
        </div>
      </div>
    </div>
  );
}
