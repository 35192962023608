// Generated with util/create-component.js
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, Popover, Tooltip, Popconfirm } from 'antd';
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons';

import PropertyPhotoDetails from 'components/property_photo_details/property_photo_details';
import { PropertySettingsActionsContext, PropertySettingsDataContext } from 'containers/data_context';
import { IPhoto } from 'components/property_photo_details/property_photo_details.types';

import { IPropertyPhotoGalleryProps } from './property_photo_gallery.types';
import styles from './property_photo_gallery.module.scss';

const PropertyPhotoGallery: React.FC<IPropertyPhotoGalleryProps> = ({
  photos,
  onChange,
  maxNumberOfPhotos = 100,
  multiple = true,
  showDetails = true,
}) => {
  const [draggedPhoto, setDraggedPhoto] = useState<IPhoto['uid'] | null>(null);
  const [photosList, setPhotosList] = useState(photos);

  useEffect(() => {
    const filteredPhotos = photos?.filter((photo: IPhoto) => !photo.isRemoved);
    setPhotosList(filteredPhotos);
  }, [photos]);

  const { t } = useTranslation();
  const propertySettingsActions = useContext(PropertySettingsActionsContext);
  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const [visiblePhotoDetails, togglePhotoDetailsVisibility] = useState<IPhoto | null>(null);
  const [isUploadingPhoto, toggleUploadingPhoto] = useState<boolean>(false);

  const renderPhotoDetails = (photo: IPhoto) => {
    if (JSON.stringify(photo) === JSON.stringify(visiblePhotoDetails)) {
      return (
        <PropertyPhotoDetails
          photo={photo}
          onChange={handlePhotoDetailsChange}
          onClose={() => togglePhotoDetailsVisibility(null)}
        />
      );
    }
  };

  const handlePhotoDetailsChange = (photo: IPhoto) => {
    const photoIndex = photos.findIndex(p => p.url === photo.url);
    const updatedPhotos = JSON.parse(JSON.stringify(photos));
    updatedPhotos[photoIndex] = photo;
    onChange(updatedPhotos);
    togglePhotoDetailsVisibility(null);
  };

  const handlePhotoDetailsVisibility = (photo: IPhoto, visible: boolean) => {
    if (!visible) {
      togglePhotoDetailsVisibility(null);
    }
    togglePhotoDetailsVisibility(photo);
  };

  const handlePhotoRemove = (photo: IPhoto) => {
    const ind = photos.findIndex(p => p.url === photo.url);
    const updatedPhotos = JSON.parse(JSON.stringify(photos));
    if (ind === -1) return;
    if (updatedPhotos[ind].isRemoved === false) {
      updatedPhotos.splice(ind, 1);
    } else {
      updatedPhotos[ind].isRemoved = true;
      delete updatedPhotos[ind].position;
    }

    onChange(updatedPhotos);
  };

  const handleReorderPhotos = (droppedPhoto: IPhoto['uid']) => {
    if (!draggedPhoto) return;

    const draggedPhotoIndex = photos.findIndex(photo => photo.uid === draggedPhoto);
    const droppedPhotoIndex = photos.findIndex(photo => photo.uid === droppedPhoto);

    let updatedPhotos = JSON.parse(JSON.stringify(photos));
    if (draggedPhotoIndex !== -1) {
      updatedPhotos.splice(draggedPhotoIndex, 1);
      updatedPhotos.splice(droppedPhotoIndex, 0, photos[draggedPhotoIndex]);
    }

    updatedPhotos = updatedPhotos.map((photo: IPhoto, index: number) => {
      photo.position = index;
      return photo;
    });

    onChange(updatedPhotos);
    setDraggedPhoto(null);
  };

  const renderItem = (photo: IPhoto) => {
    return (
      <div
        data-testid="PropertyPhoto"
        className={styles.property_photo_container}
        draggable="true"
        onDrag={() => setDraggedPhoto(photo.uid)}
        onDragOver={event => event.preventDefault()}
        onDrop={() => handleReorderPhotos(photo.uid)}
      >
        <Tooltip title={t('photo.remove_photo_title')}>
          <Popconfirm
            title={
              <>
                <p>{t('general.confirm')}</p>
                <p>{t('photo.remove_photo')}</p>
              </>
            }
            cancelText={t('link.cancel')}
            okText={t('general.yes')}
            placement="bottomRight"
            onConfirm={() => handlePhotoRemove(photo)}
          >
            <div className={styles.delete_photo_button}>
              <DeleteOutlined />
            </div>
          </Popconfirm>
        </Tooltip>
        <img src={photo.url} className={styles.property_photo_img} alt="Property" />
        {showDetails && (
          <Popover
            content={() => renderPhotoDetails(photo)}
            title={t('photo.information')}
            trigger="click"
            open={JSON.stringify(photo) === JSON.stringify(visiblePhotoDetails)}
            onOpenChange={visible => handlePhotoDetailsVisibility(photo, visible)}
          >
            <div className={styles.photo_details}>
              <div className={styles.photo_description}>{photo.description}</div>{' '}
              <div className={styles.edit_photo_details}>
                <EditOutlined />
              </div>
            </div>
          </Popover>
        )}
      </div>
    );
  };

  const handleUploadedImages = async ({ fileList }: { fileList: IPhoto[] }) => {
    if (fileList?.length > photosList?.length) {
      const additionalNumOfPhotos = fileList?.length - photosList?.length;
      const newPhotos = fileList.slice(-additionalNumOfPhotos);
      if (newPhotos?.length) {
        toggleUploadingPhoto(true);

        if (!isUploadingPhoto) {
          const data = await Promise.all(
            newPhotos.map((photo: IPhoto) => {
              const formData = new FormData();
              formData.append('image', photo?.originFileObj || '');
              return propertySettingsActions.uploadPropertyPhoto({ image: formData, propertyId: selectedProperty });
            }),
          );

          if (data?.length) {
            const allPhotos = JSON.parse(JSON.stringify(photos));
            data.map((photo: { publicUrl: string }, ind: number) => {
              allPhotos.push({
                url: photo.publicUrl,
                author: null,
                description: null,
                kind: 'ad',
                isRemoved: false,
                position: photos.length + ind,
              });
            });

            onChange(allPhotos);
          }
        }

        toggleUploadingPhoto(false);
      }
    }
  };

  return (
    <div data-testid="PropertyPhotoGallery" className={styles.root}>
      <Upload
        itemRender={(originNode, file) => renderItem(file)}
        listType="picture-card"
        multiple={multiple}
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        fileList={photosList as any}
        onChange={handleUploadedImages}
        accept={'.png,.jpeg,.jpg'}
        beforeUpload={() => false}
      >
        {isUploadingPhoto ? (
          <div className={styles.add_new_photo}>
            <LoadingOutlined className={styles.add_photo} />
            <div>{t('photo.uploading')}</div>
          </div>
        ) : (
          maxNumberOfPhotos > photosList?.length && (
            <div className={styles.add_new_photo}>
              <PlusCircleOutlined className={styles.add_photo} />
              <div>{t('photo.add_new')}</div>
            </div>
          )
        )}
      </Upload>
    </div>
  );
};

export default PropertyPhotoGallery;
