import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import PriceBreakdown from 'components/search_section/price_breakdown';
import TotalPrice from 'components/search_section/total_price';

import Dates from '../dates';
import Guests from '../guests';
import Title from '../title';

import { renderProviderLogo } from 'utils/vehicle';
import isAccommodationProperty from 'utils/is_accommodation_property';

import PropertyPhoto from './property_photo';

import styles from './summary.module.scss';
import { ISummaryProps } from './summary.types';

const Summary: React.FC<ISummaryProps> = ({ params, property, selectedRatesPerUnit, total }) => {
  const { t } = useTranslation();
  const { checkinDate, checkoutDate, currency } = params;

  const providerLogo = useMemo(() => renderProviderLogo(property.provider), [property.provider]);

  return (
    <div className={styles.summaryMainWrapper}>
      <div className={styles.title}>
        <h6>{t('payment_page.booking_summary.title')}</h6>
      </div>
      <PropertyPhoto photos={property.photos} />
      <div className={styles.summaryWrapper}>
        <Title property={property} />
        <Dates checkinDate={checkinDate} checkoutDate={checkoutDate} propertyType={params.type} />
        {isAccommodationProperty(params.type) && <Guests params={params} />}
        <PriceBreakdown selectedRatesPerUnit={selectedRatesPerUnit} currency={currency} propertyType={params.type} />
        <TotalPrice totalPrice={total} currency={currency} />
        {providerLogo && (
          <>
            <Divider className={styles.divider} />
            <div className={styles.providerLogo}>{providerLogo}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default Summary;
