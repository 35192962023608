// Generated with util/create-component.js
import React from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { IGiftCardPropertyInfoProps } from './gift_card_property_info.types';
import styles from './gift_card_property_info.module.scss';

const GiftCardPropertyInfo: React.FC<IGiftCardPropertyInfoProps> = ({ propertyInfo }) => {
  if (!propertyInfo) return null;

  const {
    title,
    content: { description },
  } = propertyInfo;

  return (
    <div data-testid="GiftCardPropertyInfo" className={styles.propertyInfoSection}>
      <div className={classNames(styles.hotelTitle, 'highlighted')}>{title}</div>
      <div className={styles.locationContainer}>
        <EnvironmentOutlined className={styles.locationIcon} />
        <div className={styles.hotelAddress}>
          {propertyInfo.address}, {propertyInfo.city}, {propertyInfo.zipCode}
        </div>
      </div>
      <div className={styles.hotel_description}>{description}</div>
    </div>
  );
};

export default GiftCardPropertyInfo;
