export default {
  background: { fillColor: '#fff9f2' },
  giftCardHeader: {
    font: 'Bianco Serif Bold',
    fontSize: 65,
    textAlign: 'center',
    text: 'Gift Card',
  },
  giftCardTitle: {
    font: 'Bianco Serif',
    fontSize: 35,
    textAlign: 'center',
    text: 'Gift Card Title',
  },
  giftCardDescription: {
    font: 'Bianco Serif',
    fontSize: 14,
    textAlign: 'center',
  },
  lineAboveGiftCardCode: {
    fontSize: 1,
    fillColor: '#000000',
    textAlign: 'center',
  },
  giftCardCode: {
    font: 'Bianco Serif',
    fontSize: 14,
    textAlign: 'center',
    text: 'Gift Card ID: ',
  },
  expirationDate: {
    font: 'Bianco Serif',
    fontSize: 14,
    textAlign: 'center',
    text: 'Expiry Date: ',
  },
  lineBelowExpirationDate: {
    font: 'Bianco Serif',
    fontSize: 1,
    fillColor: '#000000',
  },
  qrCode: {
    fillColor: '#ffffff',
  },
  bookingInstructions: {
    font: 'Bianco Serif',
    fontSize: 12,
    textAlign: 'left',
    text: 'To book with the gift card you can\r\nscan the QR - code or follow the link:\r\n\r\n',
    paddingLeft: 100,
  },
  hotelInformation: {
    font: 'Bianco Serif',
    fontSize: 9,
    textAlign: 'center',
    text: 'Address / Street / Phone Number / Company Id / Email',
    paddingTop: 10,
  },
  boxAroundQrCode: {
    fontSize: 1,
    cornerRadius: 5.0,
    fillColor: '#000000',
  },
};
