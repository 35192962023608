const locales = [
  {
    code: 'en',
    name: 'English',
    flag: '🇺🇸',
    is_production: true,
  },
  {
    code: 'es',
    name: 'Español',
    flag: '🇪🇸',
    is_production: true,
  },
  {
    code: 'it',
    name: 'Italiano',
    flag: '🇮🇹',
    is_production: true,
  },
  {
    code: 'pt',
    name: 'Português',
    flag: '🇵🇹',
    is_production: true,
  },
  {
    code: 'de',
    name: 'Deutsche',
    flag: '🇩🇪',
    is_production: true,
  },
  {
    code: 'fr',
    name: 'Français',
    flag: '🇫🇷',
    is_production: true,
  },
  {
    code: 'el',
    name: 'Ελληνικά',
    flag: '🇬🇷',
    is_production: true,
  },
  {
    code: 'is',
    name: 'Íslensku',
    flag: '🇮🇸',
    is_production: true,
  },
];

export default locales;
