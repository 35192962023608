// Generated with util/create-component.js
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Radio, Button, Spin, notification } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import currencies from 'world-currencies';

import StepPanel from 'components/step_panel/step_panel';
import timeOptions from 'constants/time_options';
import {
  PropertyPoliciesDataContext,
  PropertySettingsDataContext,
  PropertyPoliciesActionsContext,
} from 'containers/data_context';

import { IPropertyPolicyFormProps, IPropertyPolicy } from './property_policy_form.types';
import styles from './property_policy_form.module.scss';

const getTimeFromOptions = (to = '23:30') => {
  const toIndex = timeOptions.findIndex(time => time === to);
  return timeOptions.slice(0, toIndex + 1);
};

const getTimeToOptions = (from = '00:00') => {
  const fromIndex = timeOptions.findIndex(time => time === from);
  return timeOptions.slice(fromIndex, timeOptions.length);
};

const loadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

const defaultValues: IPropertyPolicy = {
  title: '',
  currency: '',
  isAdultsOnly: false,
  maxCountOfGuests: 0,
  checkinFromTime: '',
  checkinToTime: '',
  checkoutFromTime: '',
  checkoutToTime: '',
  internetAccessType: 'none',
  internetAccessCost: null,
  internetAccessCoverage: 'entire_property',
  parkingType: 'on_site',
  parkingReservation: 'not_needed',
  parkingIsPrivate: true,
  petsPolicy: 'not_allowed',
  petsNonRefundableFee: null,
  petsRefundableDeposit: null,
  smokingPolicy: 'no_smoking',
};

const formItemLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const currenciesOptions = Object.values(currencies).map(currency => ({
  label: `${currency.name} (${currency.iso.code})`,
  value: currency.iso.code,
}));

const PropertyPolicyForm: React.FC<IPropertyPolicyFormProps> = ({ onCancel, propertyPolicyId }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const [isPaidInternet, setIsPaidInternet] = useState(false);

  const {
    propertyPolicy: { data: hotelPolicyData, isLoading: isLoadingHotelPolicy },
    isUpdatingPropertyPolicy,
  } = useContext(PropertyPoliciesDataContext);

  const { selectedProperty } = useContext(PropertySettingsDataContext);

  const { loadPropertyPolicy, updatePropertyPolicy, createPropertyPolicy } = useContext(PropertyPoliciesActionsContext);

  useEffect(() => {
    if (selectedProperty && propertyPolicyId) {
      loadPropertyPolicy({ propertyId: selectedProperty, propertyPolicyId });
    } else {
      form.setFieldsValue(defaultValues);
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue(hotelPolicyData);
  }, [hotelPolicyData]);

  const onlyAdultsOptions = [
    { value: false, label: t('general.no') },
    { value: true, label: t('general.yes') },
  ];

  const internetAccessOptions: Array<{ value: IPropertyPolicy['internetAccessType']; label: string }> = [
    { value: 'none', label: t('hotel_policy.internet.type_options.none') },
    { value: 'wifi', label: t('hotel_policy.internet.type_options.wifi') },
    { value: 'wired', label: t('hotel_policy.internet.type_options.wired') },
  ];

  const internetAccessCoverageOptions = [
    { value: 'entire_property', label: t('hotel_policy.internet.coverage_options.entire_property') },
    { value: 'some_rooms', label: t('hotel_policy.internet.coverage_options.some_rooms') },
    { value: 'all_rooms', label: t('hotel_policy.internet.coverage_options.all_rooms') },
    { value: 'business_centre', label: t('hotel_policy.internet.coverage_options.business_centre') },
    { value: 'public_areas', label: t('hotel_policy.internet.coverage_options.public_areas') },
  ];

  const payingInternetOptions = [
    { value: false, label: t('hotel_policy.internet.cost_options.free') },
    { value: true, label: t('hotel_policy.internet.cost_options.paid') },
  ];

  const parkingPolicyOptions = [
    { value: 'none', label: t('parking.type_options.none') },
    { value: 'on_site', label: t('parking.type_options.on_site') },
    { value: 'nearby', label: t('parking.type_options.nearby') },
  ];

  const petsPolicyOptions = [
    { value: 'not_allowed', label: t('hotel_page.hotel_policy.pets.options.not_allowed') },
    { value: 'by_arrangements', label: t('hotel_page.hotel_policy.pets.options.by_arrangements') },
    { value: 'assistive_only', label: t('hotel_page.hotel_policy.pets.options.assistive_only') },
    { value: 'allowed', label: t('hotel_page.hotel_policy.pets.options.allowed') },
  ];

  const smokingPolicyOptions = [
    { value: 'no_smoking', label: t('hotel_page.hotel_policy.smoking.options.no_smoking') },
    { value: 'permitted_areas_only', label: t('hotel_page.hotel_policy.smoking.options.permitted_areas_only') },
    { value: 'allowed', label: t('hotel_page.hotel_policy.smoking.options.allowed') },
  ];

  const parkingPrivacyOptions = [
    { value: false, label: t('parking.property_options.public') },
    { value: true, label: t('parking.property_options.private') },
  ];

  const parkingReservationOptions = [
    { value: 'needed', label: t('parking.reservation_options.needed') },
    { value: 'not_available', label: t('parking.reservation_options.not_available') },
    { value: 'not_needed', label: t('parking.reservation_options.not_needed') },
  ];

  const validateMessages = {
    required: 'required',
  };

  const isEdit = hotelPolicyData && Object.keys(hotelPolicyData)?.length;

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('hotel_policy.saved_changes_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const onFinishFailed = ({ errorFields }: { errorFields: Array<{ name: string }> }) => {
    form.scrollToField(errorFields[0].name);
  };

  const handleSubmitSection = async () => {
    try {
      await form.validateFields();
      return true;
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    } catch (errors: any) {
      onFinishFailed(errors);
      return false;
    }
  };

  const handleSubmit = async (): Promise<void> => {
    const updatedValues = form.getFieldsValue(true);
    const hotelPolicyData = JSON.parse(JSON.stringify(updatedValues));
    if (selectedProperty) {
      hotelPolicyData.propertyId = selectedProperty;
    }

    try {
      if (propertyPolicyId) {
        await updatePropertyPolicy(hotelPolicyData);
      } else {
        await createPropertyPolicy(hotelPolicyData);
      }
      openNotificationWithIcon('success');
      onCancel();
    } catch (e) {
      console.log(e, 'error');
      openNotificationWithIcon('error');
    }
  };

  const renderGeneralPolicy = (
    <>
      <Form.Item label={t('general.title')} name="title" rules={[{ required: true }]}>
        <Input placeholder={t('general.title')} />
      </Form.Item>

      <Form.Item
        label={t('general.currency')}
        name="currency"
        className={styles.input_container}
        rules={[{ required: true }]}
      >
        <Select
          placeholder={t('general.currency')}
          showSearch
          optionFilterProp="label"
          options={currenciesOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.checkinToTime !== currentValues.checkinToTime}
      >
        {({ getFieldValue }) => (
          <Form.Item label={t('hotel_policy.check_in_from')} name="checkinFromTime" rules={[{ required: true }]}>
            <Select placeholder={t('hotel_policy.check_in_from')}>
              {getTimeFromOptions(getFieldValue('checkinToTime') ? getFieldValue('checkinToTime') : '23:30').map(
                time => (
                  <Select.Option key={time} value={time}>
                    {time}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.checkinFromTime !== currentValues.checkinFromTime}
      >
        {({ getFieldValue }) => (
          <Form.Item label={t('hotel_policy.check_in_to')} name="checkinToTime" rules={[{ required: true }]}>
            <Select placeholder={t('hotel_policy.check_in_to')}>
              {getTimeToOptions(getFieldValue('checkinFromTime') ? getFieldValue('checkinFromTime') : '00:00').map(
                time => (
                  <Select.Option key={time} value={time}>
                    {time}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.checkoutToTime !== currentValues.checkoutToTime}
      >
        {({ getFieldValue }) => (
          <Form.Item label={t('hotel_policy.check_out_from')} name="checkoutFromTime" rules={[{ required: true }]}>
            <Select placeholder={t('hotel_policy.check_out_from')}>
              {getTimeFromOptions(getFieldValue('checkoutToTime') ? getFieldValue('checkoutToTime') : '23:30').map(
                time => (
                  <Select.Option key={time} value={time}>
                    {time}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.checkoutFromTime !== currentValues.checkoutFromTime}
      >
        {({ getFieldValue }) => (
          <Form.Item label={t('hotel_policy.check_out_to')} name="checkoutToTime" rules={[{ required: true }]}>
            <Select placeholder={t('hotel_policy.check_out_to')}>
              {getTimeToOptions(getFieldValue('checkoutFromTime') ? getFieldValue('checkoutFromTime') : '00:00').map(
                time => (
                  <Select.Option key={time} value={time}>
                    {time}
                  </Select.Option>
                ),
              )}
            </Select>
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item label={t('hotel_policy.max_guests')} name="maxCountOfGuests" rules={[{ required: true }]}>
        <Input type="number" placeholder={t('hotel_policy.max_guests')} />
      </Form.Item>

      <Form.Item label={t('hotel_policy.only_adults')} name="isAdultsOnly" rules={[{ required: true }]}>
        <Radio.Group options={onlyAdultsOptions} optionType="button" />
      </Form.Item>
    </>
  );

  const renderInternetPolicy = (
    <>
      <Form.Item label={t('hotel_policy.internet_access')} name="internetAccessType" className={styles.input_container}>
        <Select
          placeholder={t('hotel_policy.internet_access')}
          showSearch
          optionFilterProp="label"
          options={internetAccessOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.internetAccessType !== currentValues.internetAccessType}
      >
        {({ getFieldValue }) =>
          getFieldValue('internetAccessType') !== 'none' ? (
            <>
              <Form.Item
                label={t('hotel_policy.coverage')}
                name="internetAccessCoverage"
                className={styles.input_container}
              >
                <Select
                  placeholder={t('hotel_policy.coverage')}
                  showSearch
                  optionFilterProp="label"
                  options={internetAccessCoverageOptions}
                  filterOption={true}
                />
              </Form.Item>

              <Form.Item label={t('hotel_policy.is_paid')} className={styles.input_container}>
                <Radio.Group
                  options={payingInternetOptions}
                  optionType="button"
                  value={isPaidInternet}
                  onChange={e => {
                    setIsPaidInternet(e.target.value);
                  }}
                />
              </Form.Item>
            </>
          ) : null
        }
      </Form.Item>

      <Form.Item
        noStyle
        hidden={!isPaidInternet}
        shouldUpdate={(prevValues, currentValues) =>
          prevValues.currency !== currentValues.currency ||
          prevValues.internetAccessType !== currentValues.internetAccessType
        }
      >
        {({ getFieldValue }) => (
          <Form.Item
            label={t('hotel_policy.cost')}
            name="cost"
            hidden={getFieldValue('internetAccessType') === 'none'}
            rules={[{ required: isPaidInternet }]}
          >
            <Input type="number" addonAfter={getFieldValue('currency')} placeholder={t('hotel_policy.cost')} />
          </Form.Item>
        )}
      </Form.Item>
    </>
  );

  const renderParkingPolicy = (
    <>
      <Form.Item label={t('hotel_policy.parking_policy')} name="parkingType" className={styles.input_container}>
        <Select
          showSearch
          placeholder={t('hotel_policy.parking_policy')}
          optionFilterProp="label"
          options={parkingPolicyOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.parkingType !== currentValues.parkingType}
      >
        {({ getFieldValue }) =>
          getFieldValue('parkingType') !== 'none' ? (
            <>
              <Form.Item
                label={t('hotel_policy.parking_reservation')}
                name="parkingReservation"
                className={styles.input_container}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  placeholder={t('hotel_policy.parking_reservation')}
                  options={parkingReservationOptions}
                  filterOption={true}
                />
              </Form.Item>

              <Form.Item label={t('parking.property_title')} name="parkingIsPrivate" className={styles.input_container}>
                <Radio.Group options={parkingPrivacyOptions} optionType="button" />
              </Form.Item>
            </>
          ) : null
        }
      </Form.Item>
    </>
  );

  const renderPetsPolicy = (
    <>
      <Form.Item label={t('hotel_page.hotel_policy.pets.title')} name="petsPolicy" className={styles.input_container}>
        <Select
          showSearch
          placeholder={t('hotel_page.hotel_policy.pets.title')}
          optionFilterProp="label"
          options={petsPolicyOptions}
          filterOption={true}
        />
      </Form.Item>

      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => prevValues.petsPolicy !== currentValues.petsPolicy}
      >
        {({ getFieldValue }) =>
          getFieldValue('petsPolicy') !== 'not_allowed' ? (
            <>
              <Form.Item
                label={t('hotel_page.hotel_policy.pets.non_refundable_fee')}
                name="petsNonRefundableFee"
                rules={[{ required: true }]}
              >
                <Input type="number" placeholder={t('hotel_page.hotel_policy.pets.non_refundable_fee')} />
              </Form.Item>
              <Form.Item
                label={t('hotel_page.hotel_policy.pets.refundable_deposit')}
                name="petsRefundableDeposit"
                rules={[{ required: true }]}
              >
                <Input type="number" placeholder={t('hotel_page.hotel_policy.pets.refundable_deposit')} />
              </Form.Item>
            </>
          ) : null
        }
      </Form.Item>
    </>
  );

  const renderSmokingPolicy = (
    <Form.Item
      label={t('hotel_page.hotel_policy.smoking.title')}
      name="smokingPolicy"
      className={styles.input_container}
    >
      <Select
        showSearch
        placeholder={t('hotel_page.hotel_policy.smoking.title')}
        optionFilterProp="label"
        options={smokingPolicyOptions}
        filterOption={true}
      />
    </Form.Item>
  );

  const formSteps = [
    {
      title: t('hotel_policy.general_policy'),
      content: renderGeneralPolicy,
    },
    {
      title: t('hotel_policy.internet_policy'),
      content: renderInternetPolicy,
    },
    {
      title: t('hotel_policy.parking_policy'),
      content: renderParkingPolicy,
    },
    {
      title: t('hotel_policy.pets_policy'),
      content: renderPetsPolicy,
    },
    {
      title: t('hotel_page.hotel_policy.smoking.title'),
      content: renderSmokingPolicy,
    },
  ];

  if (isLoadingHotelPolicy) {
    return (
      <div className={styles.loading_container} data-testid="LoadingSpinner">
        <Spin indicator={loadingIcon} />
      </div>
    );
  }

  return (
    <div data-testid="PropertyPolicyForm" className={styles.root}>
      <Form
        form={form}
        initialValues={defaultValues}
        onFinish={handleSubmit}
        {...formItemLayout}
        validateMessages={validateMessages}
      >
        {isEdit ? (
          <>
            {renderGeneralPolicy}
            {renderInternetPolicy}
            {renderParkingPolicy}
            {renderPetsPolicy}
            {renderSmokingPolicy}
            <div className={styles.footer}>
              <Form.Item>
                <Button type="default" onClick={handleCancel}>
                  {t('link.cancel')}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isUpdatingPropertyPolicy}
                  className={styles.save_button}
                >
                  {t('link.save_changes')}
                </Button>
              </Form.Item>
            </div>
          </>
        ) : (
          <StepPanel onNext={handleSubmitSection} steps={formSteps} />
        )}
      </Form>
    </div>
  );
};

export default PropertyPolicyForm;
