import ApiActions from 'api_actions';
import { formatRatePlan, filterBasicRatePlans } from './utils/hotel_property_rate_plans_format';

const PAGINATION_LIMIT = 100;

export const SET_PROPERTY_RATE_PLANS_LOADING = 'SET_PROPERTY_RATE_PLANS_LOADING';
export const SET_PROPERTY_RATE_PLANS = 'SET_PROPERTY_RATE_PLANS';

export const SET_PROPERTY_RATE_PLAN_LOADING = 'SET_PROPERTY_RATE_PLAN_LOADING';
export const SET_PROPERTY_RATE_PLAN = 'SET_PROPERTY_RATE_PLAN';

export const SET_RATE_PLAN_FACILITIES_LOADING = 'SET_RATE_PLAN_FACILITIES_LOADING';
export const SET_RATE_PLAN_FACILITIES = 'SET_RATE_PLAN_FACILITIES';

export const SET_RATE_PLAN_UPDATE_LOADING = 'SET_RATE_PLAN_UPDATE_LOADING';

export const REMOVE_SELECTED_RATE_PLAN = 'REMOVE_SELECTED_RATE_PLAN';

export const SET_REMOVE_RATE_PLAN_LOADING = 'SET_REMOVE_RATE_PLAN_LOADING';

export const SET_ALL_RATE_PLANS_LOADING = 'SET_ALL_RATE_PLANS_LOADING';
export const SET_ALL_RATE_PLANS = 'SET_ALL_RATE_PLANS';

export const RESET_PROPERTY_RATE_PLANS = 'RESET_PROPERTY_RATE_PLANS';

const setPropertyRatePlans = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_RATE_PLANS, payload });
};

const setPropertyRatePlansLoading = (dispatch, { roomTypeId }) => {
  return dispatch({ type: SET_PROPERTY_RATE_PLANS_LOADING, roomTypeId });
};

const loadPropertyRatePlans = async (dispatch, params) => {
  const filters = {
    filter: {
      roomTypeId: params.roomTypeId,
    },
    pagination: {
      page: 1,
      limit: PAGINATION_LIMIT,
    },
    propertyId: params.propertyId,
  };

  const ratePlans = await loadPropertyUnitRatePlans(dispatch, filters);
  const filteredRatePlans = filterBasicRatePlans(ratePlans);
  setPropertyRatePlans(dispatch, { roomTypeId: filters.filter.roomTypeId, data: filteredRatePlans });
};

const loadPropertyUnitRatePlans = async (dispatch, filters, allRatePlans = []) => {
  setPropertyRatePlansLoading(dispatch, { roomTypeId: filters.filter.roomTypeId });
  const ratePlans = await ApiActions.getRatePlansPerPropertyUnit(filters);
  allRatePlans = [...allRatePlans, ...ratePlans];

  if (ratePlans.length === PAGINATION_LIMIT) {
    filters.pagination.page += 1;
    return loadPropertyUnitRatePlans(dispatch, filters, allRatePlans);
  }
  return allRatePlans;
};

const setPropertyRatePlanLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_RATE_PLAN_LOADING });
};

const setPropertyRatePlan = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_RATE_PLAN, payload });
};

const loadPropertyRatePlan = async (dispatch, params) => {
  setPropertyRatePlanLoading(dispatch);

  const data = await ApiActions.getPropertyRatePlan(params);
  const formattedData = formatRatePlan(data);
  setPropertyRatePlan(dispatch, formattedData);
};

const createRatePlan = async (dispatch, params) => {
  setUpdateRatePlanLoading(dispatch);

  try {
    await ApiActions.createPropertyRatePlan({
      propertyId: params.propertyId,
      ratePlan: { ...params, roomTypeId: params.unitId }
    });
    setUpdateRatePlanLoading(dispatch, false);
    await loadPropertyRatePlans(dispatch, {
      propertyId: params.propertyId,
      roomTypeId: params.unitId
    });
  } catch (e) {
    setUpdateRatePlanLoading(dispatch, false);
    throw e;
  }
};

const setUpdateRatePlanLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_RATE_PLAN_UPDATE_LOADING, params });
};

const updateRatePlan = async (dispatch, params) => {
  setUpdateRatePlanLoading(dispatch);

  try {
    await ApiActions.updatePropertyRatePlan({
      propertyId: params.propertyId,
      ratePlan: { ...params, roomTypeId: params.unitId }
    });
    setUpdateRatePlanLoading(dispatch, false);
    await loadPropertyRatePlans(dispatch, {
      propertyId: params.propertyId,
      roomTypeId: params.unitId
    });
  } catch (e) {
    setUpdateRatePlanLoading(dispatch, false);
    throw e;
  }
};

const setRemoveRatePlanLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_REMOVE_RATE_PLAN_LOADING, params });
};

const removeRatePlan = async (dispatch, params) => {
  setRemoveRatePlanLoading(dispatch);

  const { ratePlanId, roomTypeId, propertyId } = params;

  try {
    await ApiActions.deletePropertyRatePlan({ propertyId, ratePlanId });
    setRemoveRatePlanLoading(dispatch, false);
    await loadPropertyRatePlans(dispatch, { propertyId, roomTypeId });
  } catch (e) {
    setRemoveRatePlanLoading(dispatch, false);
    throw e;
  }
};

const clearSelectedRatePlan = (dispatch, payload) => {
  return dispatch({ type: REMOVE_SELECTED_RATE_PLAN, payload });
};

const setAllPropertyRatePlansLoading = dispatch => {
  return dispatch({ type: SET_ALL_RATE_PLANS_LOADING });
};

const setAllPropertyRatePlans = (dispatch, payload) => {
  return dispatch({ type: SET_ALL_RATE_PLANS, payload });
};

const loadAllPropertyRatePlans = async (dispatch, { propertyId }) => {
  setAllPropertyRatePlansLoading(dispatch);
  const data = await ApiActions.getPropertyRatePlans(propertyId);

  setAllPropertyRatePlans(dispatch, data);
};

const resetPropertyRatePlans = dispatch => {
  return dispatch({ type: RESET_PROPERTY_RATE_PLANS });
};

export const actions = {
  loadAllPropertyRatePlans,
  loadPropertyRatePlans,
  loadPropertyRatePlan,
  createRatePlan,
  updateRatePlan,
  removeRatePlan,
  clearSelectedRatePlan,
  resetPropertyRatePlans,
};
