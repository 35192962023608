import React from 'react';
import classNames from 'classnames';

import EmptyIcon from 'static/empty-property.svg';

import styles from './properties.module.scss';

const PropertiesSkeleton: React.FC = () => {
  return (
    <div className={classNames(styles.skeleton, styles.item)}>
      <div>
        <div className={styles.imageWrapper}>
          <div className={styles.emptyImage}>
            <img src={EmptyIcon} alt="Empty" />
          </div>
        </div>
        <div className={styles.info}>
          <p className={styles.title}>
            <span />
          </p>
          <p className={styles.description}>
            <span />
          </p>
        </div>
      </div>
      <div className={styles.footer}>
        <p className={styles.bestOffer}>
          <span />
        </p>
        <p className={styles.seeMoreLink}>
          <span />
        </p>
      </div>
    </div>
  );
};

export default PropertiesSkeleton;
