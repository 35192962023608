import { useReducer, useCallback, useMemo } from 'react';
import { actions, INITIAL_STATE, reducer } from '../reducers/admin/properties_data';

export default () => {
  const [propertiesData, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadProperties = useCallback(
    params => {
      return actions.loadProperties(dispatch, params);
    },
    [dispatch],
  );

  const createProperty = useCallback(
    params => {
      return actions.createProperty(dispatch, params);
    },
    [dispatch],
  );

  const updateProperty = useCallback(
    params => {
      return actions.updateProperty(dispatch, params);
    },
    [dispatch],
  );

  const lockOverrideSupplier = useCallback(
    params => {
      return actions.lockOverrideSupplier(dispatch, params);
    },
    [dispatch],
  );

  const propertiesActions = useMemo(
    () => ({
      loadProperties,
      createProperty,
      updateProperty,
      lockOverrideSupplier,
    }),
    [loadProperties, createProperty, updateProperty],
  );

  return useMemo(() => ({ propertiesData, propertiesActions }), [propertiesData, propertiesActions]);
};
