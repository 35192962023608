// Generated with util/create-component.js
import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import routes from 'routing/routes';
import buildPath from 'utils/build_path';

import { PropertyGiftCardsActionsContext } from 'containers/data_context';

import PageTitle from 'components/page_title/page_title';
import BookByGiftLetterNumber from 'components/book_by_gift_letter_number/book_by_gift_letter_number';

import styles from './use_gift_card_store_page.module.scss';

const UseGiftCardStorePage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { selectedProperty } = useParams<{ selectedProperty: string }>();

  const { getGiftCardDetails } = useContext(PropertyGiftCardsActionsContext);

  const handleRedirectToGCPage = (giftCardNumber: string) => {
    const redirectPath = buildPath('', routes.giftCardStoreBookingPage, { selectedProperty, giftCardNumber });
    history.push(redirectPath);
  };

  const validateGiftCardNumber = async (giftCardId: string) => {
    try {
      await getGiftCardDetails({ giftCardId });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const handleBuildPath = (path: string) => buildPath('', path, { selectedProperty });

  return (
    <div data-testid="UseGiftCardStorePage" className={styles.root}>
      <div className={styles.pageTitleWrapper}>
        <PageTitle
          steps={[
            { title: t('general.home'), link: handleBuildPath(routes.giftCardStorePage) },
            { title: t('gift_card.use_gift_card') },
          ]}
        />
      </div>
      <div className={styles.bookByGiftLetterWrapper}>
        <div className={classNames(styles.bookByGiftLetter, 'roundedWrapper')}>
          <BookByGiftLetterNumber onValidateGiftCard={validateGiftCardNumber} onSubmit={handleRedirectToGCPage} />
        </div>
      </div>
    </div>
  );
};

export default UseGiftCardStorePage;
