import ApiActions from 'api_actions';
import CONTRACT_STATUS from 'constants/contract_status';

export const SET_SUPPLIER_DISCOVER_CONTRACTS_LIST_LOADING = 'SET_SUPPLIER_DISCOVER_CONTRACTS_LIST_LOADING';
export const SET_SUPPLIER_DISCOVER_CONTRACTS_LIST = 'SET_SUPPLIER_DISCOVER_CONTRACTS_LIST';
export const SET_SUPPLIER_CONTRACTS_RATE_PLANS_LOADING = 'SET_SUPPLIER_CONTRACTS_RATE_PLANS_LOADING';
export const SET_SUPPLIER_CONTRACTS_RATE_PLANS_LIST = 'SET_SUPPLIER_CONTRACTS_RATE_PLANS_LIST';
export const RESET_SUPLIER_CONTRACTS_ALL_UNIT_TYPES_LIST = 'RESET_SUPLIER_CONTRACTS_ALL_UNIT_TYPES_LIST';
export const SET_SUPPLIER_CREATE_CONTRACT_LOADING = 'SET_SUPPLIER_CREATE_CONTRACT_LOADING';

export const SET_SUPPLIER_CONTRACTS_LIST_LOADING = 'SET_SUPPLIER_CONTRACTS_LIST_LOADING';
export const SET_SUPPLIER_CONTRACTS_LIST = 'SET_SUPPLIER_CONTRACTS_LIST';

export const SET_SUPPLIER_ACCEPT_CONTRACT_LOADING = 'SET_SUPPLIER_ACCEPT_CONTRACT_LOADING';

export const SET_SUPPLIER_TERMINATING_CONTRACT_LOADING = 'SET_SUPPLIER_TERMINATING_CONTRACT_LOADING';
export const SET_SUPPLIER_DECLINING_CONTRACT_LOADING = 'SET_SUPPLIER_DECLINING_CONTRACT_LOADING';
export const SET_SUPPLIER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING = 'SET_SUPPLIER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING';
export const RESET_CONTRACTS = 'RESET_CONTRACTS';

const setSupplierContractsListLoading = dispatch => {
  return dispatch({ type: SET_SUPPLIER_DISCOVER_CONTRACTS_LIST_LOADING });
};

const setSupplierContractsList = (dispatch, payload) => {
  return dispatch({ type: SET_SUPPLIER_DISCOVER_CONTRACTS_LIST, payload });
};

const setSupplierContractsRatePlansLoading = dispatch => {
  return dispatch({ type: SET_SUPPLIER_CONTRACTS_RATE_PLANS_LOADING });
};

const setSupplierContractsRatePlansList = (dispatch, payload) => {
  return dispatch({ type: SET_SUPPLIER_CONTRACTS_RATE_PLANS_LIST, payload });
};

const loadSupplierDiscoverContractsList = async (dispatch, params) => {
  setSupplierContractsListLoading(dispatch);

  const data = await ApiActions.getContractsDiscoverResellers(params);
  setSupplierContractsList(dispatch, data);
};

const loadSupplierRatePlansList = async (dispatch, params) => {
  setSupplierContractsRatePlansLoading(dispatch);
  try {
    const units = await ApiActions.getPropertyUnitsOptions(params);
    const ratePlans = await ApiActions.getPropertyRatePlans(params);

    let rates = [];
    if (units && ratePlans) {
      units.forEach(propertyUnit => {
        const { id, defaultOccupancy, photo, title } = propertyUnit;
        const extractedRoomType = {
          id,
          defaultOccupancy,
          photo,
          title,
        };
        const extractedRatePlans = [];
        ratePlans.forEach(ratePlan => {
          if (ratePlan.roomTypeId === propertyUnit.id) {
            const { id, title, occupancy } = ratePlan;
            extractedRatePlans.push({
              id,
              title,
              occupancy,
            });
          }
        });
        extractedRoomType.ratePlans = extractedRatePlans;
        rates.push(extractedRoomType);
      });
    }
    setSupplierContractsRatePlansList(dispatch, rates);
  } catch (e) {
    setSupplierContractsRatePlansList(dispatch, []);
    throw e;
  }
};

const clearSupplierAllUnitTypesList = dispatch => {
  return dispatch({ type: RESET_SUPLIER_CONTRACTS_ALL_UNIT_TYPES_LIST });
};

const setSupplierCreateContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_SUPPLIER_CREATE_CONTRACT_LOADING, params });
};

const supplierCreateContract = async (dispatch, params) => {
  setSupplierCreateContractLoading(dispatch);

  try {
    await ApiActions.createSupplierInitiatedContract(params);
    setSupplierCreateContractLoading(dispatch, false);

    const proposedContracts = await ApiActions.getSupplierContracts({
      status: CONTRACT_STATUS.PROPOSED,
      propertyId: params.propertyId,
    });
    const approvedContracts = await ApiActions.getSupplierContracts({
      status: CONTRACT_STATUS.APPROVED,
      propertyId: params.propertyId,
    });

    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.APPROVED,
      contracts: { contracts: approvedContracts?.data, meta: approvedContracts?.meta },
    });
  } catch (e) {
    setSupplierCreateContractLoading(dispatch, false);
    throw e;
  }
};

const setSupplierContractsLoading = dispatch => {
  return dispatch({ type: SET_SUPPLIER_CONTRACTS_LIST_LOADING });
};

const setSupplierContracts = (dispatch, payload) => {
  return dispatch({ type: SET_SUPPLIER_CONTRACTS_LIST, payload });
};

const loadSupplierContractsList = async (dispatch, params) => {
  setSupplierContractsLoading(dispatch);

  const data = await ApiActions.getSupplierContracts(params);

  setSupplierContracts(dispatch, { status: params.status, contracts: { contracts: data?.data, meta: data?.meta } });
};

const setSupplierAcceptContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_SUPPLIER_ACCEPT_CONTRACT_LOADING, params });
};

const acceptSupplierContract = async (dispatch, params) => {
  try {
    setSupplierAcceptContractLoading(dispatch);

    await ApiActions.approveSupplierContract(params);
    const proposedContracts = await ApiActions.getSupplierContracts({
      status: CONTRACT_STATUS.PROPOSED,
      propertyId: params.propertyId,
    });
    const approvedContracts = await ApiActions.getSupplierContracts({
      status: CONTRACT_STATUS.APPROVED,
      propertyId: params.propertyId,
    });

    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: proposedContracts?.data, meta: proposedContracts?.meta },
    });
    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.APPROVED,
      contracts: { contracts: approvedContracts?.data, meta: approvedContracts?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setSupplierAcceptContractLoading(dispatch, false);
  }
};

const setSupplierTerminateContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_SUPPLIER_TERMINATING_CONTRACT_LOADING, params });
};

const terminateSupplierContract = async (dispatch, params) => {
  try {
    setSupplierTerminateContractLoading(dispatch);

    await ApiActions.terminateSupplierContract(params);
    const data = await ApiActions.getSupplierContracts({
      propertyId: params.propertyId,
      status: CONTRACT_STATUS.APPROVED,
    });

    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.APPROVED,
      contracts: { contracts: data?.data, meta: data?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setSupplierTerminateContractLoading(dispatch, false);
  }
};

const setSupplierDeclineContractLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_SUPPLIER_DECLINING_CONTRACT_LOADING, params });
};

const declineSupplierContract = async (dispatch, params) => {
  try {
    setSupplierDeclineContractLoading(dispatch);

    await ApiActions.declineSupplierContract(params);
    const data = await ApiActions.getSupplierContracts({
      propertyId: params.propertyId,
      status: CONTRACT_STATUS.PROPOSED,
    });

    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: data?.data, meta: data?.meta },
    });
  } catch (e) {
    throw e;
  } finally {
    setSupplierDeclineContractLoading(dispatch, false);
  }
};

const setSupplierCancelContractProposalLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_SUPPLIER_CANCELING_PROPOSAL_OF_CONTRACT_LOADING, params });
};

const cancelSupplierContractProposal = async (dispatch, params) => {
  try {
    setSupplierCancelContractProposalLoading(dispatch);

    await ApiActions.cancelSupplierContractProposal(params);
    const data = await ApiActions.getSupplierContracts({
      propertyId: params.propertyId,
      status: CONTRACT_STATUS.PROPOSED,
    });

    setSupplierContracts(dispatch, {
      status: CONTRACT_STATUS.PROPOSED,
      contracts: { contracts: data?.data, meta: data?.meta },
    });
  } catch (e) {
    console.log(e, 'error');
    throw e;
  } finally {
    setSupplierCancelContractProposalLoading(dispatch, false);
  }
};

const resetContracts = (dispatch) => {
  return dispatch({ type: RESET_CONTRACTS });
}

export const actions = {
  loadSupplierDiscoverContractsList,
  loadSupplierContractsList,
  acceptSupplierContract,
  terminateSupplierContract,
  loadSupplierRatePlansList,
  clearSupplierAllUnitTypesList,
  supplierCreateContract,
  declineSupplierContract,
  cancelSupplierContractProposal,
  resetContracts,
};
