import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import LinkButton from "components/buttons/link_button";

import { BookingActionsContext } from "containers/data_context";

import routes from "routing/routes";

import buildPath from "utils/build_path";

import styles from "./new_booking_link.module.css";

export default function NewBookingLink({ channelId, propertyType }) {
  const { resetParams, clearDataFromStorage } = useContext(BookingActionsContext);
  const history = useHistory();
  const { t } = useTranslation();

  const redirectPath = buildPath(`?type=${propertyType}`, routes.propertyPage, { channelId });

  const handleRedirect = () => {
    resetParams();
    clearDataFromStorage();

    history.push(redirectPath);
  };

  return (
    <div className={styles.container}>
      <LinkButton onClick={handleRedirect}>{t("confirmation_page.create_new_booking")}</LinkButton>
    </div>
  );
}
