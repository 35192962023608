import React from 'react';
import { Nav } from 'react-bootstrap';

import styles from './nav_item.module.scss';
import { INavItemProps } from './nav_item.types';

const NavItem: React.FC<INavItemProps> = ({ children, eventKey, disabled }) => {
  return (
    <Nav.Item className={styles.navItem}>
      <Nav.Link className={styles.link} eventKey={eventKey} disabled={disabled}>
        {children}
      </Nav.Link>
    </Nav.Item>
  );
};

export default NavItem;
