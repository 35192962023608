import React from 'react';
import { useTranslation } from 'react-i18next';

import Caption from 'components/caption';
import Cell from 'components/layout/cell';

import IconHelp from 'static/icons-help.svg';

import styles from './no_rates_placeholder.module.scss';
import { INoRatesPlaceholderProps } from './no_rates_placeholder.types';

const NoRatesPlaceholder: React.FC<INoRatesPlaceholderProps> = ({ propertyType }) => {
  const { t } = useTranslation();

  return (
    <Cell className={styles.placeholderContainer}>
      <div className={styles.placeholderContent}>
        <img className={styles.icon} src={IconHelp} alt="Info" />
        <Caption>{t(`rates_table_${propertyType}.no_rates_placeholder`)}</Caption>
      </div>
    </Cell>
  );
};

export default NoRatesPlaceholder;
