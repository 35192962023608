// Generated with util/create-component.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';

import SupplierPolicyForm from 'components/property_policy_form/property_policy_form';
import { IPropertyPolicy } from 'components/property_policy_form/property_policy_form.types';

import DRAWER_SIZE from 'constants/drawer_size';

import { IPropertyPolicyProps } from './property_policy.types';
import styles from './property_policy.module.scss';

const PropertyPolicy: React.FC<IPropertyPolicyProps> = ({ propertyPolicy = null }) => {
  const { t } = useTranslation();
  const [openedPolicyForm, tooglePolicyForm] = useState<boolean>(false);
  const [propertyPolicyId, setPolicyId] = useState<IPropertyPolicy['id'] | null>(null);

  const handleClosePolicyForm = () => {
    tooglePolicyForm(false);
    setPolicyId(null);
  };

  const handleOpenPolicyForm = (propertyPolicyId: IPropertyPolicy['id'] | null) => {
    tooglePolicyForm(true);
    setPolicyId(propertyPolicyId);
  };

  return (
    <div data-testid="PropertyPolicy" className={styles.root}>
      {!propertyPolicy && (
        <Button
          onClick={() => handleOpenPolicyForm(null)}
          type="primary"
          icon={<PlusCircleOutlined />}
          className={styles.create_button}
        >
          {t('general.create')}
        </Button>
      )}
      {propertyPolicy && (
        <div className={styles.hotel_policy}>
          <div>{t('hotel_policy.hotel_policy_title')}</div>
          <div className={styles.action_link} onClick={() => handleOpenPolicyForm(propertyPolicy.id)}>
            {propertyPolicy.title}
          </div>
        </div>
      )}

      <Drawer
        open={openedPolicyForm}
        title={`${propertyPolicyId ? t('general.edit') : t('general.create')} ${t(
          'payment_page.policy_info.hotel_policy',
        )}`}
        width={window.innerWidth > 900 ? DRAWER_SIZE.LARGE : window.innerWidth}
        onClose={handleClosePolicyForm}
        destroyOnClose={true}
      >
        <SupplierPolicyForm onCancel={handleClosePolicyForm} propertyPolicyId={propertyPolicyId} />
      </Drawer>
    </div>
  );
};

export default PropertyPolicy;
