import ApiActions from 'api_actions';
import { formatBooking } from 'containers/data_context/data_handlers/reducers/bookings_data/utils/bookings_format';

export const SET_PROPERTY_GIFT_CARD_TYPE_LOADING = 'SET_PROPERTY_GIFT_CARD_TYPE_LOADING';
export const SET_PROPERTY_GIFT_CARD_TYPES = 'SET_PROPERTY_GIFT_CARD_TYPES';
export const SET_STORE_GIFT_CARD_TYPE_LOADING = 'SET_STORE_GIFT_CARD_TYPE_LOADING';
export const SET_STORE_GIFT_CARD_TYPES = 'SET_STORE_GIFT_CARD_TYPES';
export const SET_GIFT_CARD_LOADING = 'SET_GIFT_CARD_LOADING';
export const SET_GIFT_CARD = 'SET_GIFT_CARD';
export const SET_GIFT_CARDS_LOADING = 'SET_GIFT_CARDS_LOADING';
export const SET_GIFT_CARDS = 'SET_GIFT_CARDS';
export const SET_CREATED_GIFT_CARD_LOADING = 'SET_CREATED_GIFT_CARD_LOADING';
export const SET_CREATED_GIFT_CARD = 'SET_CREATED_GIFT_CARD';
export const SET_UPDATE_GIFT_CARD_TYPE_LOADING = 'SET_UPDATE_GIFT_CARD_TYPE_LOADING';
export const SET_AVAILABLE_UNIT_LOADING = 'SET_AVAILABLE_UNIT_LOADING';
export const SET_AVAILABLE_UNITS = 'SET_AVAILABLE_UNITS';
export const SET_UNIT_LOADING = 'SET_UNIT_LOADING';
export const SET_UNIT = 'SET_UNIT';
export const SET_GIFT_CARDS_BY_PROPERTY_ID_LOADING = 'SET_GIFT_CARDS_BY_PROPERTY_ID_LOADING';
export const SET_GIFT_CARDS_BY_PROPERTY_ID = 'SET_GIFT_CARDS_BY_PROPERTY_ID';
export const SET_GIFT_CARDS_PURCHASES_LOADING = 'SET_GIFT_CARDS_PURCHASES_LOADING';
export const SET_GIFT_CARDS_PURCHASES = 'SET_GIFT_CARDS_PURCHASES';
export const SET_PARAMS = 'SET_PARAMS';
export const SET_PROPERTY_INFO_LOADING = 'SET_PROPERTY_INFO_LOADING';
export const SET_PROPERTY_INFO = 'SET_PROPERTY_INFO';
export const SET_UPDATE_GIFT_CARD_LOADING = 'SET_UPDATE_GIFT_CARD_LOADING';
export const SET_GIFT_CARD_TYPE_PHOTO_UPLOAD = 'SET_GIFT_CARD_TYPE_PHOTO_UPLOAD';
export const SET_CREATED_GIFT_CARD_BOOKING_LOADING = 'SET_CREATED_GIFT_CARD_BOOKING_LOADING';
export const SET_CREATED_GIFT_CARD_BOOKING = 'SET_CREATED_GIFT_CARD_BOOKING';
export const SET_GIFT_CARD_DETAILS_LOADING = 'SET_GIFT_CARD_DETAILS_LOADING';
export const SET_GIFT_CARD_DETAILS = 'SET_GIFT_CARD_DETAILS';
export const SET_GIFT_CARD_TYPE_SIZES_LOADING = 'SET_GIFT_CARD_TYPE_SIZES_LOADING';
export const SET_GIFT_CARD_TYPE_SIZES = 'SET_GIFT_CARD_TYPE_SIZES';
export const SET_GIFT_CARD_TYPE_SIZE_LOADING = 'SET_GIFT_CARD_TYPE_SIZE_LOADING';
export const SET_GIFT_CARD_TYPE_SIZE = 'SET_GIFT_CARD_TYPE_SIZE';
export const SET_UPDATE_GIFT_CARD_BOOKING_LOADING = 'SET_UPDATE_GIFT_CARD_BOOKING_LOADING';
export const SET_GIFT_CARD_CHECKOUT_DATA_LOADING = 'SET_GIFT_CARD_CHECKOUT_DATA_LOADING';
export const SET_GIFT_CARD_PURCHASE_STATUS_LOADING = 'SET_GIFT_CARD_PURCHASE_STATUS_LOADING';
export const SET_GIFT_CARD_BOOKING_DETAILS_LOADING = 'SET_GIFT_CARD_BOOKING_DETAILS_LOADING';
export const SET_GIFT_CARD_BOOKING_DETAILS = 'SET_GIFT_CARD_BOOKING_DETAILS';
export const SET_GIFT_CARD_CLOSED_DATES_LOADING = 'SET_CLOSED_DATES_LOADING';
export const SET_GIFT_CARD_CLOSED_DATES_DATA = 'SET_CLOSED_DATES_DATA';
export const SET_GIFT_CARD_UNITS_LOADING = 'SET_GIFT_CARD_UNITS_LOADING';
export const SET_GIFT_CARD_UNITS_DATA = 'SET_GIFT_CARD_UNITS_DATA';
export const SET_GIFT_CARD_PROPERTIES = 'SET_GIFT_CARD_PROPERTIES';
export const SET_GIFT_CARD_PROPERTIES_LOADING = 'SET_GIFT_CARD_PROPERTIES_LOADING';
export const SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_LOADING = 'SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_LOADING';
export const SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_DATA = 'SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_DATA';
export const SET_GIFT_CARD_PDF_TEMPLATES_LOADING = 'SET_GIFT_CARD_PDF_TEMPLATES_LOADING';
export const SET_GIFT_CARD_PDF_TEMPLATES_DATA = 'SET_GIFT_CARD_PDF_TEMPLATES_DATA';
export const SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_LOADING = 'SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_LOADING';
export const SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_DATA = 'SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_DATA';

const setParams = (dispatch, payload) => {
  return dispatch({ type: SET_PARAMS, payload });
};

const setEditGiftCardLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UPDATE_GIFT_CARD_TYPE_LOADING, params });
};

const updateGiftCardType = async (dispatch, params) => {
  setEditGiftCardLoading(dispatch);

  try {
    const giftCard = await ApiActions.updateGiftCardType(params);
    setEditGiftCardLoading(dispatch, false);
    await getGiftCardTypes(dispatch, { propertyId: params.propertyId });
    return giftCard;
  } catch (e) {
    setEditGiftCardLoading(dispatch, false);
    throw e;
  }
};

const setCreatedGiftCardLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_CREATED_GIFT_CARD_LOADING, params });
};

const setCreatedGiftCard = (dispatch, payload) => {
  return dispatch({ type: SET_CREATED_GIFT_CARD, payload });
};

const createGiftCard = async (dispatch, params) => {
  setCreatedGiftCardLoading(dispatch);

  try {
    const data = await ApiActions.createGiftCard({
      propertyId: params.propertyId,
      giftCardParams: params.giftCardParams,
    });
    setCreatedGiftCard(dispatch, { data });
  } catch (e) {
    setCreatedGiftCardLoading(dispatch, false);
    throw e;
  }
};

const createGiftCardType = async (dispatch, params) => {
  setUpdatePropertyGiftCardTypeLoading(dispatch);

  try {
    const giftCard = await ApiActions.createGiftCardType({
      propertyId: params.propertyId,
      giftCardTypeParams: params.giftCardTypeParams,
    });
    setUpdatePropertyGiftCardTypeLoading(dispatch, false);
    await getGiftCardTypes(dispatch, { propertyId: params.propertyId });
    return giftCard;
  } catch (e) {
    setUpdatePropertyGiftCardTypeLoading(dispatch, false);
    throw e;
  }
};

const setUpdateStorefrontGiftCardTypeLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_STORE_GIFT_CARD_TYPE_LOADING, params });
};

const getStoreGiftCardTypes = async (dispatch, params) => {
  setUpdateStorefrontGiftCardTypeLoading(dispatch);

  try {
    const data = await ApiActions.getStoreGiftCardTypes(params);
    setStoreGiftCardTypes(dispatch, { data });
  } catch (e) {
    setUpdateStorefrontGiftCardTypeLoading(dispatch, false);
    throw e;
  }
};

const setGiftCardPropertiesLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_PROPERTIES_LOADING, params });
};

const setGiftCardProperties = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_PROPERTIES, payload });
};

const getGiftCardProperties = async (dispatch, params) => {
  setGiftCardPropertiesLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardProperties(params);
    setGiftCardProperties(dispatch, data);
  } catch (e) {
    setGiftCardPropertiesLoading(dispatch, false);
    throw e;
  }
};

const setStoreGiftCardTypes = (dispatch, payload) => {
  return dispatch({ type: SET_STORE_GIFT_CARD_TYPES, payload });
};

const setUpdatePropertyGiftCardTypeLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARD_TYPE_LOADING, params });
};

const getGiftCardTypes = async (dispatch, params) => {
  setUpdatePropertyGiftCardTypeLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardTypes(params);
    setPropertyGiftCardTypes(dispatch, { data });
  } catch (e) {
    setUpdatePropertyGiftCardTypeLoading(dispatch, false);
    throw e;
  }
};

const setPropertyGiftCardTypes = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_GIFT_CARD_TYPES, payload });
};

const setUpdateGiftCardLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_LOADING, params });
};

const getGiftCardById = async (dispatch, params) => {
  setUpdateGiftCardLoading(dispatch, params);

  try {
    const data = await ApiActions.getGiftCardById(params);
    setGiftCard(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setGiftCard = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD, payload });
};

const setUpdateGiftCardsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARDS_LOADING, params });
};

const setGiftCards = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARDS, payload });
};

const getAvailableUnits = async (dispatch, params) => {
  setAvailableUnitsLoading(dispatch);

  try {
    const data = await ApiActions.getAvailableUnits(params);
    setAvailableUnits(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setAvailableUnitsLoading = dispatch => {
  return dispatch({ type: SET_AVAILABLE_UNIT_LOADING });
};

const setAvailableUnits = (dispatch, payload) => {
  return dispatch({ type: SET_AVAILABLE_UNITS, payload });
};

const getGiftCardUnit = async (dispatch, params) => {
  setUnitLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardUnit(params);
    setUnit(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setUnitLoading = dispatch => {
  return dispatch({ type: SET_UNIT_LOADING });
};

const setUnit = (dispatch, payload) => {
  return dispatch({ type: SET_UNIT, payload });
};

const getGiftCardsByPropertyId = async (dispatch, params) => {
  setGiftCardsByPropertyIdLoading(dispatch);

  try {
    const giftCards = await ApiActions.getGiftCardsByPropertyId(params);
    const { data, ...rest } = giftCards;

    const giftCardsList = data.map(giftCard => {
      return {
        ...giftCard,
        agentBooking: giftCard.agentBooking ? formatBooking(giftCard.agentBooking) : null,
      };
    })

    setGiftCardsByPropertyId(dispatch, { data: giftCardsList, ...rest });
  } catch (e) {
    setGiftCardsByPropertyId(dispatch, null);
    throw e;
  }
};

const setGiftCardsByPropertyIdLoading = dispatch => {
  return dispatch({ type: SET_GIFT_CARDS_BY_PROPERTY_ID_LOADING });
};

const setGiftCardsByPropertyId = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARDS_BY_PROPERTY_ID, payload });
};

const setPropertyInfoLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_INFO_LOADING });
};

const getPropertyInfo = async (dispatch, params) => {
  setPropertyInfoLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardPropertyInfo(params);
    setPropertyInfo(dispatch, { data });
  } catch (e) {
    setPropertyInfoLoading(dispatch, false);
    throw e;
  }
};

const setPropertyInfo = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_INFO, payload });
};

const setGiftCardEmailSentLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UPDATE_GIFT_CARD_LOADING, params });
};

const putGiftCardEmailSent = async (dispatch, params) => {
  setGiftCardEmailSentLoading(dispatch);

  try {
    await ApiActions.putGiftCardEmailSent(params);
    setGiftCardEmailSentLoading(dispatch, false);
  } catch (e) {
    setGiftCardEmailSentLoading(dispatch, false);
    throw e;
  }
};

const putGiftCard = async (dispatch, params) => {
  try {
    return await ApiActions.putGiftCard(params);
  } catch (e) {
    throw e;
  }
};

const postGiftCardIssuedMultiple = async (dispatch, params) => {
  await ApiActions.postGiftCardIssuedMultiple(params);
};

const downloadGiftCardsPdfs = async (dispatch, params) => {
  try {
    return await ApiActions.downloadGiftCardsPdfs(params);
  } catch (e) {
    throw e;
  }
};

const uploadGiftCardTypePhoto = async (dispatch, params) => {
  return await ApiActions.uploadGiftCardTypePhoto(params);
};

const setCreatedGiftCardBookingLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_CREATED_GIFT_CARD_BOOKING_LOADING, params });
};

const createGiftCardBooking = async (dispatch, params) => {
  setCreatedGiftCardBookingLoading(dispatch, true);

  try {
    const data = await ApiActions.createGiftCardBooking(params);
    setGiftCardBooking(dispatch, { data });
    return data;
  } catch (e) {
    throw e;
  } finally {
    setCreatedGiftCardBookingLoading(dispatch, false);
  }
};

const setGiftCardBooking = (dispatch, payload) => {
  return dispatch({ type: SET_CREATED_GIFT_CARD_BOOKING, payload });
};

const setGiftCardDetailsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_DETAILS_LOADING, params });
};

const cancelGiftCardBooking = async (dispatch, params) => {
  try {
    const data = await ApiActions.cancelGiftCardBooking(params);
    return data;
  } catch (e) {
    throw e;
  }
}; 

const getGiftCardDetails = async (dispatch, params) => {
  setGiftCardDetailsLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardDetails(params);
    setGiftCardDetails(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setGiftCardDetails = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_DETAILS, payload });
};

const setGiftCardTypeSizesLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_TYPE_SIZES_LOADING, params });
};

const getGiftCardTypeSizes = async (dispatch, params) => {
  setGiftCardTypeSizesLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardTypeSizes(params);
    setGiftCardTypeSizes(dispatch, { data });
  } catch (e) {
    setGiftCardTypeSizesLoading(dispatch, false);
    setGiftCardTypeSizes(dispatch, { data: [] });
    throw e;
  }
};

const setGiftCardTypeSizes = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_TYPE_SIZES, payload });
};

const setGiftCardTypeSizeLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_TYPE_SIZE_LOADING, params });
};

const getGiftCardTypeSize = async (dispatch, params) => {
  setGiftCardTypeSizeLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardTypeSize(params);
    setGiftCardTypeSize(dispatch, { data });
    return data;
  } catch (e) {
    throw e;
  }
};

const setGiftCardTypeSize = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_TYPE_SIZE, payload });
};

const setGiftCardBookingEmailSentLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_UPDATE_GIFT_CARD_BOOKING_LOADING, params });
};

const putGiftCardBookingEmailSent = async (dispatch, params) => {
  setGiftCardBookingEmailSentLoading(dispatch);

  try {
    await ApiActions.putGiftCardBookingEmailSent({ booking_emailSentParams: params.booking_emailSentParams });
    setGiftCardBookingEmailSentLoading(dispatch, false);
  } catch (e) {
    setGiftCardBookingEmailSentLoading(dispatch, false);
    throw e;
  }
};

const setGiftCardCheckoutDataLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_CHECKOUT_DATA_LOADING, params });
};

const setGiftCardCheckoutData = async (dispatch, params) => {
  setGiftCardCheckoutDataLoading(dispatch);

  try {
    const data = await ApiActions.setGiftCardCheckoutData(params);
    setGiftCardBookingEmailSentLoading(dispatch, false);
    return data;
  } catch (e) {
    setGiftCardCheckoutDataLoading(dispatch, false);
    throw e;
  }
};

const putGiftCardPurchaseMarkPaid = async (dispatch, params) => {
  try {
    return await ApiActions.putGiftCardPurchaseMarkPaid(params);
  } catch (e) {
    throw e;
  }
};

const putGiftCardPurchaseMarkIssued = async (dispatch, params) => {
  try {
    return await ApiActions.putGiftCardPurchaseMarkIssued(params);
  } catch (e) {
    throw e;
  }
};

const putGiftCardPurchaseUpdateRecipients = async (dispatch, params) => {
  try {
    return await ApiActions.putGiftCardPurchaseUpdateRecipients(params);
  } catch (e) {
    throw e;
  }
};

const setGiftCardPurchaseStatusLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_PURCHASE_STATUS_LOADING, params });
};

const getGiftCardsPurchaseStatus = async (dispatch, params) => {
  setGiftCardPurchaseStatusLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardsPurchaseStatus(params);
    setGiftCardPurchaseStatusLoading(dispatch, false);
    return data;
  } catch (e) {
    setGiftCardPurchaseStatusLoading(dispatch, false);
    return e;
  }
};

const getGiftCardsPurchases = async (dispatch, params) => {
  setGiftCardsPurchasesLoading(dispatch);

  try {
    const giftCardsPurchases = await ApiActions.getGiftCardsPurchases(params);
    setGiftCardsPurchases(dispatch, giftCardsPurchases);
  } catch (e) {
    setGiftCardsPurchases(dispatch, null);
    throw e;
  }
};

const setGiftCardsPurchasesLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARDS_PURCHASES_LOADING, params });
};

const setGiftCardsPurchases = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARDS_PURCHASES, payload });
};


const setGiftCardBookingDetailsLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_GIFT_CARD_BOOKING_DETAILS_LOADING, params });
};

const setGiftCardBookingDetails = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_BOOKING_DETAILS, payload });
};

const getGiftCardBookingDetails = async (dispatch, params) => {
  setGiftCardBookingDetailsLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardBookingDetails(params);
    setGiftCardBookingDetails(dispatch, data);
    return data;
  } catch (e) {
    setGiftCardBookingDetailsLoading(dispatch, false);
    throw e;
  }
};

const setGiftCardClosedDatesLoading = dispatch => {
  return dispatch({ type: SET_GIFT_CARD_CLOSED_DATES_LOADING });
};

const setGiftCardClosedDatesData = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_CLOSED_DATES_DATA, payload });
};

const getGiftCardClosedDates = async (dispatch, payload) => {
  if (!payload) {
    return;
  }

  setGiftCardClosedDatesLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardClosedDates(payload);

    setGiftCardClosedDatesData(dispatch, data);
  } catch (error) {
    setGiftCardClosedDatesData(dispatch, null);
  }
};

const setGiftCardRoomsLoading = dispatch => {
  return dispatch({ type: SET_GIFT_CARD_UNITS_LOADING });
};

const setGiftCardRoomsData = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_UNITS_DATA, payload });
};

const loadGiftCardUnitsInfo = async (dispatch, params) => {
  if (!params.propertyChannelId) {
    return;
  }

  setGiftCardRoomsLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardRoomsInfo(params);

    setGiftCardRoomsData(dispatch, data);
  } catch (error) {
    setGiftCardRoomsData(dispatch, null);
  }
};

const getGiftCardDetailsPdf = async (dispatch, params) => {
  try {
    return await ApiActions.getGiftCardDetailsPdf(params);
  } catch (e) {
    throw e;
  }
};

const getGiftCardDetailsPdfPreview = async (dispatch, params) => {
  try {
    return await ApiActions.getGiftCardDetailsPdfPreview(params);
  } catch (e) {
    throw e;
  }
};

const getGiftCardDetailsQrCode = async (dispatch, params) => {
  try {
    return await ApiActions.getGiftCardDetailsQrCode(params);
  } catch (e) {
    throw e;
  }
};

const loadPropertyUnitRatePlansList = async (dispatch, params) => {
  try {
    const units = await ApiActions.getPropertyUnitsOptions(params);
    const ratePlans = await ApiActions.getPropertyRatePlans(params);
    let rates = [];
    if (units && ratePlans) {
      units.forEach(unit => {
        const { id, defaultOccupancy, photo, title, propertyId } = unit;
        const extractedRoomType = {
          id,
          defaultOccupancy,
          photo,
          title,
          propertyId,
        };
        const extractedRatePlans = [];
        ratePlans.forEach(ratePlan => {
          if (ratePlan.roomTypeId === unit.id) {
            const { id, title, occupancy } = ratePlan;
            extractedRatePlans.push({
              id,
              title,
              occupancy,
            });
          }
        });
        extractedRoomType.ratePlans = extractedRatePlans;
        rates.push(extractedRoomType);
      });
    }
    return rates;
  } catch (e) {
    throw e;
  }
};

const createMultiGiftCardType = async (dispatch, params) => {
  setUpdatePropertyGiftCardTypeLoading(dispatch);

  try {
    const giftCard = await ApiActions.createMultiGiftCardType({
      propertyId: params.propertyId,
      multiGiftCardTypeParams: params.multiGiftCardTypeParams,
    });
    setUpdatePropertyGiftCardTypeLoading(dispatch, false);
    await getGiftCardTypes(dispatch, { propertyId: params.propertyId });
    return giftCard;
  } catch (e) {
    setUpdatePropertyGiftCardTypeLoading(dispatch, false);
    throw e;
  }
};

const updateMultiGiftCardType = async (dispatch, params) => {
  setEditGiftCardLoading(dispatch);

  try {
    const giftCard = await ApiActions.updateMultiGiftCardType(params);
    setEditGiftCardLoading(dispatch, false);
    await getGiftCardTypes(dispatch, { propertyId: params.propertyId });
    return giftCard;
  } catch (e) {
    setEditGiftCardLoading(dispatch, false);
    throw e;
  }
};

const deleteMultiGiftCardTypePhoto = async (dispatch, params) => {
  try {
    return await ApiActions.deleteMultiGiftCardTypePhoto(params);
  } catch (e) {
    throw e;
  }
};

const deleteGiftCardTypePhoto = async (dispatch, params) => {
  try {
    return await ApiActions.deleteGiftCardTypePhoto(params);
  } catch (e) {
    throw e;
  }
};

const resetGiftCardStoreBooking = dispatch => {
  setGiftCardRoomsData(dispatch, null);
  setGiftCardClosedDatesData(dispatch, null);
  setParams(dispatch, { checkinDate: null, checkoutDate: null });
};

const setUserHotelRoomTypeRatePlansLoading = dispatch => {
  return dispatch({ type: SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_LOADING });
};

const setUserHotelRoomTypeRatePlansData = (dispatch, payload) => {
  return dispatch({ type: SET_USER_HOTEL_ROOM_TYPE_RATE_PLANS_DATA, payload });
};

const getUserHotelRoomTypeRatePlans = async (dispatch, params) => {
  setUserHotelRoomTypeRatePlansLoading(dispatch);

  try {
    const data = await ApiActions.getUserHotelRoomTypeRatePlans(params);

    setUserHotelRoomTypeRatePlansData(dispatch, data);
  } catch (e) {
    setUserHotelRoomTypeRatePlansLoading(dispatch, null)
    throw e;
  }
};

const getGiftCardsExport = async (dispatch, params) => {
  try {
    return await ApiActions.getGiftCardsExport(params);
  } catch (e) {
    throw e;
  }
};

const setGiftCardPdfTemplatesLoading = dispatch => {
  return dispatch({ type: SET_GIFT_CARD_PDF_TEMPLATES_LOADING });
};

const setGiftCardPdfTemplatesData = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_PDF_TEMPLATES_DATA, payload });
};

const getGiftCardPdfTemplates = async (dispatch, propertyId) => {
  setGiftCardPdfTemplatesLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardPdfTemplates(propertyId);

    setGiftCardPdfTemplatesData(dispatch, data);
  } catch (error) {
    setGiftCardPdfTemplatesData(dispatch, null);
  }
};

const setGiftCardPdfTemplateFilledSectionsLoading = dispatch => {
  return dispatch({ type: SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_LOADING });
};

const setGiftCardPdfTemplateFilledSectionsData = (dispatch, payload) => {
  return dispatch({ type: SET_GIFT_CARD_PDF_TEMPLATE_FILLED_SECTIONS_DATA, payload });
};


const getGiftCardPdfTemplateFilledSections = async (dispatch, propertyId, giftCardTypeId, pdfTemplateId) => {
  setGiftCardPdfTemplateFilledSectionsLoading(dispatch);

  try {
    const data = await ApiActions.getGiftCardPdfTemplateFilledSections(propertyId, giftCardTypeId, pdfTemplateId);

    setGiftCardPdfTemplateFilledSectionsData(dispatch, data);
  } catch (error) {
    setGiftCardPdfTemplateFilledSectionsData(dispatch, null);
  }
};

export const actions = {
  getGiftCardById,
  getStoreGiftCardTypes,
  getGiftCardProperties,
  createGiftCardType,
  createGiftCard,
  getGiftCardTypes,
  updateGiftCardType,
  getAvailableUnits,
  getGiftCardUnit,
  getGiftCardsByPropertyId,
  setParams,
  getPropertyInfo,
  putGiftCardEmailSent,
  postGiftCardIssuedMultiple,
  downloadGiftCardsPdfs,
  uploadGiftCardTypePhoto,
  createGiftCardBooking,
  cancelGiftCardBooking,
  getGiftCardDetails,
  getGiftCardTypeSizes,
  getGiftCardTypeSize,
  putGiftCardBookingEmailSent,
  setGiftCardCheckoutData,
  getGiftCardsPurchaseStatus,
  getGiftCardsPurchases,
  putGiftCardPurchaseMarkPaid,
  putGiftCardPurchaseMarkIssued,
  putGiftCardPurchaseUpdateRecipients,
  getGiftCardBookingDetails,
  getGiftCardClosedDates,
  loadGiftCardUnitsInfo,
  getGiftCardDetailsPdf,
  getGiftCardDetailsQrCode,
  setGiftCardDetails,
  loadPropertyUnitRatePlansList,
  createMultiGiftCardType,
  updateMultiGiftCardType,
  deleteMultiGiftCardTypePhoto,
  deleteGiftCardTypePhoto,
  resetGiftCardStoreBooking,
  getGiftCardDetailsPdfPreview,
  putGiftCard,
  getUserHotelRoomTypeRatePlans,
  getGiftCardsExport,
  getGiftCardPdfTemplates,
  getGiftCardPdfTemplateFilledSections,
};
