import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import styles from './reseller_info.module.scss';
import { IResellerInfoProps } from './reseller_info.types';

const ResellerInfo: React.FC<IResellerInfoProps> = ({ booking }) => {
  const { t } = useTranslation();

  return (
    <div data-testid="ResellerInfo">
      <legend>{t('general.customer')}</legend>
      <Row>
        <Col span={7} className={styles.label}>
          {t('bookings_list.reseller_title')}:
        </Col>
        <Col span={16} offset={1}>
          <Row>
            <Col span={24}>{booking.resellerName}</Col>
            {booking.user?.name && <Col span={24}>{booking.user?.name}</Col>}
            {booking.user?.email && <Col span={24}>{booking.user?.email}</Col>}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default ResellerInfo;
