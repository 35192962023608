// Generated with util/create-component.js
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button } from 'antd';

import dateFormatter from 'utils/date_formatter';

import { IGiftCampaignOptionProps } from './gift_campaign_option.types';
import styles from './gift_campaign_option.module.scss';

const GiftCampaignOption: React.FC<IGiftCampaignOptionProps> = ({ option, onChooseOption }) => {
  const { t } = useTranslation();

  const expirationTime = useMemo(() => {
    return dateFormatter.toUiShortMonth(option.expirationDate);
  }, [option.expirationDate]);

  return (
    <div data-testid="GiftCampaignOption" className={styles.root}>
      <Row className={styles.optionContainer}>
        <Col
          sm={window.innerWidth < 950 ? 10 : window.innerWidth < 1500 ? 8 : 6}
          xs={12}
          className={styles.optionImageContainer}
        >
          <img src={option.photo} alt={option.name} data-testid="GiftCampaignOptionPhoto" />
        </Col>
        <Col sm={window.innerWidth < 950 ? 12 : window.innerWidth < 1500 ? 14 : 16} xs={10}>
          <Row justify="space-between" align="stretch" className={styles.descriptionContainer}>
            <Col span={24}>
              <Row>
                <Col span={24} className={styles.optionName}>
                  {option.name}
                </Col>
                <Col span={24} className={styles.description}>
                  {option.description}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles.buttonContainer}>
                <Col span={24}>
                  <Button
                    shape="round"
                    type="primary"
                    className={styles.chooseButton}
                    onClick={() => onChooseOption(option)}
                  >
                    {t('general.choose_a_gift')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={2} className={styles.expirationDate}>
          {`${t('general.valid_until')} ${expirationTime}`}
        </Col>
      </Row>
    </div>
  );
};

export default GiftCampaignOption;
