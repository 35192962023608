import React from 'react';
import { EnvironmentOutlined } from '@ant-design/icons';

import styles from './property_location.module.scss';
import { IPropertyLocationProps } from './property_location.types';

const PropertyLocation: React.FC<IPropertyLocationProps> = ({ property }) => {
  return (
    <div className={styles.locationContainer}>
      <EnvironmentOutlined className={styles.icon} />
      <div>{property.address}</div>
    </div>
  );
};

export default PropertyLocation;
