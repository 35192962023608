import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Collapse } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import styles from './booking_content.module.scss';
import { IBookingContentProps } from './booking_content.types';

import dateFormatter from 'utils/date_formatter';
import isAccommodationProperty from 'utils/is_accommodation_property';

import { IBookingGuest, IBookingUnit } from 'components/bookings_list/bookings_list.types';
import BookingGuests from 'components/booking_guests/booking_guests';
import cancellationPolicies from 'utils/cancellation_policies/cancellation_policies';

const { Panel } = Collapse;

const BookingContent: React.FC<IBookingContentProps> = ({ booking, isReseller }) => {
  const { t } = useTranslation();

  const roomsGuests = useMemo(() => {
    booking.units.map((unit: IBookingUnit, index: number) => {
      unit.ordinal = index;
      unit.deleted = false;
    });

    return booking.units.map(unit => unit.guests);
  }, [booking.units]);

  const [bookingRooms] = useState<IBookingUnit[]>(booking.units);
  const [bookingGuests] = useState(roomsGuests);

  const defaultExpandedRooms = useMemo(() => {
    let maxRoomIndex = booking.units.length - 1;

    const activeKeys = [];
    while (0 <= maxRoomIndex) {
      activeKeys.push(`unit-details-${maxRoomIndex}`);
      maxRoomIndex--;
    }

    return activeKeys;
  }, [booking.units]);

  const renderGuests = (unit: IBookingUnit, index: number) => {
    if (isReseller) {
      const maxOccupancy = Number(unit.occupancy.adults) + Number(unit.occupancy.children);
      return (
        <Row>
          <BookingGuests
            maxOccupancy={maxOccupancy}
            guests={bookingGuests[index]}
            unitTitle={unit.title}
            unitIndex={index}
            propertyType={booking.propertyType}
            canBeModified={cancellationPolicies.canBeModified(booking, unit)}
          />
        </Row>
      );
    }
    if (!isReseller && unit.guests.length === 0) {
      return <Row>{t('guests.no_info_available')}</Row>;
    }

    if (!isReseller && unit.guests.length > 0) {
      return (
        <Row>
          {unit.guests.map((guest: IBookingGuest, index: number) => (
            <Col span={24} key={`guest-${index}`}>{`${guest.name} ${guest.surname}`}</Col>
          ))}
        </Row>
      );
    }
  };

  return (
    <div data-testid="BookingContent">
      <legend>{t(`bookings_list_${booking.propertyType}.units`)}</legend>
      <Collapse ghost data-testid="RoomDetails" defaultActiveKey={defaultExpandedRooms}>
        {bookingRooms
          .filter(br => !br.deleted)
          .map((unit: IBookingUnit, index: number) => (
            <Panel
              collapsible="icon"
              header={
                <Row>
                  <Col span={23}>
                    <Row>{unit.title && <div className={styles.room_title}>{unit.title}</div>}</Row>
                    <Row className={styles.room_title_footer}>
                      <CalendarOutlined className={styles.calendar_outlined} />
                      {`${dateFormatter.toUIDateWithDay(booking.arrivalDate)} - ${dateFormatter.toUIDateWithDay(
                        booking.departureDate,
                      )}`}
                    </Row>
                  </Col>
                </Row>
              }
              key={`unit-details-${unit.ordinal || index}`}
            >
              {unit.roomRateTitle && (
                <Row>
                  <Col span={7} className={styles.label}>
                    {t('booking.rate_plan')}:
                  </Col>
                  <Col span={16} offset={1}>
                    {unit.roomRateTitle}
                  </Col>
                </Row>
              )}

              {isAccommodationProperty(booking.propertyType) && (
                <Row>
                  <Col span={7} className={styles.label}>
                    {t('general.occupancy')}:
                  </Col>
                  <Col span={16} offset={1}>
                    <Row>
                      <Col span={24}>
                        {t('hotel_page.adults_label')}: {unit.occupancy.adults}
                      </Col>
                      <Col span={24}>
                        {t('hotel_page.children_label')}: {unit.occupancy.children}
                      </Col>
                      <Col span={24}>
                        {t('general.infants')}: {unit.occupancy.infants}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row>
                <Col span={7} className={styles.label}>
                  {t(`hotel_page_${booking.propertyType}.occupants`)}:
                </Col>
                <Col sm={16} offset={1} xs={24}>
                  {renderGuests(unit, unit.ordinal || index)}
                </Col>
                <Col span={24}></Col>
              </Row>
            </Panel>
          ))}
      </Collapse>
    </div>
  );
};

export default BookingContent;
