import React from 'react';

import GiftCardStorePayment from 'components/gift_card_store_payment/gift_card_store_payment';

import styles from './gift_card_store_payment_page.module.scss';

const GiftCardStorePaymentPage: React.FC = () => {
  return (
    <div className={styles.root} data-testid="GiftCardStorePaymentPage">
      <GiftCardStorePayment />
    </div>
  );
};

export default GiftCardStorePaymentPage;
