// Generated with util/create-component.js
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { notification, Spin } from 'antd';
import { Redirect } from 'react-router-dom';
import LocaleStorage from 'containers/data_context/utils/locale_storage_manager';
import { LoadingOutlined } from '@ant-design/icons';

import buildPath from 'utils/build_path';
import routes from 'routing/routes';

import { AuthenticationActionsContext, AuthenticationDataContext } from 'containers/data_context';
import styles from './verify_reset_password_email_page.module.scss';

const loadingIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

const VerifyResetPasswordEmailPage: React.FC = () => {
  const { t } = useTranslation();

  const { verifyResetPasswordEmail, setLoggedInUser } = useContext(AuthenticationActionsContext);
  const { isVerifyingResetPasswordEmail } = useContext(AuthenticationDataContext);

  useEffect(() => {
    handleVerifyResetPasswordEmail();
  }, []);

  const handleVerifyResetPasswordEmail = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (!token) return;

    try {
      const authorizationData = await verifyResetPasswordEmail(token);
      if (authorizationData) {
        setLoggedInUser(authorizationData.session);
      }
    } catch (error) {
      notification['error']({
        message: t('auth.token_verification.invalid_token'),
        description: t('general.error_description'),
      });
    }
  };

  if (LocaleStorage.get('session')) {
    const redirectPath = buildPath('', routes.changePassword);
    return <Redirect to={redirectPath} />;
  }

  return (
    <div data-testid="VerifyResetPasswordEmailPage" className={styles.root}>
      {isVerifyingResetPasswordEmail && (
        <div className={styles.loading_container} data-testid="LoadingSpinner">
          <Spin indicator={loadingIcon} />
          <div className={styles.loading_description}>{t('auth.token_verification.in_progress')}</div>
        </div>
      )}
    </div>
  );
};

export default VerifyResetPasswordEmailPage;
