// Generated with util/create-component.js
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import Loading from 'components/loading';
import GiftCardPropertiesList from 'components/gift_card_properties_list/gift_card_properties_list';
import { PropertyGiftCardsActionsContext, PropertyGiftCardsDataContext } from 'containers/data_context';

import styles from './gift_card_properties_list_page.module.scss';
import classNames from 'classnames';

const GiftCardPropertiesListPage: React.FC = () => {
  const { t } = useTranslation();
  const { giftCardId } = useParams<{ giftCardId: string }>();

  const { getGiftCardProperties, setGiftCardDetails } = useContext(PropertyGiftCardsActionsContext);
  const {
    giftCardProperties: { data: properties, isLoading: isLoadingGiftCardProperties },
  } = useContext(PropertyGiftCardsDataContext);

  useEffect(() => {
    setGiftCardDetails({ data: null });
    async function handleGetGiftCardProperties() {
      try {
        await getGiftCardProperties({ giftCardId });
      } catch (e) {
        openNotificationWithIcon('error');
      }
    }

    handleGetGiftCardProperties();
  }, []);

  const openNotificationWithIcon = (type: 'success' | 'error') => {
    if (type === 'success') {
      return notification['success']({
        message: t('general.success_message'),
      });
    } else {
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  if (isLoadingGiftCardProperties) {
    return (
      <div className={styles.loadingContainer}>
        <Loading size={64} />
      </div>
    );
  }

  return (
    <div data-testid="GiftCardPropertiesListPage" className={styles.root}>
      <div className={styles.propertiesList}>
        <div className={classNames(styles.title, 'highlighted')}>{t('gift_card.choose_property')}</div>
        <GiftCardPropertiesList properties={properties} giftCardId={giftCardId} />
      </div>
    </div>
  );
};

export default GiftCardPropertiesListPage;
