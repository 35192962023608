import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from '../properties_search_map.module.scss';
import { IMarkerProps } from './marker.types';

const Marker: React.FC<IMarkerProps> = ({ item, isHighlighted, onMouseOver, onMouseOut, onSelect }) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePropertySelect = () => {
    onSelect(item);
  };

  const handleMouseOver = () => {
    if (!containerRef?.current) {
      return;
    }

    onMouseOver(item);
    if (containerRef.current.parentElement) {
      containerRef.current.parentElement.style.zIndex = '1';
    }
  };

  const handleMouseOut = () => {
    if (!containerRef?.current) {
      return;
    }

    onMouseOut(item);
    if (containerRef?.current?.parentElement) {
      containerRef.current.parentElement.style.zIndex = '0';
    }
  };

  useEffect(
    function handleMarkerHighlighted() {
      if (!containerRef?.current) {
        return;
      }

      if (containerRef?.current?.parentElement) {
        containerRef.current.parentElement.style.zIndex = isHighlighted ? '1' : '0';
      }
    },
    [containerRef, isHighlighted],
  );

  const markerClassName = classNames(styles.marker, isHighlighted && styles.markerHighlited);

  return (
    <div
      ref={containerRef}
      className={markerClassName}
      onClick={handlePropertySelect}
      onMouseOver={handleMouseOver}
      onFocus={handleMouseOver}
      onMouseOut={handleMouseOut}
      onBlur={handleMouseOut}
    >
      <span className={styles.markerContent}>{item.bestOffer}</span>
      <span className={styles.markerOverlay}>{t('properties.preview')}</span>
    </div>
  );
};

export default Marker;
