// Generated with util/create-component.js
import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import Loading from 'components/loading';
import GiftCampaignOptionsList from 'components/gift_campaign_options_list/gift_campaign_options_list';
import { IGiftCampaignOption } from 'components/gift_campaign_option/gift_campaign_option.types';
import GiftCampaignChoosenOptionForm from 'components/gift_campaign_choosen_option_form/gift_campaign_choosen_option_form';
import { IGiftCampaignRecipient } from 'components/gift_campaign_recipient_form/gift_campaign_recipient_form.types';

import { GiftCampaignActionsContext, GiftCampaignDataContext } from 'containers/data_context';

import styles from './gift_campaign_page.module.scss';

const GiftCampaignPage: React.FC = () => {
  const { t } = useTranslation();

  const { giftCampaignId } = useParams() as { giftCampaignId: string };
  const { getGiftCampaignOptions, saveGiftCampaignRecipient } = useContext(GiftCampaignActionsContext);
  const {
    giftCampaignOptions: { data: giftCampaignDetails, isLoading },
  } = useContext(GiftCampaignDataContext);

  const [choosenOption, setChoosenOption] = useState<IGiftCampaignOption | null>(null);
  const [isRecipientSaved, setIsRecipientSaved] = useState<boolean>(false);

  useEffect(() => {
    const loadGiftCampaignOptions = async () => {
      try {
        await getGiftCampaignOptions(giftCampaignId);
      } catch (e) {
        console.error(e, 'Error');
        return notification['error']({
          message: t('general.error_message'),
          description: t('general.error_description'),
        });
      }
    };

    loadGiftCampaignOptions();
  }, []);

  useEffect(() => {
    const container = document.getElementById('general-page-layout-section');
    if (container) {
      container.scrollTop = 0;
    }
  }, [choosenOption]);

  const handleChooseOption = (option: IGiftCampaignOption) => {
    setChoosenOption(option);
  };

  const handleResetGiftRecipient = () => {
    setChoosenOption(null);
  };

  const handleSaveGiftRecipient = async (giftRecipient: IGiftCampaignRecipient) => {
    try {
      await saveGiftCampaignRecipient({ giftCampaignId, giftRecipient });
      setChoosenOption(null);
      setIsRecipientSaved(true);
    } catch (e) {
      console.error(e);
      return notification['error']({
        message: t('general.error_message'),
        description: t('general.error_description'),
      });
    }
  };

  if (isLoading) {
    return (
      <div className={styles.loadingContainer}>
        <Loading size={64} />
      </div>
    );
  }

  return (
    <div data-testid="GiftCampaignPage" className={styles.root} id="gift-campaign-page">
      {!choosenOption && !isRecipientSaved && giftCampaignDetails?.giftOptions?.length && (
        <div className={styles.giftCampaignContainer}>
          <div className={styles.titleContainer}>
            <div className={styles.giftCampaignDetails}>{giftCampaignDetails.name}</div>
            <div className={styles.giftCampaignDescription}>{giftCampaignDetails.description}</div>
          </div>
          <div className={styles.giftCampaignDetails}>
            <GiftCampaignOptionsList options={giftCampaignDetails?.giftOptions} onChooseOption={handleChooseOption} />
          </div>
        </div>
      )}

      {choosenOption && !isRecipientSaved && (
        <div className={styles.giftCampaignContainer}>
          <div className={styles.titleContainer}>
            <p>Flott val!</p>
          </div>
          <div className={styles.giftCampaignOptionContainer}>
            <GiftCampaignChoosenOptionForm
              giftCampaignOption={choosenOption}
              onSaveGiftRecipient={handleSaveGiftRecipient}
              onResetGiftRecipient={handleResetGiftRecipient}
            />
          </div>
        </div>
      )}

      {isRecipientSaved && (
        <div className={styles.giftCampaignContainer}>
          <div className={styles.titleContainer}>
            <p>{t('general.thank_you')}</p>
            <div className={styles.descriptionContainer}>{t('gift_campaign.email_sent')}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GiftCampaignPage;
