import ApiActions from 'api_actions';
import { formatCancellationPoliciesOptions } from './utils/hotel_property_policies_format';

export const SET_PROPERTY_CANCELLATION_POLICIES_LOADING = 'SET_PROPERTY_CANCELLATION_POLICIES_LOADING';
export const SET_PROPERTY_CANCELLATION_POLICIES = 'SET_PROPERTY_CANCELLATION_POLICIES';
export const SET_PROPERTY_CANCELLATION_POLICY_LOADING = 'SET_PROPERTY_CANCELLATION_POLICY_LOADING';
export const SET_PROPERTY_CANCELLATION_POLICY = 'SET_PROPERTY_CANCELLATION_POLICY';
export const SET_CANCELLATION_POLICY_UPDATE_LOADING = 'SET_CANCELLATION_POLICY_UPDATE_LOADING';
export const SET_REMOVE_CANCELLATION_POLICY_LOADING = 'SET_REMOVE_CANCELLATION_POLICY_LOADING';
export const REMOVE_SELECTED_CANCELLATION_POLICY = 'REMOVE_SELECTED_CANCELLATION_POLICY';

export const SET_PROPERTY_POLICIES_LOADING = 'SET_PROPERTY_POLICIES_LOADING';
export const SET_PROPERTY_POLICIES = 'SET_PROPERTY_POLICIES';
export const SET_PROPERTY_POLICY_LOADING = 'SET_PROPERTY_POLICY_LOADING';
export const SET_PROPERTY_POLICY = 'SET_PROPERTY_POLICY';
export const SET_PROPERTY_POLICY_UPDATE_LOADING = 'SET_PROPERTY_POLICY_UPDATE_LOADING';
export const SET_REMOVE_POLICY_LOADING = 'SET_REMOVE_POLICY_LOADING';
export const REMOVE_SELECTED_POLICY = 'REMOVE_SELECTED_POLICY';
export const RESET_PROPERTY_POLICIES = 'RESET_PROPERTY_POLICIES';

const setPropertyCancellationPoliciesLoading = (dispatch, params) => {
  return dispatch({ type: SET_PROPERTY_CANCELLATION_POLICIES_LOADING });
};

const loadPropertyCancellationPolicies = async (dispatch, params) => {
  setPropertyCancellationPoliciesLoading(dispatch, params);
  try {
    const data = await ApiActions.getPropertyCancellationPolicies(params);
    const formattedData = formatCancellationPoliciesOptions(data);
    setPropertyCancellationPolicies(dispatch, { data: formattedData });
  } catch (e) {
    throw e;
  }
};

const setPropertyCancellationPolicies = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_CANCELLATION_POLICIES, payload });
};

const setPropertyCancellationPolicyLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_CANCELLATION_POLICY_LOADING });
};

const setPropertyCancellationPolicy = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_CANCELLATION_POLICY, payload });
};

const loadPropertyCancellationPolicy = async (dispatch, params) => {
  setPropertyCancellationPolicyLoading(dispatch);

  const data = await ApiActions.getPropertyCancellationPolicy(params);
  setPropertyCancellationPolicy(dispatch, data);
};

const createCancellationPolicy = async (dispatch, params) => {
  setUpdateCancellationPolicyLoading(dispatch);

  try {
    await ApiActions.createPropertyCancellationPolicy({ propertyId: params.propertyId, cancellationPolicy: params });
    setUpdateCancellationPolicyLoading(dispatch, false);
    await loadPropertyCancellationPolicies(dispatch, { propertyId: params.propertyId });
  } catch (e) {
    setUpdateCancellationPolicyLoading(dispatch, false);
    throw e;
  }
};

const setUpdateCancellationPolicyLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_CANCELLATION_POLICY_UPDATE_LOADING, params });
};

const updateCancellationPolicy = async (dispatch, params) => {
  setUpdateCancellationPolicyLoading(dispatch);

  try {
    await ApiActions.updatePropertyCancellationPolicy({ propertyId: params.propertyId, cancellationPolicy: params });
    setUpdateCancellationPolicyLoading(dispatch, false);
    await loadPropertyCancellationPolicies(dispatch, { propertyId: params.propertyId });
  } catch (e) {
    setUpdateCancellationPolicyLoading(dispatch, false);
    throw e;
  }
};

const setRemoveCancellationPolicyLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_REMOVE_CANCELLATION_POLICY_LOADING, params });
};

const removeCancellationPolicy = async (dispatch, params) => {
  setRemoveCancellationPolicyLoading(dispatch);

  try {
    await ApiActions.deletePropertyCancellationPolicy(params);
    setRemoveCancellationPolicyLoading(dispatch, false);
    await loadPropertyCancellationPolicies(dispatch, { propertyId: params.propertyId });
  } catch (e) {
    setRemoveCancellationPolicyLoading(dispatch, false);
    throw e;
  }
};

const clearSelectedCancellationPolicy = (dispatch, payload) => {
  return dispatch({ type: REMOVE_SELECTED_CANCELLATION_POLICY, payload });
};

const setPropertyPoliciesLoading = (dispatch, params) => {
  return dispatch({ type: SET_PROPERTY_POLICIES_LOADING });
};

const loadPropertyPolicies = async (dispatch, params) => {
  setPropertyPoliciesLoading(dispatch, params);
  try {
    const data = await ApiActions.getPropertyPolicies(params);
    setPropertyPolicies(dispatch, { data });
  } catch (e) {
    throw e;
  }
};

const setPropertyPolicies = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_POLICIES, payload });
};

const setPropertyPolicyLoading = dispatch => {
  return dispatch({ type: SET_PROPERTY_POLICY_LOADING });
};

const setPropertyPolicy = (dispatch, payload) => {
  return dispatch({ type: SET_PROPERTY_POLICY, payload });
};

const loadPropertyPolicy = async (dispatch, params) => {
  setPropertyPolicyLoading(dispatch);

  const data = await ApiActions.getPropertyPolicy(params);
  setPropertyPolicy(dispatch, data);
};

const createPropertyPolicy = async (dispatch, params) => {
  setUpdatePropertyPolicyLoading(dispatch);

  try {
    await ApiActions.createPropertyPolicy({ propertyId: params.propertyId, hotelPolicy: params });
    setUpdatePropertyPolicyLoading(dispatch, false);
    await loadPropertyPolicies(dispatch, { propertyId: params.propertyId });
  } catch (e) {
    setUpdatePropertyPolicyLoading(dispatch, false);
    throw e;
  }
};

const setUpdatePropertyPolicyLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_PROPERTY_POLICY_UPDATE_LOADING, params });
};

const updatePropertyPolicy = async (dispatch, params) => {
  setUpdatePropertyPolicyLoading(dispatch);

  try {
    await ApiActions.updatePropertyPolicy({ propertyId: params.propertyId, hotelPolicy: params });
    setUpdatePropertyPolicyLoading(dispatch, false);
    await loadPropertyPolicies(dispatch, { propertyId: params.propertyId });
  } catch (e) {
    setUpdatePropertyPolicyLoading(dispatch, false);
    throw e;
  }
};

const setRemovePropertyPolicyLoading = (dispatch, params = true) => {
  return dispatch({ type: SET_REMOVE_POLICY_LOADING, params });
};

const removePropertyPolicy = async (dispatch, params) => {
  setRemovePropertyPolicyLoading(dispatch);

  try {
    await ApiActions.deletePropertyPolicy(params);
    setRemovePropertyPolicyLoading(dispatch, false);
    await loadPropertyPolicies(dispatch, params.propertyId);
  } catch (e) {
    setRemovePropertyPolicyLoading(dispatch, false);
    throw e;
  }
};

const clearSelectedPropertyPolicy = (dispatch, payload) => {
  return dispatch({ type: REMOVE_SELECTED_POLICY, payload });
};

const resetPropertyPolicies = (dispatch) => {
  return dispatch({ type: RESET_PROPERTY_POLICIES });
}

export const actions = {
  loadPropertyCancellationPolicies,
  loadPropertyPolicies,

  loadPropertyCancellationPolicy,
  clearSelectedCancellationPolicy,
  createCancellationPolicy,
  updateCancellationPolicy,
  removeCancellationPolicy,

  loadPropertyPolicy,
  clearSelectedPropertyPolicy,
  createPropertyPolicy,
  updatePropertyPolicy,
  removePropertyPolicy,
  resetPropertyPolicies,
};
